import { useEffect, useState } from "react";
import LoginNavBar from "../ui/login-nav_bar/LoginNavBar";
import { loginRequest, loginUserInfoRequest } from "../../data_featching/login";
import { useNavigate, useLocation, Link } from "react-router-dom";
import useAuth from "../../hooks/UseAuth.jsx";
import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
const googleClientId = import.meta.env.VITE_REACT_APP_GOOGLE_CLIENT_ID;
const linkedInClientId = import.meta.env.VITE_REACT_APP_LinkedIn_CLIENT_ID;

import "./Login.css";
import {
  fetchUserProfile,
  GoogleLoginWithBE,
} from "../../data_featching/GoogleLogin.js";
import { useTranslation } from "react-i18next";
import {
  LinkedInAccessTokenExchange,
  linkedInLoginWithBE,
} from "../../data_featching/LinkedInLogin.js";

const Login = () => {
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <LoginInto />
    </GoogleOAuthProvider>
  );
};
const LoginInto = () => {
  const { t } = useTranslation("login");
  const [loginError, setLoginError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginInfo, setLoginInfo] = useState({
    email_phone: "",
    password: "",
  });
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [profileType, setProfileType] = useState("");
  const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);
  const [loginWithNafath, setLoginWithNafath] = useState(false);
  const [loggedWithNafath, setLoggedWithNafath] = useState(false);
  const [idNumber, setIdNumber] = useState("");
  const [LinkedInAccessToken, setLinkedInAccessToken] = useState(null);
  const [LinkedInAuthorizationCode, setLinkedInAuthorizationCodeode] =
    useState(null);
  /*store Token */
  const { setAuth } = useAuth();
  /*social login Google Login*/
  const googleLogin = useGoogleLogin({
    // flow: "auth-code", // comment this to get Access token

    onSuccess: async (codeResponse) => {
      console.log("codeResponse", codeResponse);

      // Call the function once you get the access token
      const result = await fetchUserProfile(codeResponse.access_token);
      console.log("google Login result", result);
      if (result.status == 200 || result.status == 201) {
        //you should handel to get token from the BE
        const dataForBe = {
          provider: "google",
          access_token: codeResponse.access_token,
          profile: CLIENT_LOGIN,
        };
        const backEndResult = await GoogleLoginWithBE(dataForBe);
        console.log(" Login With BE after google Login", backEndResult);

        if (backEndResult.status == 200 || backEndResult.status == 201) {
          const { token: BE_Token } = backEndResult.data.data;
          localStorage.setItem("authToken", BE_Token); // Store token in localStorage
          navigate("/");
        }
      }
    },
    onError: (errorResponse) => console.log("Error response ", errorResponse),
  });
  /*END END social login Google Login END END*/
  /*Linked in Login */
  /*call LinkedIn to get Authorization code */
  const CLIENT_ID = linkedInClientId;
  // const REDIRECT_URI = "http://localhost:5173/login";// for development
  const REDIRECT_URI = `${import.meta.env.VITE_CUSTOMER_URL}/login`; //for production
  const state = "760iz0bjh9gy71asfFqa";
  const SCOPE = "openid profile email "; // Adjust scopes as needed
  const code_challenge = "mb38T1LdP3cjL_0Ms8_J2j1gmP7ugHRn5Uufxri4tEg";
  const authorizationUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(
    REDIRECT_URI
  )}&scope=${encodeURIComponent(
    SCOPE
  )} &state=${state}&code_challenge=${code_challenge}&code_challenge_method="S256" `;
  const handelLinkedINLogin = () => {
    window.location.href = authorizationUrl;
  };
  console.log("REDIRECT_URI", REDIRECT_URI);
  /*this side effect to handle LinkedIn redirection to the Login  */
  useEffect(() => {
    // Get the query parameters from the URL
    const searchParams = new URLSearchParams(window.location.search);
    console.log("searchParams", searchParams);
    // Check if there is a "code" parameter in the URL
    const code = searchParams.get("code");
    if (code) {
      setLinkedInAuthorizationCodeode(code);
      console.log("authorizationCode", code);
    }
    console.log("authorizationCode", LinkedInAuthorizationCode);

    // Optionally handle errors if they exist
    const error = searchParams.get("error");
    if (error) {
      console.error(
        "Error during authorization:",
        searchParams.get("error_description")
      );
    }
  }, []);
  /* Once you have the LinkedIn Authorization Code, you can exchange it for an access token*/
  useEffect(() => {
    const getAccessToken = async () => {
      const response = await LinkedInAccessTokenExchange(
        LinkedInAuthorizationCode
      );
      if (response.status == 200 || response.status == 201) {
        console.log("getAccessToken:response", response);
        console.log("LinkedInAccessToken", response?.data?.data?.access_token);
        setLinkedInAccessToken(response?.data?.data?.access_token);
      }
      if (response.status !== 200 || response.status !== 201) {
        setLoginError(response?.data?.message); //show social login error
      }
      console.log("LinkedInAuthorizationCode Code:", LinkedInAuthorizationCode);
    };
    // Now you can use the authorization code to get the access token
    if (LinkedInAuthorizationCode) {
      getAccessToken(); // Call your function to exchange the code for an access token
    }
  }, [LinkedInAuthorizationCode]);
  /*get token from BE after get LinkedIn access token */
  useEffect(() => {
    const LoginWithLinkedIn = async () => {
      const dataForBe = {
        provider: "linkedin-openid",
        access_token: LinkedInAccessToken,
        profile: CLIENT_LOGIN,
      };
      const accessTokenResultFromBE = await linkedInLoginWithBE(dataForBe);
      if (
        accessTokenResultFromBE.status == 200 ||
        accessTokenResultFromBE.status == 201
      ) {
        const { token: BE_Token } = accessTokenResultFromBE.data.data;
        localStorage.setItem("authToken", BE_Token); // Store token in localStorage
        navigate("/");
      }
    };
    if (LinkedInAccessToken) LoginWithLinkedIn();
  }, [LinkedInAccessToken]);
  /*Navigattion */
  const navigate = useNavigate();

  /*location  */
  const location = useLocation();
  const from = location?.state?.from?.pathName || "/";
  console.log("location", location);
  /*to tell Api that the user is a client > person */
  /*later we will get the real profile type up on Api request */
  const CLIENT_LOGIN = "personal";

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  console.log("loginInfo", loginInfo);
  console.log("profileType", profileType);
  /*send lofin info to Api */
  async function handleLogin() {
    const userLoginData = new FormData();
    userLoginData.append("identifier", loginInfo.email_phone);
    userLoginData.append("password", loginInfo.password);
    userLoginData.append(
      "profile",
      CLIENT_LOGIN
    ); /*either personal or busnisses*/

    const result = await loginRequest(userLoginData);
    if (result.status == 200 || result.status == 201) {
      console.log("result", result);
      console.log("token", result.data?.data?.token);
      const token = result.data?.data?.token;

      const userInfoResult = await loginUserInfoRequest(token);
      if (userInfoResult.status == 200 || userInfoResult.status == 201) {
        console.log("userInfoResult", userInfoResult);
        console.log(
          "has bussinss profile",
          userInfoResult?.data?.data?.has_business_profile
        );
        localStorage.setItem("authToken", token); // Store token in localStorage
        setAuth(() => {
          setProfileType(() => {
            return userInfoResult?.data?.data?.has_business_profile;
          });
          console.log("auth", {
            loginInfo,
            profileType: userInfoResult?.data?.data?.has_business_profile,
            token,
          });
          return {
            loginInfo,
            profileType: userInfoResult?.data?.data?.has_business_profile,
            token,
          };
        });

        /* profile=={has_business_profile} */
        // !profileType ? navigate("/login-home") : navigate("/community");
        navigate(from, { replace: true });
      } else {
        console.log("request user info request has fail");
      }

      // setAuth({ token });
    } else if (result.status !== 200 || result.status !== 201) {
      const Errors = result.data.errors;
      if (Errors?.password) {
        setLoginError(Errors?.password[0]);
        console.log("Login error", Errors);
      }
      if (Errors?.identifier) {
        setLoginError(Errors?.identifier[0]);
      }

      // setLoginError(Errors.pass);
    }
  }
  return (
    <>
      <div className="Login_container">
        <LoginNavBar
          loginNav={true}
          showBurgerLoginMenu={showBurgerLoginMenu}
          setShowBurgerLoginMenu={setShowBurgerLoginMenu}
        />

        {!showBurgerLoginMenu && (
          <>
            <div
              className={`px-3 min-h-[calc(100vh-8rem)]  max-h-fit ${
                loginWithNafath ? "" : "pt-16 pb-8"
              }  md:px-[3.5rem] lg:px-[4.375rem] xl:px-0 Login-container`}
            >
              {/* <div className="hidden chat_plus md:block">
                <img src="/Chat_plus.png" alt="Chat_plus" />
              </div> */}

              <div
                className={`${
                  loginWithNafath
                    ? "hidden"
                    : "block Login_form_container sm:mx-0 xl:mx-[7rem]  sm:w-full xl:w-[36rem]"
                }`}
              >
                <h1 className="login_header">{t("login to your account")}</h1>

                <form action="Login" className="Login_form">
                  <div className="Email_phone">
                    <label htmlFor="" className="Email_Login_number">
                      {t("email or phone")}
                    </label>
                    <input
                      type="text"
                      className="Email_input_number"
                      value={loginInfo.email_phone}
                      onChange={(e) => {
                        setLoginInfo((prev) => {
                          return {
                            ...prev,
                            email_phone: e.target.value.trim(),
                          };
                        });
                        setLoginError("");
                      }}
                    />
                  </div>
                  <div className="Email_phone_password">
                    <label htmlFor="" className="Email_Login_number">
                      {t("password")}
                    </label>
                    <div className="Email_input_number">
                      <img src="/lock-closed-black.png" alt="" />
                      <input
                        value={loginInfo.password}
                        onChange={(e) => {
                          setLoginInfo((prev) => {
                            return { ...prev, password: e.target.value.trim() };
                          });
                          setLoginError("");
                        }}
                        type={showPassword ? "text" : "password"}
                        className="input_field"
                      />
                      <img
                        src={!showPassword ? "/eye-off.png" : "/Eye.png"}
                        alt="eye-off"
                        onClick={() => setShowPassword((prev) => !prev)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>

                    <Link className="forget_password" to={"/forget-password"}>
                      <span>{t("forgot password")}</span>
                    </Link>
                  </div>
                  {loginError !== "" ? (
                    <div className="Error-container">
                      <div style={{ color: "red" }}>{loginError}</div>
                    </div>
                  ) : null}
                </form>

                {windowSize < 600 ? (
                  <div className="flex flex-col w-full gap-4">
                    <button
                      onClick={handleLogin}
                      //      onSubmit={(e) => {
                      //   e.preventDefault();
                      // }}
                      className="submit_form"
                      style={
                        loginInfo.email_phone !== "" &&
                        loginInfo.password !== ""
                          ? { backgroundColor: "var(--Primary2, #009e1e)" }
                          : null
                      }
                    >
                      <span>{t("login")}</span>
                      <img
                        src="/lock-closed-white.png
            "
                        alt="lock-closed img"
                      />
                    </button>
                    <div className="flex gap-4 social_buttons W-full">
                      <div className="flex flex-col w-full gap-4">
                        <div className="flex gap-4 social_buttons W-full">
                          <button
                            onClick={handelLinkedINLogin}
                            className="submit_form_social w-50% back_mobile_view"
                          >
                            <img
                              src={
                                windowSize
                                  ? "/Logo_LIked.png"
                                  : "/LinkedIn_Logo.png"
                              }
                              alt="lock-closed img"
                            />
                          </button>
                          <button
                            onClick={googleLogin}
                            className="submit_form_social w-50%"
                          >
                            <img
                              src="/Google_Logo.png
            "
                              alt="lock-closed img"
                            />
                          </button>
                        </div>
                        <div className="flex w-full gap-4 social_buttons">
                          <button
                            onClick={() => {
                              setLoginWithNafath(true);
                            }}
                            className="flex w-full gap-2 submit_form_social"
                          >
                            <span className="text-base font-medium">
                              {t("login with")}
                            </span>
                            <div className="w-[36px] h-[20px] mt-[-2px]">
                              <img
                                src="/nafath_logo.png"
                                alt="lock-closed img"
                                className="object-cover w-full h-full"
                              />
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col w-full gap-4">
                    <button
                      onClick={handleLogin}
                      //      onSubmit={(e) => {
                      //   e.preventDefault();
                      // }}
                      className="submit_form"
                      style={
                        loginInfo.email_phone !== "" &&
                        loginInfo.password !== ""
                          ? { backgroundColor: "var(--Primary2, #009e1e)" }
                          : null
                      }
                    >
                      <span>{t("login")}</span>
                      <img
                        src="/lock-closed-white.png
            "
                        alt="lock-closed img"
                      />
                    </button>
                    <div className="flex w-full gap-4 social_buttons">
                      <button
                        className="submit_form_social w-fit"
                        onClick={handelLinkedINLogin}
                      >
                        <img
                          src="/LinkedIn_Logo.png
            "
                          alt="lock-closed img"
                        />
                        <span>{t("login with linkedin")}</span>
                      </button>
                      <button
                        className="submit_form_social w-fit"
                        onClick={googleLogin}
                      >
                        <img
                          src="/Google_Logo.png
            "
                          alt="lock-closed img"
                        />
                        <span>{t("login with google")}</span>
                      </button>
                    </div>
                    {/* login with nafath */}
                    <div className="flex w-full gap-4 social_buttons">
                      <button
                        onClick={() => {
                          setLoginWithNafath(true);
                        }}
                        className="flex w-full gap-2 submit_form_social"
                      >
                        <span className="text-base font-medium">
                          {t("login with")}
                        </span>
                        <div className="w-[36px] h-[20px] mt-[-2px]">
                          <img
                            src="/nafath_logo.png"
                            alt="lock-closed img"
                            className="object-cover w-full h-full"
                          />
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {!loginWithNafath && !loggedWithNafath && (
                <div className="mt-6 text-center right_reserved_hint_login xl:ltr:ml-auto xl:rtl:mr-auto xl:ltr:pr-28 xl:rtl:pl-28">
                  {t("copy_rights")}
                </div>
              )}
              {loginWithNafath && !loggedWithNafath && (
                <div className="bg-white p-5 shadow-sm w-[34.438rem] border-[0.188rem] rounded-2xl border-[#C8CACC] my-[8%] sm:w-[28.75rem] sm:mx-0 xl:mx-[7rem]">
                  <form>
                    <h1 className="text-[1.75rem] font-bold text-center w-full mb-8">
                      {t("login with nafath")}
                    </h1>
                    <div className="mb-8">
                      <label
                        htmlFor="id-number"
                        className="block mb-2 text-base font-medium"
                      >
                        {t("id number")}
                      </label>
                      <input
                        value={idNumber}
                        onChange={(e) => {
                          setIdNumber(e.target.value);
                        }}
                        type="number"
                        id="id-number"
                        placeholder={t("id placeholder")}
                        className="px-3 py-2 text-[#9CA3AF] font-normal text-sm outline-none border w-full rounded-lg border-[#6B7280]"
                      />
                    </div>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        if (idNumber) {
                          setLoggedWithNafath(true);
                        }
                      }}
                      className="submit_form"
                      style={
                        idNumber !== ""
                          ? { backgroundColor: "var(--Primary2, #009e1e)" }
                          : null
                      }
                    >
                      <span>{t("login")}</span>
                      <img
                        src="/lock-closed-white.png
            "
                        alt="lock-closed img"
                      />
                    </button>
                  </form>
                </div>
              )}
              {loggedWithNafath && (
                <div className="bg-white my-[8%] sm:mx-0 xl:mx-[7rem] p-5 shadow-sm w-[34.438rem] border-[0.188rem] rounded-2xl border-[#C8CACC] sm:w-[28.75rem]">
                  <h1 className="text-[1.75rem] font-bold text-center w-full mb-4">
                    {t("nafath verify")}
                  </h1>
                  <p className="text-[#6B7280] mb-8 font-normal text-lg text-center">
                    {t("plz open app")}
                  </p>
                  <p className="px-6 text-center w-[5.188rem] py-4 bg-[#B8E9B7] rounded-lg mx-auto font-medium text-2xl">
                    28
                  </p>
                </div>
              )}
              <div className="w-full text-center right_reserver_mobile_view">
                {t("copy_rights")}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Login;
