import { useState, useEffect, useContext } from "react";
import "react-day-picker/style.css";
import "./Plans.css";
// import { convertTo24HourTime } from "../../../helpers/convertInto24Hour";
// import { postInitialBookingData } from "../../../data_featching/booking";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, startOfMonth } from "date-fns";
import { BookingContext } from "../../../context/BookingProvider";
import { arSA } from "react-day-picker/locale";

import { useTranslation } from "react-i18next";
// import LanguageContext from "../../../context/LanguageProvider";

const AnnualPlan = ({
  spaceDetails,
  onBack,
  setCurrentStep,
  initialBookingData,
  updateInitialBookingData,
  setShiftType,
  updateBookedShift,
  updateInstantBooking,
  handlePlanID,
}) => {
  const {
    AnnualselectedMonth,
    setAnnualSelectedMonth,
    selectedAnnualOption,
    setSelectedAnnualOption,
    AnnualshiftID,
    setAnnualShiftID,
  } = useContext(BookingContext);
  const [bookingStartTime, setBookingStartTime] = useState(null);
  const [bookingEndTime, setBookingEndTime] = useState(null);
  const { t,i18n } = useTranslation("bookings");
  // const { language } = useContext(LanguageContext);

  const planDetails = spaceDetails.plans.find(
    (plan) => plan?.name?.key === "annually"
  );

  const getCheckinDay = (AnnualselectedMonth) => {
    const now = new Date();
    const isCurrentMonth =
      AnnualselectedMonth?.getMonth() === now.getMonth() &&
      AnnualselectedMonth?.getFullYear() === now.getFullYear();

    if (isCurrentMonth) {
      const tomorrow = new Date();
      tomorrow.setDate(now.getDate() + 1);
      return tomorrow.toLocaleDateString("en-CA");
    } else {
      const startOfAnnualSelectedMonth = startOfMonth(AnnualselectedMonth);
      return startOfAnnualSelectedMonth.toLocaleDateString("en-CA");
    }
  };

  const checkinDay = getCheckinDay(AnnualselectedMonth);
  console.log("checkinDay", checkinDay);
  const planId = planDetails.id;
  console.log("planId", planId);
  handlePlanID(planId);

  function addOneYearToDate(dateString) {
    const date = new Date(dateString);
    date.setFullYear(date.getFullYear() + 1);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const checkoutDay = addOneYearToDate(checkinDay);
  console.log("checkoutDay", checkoutDay);

  function convertTime(timeString) {
    if (!timeString) return null;
    const [hours, minutes] = timeString.split(":");
    let hour = parseInt(hours);
    const period = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    return `${hour}:${minutes.padStart(2, "0")} ${period}`;
  }

  useEffect(() => {
    const selectedShift = planDetails.shifts.find(
      (shift) => shift?.name?.key === selectedAnnualOption
    );

    if (selectedShift) {
      updateBookedShift(selectedShift);
      setAnnualShiftID(selectedShift.id);
      setBookingStartTime(selectedShift.start_time);
      setBookingEndTime(selectedShift.end_time);
      setShiftType(selectedShift.name.label);
    }
  }, [selectedAnnualOption]);

  const handleDailyShiftChange = (event) => {
    setSelectedAnnualOption(event.target.value);
  };

  const startTime = `${checkinDay} ${bookingStartTime}`;
  console.log("startDate", startTime);
  const endTime = `${checkoutDay} ${bookingEndTime}`;
  console.log("endDate", endTime);
  console.log("AnnualshiftID", AnnualshiftID);

  const selectedBookingData = {
    shift_id: AnnualshiftID,
    start_at: startTime,
    end_at: endTime,
  };
  console.log("selectedBookingData", selectedBookingData);

  useEffect(() => {
    updateInitialBookingData(selectedBookingData);
  }, [
    AnnualshiftID,
    checkinDay,
    checkoutDay,
    bookingStartTime,
    bookingEndTime,
  ]);
  console.log("initialBookingData", initialBookingData);

  const isReviewButtonDisabled = () => {
    return (
      AnnualshiftID === null ||
      AnnualshiftID === undefined ||
      checkinDay === null ||
      checkinDay === undefined ||
      checkoutDay === null ||
      checkoutDay === undefined ||
      bookingStartTime === null ||
      bookingStartTime === undefined ||
      bookingEndTime === null ||
      bookingEndTime === undefined
    );
  };

  const handleSubmit = async () => {
    updateInstantBooking(planDetails?.is_instant_booking);
    setShiftType(`Year`);
    setCurrentStep(3);
  };
  const minDate = startOfMonth(addDays(new Date(), 1));

  return (
    <div>
      <div className="flex w-full gap-2 mt-5 lg:flex-row sm:flex-col">
        <div className="w-full sm:text-center lg:text-left sm:m-auto lg:m-0">
          <h1 className="mb-2 text-lg font-medium ltr:text-left rtl:text-right">
            {t("Check in Date")}
          </h1>

          <DatePicker
            selected={AnnualselectedMonth}
            onChange={(date) => setAnnualSelectedMonth(date)}
            locale={i18n.language === "ar" ? arSA : undefined}
            onYearChange={(date) => {
              // if larger than minDate
              if (date > minDate) {
                setAnnualSelectedMonth(date);
              } else {
                setAnnualSelectedMonth(minDate);
              }
            }}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            inline
            minDate={minDate}
            calendarClassName="w-[350px] h-[300px] text-lg"
            dayClassName={() => "hover:bg-gray-200 text-center p-4"}
            monthClassName={() =>
              "text-center py-2 px-5 border border-gray-300"
            }
          />
        </div>

        <div className="w-full daily-plan">
          <p className="text-[#000021] font-medium text-lg">
            {t("Please Select Shift")}
          </p>
          <div className="mt-5 checkbox-group">
            {planDetails?.shifts.map((shift, index) => {
              if (!shift?.is_active) return null;

              return (
                <label
                  key={index}
                  className={`checkbox-container font-[400] text-[#6B7280]`}
                >
                  <input
                    type="checkbox"
                    value={shift?.name?.key}
                    checked={selectedAnnualOption === shift?.name?.key}
                    onChange={handleDailyShiftChange}
                    disabled={!shift?.is_active}
                  />
                  <span className="checkmark ltr:mr-2 rtl:ml-2"></span>
                  {shift?.name?.label}: {t("from")}{" "}
                  {convertTime(shift.start_time)} {`${t("to")} `}
                  {convertTime(shift.end_time)}
                </label>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-4 mt-5">
        <button
          className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow-sm"
          onClick={onBack}
        >
           {i18n.language === "en" ? (
            <svg
              className="mt-[5px]"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 11L2 6L7 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M12 11L7 6L12 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          ) : (
            <svg
              className="mt-[5px]"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 11L11 6L6 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M1 11L6 6L1 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          )}
          {t("Back")}
        </button>
        <button
          className="bg-[#009E1E] px-4 py-2 text-white rounded-lg"
          onClick={handleSubmit}
          disabled={isReviewButtonDisabled()}
        >
          {t("Select Resources")}
        </button>
      </div>
    </div>
  );
};
export default AnnualPlan;
