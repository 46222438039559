import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ConfirmModal, IconModal } from "../ui/Models";
import PaginationUI from "../ui/pagination/pagination";
import { getPageList } from "../ui/pagination/pagingList.js";
import { Eye, Edit2, Trash2 } from "lucide-react";
import {
  toggleCompany,
  fetchMyCompanies,
  deleteCompany,
} from "../../data_featching/community";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../context/LanguageProvider";
import StatusBar from "../ui/status-bar/StatusBar";
import styles from "./Companies.module.css";
import { Spinner } from "../ui/Spinner.jsx";

const Companies = () => {
  const token = localStorage.getItem("authToken");
  const { t } = useTranslation("profile");
  const { language } = useContext(LanguageContext);
  const [deletedCompany, setDeletedCompany] = useState(null);
  const [pagination, setPagination] = useState({});
  const [companies, setCompanies] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState({
    isActive: false,
    message: "",
    currentStatus: 0,
  });
  const toggleListing = (uuid) => {
    setStatusMessage({
      isActive: true,
      message: t("Company toggling now"),
      currentStatus: 1,
    });

    toggleCompany(token, uuid)
      .then((res) => {
        console.log("Company toggled >>>>>> ", res.data);
        setStatusMessage({
          isActive: true,
          message: t("Company toggled successfully"),
          currentStatus: 3,
        });
        setCompanies(
          companies.map((company) =>
            company.uuid === uuid
              ? { ...company, active: !company.active }
              : company
          )
        );
      })
      .catch((error) => {
        console.log("Company toggling error >>>>>> ", error);
        setStatusMessage({
          isActive: true,
          message: t("Company toggling failed"),
          currentStatus: 2,
        });
      });
    setTimeout(() => {
      setStatusMessage({
        isActive: false,
        message: "",
        currentStatus: 0,
      });
    }, 3000);
  };
  const getData = async (page) => {
    setLoading(true);
    const response = await fetchMyCompanies(token, page, language);
    if (response?.status == 200 || response?.status == 201) {
      setLoading(false);
      setCompanies(response.data?.data || []);
      const totalPageNo = response?.meta?.last_page;
      const currentPage = response?.meta?.current_page;
      const pagesList = getPageList(totalPageNo, currentPage);
      setPagination({
        page: currentPage,
        pagesCount: totalPageNo,
        pagesList,
      });
    } else {
      setLoading(false);
      console.log("Error fetching companies >>>>>> ", response);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [isArchivingModalOpen, setIsArchivingModalOpen] = useState(false);
  const [isArchivedModalOpen, setIsArchivedModalOpen] = useState(false);

  const handleDelete = (uuid) => {
    deleteCompany(token, uuid).then((res) => {
      console.log("Company deleted >>>>>> ", res.data);
      setIsArchivedModalOpen(true);
      getData();
      setDeletedCompany(null);
    });
  };

  return (
    <div className="w-full h-full p-6">
      {statusMessage.isActive && (
        <StatusBar
          message={statusMessage.message}
          statusFlag={statusMessage.currentStatus}
        />
      )}
      {isArchivingModalOpen && (
        <ConfirmModal
          isOpen={isArchivingModalOpen}
          onClose={() => setIsArchivingModalOpen(false)}
          onConfirm={() => {
            setIsArchivingModalOpen(false);
            handleDelete(deletedCompany.uuid);
          }}
          title={t("Archiving Company")}
          message={`${t(`Are you sure you want to archive Company`)}
          "${deletedCompany.name}"`}
          confirmText={t("Yes,Archive Company")}
          icon={deletedCompany.logo?.url}
          name={deletedCompany.name}
        />
      )}
      {isArchivedModalOpen && (
        <IconModal
          isOpen={isArchivedModalOpen}
          onClose={() => setIsArchivedModalOpen(false)}
          icon="/build_info.svg"
          message={t(`Company has been archived successfully`)}
          actionText={t("Undo")}
          onAction={() => setIsArchivedModalOpen(false)}
          title={t("Archive Company")}
        />
      )}

      <h3 className="mb-6 text-xl font-semibold">{t("my companies")}</h3>
      <div className="space-y-4 overflow-y-auto max-h-[500px]">
        {companies.length === 0 && Loading && <Spinner />}
        {companies.map((company) => (
          <div
            key={company.uuid}
            className="flex items-center justify-between p-4 bg-white border-b border-gray-200 last:border-b-0"
          >
            <div className="flex items-center gap-4">
              <img
                src={company.logo?.url}
                alt={company.name}
                className="w-12 h-12 rounded-full"
              />
              <span className="font-medium">{company.name}</span>
            </div>
            <div className="flex items-center gap-4">
              <Link
                to={`/my-companies/view/${company.uuid}`}
                className="flex items-center gap-1 text-blue-500 hover:text-blue-700"
              >
                {t("view")}
                <Eye size={18} />
              </Link>
              <Link
                to={`/my-companies/edit/${company.uuid}`}
                className="flex items-center gap-1 text-gray-500 hover:text-gray-700"
              >
                {t("edit")}
                <Edit2 size={16} />
              </Link>
              <button
                onClick={() => {
                  setDeletedCompany(company);
                  setIsArchivingModalOpen(true);
                }}
                className="flex items-center gap-1 text-red-500 hover:text-red-700"
              >
                {t("delete")}
                <Trash2 size={18} />
              </button>

              <label className={styles.switch}>
                <input
                  type="checkbox"
                  checked={company.active}
                  onChange={() => toggleListing(company.uuid)}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
          </div>
        ))}

        <div className="flex justify-center">
          <PaginationUI
            pagination={pagination}
            changePageHandler={setPagination}
            fetchDataHandler={getData}
            currentLanguage={"en"}
          />
        </div>
      </div>
      <div className="flex justify-end mt-8">
        <Link
          to="/my-companies/create"
          className="px-5 py-2 text-sm font-medium text-white bg-green-600 rounded-lg hover:bg-green-700"
        >
          {t("add company")}
        </Link>
      </div>
    </div>
  );
};

export default Companies;
