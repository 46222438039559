import PropTypes from "prop-types";
import { StarIcon } from "lucide-react";

const ReviewCard = ({ avatar, name, title, rating, testimonial }) => {
  return (
    <div className="p-8 min-w-[24rem] bg-white rounded-lg shadow-md">
      <div className="flex items-center w-full gap-3 mb-5">
        <div className="w-16 h-16 overflow-hidden rounded-full">
          <img
            className="object-cover w-full h-full"
            src={avatar}
            alt="Person Avatar"
          />
        </div>
        <div>
          <p className="text-sm font-medium">{name}</p>
          <p className="text-sm font-normal text-gray-400">{title}</p>
          <div className="flex gap-1">
            {Array.from({ length: rating }).map((_, index) => (
              <StarIcon
                key={index}
                size={16}
                className="text-yellow-400"
                fill="currentColor"
              />
            ))}
          </div>
        </div>
      </div>
      <p className="text-base font-normal">{`"${testimonial}"`}</p>
    </div>
  );
};

ReviewCard.propTypes = {
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  testimonial: PropTypes.string.isRequired,
};

export default ReviewCard;
