import { useState, useEffect } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useTranslation } from "react-i18next";

const SortByDropdown = ({ setSelectedOption, selectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("explore");

  const options = ["top rated", "lowest price", "nearest", "newest"];

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    console.log(option);
    setSelectedOption(option);
    setIsOpen(false);
  };

  // close dropdown when clicked outside
  useEffect(() => {
    const closeDropdown = (event) => {
      if (!event.target.closest(".relative")) {
        setIsOpen(false);
      }
    };
    window.addEventListener("click", closeDropdown);
    return () => {
      window.removeEventListener("click", closeDropdown);
    };
  }, [setIsOpen]);

  return (
    <div className="relative w-45 bg-none">
      <button
        onClick={toggleDropdown}
        className="items-center justify-between hidden w-full px-4 py-2 rounded-md lg:flex text-md bg-none"
      >
        <span className="flex items-center gap-2">
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 0.75H13.375M1.5 4.5H9.625M1.5 8.25H9.625M13.375 4.5V14.5M13.375 14.5L10.25 11.375M13.375 14.5L16.5 11.375"
              stroke="#252C34"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span className="block text-gray-600">
            {t(selectedOption) || t("sort by")}
          </span>
        </span>
        {isOpen ? (
          <ChevronUp className="w-4 h-4 ml-2" />
        ) : (
          <ChevronDown className="w-4 h-4 ml-2" />
        )}
      </button>
      <button
        onClick={toggleDropdown}
        className=" bg-[#009E1E]  gap-1  rounded-full  cursor-pointer xs:flex items-center justify-between  p-2  lg:hidden text-md"
      >
        <img src="sort.svg" alt="" />
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg ltr:right-8 rtl:left-8 min-w-fit">
          <ul className="py-1">
            {options.map((option, index) => (
              <li
                key={option}
                className={`px-4 py-2 text-sm whitespace-nowrap  cursor-pointer hover:bg-gray-100 ${
                  index !== options.length - 1
                    ? "border-b border-green-200"
                    : ""
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {t(option)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SortByDropdown;
