import LoginNavBar from "../ui/login-nav_bar/LoginNavBar";
import Footer from "../ui/footer/Footer";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "../ui/Spinner";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../context/LanguageProvider";
import EmptyState from "../ui/EmptyState";
import ScrollableCardContainer from "../ui/GenericScroll";

const Supplier = () => {
  const [filters, setFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [filter, setFilter] = useState("");
  const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);
  const [Loading, setLoading] = useState(false);
  const apiBaseUrl = import.meta.env.VITE_BASE_URL;
  const supplierWebsite = import.meta.env.VITE_SUPPLIERS_URL;
  const { t, i18n } = useTranslation("supplier");
  const { language } = useContext(LanguageContext);

  function goToWebsite(website) {
    window.location.href = website;
  }

  const handleFilter = async (option) => {
    if (filter === option) {
      option = "";
      setFilter("");
    }

    setLoading(true);
    const url = option
      ? `${apiBaseUrl}/api/v1/application/supplier-companies?filters[segment]=${option}`
      : `${apiBaseUrl}/api/v1/application/supplier-companies`;
    try {
      const response = await fetch(url, {
        headers: {
          "Accept-Language": language,
        },
      });

      if (response?.status === 429) {
        const message = response.data.message;
        const waitTimeMatch = message.match(/(\d+)\s*seconds/);
        const waitTime = waitTimeMatch ? parseInt(waitTimeMatch[1], 10) : 60;

        setTimeout(() => {
          handleFilter(option);
        }, waitTime * 1000);
      }
      // if (!response.ok) {
      //   throw new Error("failed");
      // }
      const data = await response.json();
      console.log(data.data);
      setCompanies(data.data);
      setFilter(option);
    } catch (error) {
      if (error?.response?.status === 429) {
        const message = response?.data?.message;
        const waitTimeMatch = message.match(/(\d+)\s*seconds/);
        const waitTime = waitTimeMatch ? parseInt(waitTimeMatch[1], 10) : 60;

        setTimeout(() => {
          handleFilter(option);
        }, waitTime * 1000);
      }
      console.error("Error during:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const getFilters = async () => {
      try {
        const response = await fetch(
          `${apiBaseUrl}/api/v1/application/tags?filters[type]=business-segment&&per_page=100`,
          {
            headers: {
              "Accept-Language": language,
            },
          }
        );

        if (!response.ok) {
          throw new Error("failed");
        }
        const data = await response.json();
        console.log(data.data);
        setFilters(data.data);
      } catch (error) {
        console.error("Error during:", error);
      }
    };
    getFilters();
  }, [apiBaseUrl, language]);

  useEffect(() => {
    const getCompanies = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${apiBaseUrl}/api/v1/application/supplier-companies`,
          {
            headers: {
              "Accept-Language": language,
            },
          }
        );
        if (response?.status === 429) {
          const message = response.data.message;
          const waitTimeMatch = message.match(/(\d+)\s*seconds/);
          const waitTime = waitTimeMatch ? parseInt(waitTimeMatch[1], 10) : 60;

          setTimeout(() => {
            getCompanies();
          }, waitTime * 1000);
        }
        if (!response.ok) {
          throw new Error("failed");
        }
        const data = await response.json();
        console.log(data.data);
        setCompanies(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error during:", error);
        if (error?.response?.status === 429) {
          const message = response.data.message;
          const waitTimeMatch = message.match(/(\d+)\s*seconds/);
          const waitTime = waitTimeMatch ? parseInt(waitTimeMatch[1], 10) : 60;

          setTimeout(() => {
            getCompanies();
          }, waitTime * 1000);
        }
        setLoading(false);
      }
    };
    getCompanies();
  }, [apiBaseUrl, language]);

  return (
    <>
      <LoginNavBar
        showBurgerLoginMenu={showBurgerLoginMenu}
        setShowBurgerLoginMenu={setShowBurgerLoginMenu}
      />
      {!showBurgerLoginMenu && (
        <>
          <div className="relative mb-[2.75rem]">
            <div className="w-full h-[28.125rem]">
              <img
                src="/sup-companies-cover.svg"
                alt=""
                className="object-cover w-full h-full"
              />
            </div>
            <div className="absolute w-[50.688rem] rtl:right-[7rem] ltr:left-[7rem] text-white top-1/2  transform -translate-y-1/2">
              <h1 className="mb-4 text-5xl font-bold">{t("explore")}</h1>
              <p className="mb-8 text-xl font-normal">{t("find")}</p>
              <button
                onClick={() => goToWebsite(supplierWebsite)}
                className="flex items-center justify-center gap-2 bg-[#009E1E] w-[13.688rem] py-[0.875em] px-5 rounded-lg"
              >
                <span className="text-base font-medium">{t("register")}</span>
                <img src="/reg-sup.svg" alt="" className="mt-[5px]" />
              </button>
            </div>
          </div>
          <div className="flex items-center gap-4 py-6 px-[7rem]">
            <button onClick={() => setShowFilters(!showFilters)}>
              <img src="/filter-sup.svg" alt="" />
            </button>
           { showFilters && (
            <ScrollableCardContainer>
              {filters?.map((fil) => (
                <button
                  onClick={() => handleFilter(fil.slug)}
                  key={crypto.randomUUID()}
                  className={`p-4 text-sm drop-shadow-[0_2px_6px_rgba(0,0,0,0.1)] font-medium border rounded-lg min-w-[15rem] whitespace-nowrap overflow-hidden text-ellipsis
                     ${filter === fil.slug ? "bg-[#009E1E]" : ""}`}
                >
                  {fil.name}
                </button>
              ))}
            </ScrollableCardContainer>
          )}
          </div>
          <div className="xl:px-[112px] lg:px-[70px] md:px[48px] sm:px-[16px] my-10">
            <div className="grid gap-4 xl:grid-cols-3 md:grid-cols-2 sm:gid-cols-1 gap-y-4">
              {companies.map((company) => (
                <Link
                  to={`${company.uuid}`}
                  key={company.uuid}
                  className=" companyCard relative rounded-lg shadow-sm max-h-[335px] my-4"
                >
                  <div
                    className={`flex flex-wrap gap-1 absolute top-[1.125rem] 
                  ${
                    i18n.language === "ar"
                      ? "right-[1.125rem]"
                      : "left-[1.125rem]"
                  }`}
                  >
                    {company?.business_segments?.map((segment) => (
                      <p
                        key={segment.id}
                        className="font-medium text-sm  bg-[#B8E9B7] px-2 py-1 rounded-3xl"
                      >
                        {segment.name}
                      </p>
                    ))}
                  </div>
                  <div className="companyImg w-full h-[124px] ">
                    <img
                      src={company.cover?.url || "/company1.png"}
                      alt="company1"
                      className="w-full h-full rounded-t-lg"
                    />
                  </div>
                  <div className="bg-white ">
                    <img
                      src={company.logo?.url || "/companyLogo.png"}
                      alt="companyLogo"
                      className="m-auto absolute rounded-full w-[100px] h-[100px]  top-20 left-1/2 transform -translate-x-1/2"
                    />
                  </div>
                  <div className="p-2 bg-white rounded-b-lg ">
                    <h1 className="mt-12 text-xl font-medium text-center">
                      {company.name}
                    </h1>
                    <p className="text-[15px] font-normal flex items-center my-4 gap-2">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.48157 14.2213L8.24417 13.7813L8.48157 14.2213ZM7.5188 14.2213L7.7562 13.7813H7.7562L7.5188 14.2213ZM13.1468 7.20428C13.1468 8.97138 12.2835 10.4243 11.2142 11.5474C10.1443 12.671 8.90372 13.4254 8.24417 13.7813L8.71897 14.6614C9.4249 14.2805 10.7669 13.4673 11.9384 12.237C13.1103 11.0061 14.1468 9.31887 14.1468 7.20428H13.1468ZM8.00018 2.05762C10.8426 2.05762 13.1468 4.36186 13.1468 7.20428H14.1468C14.1468 3.80957 11.3949 1.05762 8.00018 1.05762V2.05762ZM2.85352 7.20428C2.85352 4.36186 5.15776 2.05762 8.00018 2.05762V1.05762C4.60547 1.05762 1.85352 3.80957 1.85352 7.20428H2.85352ZM7.7562 13.7813C7.09664 13.4254 5.85603 12.671 4.78621 11.5474C3.71687 10.4243 2.85352 8.97138 2.85352 7.20428H1.85352C1.85352 9.31887 2.89006 11.0061 4.06199 12.237C5.23345 13.4673 6.57547 14.2805 7.2814 14.6614L7.7562 13.7813ZM8.24417 13.7813C8.089 13.865 7.91137 13.865 7.7562 13.7813L7.2814 14.6614C7.73291 14.9049 8.26745 14.9049 8.71897 14.6614L8.24417 13.7813ZM9.9198 7.20448C9.9198 8.26486 9.06019 9.12448 7.9998 9.12448V10.1245C9.61247 10.1245 10.9198 8.81715 10.9198 7.20448H9.9198ZM7.9998 5.28448C9.06019 5.28448 9.9198 6.14409 9.9198 7.20448H10.9198C10.9198 5.59181 9.61247 4.28448 7.9998 4.28448V5.28448ZM6.0798 7.20448C6.0798 6.14409 6.93942 5.28448 7.9998 5.28448V4.28448C6.38713 4.28448 5.0798 5.59181 5.0798 7.20448H6.0798ZM7.9998 9.12448C6.93942 9.12448 6.0798 8.26486 6.0798 7.20448H5.0798C5.0798 8.81715 6.38713 10.1245 7.9998 10.1245V9.12448Z"
                          fill="#009E1E"
                        />
                      </svg>
                      <span>{company.address}</span>
                    </p>
                    <p className="text-[15px] font-normal flex items-center my-4 gap-2">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.5 4.5V11.5C14.5 12.3284 13.8284 13 13 13H3C2.17157 13 1.5 12.3284 1.5 11.5V4.5M14.5 4.5C14.5 3.67157 13.8284 3 13 3H3C2.17157 3 1.5 3.67157 1.5 4.5M14.5 4.5V4.66181C14.5 5.1827 14.2298 5.6663 13.7861 5.93929L8.78615 9.01622C8.30404 9.3129 7.69596 9.3129 7.21385 9.01622L2.21385 5.93929C1.77023 5.6663 1.5 5.1827 1.5 4.66181V4.5"
                          stroke="#009E1E"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>{company.email}</span>
                    </p>
                    <p className="text-[15px] font-normal flex items-center my-4 gap-2">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.9237 10.5499L12.1583 8.78452C11.7027 8.32891 10.964 8.32891 10.5084 8.78452L9.9018 9.3911C8.48968 8.66098 7.33902 7.51032 6.6089 6.0982L7.21548 5.49163C7.67109 5.03601 7.67109 4.29732 7.21548 3.84171L5.45007 2.07629C5.01741 1.64363 4.31593 1.64363 3.88327 2.07629C2.42384 3.53572 2.25964 5.84619 3.498 7.49734L4.28572 8.54762C5.18591 9.74788 6.25213 10.8141 7.45238 11.7143L8.50266 12.502C10.1538 13.7404 12.4643 13.5762 13.9237 12.1167C14.3564 11.6841 14.3564 10.9826 13.9237 10.5499ZM9.82761 9.46528C9.82768 9.46521 9.82776 9.46514 9.82783 9.46506L9.82761 9.46528Z"
                          stroke="#009E1E"
                        />
                      </svg>

                      <span>{company.phone}</span>
                    </p>
                  </div>
                </Link>
              ))}
            </div>
            {companies.length === 0 && Loading && <Spinner />}
            {companies.length === 0 && !Loading && (
              <div className="flex justify-center items-center h-[470px]  w-full">
                <EmptyState message={t("no suppliers")} />
              </div>
            )}
          </div>
          <div className="w-full py-4 bg-[#252C34]">
            <div className="w-[76rem] mx-auto flex items-center justify-between">
              <p className="text-4xl font-extrabold text-green-500">
                {t("get workey")}
              </p>
              <div className="flex gap-8">
                <img src="/Group.png" alt="google play img" />
                <img src="/AppleStore.png" alt="Apple Store img" />
              </div>
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default Supplier;
