/* eslint-disable react/prop-types */
import { useTranslation } from "react-i18next";
import { verify_OTP_ID_reset_password } from "../../../../data_featching/forgetPassword";
import { verficationValidation } from "../../../register/registrationValidation";
import "./VerificationResetPassword.css";
import { useEffect, useRef, useState } from "react";
import useFocusOnMount from "../../../../hooks/useFocusOnMount";
import useInputNavigation from "../../../../hooks/useInputNavigation";
import useVerificationInput from "../../../../hooks/useVerificationInput";

const VerificationResetPassword = ({
  verificationNum,
  setVerificationNum,
  setShowSetPassword,
  setShowVerificationPhase,
  sendOtp,
  VERIFIATCAION_ID,
  VERIFIATCAION_CODE,
  setVERIFIATCAION_CODE,
}) => {
  const [secTimer, setSecTimer] = useState(59);
  const [minTimer, setMinTimer] = useState(1);
  const [minTimerShow, setMinTimerShow] = useState("01");

  const [disableHint, setDisableHint] = useState(true);
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [showVerificationError, setShowVerificationError] = useState("");
  const inputsRef = useRef([]);
  /*handle change the input field of the verfication code */
  const { handleInputChange } = useVerificationInput(
    inputsRef,
    setVerificationNum
  );
  // Call the custom hook to handle focusing the first input
  useFocusOnMount(inputsRef);
  //listen for keydown events and handle navigation between input fields.
  useInputNavigation(inputsRef);
  const { t, i18n } = useTranslation("register");
  async function HandelVerificationSubmit() {
    const verificationInfo = new FormData();
    /**for registeration */
    /**for registeration */
    verificationInfo.append("verification_id", VERIFIATCAION_ID);
    verificationInfo.append("verification_code", VERIFIATCAION_CODE);
    console.log("VERIFIATCAION_ID", VERIFIATCAION_ID);
    console.log("VERIFIATCAION_CODE", VERIFIATCAION_CODE);

    /**for Reset_password */
    /**for Reset_password */
    const result = await verify_OTP_ID_reset_password(
      verificationInfo,
      i18n.language
    );

    if (result.status == 200 || result.status == 201) {
      setShowSetPassword(true);
      setShowVerificationPhase(false);
      console.log("verificationNum", verificationNum);
    } else if (result.status !== 200 || result.status !== 201) {
      setShowVerificationError(result?.data?.message);
    }
  }

  const concatenateAndConvertToNumber = (obj) => {
    const { code1, code2, code3, code4, code5, code6 } = obj;
    const concatenatedString = `${code1}${code2}${code3}${code4}${code5}${code6}`;
    setVERIFIATCAION_CODE(Number(concatenatedString));
  };
  // const handleInputChange = (event, field, index) => {
  //   const { value } = event.target;
  //   const key = event.key;

  //   // Update the state with the last entered character
  //   setVerificationNum((prev) => ({
  //     ...prev,
  //     [field]: value.slice(-1),
  //   }));

  //   // Handle backspace to move focus to the previous field if empty
  //   if (key === "Backspace" && value === "" && index > 0) {
  //     inputsRef.current[index - 1].focus();
  //   }
  //   // Automatically focus the next input only if the current input is empty
  //   else if (
  //     value &&
  //     value.length === 1 &&
  //     index < inputsRef.current.length - 1
  //   ) {
  //     inputsRef.current[index + 1].focus();
  //   }
  //   // Handle arrow keys for navigation only if the field is empty
  //   else if (key === "ArrowLeft" && index > 0 && !value) {
  //     inputsRef.current[index - 1].focus();
  //   } else if (
  //     key === "ArrowRight" &&
  //     index < inputsRef.current.length - 1 &&
  //     !value
  //   ) {
  //     inputsRef.current[index + 1].focus();
  //   }
  // };

  /*show verification button */
  useEffect(() => {
    const verificationCodeResult = verficationValidation(verificationNum);
    console.log("verificationCodeResult", verificationCodeResult);
    if (verificationCodeResult) {
      setShowVerifyButton(true);
    } else {
      setShowVerifyButton(false);
    }
    concatenateAndConvertToNumber(verificationNum);
  }, [
    verificationNum.code1,
    verificationNum.code2,
    verificationNum.code3,
    verificationNum.code4,
    verificationNum.code5,
    verificationNum.code6,
  ]);
  /*timer handler */
  useEffect(() => {
    const interval = setInterval(() => {
      setSecTimer((prevSec) => {
        if (prevSec > 0) {
          return prevSec - 1;
        } else {
          if (minTimer > 0) {
            setMinTimer((prevMin) => prevMin - 1);
            setMinTimerShow("00");
            return 59;
          } else {
            clearInterval(interval);
            setDisableHint(false);
            return 0;
          }
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [minTimer]);
  /*reset error message when user change language or reenter new code */
  useEffect(() => {
    if (showVerificationError) {
      // let timer = setTimeout(() => {
      setShowVerificationError("");
      // }, 3000);
      // return () => {
      //   clearTimeout(timer);
      // };
    }
  }, [verificationNum, i18n.language]);

  console.log("code", verificationNum);
  return (
    <div className="personal_main_container sm:mx-0 xl:mx-[7rem]  sm:w-full xl:w-[36rem]">
      <div className="w-full Header_register_phase">
        <h1>
          {i18n.language == "en"
            ? "password reset "
            : "إعادة تعيين كلمة المرور"}
        </h1>
        <div className="flex gap-1.5 items-start">
          <div className="phase Active_phase"></div>
          <div className="phase Active_phase"></div>
          <div className="phase"></div>
        </div>
      </div>
      <form
        className="flex flex-col justify-start w-full gap-8 personal_registerForm"
        onSubmit={(event) => {
          event.preventDefault();
          if (verficationValidation(verificationNum)) {
            /*here there are a check with API to validate the sent SMS and  */
            /*verification id which recieved */
            HandelVerificationSubmit();
          }
        }}
      >
        <div className="flex flex-col items-center w-full gap-6 py-4 verif_code_container">
          <h1 htmlFor="" className="name_input_text">
            {i18n.language == "en"
              ? " Please Enter verification code sent to your phone number"
              : " الرجاء إدخال رمز التحقق المرسل إلى رقم هاتفك"}
          </h1>
          <div className="flex flex-col items-center w-full gap-25 input_Ver_code_container">
            <div className="flex-col code_error_container">
              {showVerificationError && (
                <div className="error_verify">{showVerificationError}</div>
              )}
              <div className="code_input">
                {Object.keys(verificationNum).map((field, index) => (
                  <input
                    key={field}
                    ref={(el) => (inputsRef.current[index] = el)}
                    type="number"
                    className="cursor-pointer code_field"
                    maxLength="1"
                    value={verificationNum[field]}
                    onChange={(event) => handleInputChange(event, field, index)}
                    // onKeyDown={(event) => handleKeyDown(event, index)}
                  />
                ))}
              </div>
              {disableHint ? (
                <div className="code_hint">
                  <p className="code_timer_hint">{t("getting code")}</p>
                  <p className="code_timer_hint">
                    {minTimerShow} {String(secTimer).padStart(2, "0")}
                  </p>
                </div>
              ) : (
                <div
                  className="flex hint_container code_hint  cursor-pointer"
                  onClick={() => {
                    sendOtp(); /*resend OTP request */
                    setVerificationNum({
                      code1: "",
                      code2: "",
                      code3: "",
                      code4: "",
                      code5: "",
                      code6: "",
                    }); /*reset verification field to write the recieved OTP again */
                    setDisableHint(true);
                    setSecTimer(59);
                    setMinTimer(1);
                    setMinTimerShow("01");
                  }}
                >
                  <p className="code_timer_hint"> {t("resend")}</p>
                  <div className="resend_img">
                    <img src="/resend.png" alt="" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <button
          className="w-full next_button"
          style={showVerifyButton ? { backgroundColor: "#009e1e" } : null}
        >
          <span
            className={`flex items-center gap-2 ${
              i18n.language == "ar" && "flex-row-reverse"
            }`}
          >
            {t("verify")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M10.5 15.5L15.5 10.5L10.5 5.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M5.5 15.5L10.5 10.5L5.5 5.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </span>
        </button>
      </form>
    </div>
  );
};

export default VerificationResetPassword;
