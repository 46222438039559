import React, { useEffect, useRef } from "react";
import { X } from "lucide-react";

import { Modal } from "../ui/Models";
import { useTranslation } from "react-i18next";

const WorkingHoursModal = ({ isOpen, onClose, workingDays }) => {
  const { t, i18n } = useTranslation("workspace");
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  function convertTime(timeString) {
    if (!timeString) return;
    const [hours, minutes] = timeString.split(":");
    let hour = parseInt(hours);
    const period = hour >= 12 ? "pm" : "am";
    hour = hour % 12 || 12;

    return `${hour}:${minutes.padStart(2, "0")} ${t(period)}`;
  }

  const isRTL = i18n.dir() === "rtl";

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div ref={modalRef} className="bg-white rounded-lg shadow-xl w-2/3 p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-medium text-gray-900">
            {" "}
          </h3>
          <X
            className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
            onClick={onClose}
          />
        </div>
        <div className="space-y-2 px-3">
        <h1 className="text-lg font-bold text-gray-900">
        {t("Working hours")}
        </h1>
          <div className="flex justify-between items-center py-2 border-b border-green-200">
            <span className={`text-gray-600 ${isRTL ? 'text-right' : 'text-left'} w-1/3`}>{t("Day")}</span>
            <span className="text-gray-800 text-center w-1/3">{t("From")}</span>
            <span className={`text-gray-800 ${isRTL ? 'text-left' : 'text-right'} w-1/3`}>{t("To")}</span>
          </div>
          {workingDays.map(
            (day, index) =>
              day?.is_active && (
                <div
                  key={day.id}
                  className={`flex justify-between items-center py-2 ${
                    index !== workingDays?.length - 1
                      ? "border-b border-green-200"
                      : ""
                  }`}
                >
                  <span className={`text-gray-600 ${isRTL ? 'text-right' : 'text-left'} w-1/3`}>
                    {day?.day?.label}
                  </span>
                  <span className="text-gray-800 text-center w-1/3">
                    {convertTime(day?.start_time)}
                  </span>
                  <span className={`text-gray-800 ${isRTL ? 'text-left' : 'text-right'} w-1/3`}>
                    {convertTime(day?.end_time)}
                  </span>
                </div>
              )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default WorkingHoursModal;