import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "../ui/footer/Footer";
import LoginNavBar from "../ui/login-nav_bar/LoginNavBar";
import { Spinner } from "../ui/Spinner";
import {
  OverallRatingItem,
  getRatingCounts,
  getOverAllRating,
} from "../ui/feedback/OverallRatingItem";
import EmptyRatings from "../ui/feedback/empty/EmptyRatings";
import EmptyReviwes from "../ui/feedback/empty/EmptyReviwes";
import ReviewCard from "../ui/feedback/ReviewCard";

import {
  getWorkspaceSpaces,
  getWorkspaceRatings,
} from "../../data_featching/workspace";
import SpaceCardComponent from "./WorspacesSpaces";
import { Star } from "lucide-react";
import { useTranslation } from "react-i18next";
import ScrollableCardContainer from "../ui/GenericScroll";
import WorkingHoursModal from "../ui/ScheduleModel";
import LocateMap from "../ui/Map/LocateMap";

const WorkspacePage = () => {
  const { t, i18n } = useTranslation("workspace");
  const [workspace, setWorkspace] = useState(null);
  const { id: workspaceID } = useParams();
  const [reviews, setReviews] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);

  // get spaces for the workspace
  const [spaceTypes, setSpacesTypes] = useState([]);
  useEffect(() => {
    const getSpaces = async () => {
      const response = await getWorkspaceSpaces(workspaceID, i18n.language);

      if (response?.status === 200 || response?.status === 201) {
        setWorkspace(response.data?.data);
        setSpacesTypes(response.data?.data?.space_types);
        setReviews(response.data?.data?.space_ratings);
      }
    };
    getSpaces();
  }, [i18n.language, workspaceID]);

  const ratingsList = async () => {
    const result = await getWorkspaceRatings(workspaceID, i18n.language);
    if (result?.status == 200 || result?.status == 201) {
      console.log("ratings", result?.data?.data);
      setRatings(result?.data?.data);
    }
  };

  useEffect(() => {
    ratingsList();
  }, []);

  const ratingCount = getRatingCounts(ratings);
  const ratingStars = getOverAllRating(ratings);
  function getDirections() {
    // Get the latitude & longitude of the location
    const latitude  =workspace?.address?.latitude;
    const longitude = workspace?.address?.longitude;
    // Construct the Google Maps directions URL
    const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    // Open the URL in a new tab
    window.open(url, '_blank');
}
  if (!workspace) {
    return <Spinner />;
  }

  return (
    <>
      <LoginNavBar
        showBurgerLoginMenu={showBurgerLoginMenu}
        setShowBurgerLoginMenu={setShowBurgerLoginMenu}
      />
       {showScheduleModal && (
        <WorkingHoursModal
          isOpen={showScheduleModal}
          onClose={() => setShowScheduleModal(false)}
          workingDays={workspace?.working_days}
        />
      )}
      {!showBurgerLoginMenu && (
        <>
          <div className="mb-10 spacePage">
            <div className="w-full h-[500px] gap-2">
              {workspace?.media?.length === 1 ? (
                // If there's only 1 photo, render it full width
                <div className="w-full h-full">
                  <img
                    src={workspace?.media[0]?.url}
                    alt={workspace?.media[0]?.name}
                    className="object-cover w-full h-full"
                  />
                </div>
              ) : workspace?.media?.length === 2 ? (
                // If there are 2 photos, render them side by side
                <div className="flex w-full h-full">
                  {workspace?.media?.map((photo) => (
                    <div key={photo.id} className="w-1/2 h-full">
                      <img
                        src={photo.url}
                        alt={photo.name}
                        className="object-cover w-full h-full"
                      />
                    </div>
                  ))}
                </div>
              ) : (
                // If there are more than 2 photos
                <div className="flex w-full h-full">
                  {/* First photo takes half of the width */}
                  <div className="w-1/2 h-full">
                    <img
                      src={workspace?.media[0].url}
                      alt={workspace?.media[0].name}
                      className="object-cover w-full h-full"
                    />
                  </div>

                  {/* Other photos in a 2x2 grid on the right side */}
                  <div className="grid w-1/2 h-full grid-cols-2 grid-rows-2 gap-2">
                    {workspace?.media?.slice(1).map((photo) => (
                      <img
                        key={photo.id}
                        src={photo.url}
                        alt={photo.name}
                        className="object-cover w-full h-full"
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="w-full  xl:px-[112px] lg:px-[70px] md:px[48px] sm:px-[16px] mt-8">
              <div className="flex items-start justify-between gap-4 sm:flex-col lg:flex-row">
                <div className="sm:w-full">
                  <div className="flex flex-row items-center justify-between">
                    <h1 className="flex items-center gap-2 text-2xl font-bold">
                      <img
                        className=" w-[40px] h-[40px] rounded-full "
                        src={workspace?.logo?.url}
                      />
                      {workspace?.name}
                    </h1>
                    <button className=" text-center align-middle rounded-full shadow-md p-2 w-[40px] h-[40px] bg-white">
                      <svg
                        width="22"
                        height="19"
                        viewBox="0 0 22 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.40325 11.0361L10.4846 17.6883C10.662 17.855 10.7507 17.9383 10.8472 17.9806C11.0035 18.049 11.1813 18.049 11.3376 17.9806C11.434 17.9383 11.5227 17.855 11.7001 17.6883L18.7815 11.0361C20.7739 9.16449 21.0159 6.0845 19.3401 3.9247L19.0251 3.51859C17.0204 0.934846 12.9966 1.36816 11.5881 4.31946C11.3891 4.73634 10.7957 4.73634 10.5967 4.31946C9.18812 1.36816 5.16432 0.934844 3.15969 3.51858L2.8446 3.92469C1.1689 6.0845 1.41085 9.16449 3.40325 11.0361Z"
                          stroke="#252C34"
                          strokeWidth="1.83333"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="my-4 ">
                    <span className="bg-[#E8F8E7] text-[#005C00] py-1 px-2 rounded-full text-[16px] font-normal ">
                      {workspace?.type?.label}
                    </span>
                  </div>
                  {ratingStars > 0 && (
                    <div className="flex items-center space-x-2">
                      <div className="flex items-center my-2">
                        {Array.from({ length: ratingStars }).map((_, index) => (
                          <Star
                            key={index}
                            size={24}
                            className="text-yellow-400"
                            fill="currentColor"
                          />
                        ))}
                      </div>
                      <span className="text-gray-500">
                        ({ratingStars} {t("based on")} {ratingCount} {t("reviews")})
                      </span>
                    </div>
                  )}
                  <p className="flex items-center my-4 text-gray-500 ">
                    <svg
                      className="me-2"
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.39804 16.804L8.74336 17.4697H8.74336L8.39804 16.804ZM7.60196 16.804L7.25664 17.4697H7.25664L7.60196 16.804ZM14.25 8C14.25 10.2364 13.111 12.0661 11.7225 13.4666C10.3347 14.8664 8.76052 15.7711 8.05271 16.1382L8.74336 17.4697C9.51848 17.0677 11.2453 16.0785 12.7877 14.5227C14.3295 12.9676 15.75 10.78 15.75 8H14.25ZM8 1.75C11.4518 1.75 14.25 4.54822 14.25 8H15.75C15.75 3.71979 12.2802 0.25 8 0.25V1.75ZM1.75 8C1.75 4.54822 4.54822 1.75 8 1.75V0.25C3.71979 0.25 0.25 3.71979 0.25 8H1.75ZM7.94729 16.1382C7.23948 15.7711 5.66527 14.8664 4.27749 13.4666C2.88902 12.0661 1.75 10.2364 1.75 8H0.25C0.25 10.78 1.67049 12.9676 3.21227 14.5227C4.75474 16.0785 6.48152 17.0677 7.25664 17.4697L7.94729 16.1382ZM8.05271 16.1382C8.01637 16.157 7.98363 16.157 7.94729 16.1382L7.25664 17.4697C7.72602 17.7132 8.27398 17.7132 8.74336 17.4697L8.05271 16.1382ZM10.25 8C10.25 9.24264 9.24264 10.25 8 10.25V11.75C10.0711 11.75 11.75 10.0711 11.75 8H10.25ZM8 5.75C9.24264 5.75 10.25 6.75736 10.25 8H11.75C11.75 5.92893 10.0711 4.25 8 4.25V5.75ZM5.75 8C5.75 6.75736 6.75736 5.75 8 5.75V4.25C5.92893 4.25 4.25 5.92893 4.25 8H5.75ZM8 10.25C6.75736 10.25 5.75 9.24264 5.75 8H4.25C4.25 10.0711 5.92893 11.75 8 11.75V10.25Z"
                        fill="#000021"
                      />
                    </svg>
                    
                    {workspace?.address?.building_number},
                    {workspace?.address?.district.name}
                  </p>
                  <h2 className="mt-8 text-xl font-bold">{t("about")}</h2>
                  <p className="mt-4 text-gray-700">{workspace?.about}</p>
                  <h2 className="mt-8 text-xl font-bold">
                    {t("basic amenities")}
                  </h2>
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    {workspace?.amenities?.map((amenity) => (
                    <div
                    key={crypto.randomUUID()}
                    className="flex items-center "
                  >
                    <div
                      className="icon"
                      dangerouslySetInnerHTML={{
                        __html: amenity.icon,
                      }}
                    />
                    <span className="rtl:mr-2 ltr:ml-2"
                    >{amenity.name}</span>
                  </div>
                    ))}
                  </div>
                </div>
                <div className="sm:w-full lg:max-w-[400px]">
                  {/* <div className="">
                    <h2 className="text-xl font-bold">{t("plans")}</h2>
                    <div className="mt-2 border border-[#DCE1E9] rounded-lg">
                     
 <div className="p-4 border-b-2">
                        {workspace?.space_types[0]?.spaces[0]?.plans?.map((plan, planIndex) => (
                          <div key={planIndex} className="mt-2">
                            {plan?.is_active && (
                              <>
                                {plan?.shifts?.map((shift, shiftIndex) => (
                                  <div
                                    key={shiftIndex}
                                    className="flex items-center justify-between gap-2 mt-2"
                                  >
                                    {shift?.is_active && (
                                      <span className="whitespace-nowrap">
                                        <span className="text-xl font-bold">
                                          {shift?.price}
                                        </span>{" "}
                                        {shift?.currency?.label} /
                                        {plan?.name?.label}
                                        <span className="text-gray-500 ml-4">
                                        {shift?.name?.key !== "all" &&
                                          '"' + shift?.name?.label + '"'}
                                          </span>
                                      </span>
                                    )}
                                    {plan?.instant_booking &&
                                      shift?.is_active && (
                                        <span className="bg-[#2563EB] text-white text-[12px] flex items-center rounded-3xl px-2 py-1 whitespace-nowrap">
                                          {t("instant")}
                                          <svg
                                            className="ltr:ml-1 rtl:mr-1"
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M10.334 4.97272C10.2739 4.83525 10.138 4.74646 9.98788 4.74646H6.84685L8.35982 0.504986C8.42014 0.335815 8.35307 0.147495 8.19937 0.0545691C8.04556 -0.0384069 7.84767 -0.0102874 7.72592 0.121744L1.73388 6.61943C1.63214 6.72974 1.60533 6.88984 1.6656 7.02732C1.72577 7.16479 1.86168 7.25358 2.01175 7.25358H4.05375C4.26253 7.25358 4.4317 7.08436 4.4317 6.87563C4.4317 6.6669 4.26253 6.49768 4.05375 6.49768H2.87439L7.02505 1.99678L5.95469 4.99736C5.91347 5.11312 5.93292 5.24172 6.00377 5.34216C6.07457 5.44259 6.19174 5.50231 6.31465 5.50231H9.12519L4.97514 10.0027L6.04565 7.00262C6.08697 6.88687 6.06943 6.75826 5.99858 6.65783C5.92773 6.5574 5.81258 6.49763 5.68967 6.49763H5.68594C5.5083 6.49763 5.36065 6.62019 5.32059 6.78533L3.64006 11.4949C3.57969 11.6641 3.64677 11.8524 3.80047 11.9453C3.86119 11.9821 3.92882 11.9999 3.99589 11.9999C4.09865 11.9999 4.20024 11.958 4.27391 11.8781L10.2656 5.38051C10.3674 5.27025 10.3943 5.1102 10.334 4.97272Z"
                                              fill="white"
                                            />
                                          </svg>
                                        </span>
                                      )}
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="flex items-center justify-between mt-2">
                      </div>
                    </div>
                  </div> */}
                  <div className="relative mt-4 ">
                    <h2 className="text-xl font-bold mb-4">{t("location")}</h2>
                   <LocateMap address={workspace?.address} />
                    <button
                                            onClick={getDirections}

                    className="mt-4 p-3 bg-[#252C34] rounded-lg text-white absolute bottom-10 left-1/2 -translate-x-1/2 ">
                      {t("get directions")}
                    </button>
                  </div>
                  <div className="mt-4 ">
                    <h2 className="text-xl font-bold">{t("working-hours")}</h2>
                    <button 
                    onClick={() => setShowScheduleModal(true)}
                    className="mt-2 text-blue-500">
                      {t("check schedule")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {spaceTypes.map((spacesType) => (
            <SpaceCardComponent 
            key={crypto.randomUUID()}

            spacesType={spacesType} 
            />
          ))}
          {workspace?.media?.length > 0 && (
            <>
              <h2 className="mt-10 text-xl font-bold  px-[114px]">
                {t("workspace photos")}
              </h2>
              <ScrollableCardContainer>
                <div className="w-[100px] flex-shrink-0"></div>

                {workspace?.media?.map((photo) => (
                  <img
                    src={photo.url ? photo.url : "/photo_GA3.png"}
                    alt={photo.name}
                    key={photo.id}
                    className="w-[300px] h-[250px] object-cover"
                    width="100"
                    height="100"
                    style={{ aspectRatio: "100/100", objectFit: "cover" }}
                  />
                ))}
              </ScrollableCardContainer>
            </>
          )}
          <div className="flex flex-col gap-6 w-full mb-10  mt-8">
            <h1 className="text-xl font-bold Basic_Amenities xl:px-[112px] lg:px-[70px] md:px[48px] sm:px-[16px]">
              {t("testimonials")}
            </h1>
            {reviews?.length == 0 && <EmptyReviwes />}
            <ScrollableCardContainer>
              <div className="w-[100px] flex-shrink-0"></div>

              {reviews.map((testimonial, index) => (
                <ReviewCard
                  key={index}
                  avatar={
                    testimonial.user.profile_image?.url || "/person-avatar.svg"
                  }
                  name={`${testimonial.user.first_name} ${testimonial.user.last_name}`}
                  title={
                    testimonial.user.has_business_profile
                      ? "Business Profile"
                      : "User"
                  }
                  rating={parseFloat(testimonial.overall_rating)}
                  testimonial={testimonial.review}
                />
              ))}
            </ScrollableCardContainer>
          </div>
          <div className="flex flex-col gap-6 w-full xl:px-[112px] lg:px-[70px] md:px[48px] sm:px-[16px] mt-8">
            <h1 className="text-xl font-bold Basic_Amenities ">
              {t("ratings")}
            </h1>
            {reviews?.length == 0 ? (
              <EmptyRatings />
            ) : (
              <OverallRatingItem
                itemNo={false}
                ratingItem={ratings}
                removeTitle={true}
              />
            )}
             <div className="max-w-md p-6 bg-white border-2 border-red-500 rounded-lg">
                  <h3 className="mb-4 text-lg font-semibold text-gray-900">
                    {i18n.language ==="en" ? "REGA Verified Information" : "معلومات موثقة من REGA"}
                  </h3>

                  <div className="flex justify-between mb-2">
                    <span className="text-gray-500">
                      {i18n.language ==="en" ? "FAL License Number" : "رقم ترخيص FAL"}
                    </span>
                    <span className="font-semibold text-gray-900">
                      1200029691
                    </span>
                  </div>

                  <div className="flex justify-between mb-2">
                    <span className="text-gray-500">
                      {i18n.language ==="en" ? "Advertising License Number" : "رقم ترخيص الإعلان"}
                    </span>
                    <span className="font-semibold text-gray-900">
                      7800265218
                    </span>
                  </div>

                  <div className="flex justify-between mb-2">
                    <span className="text-gray-500">
                      {i18n.language ==="en" ? "License Issue Date" : "تاريخ إصدار الترخيص"}
                    </span>
                    <span className="font-semibold text-gray-900">
                      7800265218
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span className="text-gray-500">
                      {i18n.language ==="en" ? "License Expiry Date" : "تاريخ انتهاء الترخيص"}
                    </span>
                    <span className="font-semibold text-gray-900">
                      7800265218
                    </span>
                  </div>
                </div>
          </div>
         

          <div className="mt-10 bg-black">
            <div className="GooglePlay">
              <div className="Hint">{t("get workery")}</div>
              <div className="GooglePlay_logo">
                <img src="/Group.png" alt="google play img" />
                <img src="/AppleStore.png" alt="Apple Store img" />
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default WorkspacePage;
