import baseURL from "../../axios";

let isRequestInProgress = false;

export async function updateLanguagePreferance(token, currentLanguage) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }

  isRequestInProgress = true;

  const endPoint = `/api/v1/user/preferences`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, currentLanguage, {
      headers: {
        Authorization: `Bearer ${token}`, // Ensure correct prefix for token
      },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    isRequestInProgress = false;

    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error?.response?.status !== 200) {
      throw new Error(error.response?.data?.message);
      // return error.response;
    }
  }
}

export async function updateUserPersonalInfo(token, data, currentLanguage) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }

  isRequestInProgress = true;

  const endPoint = `/api/v1/user?_method=PATCH`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": currentLanguage, // Ensure correct prefix for token
      },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    isRequestInProgress = false;

    console.log("error.response.data", error.response);
    if (error?.response?.status !== 200) {
      throw new Error(error.response?.data?.message);
    }
  }
}

export async function completeProfileStepOne(token, data, currentLanguage) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }

  isRequestInProgress = true;

  const endPoint = `/api/v1/user/verification`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": currentLanguage, // Ensure correct prefix for token
      },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    isRequestInProgress = false;

    console.log("error.response.data", error.response);
    if (error.response?.status !== 200) {
      throw new Error(error.response?.data?.message);
    }
  }
}

export async function completeProfileStepTwo(token, data, currentLanguage) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }

  isRequestInProgress = true;

  const endPoint = `/api/v1/user/verification/verify`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": currentLanguage,
      },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    isRequestInProgress = false;

    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response?.status !== 200) {
      throw new Error(error.response?.data?.message);
      // return error.response;
    }
  }
}

export async function completeProfile(token, data, currentLanguage) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }

  isRequestInProgress = true;

  const endPoint = `/api/v1/user/complete-data`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.patch(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    isRequestInProgress = false;

    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      throw new Error(error.response.data.message);
      // return error.response;
    }
  }
}

export async function changeEmailStepOne(token, data, currentLanguage) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }

  isRequestInProgress = true;

  const endPoint = `/api/v1/user/change-email`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.patch(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    isRequestInProgress = false;

    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      throw new Error(error.response.data.message);
      // return error.response;
    }
  }
}

export async function changeNumberStepOne(token, data, currentLanguage) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }

  isRequestInProgress = true;

  const endPoint = `/api/v1/user/verification`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": currentLanguage,
      },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    isRequestInProgress = false;

    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      throw new Error(error.response.data.message);
      // return error.response;
    }
  }
}

export async function changeNumberStepTwo(token, data, currentLanguage) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }

  isRequestInProgress = true;

  const endPoint = `/api/v1/user/verification/verify`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": currentLanguage,
      },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    isRequestInProgress = false;

    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      throw new Error(error.response.data.message);
      // return error.response;
    }
  }
}

export async function changePhoneNumber(token, data, currentLanguage) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }

  isRequestInProgress = true;

  const endPoint = `/api/v1/user/change-phone`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.patch(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": currentLanguage,
      },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    isRequestInProgress = false;

    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      throw new Error(error.response.data.message);
      // return error.response;
    }
  }
}

export async function deleteUserAccount(
  token,
  accountPassword,
  currentLanguage
) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }

  isRequestInProgress = true;

  const endPoint = `/api/v1/user/delete-request`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, accountPassword, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": currentLanguage,
      },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    isRequestInProgress = false;

    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      throw new Error(error.response.data.message);
    }
  }
}

export async function switchToPersonalProfile(
  token,
  accountPassword,
  currentLanguage
) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }

  isRequestInProgress = true;

  const endPoint = `/api/v1/user/profile/business/downgrade`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, accountPassword, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": currentLanguage,
      },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    isRequestInProgress = false;

    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      throw new Error(error.response.data.message);
    }
  }
}

export async function switchToBusinessProfile(
  token,
  accountPassword,
  currentLanguage
) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }

  isRequestInProgress = true;

  const endPoint = `/api/v1/user/profile/business/upgrade`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, accountPassword, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": currentLanguage,
      },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    isRequestInProgress = false;

    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      throw new Error(error.response.data.message);
    }
  }
}

export async function changePassword(token, data, currentLanguage) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }

  isRequestInProgress = true;

  const endPoint = `/api/v1/user/change-password`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.patch(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    isRequestInProgress = false;

    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      throw new Error(error.response.data.message);
      // return error.response;
    }
  }
}
