import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";
import UseAuth from "../hooks/UseAuth";
import { AuthModal } from "../component/ui/Models";

const RequireAuth = ({ children }) => {
  const { auth } = UseAuth();
  const navigate = useNavigate();
  const location = useLocation();
  console.log("RequireAuth", auth);
  const[showModal, setShowModal] = useState(true);
  // Catch the local storage token
  const localStorageToken = localStorage.getItem("authToken");
  console.log("localStorageToken", localStorageToken);

  // Check if the user is authenticated
  const isAuthenticated = auth?.token || localStorageToken;

  return (
    <>
      {children}
      {!isAuthenticated && (
        <AuthModal
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
            navigate("/");
          }}
        />
      )}
      <Outlet />
    </>
  );
};

export default RequireAuth;