import baseURL from "../../axios";

export const fetchUserProfile = async (accessToken) => {
  try {
    const response = await baseURL.get(
      "https://www.googleapis.com/oauth2/v1/userinfo",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    console.log("User Profile:", response.data);
    return response; // Contains user's profile information (name, email, etc.)
  } catch (error) {
    console.error("Error fetching user profile:", error);
  }
};
///api/v1/auth/social-login
let isRequestInProgress = false;

export async function GoogleLoginWithBE(data) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skip this request.");
    return;
  }
  isRequestInProgress = true;
  const endPoint = `/api/v1/auth/social-login`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, data, {
      //   headers: {
      //     Authorization: `Bearer ${token}`, // Ensure correct prefix for token
      //   },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    if (error.response.status !== 200) {
      isRequestInProgress = false;
      return error.response;
    }
  }
}
