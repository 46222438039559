import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function EmailAndPhoneHint({ setShowHint }) {
  const { t } = useTranslation("profile");
  const navigate = useNavigate();
  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center bg-black bg-opacity-25">
      <div className="p-6 bg-white flex flex-col items-center  rounded-lg shadow-md w-[31.25rem]">
        <div className="w-[9.375rem] h-[9.375rem] mb-4">
          <img src="/email&phone.svg" alt="" className="w-full h-full" />
        </div>
        <h3 className="mb-2 text-lg font-semibold leading-[1.361rem]">
          {t("locked")}
        </h3>
        <p className="mb-6 text-base font-normal leading-[1.141rem] text-center text-[#6B7280]">
          {t("to update")}
        </p>
        <div className="flex items-center w-full gap-2">
          <button
            type="button"
            onClick={() => setShowHint(false)}
            className="text-sm shadow-sm outline-none font-medium border px-4 py-[0.625rem] rounded-lg border-[#E5E7EB] flex-1"
          >
            {t("cancel")}
          </button>
          <button
            type="button"
            onClick={() => navigate("/profile/account")}
            className={`text-sm flex-1 shadow-sm outline-none font-medium bg-[#009E1E] text-white px-4 py-[0.625rem] rounded-lg`}
          >
            {t("go to account")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EmailAndPhoneHint;
EmailAndPhoneHint.propTypes = null;
