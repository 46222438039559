import { useTranslation } from "react-i18next";

function DeleteAccountSuccess() {
  const { t } = useTranslation("profile");
  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center bg-black bg-opacity-25">
      <div className="p-6 bg-white flex flex-col items-center  rounded-lg shadow-md w-[31.25rem]">
        <img src="/account-deleted.svg" className="w-[50px] h-[50px] mb-4" />
        <p className="text-lg font-medium">{t("account deleted")}</p>
      </div>
    </div>
  );
}

export default DeleteAccountSuccess;
