import baseURL from "../../axios";

let isRequestInProgress = false;

// export async function getCountOFUnReadMessages(token) {
//   if (isRequestInProgress) {
//     console.log("Request already in progress. Skipping this request.");
//     return;
//   }
//   isRequestInProgress = true;

//   const endPoint = `/api/v1/host/chat/threads/count`;
//   const url = `${apiBaseUrl}${endPoint}`;
//   try {
//     const result = await axios.get(url, {
//       headers: {
//         Authorization: `Bearer ${token}`, // Ensure correct prefix for token
//       },
//     });
//     console.log("reselt inside  function", result);
//     isRequestInProgress = false;

//     return result;
//   } catch (error) {
//     /*return repnse when post validation faild */
//     console.log("error.response.data", error.response);
//     if (error.response?.status !== 200) {
//       isRequestInProgress = false;

//       return error.response;
//     }
//   }
// }
export async function getAllThreads(token) {
  isRequestInProgress = true;

  const endPoint = `/api/v1/application/chat/threads`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("reselt inside  function", result);
    isRequestInProgress = false;

    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response?.status !== 200) {
      isRequestInProgress = false;

      return error.response;
    }
  }
}
export async function getThreadForWorkspace(token, workspaceId) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }
  isRequestInProgress = true;

  const url = `/api/v1/application/chat/workspaces/${workspaceId}/threads`;
  try {
    const result = await baseURL.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("reselt inside  function", result);
    isRequestInProgress = false;

    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response?.status !== 200) {
      isRequestInProgress = false;

      return error.response;
    }
  }
}

export async function getThreadMeassages(token, threadId) {
  const endPoint = `/api/v1/application/chat/threads/${threadId}/messages`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("reselt inside  function", result);
    isRequestInProgress = false;

    return result;
  } catch (error) {
    return error.response;
  }
}

export async function sendClientMessage(token, threadId, message) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }
  isRequestInProgress = true;
  const endPoint = `/api/v1/application/chat/threads/${threadId}/messages`;
  const url = `${endPoint}`;
  try {
    const messageFormData = new FormData();
    messageFormData.append("body", message);

    const result = await baseURL.post(url, messageFormData, {
      headers: {
        Authorization: `Bearer ${token}`, // Ensure correct prefix for token
      },
    });
    console.log("reselt inside  function", result);
    isRequestInProgress = false;

    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response?.status !== 200) {
      isRequestInProgress = false;

      return error.response;
    }
  }
}

export async function getCountOFUnReadMessages(token) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }
  isRequestInProgress = true;

  const endPoint = `/api/v1/host/chat/threads/count`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: {
        Authorization: `Bearer ${token}`, // Ensure correct prefix for token
      },
    });
    console.log("reselt inside  function", result);
    isRequestInProgress = false;

    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response?.status !== 200) {
      isRequestInProgress = false;

      return error.response;
    }
  }
}
export async function getAllMessagesToHost(token) {
  //   if (isRequestInProgress) {
  //     console.log("Request already in progress. Skipping this request.");
  //     return;
  //   }
  isRequestInProgress = true;

  const endPoint = `/api/v1/host/chat/threads`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: {
        Authorization: `Bearer ${token}`, // Ensure correct prefix for token
      },
    });
    console.log("reselt inside  function", result);
    isRequestInProgress = false;

    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response?.status !== 200) {
      isRequestInProgress = false;

      return error.response;
    }
  }
}

export async function sendHostMessageToClient(
  token,
  workspaceId,
  threadId,
  message
) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }
  isRequestInProgress = true;
  const endPoint = `/api/v1/host/chat/threads/${threadId}/workspaces/${workspaceId}/messages`;
  const url = `${endPoint}`;
  try {
    const messageFormData = new FormData();
    messageFormData.append("body", message);

    const result = await baseURL.post(url, messageFormData, {
      headers: {
        Authorization: `Bearer ${token}`, // Ensure correct prefix for token
      },
    });
    console.log("reselt inside  function", result);
    isRequestInProgress = false;

    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response?.status !== 200) {
      isRequestInProgress = false;

      return error.response;
    }
  }
}
