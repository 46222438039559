import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import NavBar from "../NavBar/NavBar";
import LoginNavBar from "../ui/login-nav_bar/LoginNavBar";
import { Spinner } from "../ui/Spinner";
import PaginationUI from "../ui/pagination/pagination.jsx";
import { getPageList } from "../ui/pagination/pagingList.js";
import {
  getTags,
  fetchSpaceTypes,
  fetchWorkspaceTypes,
} from "../../data_featching/tags";
import { fetchSpaces } from "../../data_featching/spaces";
import FilterComponents from "../ui/spaces/Filters.jsx";
import SortByDropdown from "../ui/spaces/Sort.jsx";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../context/LanguageProvider.jsx";
import { ListIcon, Star } from "lucide-react";
import EmptyState from "../ui/EmptyState.jsx";
import SpacesMap from "../ui/Map/SpacesMap";
import SearchContext from "../../context/SearchContext.jsx";
import RadioFilters from "../ui/spaces/RadioFilters.jsx";
import ScrollableCardContainer from "../ui/GenericScroll.jsx";

const Explore = () => {
  const location = useLocation();
  const [mapView, setMapView] = useState(false);
  const { homeSpaceType } = location.state || "";
  const [Loading, setLoading] = useState(true);
  const { t } = useTranslation("explore");
  const [spaces, setSpaces] = useState([]);
  const [spaceTypes, setSpaceTypes] = useState([]);
  const [workspaceTypes, setWorkspaceTypes] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [next12Months, setNext12Months] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedWorkspaceTypes, setSelectedWorkspaceTypes] = useState([]);
  const [selectedSpaceType, setSelectedSpaceTypes] = useState(
    homeSpaceType || ""
  );
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  // const [availableToDate, setAvailableToDate] = useState(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [pagination, setPagination] = useState({});
  // sort type
  const [selectedOption, setSelectedOption] = useState(null);

  // price range
  const [priceRange, setPriceRange] = useState([0, 500]);
  // plan type
  const [selectedPlans, setSelectedPlans] = useState(null);
  // rating
  const [selectedRatings, setSelectedRatings] = useState([]);
  // instant booking
  const [instantBooking, setInstantBooking] = useState(false);

  // capacity
  const [selectedCapacity, setSelectedCapacity] = useState(null);

  // Area
  const [selectedArea, setSelectedArea] = useState(null);
  const [HasPromotions, setHasPromotions] = useState(false);

  const [myLocation, setMyLocation] = useState(null);
  const { language } = useContext(LanguageContext);
  const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);
  const { searchCityId, searchDistrictsIds, Nearby } =
    useContext(SearchContext);

  const navigate = useNavigate();
  const yearFromNow = new Date();
  yearFromNow.setFullYear(yearFromNow.getFullYear() + 1);
  yearFromNow.setMonth(8);
  yearFromNow.setDate(1);

  const availableToDate = yearFromNow.toISOString().split("T")[0];
  useEffect(() => {
    const getSpcesTypes = async () => {
      try {
        const response = await fetchSpaceTypes(language);
        if (response.status === 200 || response.status === 201) {
          setSpaceTypes(response.data?.data);
          console.log("space types", response.data?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getSpcesTypes();
  }, [language]);

  useEffect(() => {
    const getWorkspaceTypes = async () => {
      try {
        const response = await fetchWorkspaceTypes(language);
        if (response.status === 200 || response.status === 201) {
          setWorkspaceTypes(response.data?.data);
          console.log("workspaces types", response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getWorkspaceTypes();
  }, [language]);

  const AdjustSort = (option) => {
    if (option === "top rated") return { by: "rating", direction: "desc" };
    else if (option === "lowest price")
      return { by: "minimum_price", direction: "asc" };
    else if (option === "newest") return { by: "created", direction: "desc" };
    else if (option === "nearest") return { by: "distance", direction: "asc" };
  };
  const getSpaces = async (page = 1, availableToDate) => {
    // if selectedoption is nearest  or nearby and mylocation is not set return
    if (
      (selectedOption === "nearest" || Nearby) &&
      (!myLocation || !myLocation.lat || !myLocation.lng)
    )
      return;
    try {
      setLoading(true);
      const response = await fetchSpaces(
        page,
        selectedWorkspaceTypes,
        selectedSpaceType,
        availableToDate,
        selectedFromDate,
        selectedAmenities,
        priceRange,
        selectedPlans,
        selectedRatings,
        AdjustSort(selectedOption),
        instantBooking,
        searchCityId,
        searchDistrictsIds,
        language,
        mapView,
        myLocation,
        Nearby,
        selectedCapacity,
        selectedArea,
        HasPromotions
      );
      if (response?.status === 429) {
        const message = response.data?.message;
        const waitTimeMatch = message.match(/(\d+)\s*seconds/);
        const waitTime = waitTimeMatch ? parseInt(waitTimeMatch[1], 10) : 60;
        setTimeout(() => {
          getSpaces(page, availableToDate);
        }, waitTime * 1000);
      }
      if (response?.status === 200 || response?.status === 201) {
        setSpaces(response?.data);
        const totalPageNo = response?.data?.meta?.last_page;
        const currentPage = response?.data?.meta?.current_page;
        const pagesList = getPageList(totalPageNo, currentPage);
        setPagination({
          page: currentPage,
          pagesCount: totalPageNo,
          pagesList,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);

      setLoading(false);
    }
  };
  function getNext12Months() {
    const currentDate = new Date();
    const months = [];

    for (let i = 0; i < 12; i++) {
      // Set the date to the 1st of the next months
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + i,
        1
      );

      // Format value as YYYY-MM-DD (always the 1st of the month)
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two digits for the month
      const value = `${year}-${month}-01`;

      // Format label as Month YYYY
      const label = date.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });

      // Push the object to the array
      months.push({
        value: value,
        label: label,
      });
    }

    setNext12Months(months);
  }

  useEffect(() => {
    getNext12Months();
  }, []);

  useEffect(() => {
    if (selectedSpaceType === "long_term") {
      getSpaces(1, availableToDate);
    } else {
      getSpaces(1, null);
    }
  }, [
    selectedWorkspaceTypes,
    language,
    selectedSpaceType,
    selectedAmenities,
    priceRange,
    selectedPlans,
    selectedRatings,
    selectedOption,
    instantBooking,
    selectedFromDate,
    searchCityId,
    searchDistrictsIds,
    myLocation,
    mapView,
    Nearby,
    selectedCapacity,
    selectedArea,
    HasPromotions,
  ]);

  // get tags
  const getAmenities = async () => {
    const response = await getTags("workspace", 100, language);
    if (response.status === 200 || response.status === 201) {
      setAmenities(response.data.data);
    }
  };
  useEffect(() => {
    getAmenities();
  }, [language]);

  const handleWorkspaceTypeChange = (type) => {
    const isSelected = selectedWorkspaceTypes.find((elem) => elem === type);
    if (isSelected) {
      setSelectedWorkspaceTypes((prev) => prev.filter((elem) => elem !== type));
    } else {
      setSelectedWorkspaceTypes((prev) => [...prev, type]);
    }
  };

  const handleSpaceTypeChange = (type) => {
    // unselect if same type is selected
    if (selectedSpaceType === type) setSelectedSpaceTypes("");
    else setSelectedSpaceTypes(type);
  };

  const handleLongTermSelectedDate = (date) => {
    if (selectedFromDate === date) {
      setSelectedFromDate(null);
    } else {
      setSelectedFromDate(date);
    }
  };

  const handleAmenitiesChange = (type) => {
    const isSelected = selectedAmenities.find((elem) => elem === type);
    if (isSelected) {
      setSelectedAmenities((prev) => prev.filter((elem) => elem !== type));
    } else {
      setSelectedAmenities((prev) => [...prev, type]);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  // set my location
  useEffect(() => {
    if (myLocation) return;
    if (Nearby || selectedOption === "nearest") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          setMyLocation({
            lat: latitude,
            lng: longitude,
          });
        });
      }
    }
  }, [Nearby, selectedOption]);

  return (
    <div className="">
      <LoginNavBar
        showBurgerLoginMenu={showBurgerLoginMenu}
        setShowBurgerLoginMenu={setShowBurgerLoginMenu}
      />
      {!showBurgerLoginMenu && spaces.data && (
        <div className="flex flex-row SpacesPage">
          <div
            className={`spacesSideBar ${
              isSidebarOpen ? "block w-[300px]" : "hidden w-[500px]"
            } xl:block fixed xl:relative xl:min-h-screen  h-full  px-6 border-r border-r-[#E9EAEB] bg-white z-5
						 overflow-y-auto
						`}
            style={{ zIndex: 5 }}
          >
            <div
              className={`${
                !isSidebarOpen ? "ltr:ml-[100px] rtl:mr-[100px]" : ""
              }`}
            >
              <div className="pb-6 mb-6 ">
                <div className="flex flex-row items-center justify-between mt-5 mb-5 xl:hidden ">
                  <p className="text-[#111827] text-2xl font-bold xl:hidden">
                    {t("filters")}
                  </p>
                  <button className="xl:hidden" onClick={toggleSidebar}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 17L17 1M1 1L17 17"
                        stroke="#1E293B"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                {selectedSpaceType === "long_term" && (
                  <>
                    <div className="mb-6">
                      <h3 className="flex flex-row items-center gap-2 mb-2 text-lg font-medium">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.4119 1.17647H15.6472V2.94118C15.6472 3.29412 15.3531 3.52941 15.059 3.52941C14.7649 3.52941 14.4707 3.29412 14.4707 2.94118V1.17647H5.05897V2.94118C5.05897 3.29412 4.76485 3.52941 4.47073 3.52941C4.17661 3.52941 3.8825 3.29412 3.8825 2.94118V1.17647H2.11779C1.23544 1.17647 0.588379 1.94118 0.588379 2.94118V5.05882H19.4119V2.94118C19.4119 1.94118 18.3531 1.17647 17.4119 1.17647ZM0.588379 6.29412V17.0588C0.588379 18.1176 1.23544 18.8235 2.17661 18.8235H17.4707C18.4119 18.8235 19.4707 18.0588 19.4707 17.0588V6.29412H0.588379ZM5.82367 16.1765H4.41191C4.17661 16.1765 3.94132 16 3.94132 15.7059V14.2353C3.94132 14 4.11779 13.7647 4.41191 13.7647H5.8825C6.11779 13.7647 6.35309 13.9412 6.35309 14.2353V15.7059C6.29426 16 6.11779 16.1765 5.82367 16.1765ZM5.82367 10.8824H4.41191C4.17661 10.8824 3.94132 10.7059 3.94132 10.4118V8.94118C3.94132 8.70588 4.11779 8.47059 4.41191 8.47059H5.8825C6.11779 8.47059 6.35309 8.64706 6.35309 8.94118V10.4118C6.29426 10.7059 6.11779 10.8824 5.82367 10.8824ZM10.5296 16.1765H9.05897C8.82367 16.1765 8.58838 16 8.58838 15.7059V14.2353C8.58838 14 8.76485 13.7647 9.05897 13.7647H10.5296C10.7648 13.7647 11.0001 13.9412 11.0001 14.2353V15.7059C11.0001 16 10.8237 16.1765 10.5296 16.1765ZM10.5296 10.8824H9.05897C8.82367 10.8824 8.58838 10.7059 8.58838 10.4118V8.94118C8.58838 8.70588 8.76485 8.47059 9.05897 8.47059H10.5296C10.7648 8.47059 11.0001 8.64706 11.0001 8.94118V10.4118C11.0001 10.7059 10.8237 10.8824 10.5296 10.8824ZM15.2354 16.1765H13.7648C13.5296 16.1765 13.2943 16 13.2943 15.7059V14.2353C13.2943 14 13.4707 13.7647 13.7648 13.7647H15.2354C15.4707 13.7647 15.706 13.9412 15.706 14.2353V15.7059C15.706 16 15.5296 16.1765 15.2354 16.1765ZM15.2354 10.8824H13.7648C13.5296 10.8824 13.2943 10.7059 13.2943 10.4118V8.94118C13.2943 8.70588 13.4707 8.47059 13.7648 8.47059H15.2354C15.4707 8.47059 15.706 8.64706 15.706 8.94118V10.4118C15.706 10.7059 15.5296 10.8824 15.2354 10.8824Z"
                            fill="#252C34"
                          />
                        </svg>

                        {t("Available from")}
                      </h3>
                      <div className="flex flex-col space-y-2 ps-5">
                        {next12Months.map((month) => (
                          <label
                            key={crypto.randomUUID()}
                            className="inline-flex items-center py-1"
                          >
                            <input
                              type="checkbox"
                              name="longTermDates"
                              className="form-checkbox h-[18px] w-[18px] border rounded-[4px] accent-[#009E1E]"
                              onChange={() =>
                                handleLongTermSelectedDate(month.value)
                              }
                              checked={selectedFromDate?.includes(month.value)}
                            />
                            <span
                              htmlFor={month.value}
                              className="ltr:ml-2 rtl:mr-2"
                            >
                              {t(month.label.split(" ")[0]) +
                                " " +
                                month.label.split(" ")[1]}
                            </span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </>
                )}
                <RadioFilters
                  selectedSpaceType={selectedSpaceType}
                  selectedCapacity={selectedCapacity}
                  setSelectedCapacity={setSelectedCapacity}
                  selectedArea={selectedArea}
                  setSelectedArea={setSelectedArea}
                />

                <h3 className="flex flex-row items-center gap-2 mb-2 text-lg font-medium">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.333374 3.66634H3.66671V0.333008H0.333374V3.66634ZM5.33337 13.6663H8.66671V10.333H5.33337V13.6663ZM0.333374 13.6663H3.66671V10.333H0.333374V13.6663ZM0.333374 8.66634H3.66671V5.33301H0.333374V8.66634ZM5.33337 8.66634H8.66671V5.33301H5.33337V8.66634ZM10.3334 0.333008V3.66634H13.6667V0.333008H10.3334ZM5.33337 3.66634H8.66671V0.333008H5.33337V3.66634ZM10.3334 8.66634H13.6667V5.33301H10.3334V8.66634ZM10.3334 13.6663H13.6667V10.333H10.3334V13.6663Z"
                      fill="#252C34"
                    />
                  </svg>
                  {t("workspace types")}
                </h3>
                <div className="flex flex-col space-y-2 ps-5">
                  {workspaceTypes.map((type) => (
                    <label
                      key={type.key}
                      className="inline-flex items-center py-1"
                    >
                      <input
                        type="checkbox"
                        name="workspaceType"
                        className="form-checkbox h-[18px] w-[18px] border rounded-[4px] accent-[#009E1E]"
                        onChange={() => handleWorkspaceTypeChange(type.key)}
                        checked={selectedWorkspaceTypes.includes(type.key)}
                      />
                      <span htmlFor={type.key} className="ltr:ml-2 rtl:mr-2">
                        {type.label}
                      </span>
                    </label>
                  ))}
                </div>
              </div>

              {/* Amenities */}
              <div className="mb-6">
                <h3 className="flex flex-row items-center gap-1 mb-2 text-lg font-medium">
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.00008 7.16667C8.08341 7.16667 7.33341 7.91667 7.33341 8.83333C7.33341 9.75 8.08341 10.5 9.00008 10.5C9.91675 10.5 10.6667 9.75 10.6667 8.83333C10.6667 7.91667 9.91675 7.16667 9.00008 7.16667ZM14.0001 8.83333C14.0001 6.075 11.7584 3.83333 9.00008 3.83333C6.24175 3.83333 4.00008 6.075 4.00008 8.83333C4.00008 10.6833 5.00841 12.2917 6.50008 13.1583L7.33341 11.7083C6.34175 11.125 5.66675 10.0667 5.66675 8.83333C5.66675 6.99167 7.15841 5.5 9.00008 5.5C10.8417 5.5 12.3334 6.99167 12.3334 8.83333C12.3334 10.0667 11.6584 11.125 10.6667 11.7083L11.5001 13.1583C12.9917 12.2917 14.0001 10.6833 14.0001 8.83333ZM9.00008 0.5C4.40008 0.5 0.666748 4.23333 0.666748 8.83333C0.666748 11.9167 2.34175 14.6 4.82508 16.0417L5.65841 14.6C3.67508 13.4417 2.33341 11.3 2.33341 8.83333C2.33341 5.15 5.31675 2.16667 9.00008 2.16667C12.6834 2.16667 15.6667 5.15 15.6667 8.83333C15.6667 11.3 14.3251 13.4417 12.3334 14.6L13.1667 16.0417C15.6584 14.6 17.3334 11.9167 17.3334 8.83333C17.3334 4.23333 13.6001 0.5 9.00008 0.5Z"
                      fill="#252C34"
                    />
                  </svg>
                  {t("amenities")}
                </h3>
                <div className="flex flex-col space-y-2 ps-5 max-h-[200px] overflow-y-auto">
                  {amenities.map((type) => (
                    <label
                      key={type.id}
                      className="inline-flex items-center py-1"
                    >
                      <input
                        type="checkbox"
                        name="amenities"
                        className="form-checkbox h-[18px] w-[18px] border rounded-[4px] accent-[#009E1E]"
                        onChange={() => handleAmenitiesChange(type.id)}
                        checked={selectedAmenities.includes(type.id)}
                      />
                      <span htmlFor={type.slug} className="ltr:ml-2 rtl:mr-2">
                        {type.name}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
              <FilterComponents
                priceRange={priceRange}
                setPriceRange={setPriceRange}
                setSelectedPlan={setSelectedPlans}
                setSelectedRatings={setSelectedRatings}
                setInstantBooking={setInstantBooking}
                setHasPromotions={setHasPromotions}
              />
            </div>
          </div>

          <div
            className={
              mapView
                ? "relative xl:ps-5 p-3 xl:pe-[112px] px-10 h-[10px] w-full "
                : "spacesContent w-full bg-[#f9fafb] xl:ps-5 p-3 xl:pe-[112px] md:px-10 px-2"
            }
            style={{ zIndex: 4 }}
          >
            <div className="flex flex-row items-center justify-between gap-2 mt-5 filterationButtons ">
              <ScrollableCardContainer>
                <div className="flex items-center justify-between gap-2 spacesfilters">
                  {spaceTypes &&
                    spaceTypes.map((type) => {
                      if (type.key === "studio") return null;
                      return (
                        <button
                          key={type.key}
                          className={` py-1 outline-none px-4 rounded-lg shadow-md font-[500] whitespace-nowrap cursor-pointer ${
                            selectedSpaceType === type.key
                              ? "bg-[#B8E9B7] text-[#005C00]"
                              : "bg-white"
                          }`}
                          onClick={() => handleSpaceTypeChange(type.key)}
                        >
                          {type.label}
                        </button>
                      );
                    })}
                </div>
              </ScrollableCardContainer>
              <div className="hidden mapView lg:block xl:hidden">
                <button
                  onClick={() => setMapView(!mapView)}
                  className="flex bg-[#009E1E] text-white py-2 px-4 border rounded-lg whitespace-nowrap cursor-pointer
                   items-center justify-center gap-1
                  "
                >
                  {!mapView ? (
                    <>
                      {t("map view")}
                      <svg
                        className="rtl:mr-2 ltr:ml-2"
                        width="10"
                        height="15"
                        viewBox="0 0 15 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.5 7.7002C14.5 12.2655 10.26 17.1749 8.37981 19.1276C7.89319 19.6329 7.10681 19.6329 6.62019 19.1276C4.73995 17.1749 0.5 12.2655 0.5 7.7002C0.5 3.8342 3.63401 0.700195 7.5 0.700195C11.366 0.700195 14.5 3.8342 14.5 7.7002Z"
                          fill="white"
                        />
                        <circle cx="7.5" cy="7.5" r="4" fill="#009E1E" />
                      </svg>
                    </>
                  ) : (
                    <>
                      {t("list view")}
                      <ListIcon size={24} fill="white" />
                    </>
                  )}
                </button>
              </div>
              <div className="hidden mapView xl:block">
                <button
                  onClick={() => setMapView(!mapView)}
                  className="flex justify-center  bg-[#009E1E] text-white w-9 h-9  p-2 border rounded-[50%] cursor-pointer"
                >
                  {!mapView ? (
                    <svg
                      width="15"
                      height="20"
                      viewBox="0 0 15 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5 7.7002C14.5 12.2655 10.26 17.1749 8.37981 19.1276C7.89319 19.6329 7.10681 19.6329 6.62019 19.1276C4.73995 17.1749 0.5 12.2655 0.5 7.7002C0.5 3.8342 3.63401 0.700195 7.5 0.700195C11.366 0.700195 14.5 3.8342 14.5 7.7002Z"
                        fill="white"
                      />
                      <circle cx="7.5" cy="7.5" r="4" fill="#009E1E" />
                    </svg>
                  ) : (
                    <ListIcon size={24} fill="green" />
                  )}
                </button>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between mt-8">
              <div className="flex items-center gap-4">
                <button
                  className={`bg-[#009E1E] flex gap-1 items-center text-white py-2 px-2 md:px-4 rounded-full md:rounded-lg  cursor-pointer `}
                  onClick={toggleSidebar}
                >
                  <span className="hidden md:block">{t("filters")}</span>
                  <img src="/filters.svg" alt="" className="" />
                </button>
                {!mapView && (
                  <p className=" resultsNumber whitespace-nowrap text-[#111827] sm:text-2xl text-xl font-bold">
                    {spaces.meta["total"]} {t("result found")}
                  </p>
                )}
              </div>
              <div className="flex items-center gap-1 md:gap-3">
                {!mapView && (
                  <SortByDropdown
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                  />
                )}
                <div className="block mapView lg:hidden">
                  <button
                    onClick={() => setMapView(!mapView)}
                    className="flex justify-center  bg-[#009E1E] text-white w-9 h-9  p-2 border rounded-[50%] cursor-pointer"
                  >
                    {!mapView ? (
                      <svg
                        width="15"
                        height="20"
                        viewBox="0 0 15 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.5 7.7002C14.5 12.2655 10.26 17.1749 8.37981 19.1276C7.89319 19.6329 7.10681 19.6329 6.62019 19.1276C4.73995 17.1749 0.5 12.2655 0.5 7.7002C0.5 3.8342 3.63401 0.700195 7.5 0.700195C11.366 0.700195 14.5 3.8342 14.5 7.7002Z"
                          fill="white"
                        />
                        <circle cx="7.5" cy="7.5" r="4" fill="#009E1E" />
                      </svg>
                    ) : (
                      <ListIcon size={24} fill="green" />
                    )}
                  </button>
                </div>
              </div>
            </div>
            {!mapView && (
              <div className="flex flex-col ">
                {spaces.data &&
                  spaces.data.map((space) => {
                    return (
                      <div
                        onClick={() => {
                          navigate(`/explore/space/${space.id}`);
                        }}
                        key={space.id}
                        className="cursor-pointer"
                      >
                        <div
                          key={space.id}
                          className="flex items-start my-4 bg-white shadow-sm feature_workSpace_card rounded-2xl"
                        >
                          <div className="Workspace_img relative  flex items-center 2xl:w-[26.25rem] lg:w-[25rem] sm:w-full bg-white  sm:rounded-t-2xl lg:ltr:rounded-l-2xl lg:rtl:rounded-r-2xl lg:rounded-t-none">
                            <img
                              src={`${
                                space?.media?.length > 0
                                  ? space?.media[0]?.url
                                  : "/workspace-img.png"
                              }`}
                              alt=""
                              className="object-cover w-full h-full sm:rounded-t-2xl lg:ltr:rounded-l-2xl lg:rtl:rounded-r-2xl lg:rounded-t-none"
                            />
                            {space.rating > 0 && (
                              <div className="absolute flex items-center px-2 py-1 text-white bg-black bg-opacity-50 rounded-lg top-2 rtl:right-2 ltr:left-2">
                                <span className="mr-1 font-bold text-md">
                                  {space.rating}
                                </span>
                                <Star className="w-4 h-4 text-yellow-400 fill-current" />
                              </div>
                            )}
                          </div>
                          <div className="relative flex flex-col justify-start w-full gap-4 p-2 md:p-4 WorkSpace_card_details">
                            <div className="flex flex-col gap-1 WorkSpace_title_address">
                              <div className="flex items-center gap-2 workSpace_title">
                                <span className="text-[#111827] text-xl font-bold">
                                  {space.name}
                                </span>
                              </div>
                              <div className="flex items-center space-x-2">
                                {space.rating > 0 && (
                                  <>
                                    <div className="flex items-center my-2">
                                      {Array.from({ length: space.rating }).map(
                                        (_, index) => (
                                          <Star
                                            key={index}
                                            size={24}
                                            className="text-yellow-400"
                                            fill="currentColor"
                                          />
                                        )
                                      )}
                                    </div>

                                    <span className="font-bold text-gray-800 rtl:pr-2">
                                      ({space?.rating})
                                    </span>
                                    {space.ratings_count && (
                                      <span className="text-gray-500">
                                        {t("based on")} {space.ratings_count}{" "}
                                        {t("reviews")}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="workspac_adderes text-[#6b7280] text-sm">
                                <span>
                                  {space?.workspace?.address?.building_number ||
                                    space?.workspace?.address?.street_name}
                                  ,{space.workspace.address.district.name}
                                </span>
                              </div>
                            </div>
                            <div className="hidden sm:grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2 h-[135px] overflow-hidden">
                              {space?.amenities?.map((amenity, index) => (
                                <div
                                  key={amenity.id}
                                  className="flex items-center gap-2"
                                  style={{
                                    gridColumn: `${Math.floor(index / 4) + 1}`,
                                    gridRow: `${(index % 4) + 1}`,
                                  }}
                                >
                                  <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{
                                      __html: amenity.icon,
                                    }}
                                  />
                                  <span className="text-sm truncate">
                                    {amenity.name}
                                  </span>
                                </div>
                              ))}
                            </div>

                            <div className="flex flex-row items-center justify-between workspace-cost-action">
                              <div className="flex items-center gap-1 cost-details">
                                <span className="text-[#252c34] text-4xl font-bold">
                                  {`${
                                    space.plan.shift.price
                                      ? Number(space.plan.shift.price).toFixed(
                                          0
                                        )
                                      : ""
                                  }`}
                                </span>
                                <span className="cost-currency text-[#252c34] text-base">
                                  {space.plan.shift?.currency?.label}
                                </span>

                                <span className="cost-per text-[#6b7280] text-sm">
                                  {"/"}
                                  {space.plan?.name?.label}
                                  <span className="ml-2 text-gray-500">
                                    {space.plan?.shift?.name?.key !== "all" &&
                                      '"' +
                                        space.plan?.shift?.name?.label +
                                        '"'}
                                  </span>
                                </span>
                              </div>
                              <Link
                                to={`/explore/space/${space.id}`}
                                className="space-action flex items-center justify-center whitespace-nowrap text-[#000021] px-5  py-2 rounded-lg bg-white border border-[#009e1e] cursor-pointer shadow-sm"
                              >
                                <span className=" text-sm font-medium leading-5 text-[#000021] pe-3">
                                  {t("get pass")}
                                </span>
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M10.8571 0C8.01682 0 5.71429 2.30254 5.71429 5.14286C5.71429 5.44284 5.74003 5.73726 5.78956 6.02392C5.84075 6.32021 5.76517 6.56957 5.62108 6.71365L0.66947 11.6653C0.240816 12.0939 0 12.6753 0 13.2815V15.4286C0 15.7442 0.255837 16 0.571429 16H3.42857C3.74416 16 4 15.7442 4 15.4286V14.2857H5.14286C5.45845 14.2857 5.71429 14.0299 5.71429 13.7143V12.5714H6.85714C7.0087 12.5714 7.15404 12.5112 7.2612 12.4041L9.28635 10.3789C9.43043 10.2348 9.67979 10.1593 9.97608 10.2104C10.2627 10.26 10.5572 10.2857 10.8571 10.2857C13.6975 10.2857 16 7.98318 16 5.14286C16 2.30254 13.6975 0 10.8571 0ZM10.8571 2.28571C10.5416 2.28571 10.2857 2.54155 10.2857 2.85714C10.2857 3.17273 10.5416 3.42857 10.8571 3.42857C11.8039 3.42857 12.5714 4.19608 12.5714 5.14286C12.5714 5.45845 12.8273 5.71429 13.1429 5.71429C13.4584 5.71429 13.7143 5.45845 13.7143 5.14286C13.7143 3.5649 12.4351 2.28571 10.8571 2.28571Z"
                                    fill="#000021"
                                  />
                                </svg>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          {mapView && (
            <div className="absolute inset-0 z-0 w-full h-[100vh]">
              <SpacesMap addresses={spaces.data} />
            </div>
          )}
        </div>
      )}
      {!spaces.data && Loading && <Spinner />}
      {!spaces.data && !Loading && <EmptyState message={t("No Spaces")} />}
      {!mapView && spaces.data && (
        <div className="flex justify-center">
          <PaginationUI
            pagination={pagination}
            changePageHandler={setPagination}
            fetchDataHandler={getSpaces}
            currentLanguage={"en"}
          />
        </div>
      )}
    </div>
  );
};

export default Explore;
