import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function FirstStep({
  email,
  setEmail,
  setIsValidEmail,
  stepOneClicked,
  emailStepOneError,
}) {
  function validateEmail(email) {
    const emailRegex =
      /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/i;

    return emailRegex.test(email);
  }
  const isValid = validateEmail(email);
  const { t } = useTranslation("profile");

  useEffect(() => {
    setIsValidEmail(isValid);
  }, [isValid]);

  return (
    <>
      <label htmlFor="email" className="block mb-2">
        {t("new email")}
      </label>
      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        className="w-full outline-none border shadow-sm rounded-lg border-[#E5E7EB] py-2 px-3"
      />
      {!validateEmail(email) && stepOneClicked && !emailStepOneError && (
        <p className="mb-2 text-red-500 font-sm">{t("enter valid email")}</p>
      )}
      {emailStepOneError && (
        <p className="mb-2 text-red-500 font-sm">{emailStepOneError}</p>
      )}
    </>
  );
}

export default FirstStep;

FirstStep.propTypes = null;
