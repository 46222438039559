import { useContext } from "react";
import { BookingContext } from "../../context/BookingProvider";
import { useTranslation } from "react-i18next";

const PlanSelection = ({ onPlanSelect, spaceDetails }) => {
  const { selectedPlan, setSelectedPlan } = useContext(BookingContext);
  const { t } = useTranslation("bookings");

  const handleSelect = (planType) => {
    setSelectedPlan(planType);
    onPlanSelect(planType);
  };

  const PlansImages = {
    hourly: "/hourlyPlan.png",
    daily: "/dayShiftPlan.png",
    monthly: "/MonthlyPlan.png",
    annually: "/annualPlan.png",
  };

  return (
    <div className="grid items-center justify-center gap-4 p-2 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1">
      {spaceDetails.plans.map(
        (plan, index) =>
          plan?.is_active && (
            <button
              key={index}
              className={`relative text-center flex sm:flex-row lg:flex-col sm:justify-between sm:items-center sm:h-[90px] lg:h-auto lg:justify-center bg-white rounded-lg lg:p-10 sm:p-4 shadow-md ${
                selectedPlan === plan?.name?.key
                  ? "border-4 border-green-500"
                  : ""
              }`}
              onClick={() => handleSelect(plan?.name?.key)}
              disabled={!plan.is_active}
            >
              {plan?.instant_booking && (
                <span className="absolute top-2 right-4 bg-[#2563EB] text-white text-[12px] flex items-center rounded-3xl px-2 py-1 whitespace-nowrap">
                  {t("instant booking")}
                  <svg
                    className="ml-1"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.334 4.97272C10.2739 4.83525 10.138 4.74646 9.98788 4.74646H6.84685L8.35982 0.504986C8.42014 0.335815 8.35307 0.147495 8.19937 0.0545691C8.04556 -0.0384069 7.84767 -0.0102874 7.72592 0.121744L1.73388 6.61943C1.63214 6.72974 1.60533 6.88984 1.6656 7.02732C1.72577 7.16479 1.86168 7.25358 2.01175 7.25358H4.05375C4.26253 7.25358 4.4317 7.08436 4.4317 6.87563C4.4317 6.6669 4.26253 6.49768 4.05375 6.49768H2.87439L7.02505 1.99678L5.95469 4.99736C5.91347 5.11312 5.93292 5.24172 6.00377 5.34216C6.07457 5.44259 6.19174 5.50231 6.31465 5.50231H9.12519L4.97514 10.0027L6.04565 7.00262C6.08697 6.88687 6.06943 6.75826 5.99858 6.65783C5.92773 6.5574 5.81258 6.49763 5.68967 6.49763H5.68594C5.5083 6.49763 5.36065 6.62019 5.32059 6.78533L3.64006 11.4949C3.57969 11.6641 3.64677 11.8524 3.80047 11.9453C3.86119 11.9821 3.92882 11.9999 3.99589 11.9999C4.09865 11.9999 4.20024 11.958 4.27391 11.8781L10.2656 5.38051C10.3674 5.27025 10.3943 5.1102 10.334 4.97272Z"
                      fill="white"
                    />
                  </svg>
                </span>
              )}
              <div className="sm:h-[50px] lg:h-auto">
                <img
                  src={PlansImages[plan.name?.key]}
                  alt={plan.name.label}
                  className="h-full"
                />
              </div>
              <div>
                <p className="mt-2 text-xl font-bold">
                  {plan.shifts[0]?.price}
                  <span className="text-sm text-[#B3B5B8] font-normal ml-1">
                    {plan.shifts[0]?.currency?.label}/{plan.name.label}
                  </span>
                </p>
              </div>
            </button>
          )
      )}
    </div>
  );
};

export default PlanSelection;
