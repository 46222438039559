import { X } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50 "
      style={{ zIndex: 1000 }}
    >
      {children}
    </div>
  );
};

const SuccessModal = ({ isOpen, onClose, message }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <div className="w-full max-w-md overflow-hidden bg-white rounded-lg">
      <div className="text-center">
        <div className="flex items-center justify-center mx-auto mb-4">
          <img src="/info.svg" alt="Checkmark" />
        </div>
        <h3 className="mb-2 text-lg font-medium text-gray-900">{message}</h3>
        {/* <button
        onClick={onClose}
        className="inline-flex justify-center w-full px-4 py-2 mt-4 text-base font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
      >
        Close
      </button> */}
      </div>
    </div>
  </Modal>
);

const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmText,
  icon,
  name,
  showBottom = true,
}) => {
  const { t, i18n } = useTranslation("entities");
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="w-full max-w-md overflow-hidden bg-white rounded-lg">
        <div className="flex flex-col w-full h-full">
          <div className="flex items-center justify-between p-4 ">
            <h3 className="text-lg font-medium text-gray-900">{title}</h3>
            <X
              className="w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-500"
              onClick={onClose}
            />
          </div>
          <div className="flex-grow p-6">
            <p className="text-sm text-gray-500" dir={i18n.dir()}>
              {message} {i18n.dir() === "rtl" ? "؟" : "?"}
            </p>
          </div>
          {showBottom && (
            <div className="flex flex-row items-center p-4 ">
              <img src={icon} alt={name} className="w-12 h-12" />
              <span>{name}</span>
            </div>
          )}
          <div className="flex justify-end gap-3 p-4 ">
            <button
              type="button"
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={onClose}
            >
              {t("No")}
            </button>
            <button
              type="button"
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={onConfirm}
            >
              {confirmText}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const IconModal = ({
  isOpen,
  onClose,
  icon,
  message,
  showBottom = true,
  actionText,
  onAction,
  title,
}) => {
  const { t } = useTranslation("entities");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="w-full max-w-md overflow-hidden bg-white rounded-lg">
        <div className="flex flex-col w-full h-full">
          <div className="flex items-center justify-between p-6 ">
            <h3 className="text-lg font-medium text-gray-900">{title}</h3>
            <X
              className="w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-500"
              onClick={onClose}
            />
          </div>
          <div className="text-center">
            <div className="flex items-center justify-center ">
              <img src={icon} alt="Checkmark" />
            </div>
            <h3 className="p-2 text-lg font-medium text-gray-400">{message}</h3>
            {showBottom && (
              <div className="flex justify-end p-6 space-x-3 ">
                <button
                  onClick={onClose}
                  className="px-4 py-2 mr-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {t("Cancel")}
                </button>

                {/* <button
            onClick={onAction}
            className="flex items-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"

         >
            {actionText}
            <Undo2 className="w-5 h-5 ml-2" />
          </button> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const AuthModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation("home");
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="w-full max-w-md p-6 overflow-hidden bg-white rounded-lg shadow-xl">
        <div className="flex items-center justify-between mb-4">
          <h2 className="mb-4 text-xl font-semibold">
            {t("Join WORKEY")}
            </h2>
          <X
            className="w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-500"
            onClick={onClose}
          />
        </div>
        <img src="/WORKEY_footer.png" alt="auth" className="m-auto " />

        <p className="mb-6 text-gray-600">
          {t("Please login or create account to enjoy limitless features")}
        </p>
        <div className="flex items-center w-full gap-2 ">
          <Link
            to="/get-started"
            className="w-full px-4 py-2 text-sm font-medium text-center text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t("Create account")}
          </Link>
          <Link
            to="/login"
            className="w-full px-4 py-2 text-sm font-medium text-center text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            {t("Login")}
          </Link>
        </div>
      </div>
    </Modal>
  );
};
const CommunityModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation("home");
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="w-full max-w-md p-6 overflow-hidden bg-white rounded-lg shadow-xl">
        <div className="flex items-center justify-between mb-4">
          <h2 className="mb-4 text-xl font-semibold">
            {t("Company account")}
          </h2>
          <X
            className="w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-500"
            onClick={onClose}
          />
        </div>
        <img src="/WORKEY_footer.png" alt="auth" className="m-auto " />

        <p className="mb-6 text-gray-600">
          {t("Please switch to business account to join WORKEY community")}
        </p>
        <div className="flex items-center w-full gap-2 ">
          <button
            onClick={onClose}
            className="w-full px-4 py-2 text-sm font-medium text-center text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t("Cancel")}
          </button>
          <Link
            to="/get-started"
            className="w-full px-4 py-2 text-sm font-medium text-center text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            {t("Create company account")}
          </Link>
        </div>
      </div>
    </Modal>
  );
};
// terms and conditions modal

const TermsModal = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation("terms");

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50"
      style={{ zIndex: 1000 }}
    >
      <div className="bg-white rounded-lg overflow-y-auto shadow-xl max-w-[70%] w-full p-6 flex flex-col max-h-[70%]">
        <div className="flex items-center justify-between mb-8">
          <h2 className="text-xl font-bold text-gray-800">{t("title")}</h2>
          <X
            className="w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-500"
            onClick={onClose}
          />
        </div>
        <div className="flex flex-col mb-6 text-gray-600">
          {Array.from({ length: 12 }, (_, i) => (
            <div key={i} className="mb-4">
              <h3 className="mb-2 text-lg font-bold">{t(`title${i + 1}`)}</h3>
              <ul className="space-y-2 list-disc list-inside">
                {Array.from({ length: 10 }, (_, j) =>
                  t(`point${i + 1}${j + 1}`)
                )
                  .filter(Boolean)
                  .map(
                    (point, idx) =>
                      !point.startsWith("point") && (
                        <li
                          key={idx}
                          className="text-sm leading-relaxed text-gray-600"
                        >
                          {point.trim()}
                          {point.trim().endsWith(".") ? "" : "."}
                        </li>
                      )
                  )}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export {
  Modal,
  SuccessModal,
  ConfirmModal,
  IconModal,
  AuthModal,
  CommunityModal,
  TermsModal,
};
