import { useTransition } from "react";
import "./Pagination.css";

function PaginationUI({
  pagination,
  changePageHandler,
  fetchDataHandler,
  currentLanguage,
}) {
  const [isPending, startTransition] = useTransition();

  // Function to generate page numbers with dots
  const getPagesList = () => {
    const { page, pagesCount } = pagination;
    const pages = [];
    
    if (pagesCount <= 10) {
      // If 10 or fewer pages, show all pages
      return pagination.pagesList;
    }

    // Always add first page
    pages.push([1, page === 1]);

    if (page <= 4) {
      // Near the start
      for (let i = 2; i <= 5; i++) {
        pages.push([i, page === i]);
      }
      pages.push(["...", false]);
      for (let i = pagesCount - 1; i <= pagesCount; i++) {
        pages.push([i, page === i]);
      }
    } else if (page >= pagesCount - 3) {
      // Near the end
      pages.push(["...", false]);
      for (let i = pagesCount - 4; i <= pagesCount; i++) {
        pages.push([i, page === i]);
      }
    } else {
      // In the middle
      pages.push(["...", false]);
      for (let i = page - 1; i <= page + 1; i++) {
        pages.push([i, page === i]);
      }
      pages.push(["...", false]);
      pages.push([pagesCount, page === pagesCount]);
    }

    return pages;
  };

  return (
    <>
      {isPending ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          {currentLanguage === "en" ? "Loading..." : "جار التحميل..."}
        </div>
      ) : (
        <>
          {pagination.pagesCount > 1 && (
            <div className="pageing-admin-dash">
              <div className="page-items flex w-full justify-center gap-[7px]">
                    {pagination.page !== 1 && (
                      <>
                        <div
                          className="relative cursor-pointer circul_div"
                          onClick={() => {
                            if (pagination.page > 1) {
                              changePageHandler({
                                ...pagination,
                                page: +pagination.page - 1,
                              });
                              startTransition(() => {
                                fetchDataHandler(+pagination.page - 1);
                              });
                            }
                          }}
                        >
                          <svg
                            className=""
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="35"
                            viewBox="0 0 36 35"
                            fill="none"
                          >
                            <circle
                              cx="18.145"
                              cy="17.5"
                              r="17"
                              transform="rotate(180 18.145 17.5)"
                              fill="white"
                              stroke="#E9EAEB"
                            />
                          </svg>
                          <svg
                            className="absolute top-[30%] left-[28%] arrow"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="15"
                            viewBox="0 0 16 15"
                            fill="none"
                          >
                            <path
                              d="M13.77 6.98438H3.76221L7.74658 2.92969C7.95752 2.71875 7.95752 2.39062 7.74658 2.17969C7.53564 1.96875 7.20752 1.96875 6.99658 2.17969L2.14502 7.10156C1.93408 7.3125 1.93408 7.64062 2.14502 7.85156L6.99658 12.7734C7.09033 12.8672 7.23096 12.9375 7.37158 12.9375C7.51221 12.9375 7.62939 12.8906 7.74658 12.7969C7.95752 12.5859 7.95752 12.2578 7.74658 12.0469L3.78564 8.03906H13.77C14.0513 8.03906 14.2856 7.80469 14.2856 7.52344C14.2856 7.21875 14.0513 6.98438 13.77 6.98438Z"
                              fill="#9CA3AF"
                            />
                          </svg>
                        </div>
                      </>
                    )}

                    {getPagesList().map((item, index) => (
                      <button
                        key={index}
                        className={`${
                          item[1]
                            ? "active circul_div relative"
                            : "circul_div relative"
                        }`}
                        onClick={() => {
                          if (item[0] !== "...") {
                            changePageHandler({
                              ...pagination,
                              page: +item[0],
                            });
                            startTransition(() => {
                              fetchDataHandler(item[0]);
                            });
                          }
                        }}
                        disabled={item[0] === "..."}
                      >
                        {item[0] !== "..." ? (
                          <>
                            {!item[1] ? (
                              <svg
                                className=""
                                xmlns="http://www.w3.org/2000/svg"
                                width="36"
                                height="35"
                                viewBox="0 0 36 35"
                                fill="none"
                              >
                                <circle
                                  cx="18.145"
                                  cy="17.5"
                                  r="17"
                                  transform="rotate(180 18.145 17.5)"
                                  fill="white"
                                  stroke="#E9EAEB"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="36"
                                height="35"
                                viewBox="0 0 36 35"
                                fill="none"
                              >
                                <circle
                                  cx="18.145"
                                  cy="17.5"
                                  r="17.5"
                                  fill="#E9EAEB"
                                />
                              </svg>
                            )}
                            <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                              {item[0]}
                            </div>
                          </>
                        ) : (
                          <div className="w-[36px] h-[35px] flex items-center justify-center text-gray-500">
                            ...
                          </div>
                        )}
                      </button>
                    ))}

                    {pagination.page < pagination.pagesCount && (
                      <div
                        className="relative cursor-pointer circul_div"
                        onClick={() => {
                          if (pagination.page < pagination.pagesCount) {
                            changePageHandler({
                              ...pagination,
                              page: +pagination.page + 1,
                            });
                            startTransition(() => {
                              fetchDataHandler(+pagination.page + 1);
                            });
                          }
                        }}
                      >
                        <svg
                          className=""
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="35"
                          viewBox="0 0 36 35"
                          fill="none"
                        >
                          <circle
                            cx="18.145"
                            cy="17.5"
                            r="17"
                            transform="rotate(180 18.145 17.5)"
                            fill="white"
                            stroke="#E9EAEB"
                          />
                        </svg>
                        <svg
                            className="absolute top-[30%] left-[28%] arrow"
                            xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="15"
                          viewBox="0 0 16 15"
                          fill="none"
                        >
                          <path
                            d="M14.145 7.125L9.29346 2.20312C9.08252 1.99219 8.75439 1.99219 8.54346 2.20312C8.33252 2.41406 8.33252 2.74219 8.54346 2.95313L12.481 6.96094H2.52002C2.23877 6.96094 2.00439 7.19531 2.00439 7.47656C2.00439 7.75781 2.23877 8.01562 2.52002 8.01562H12.5278L8.54346 12.0703C8.33252 12.2812 8.33252 12.6094 8.54346 12.8203C8.63721 12.9141 8.77783 12.9609 8.91846 12.9609C9.05908 12.9609 9.19971 12.9141 9.29346 12.7969L14.145 7.875C14.356 7.66406 14.356 7.33594 14.145 7.125Z"
                            fill="#9CA3AF"
                          />
                        </svg>
                      </div>
                    )}
                  
             
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default PaginationUI;
PaginationUI.propTypes = null;