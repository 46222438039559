import { useState, useEffect, useContext } from "react";
import { getAvailableResources } from "../../data_featching/booking";
import { Spinner } from "../ui/Spinner";
import { postInitialBookingData } from "../../data_featching/booking";
import { BookingContext } from "../../context/BookingProvider";
import { useTranslation } from "react-i18next";

const ResourcesSelection = ({
  // planType,
  planId,
  onBack,
  setCurrentStep,
  updateInitialBookingData,
  initialBookingData,
}) => {
  const [resourcesList, setResourcesList] = useState([]);
  const { bookedResources, setBookedResources,
    setBookingData

   } = useContext(BookingContext);
  const [loading, setLoading] = useState(true);
  const { t ,i18n} = useTranslation("bookings");

  const token = localStorage.getItem("authToken");

  const handleAddResource = (resourceId, availableQuantity, planId) => {
    setBookedResources((prevResources) => {
      const updatedResources = [...prevResources];
      const resourceIndex = updatedResources.findIndex(
        (res) => res.id === planId
      );

      if (resourceIndex === -1) {
        return [{ id: planId, quantity: 1 }, ...prevResources];
      }

      const currentQuantity = updatedResources[resourceIndex].quantity;
      if (currentQuantity < availableQuantity) {
        updatedResources[resourceIndex] = {
          ...updatedResources[resourceIndex],
          quantity: currentQuantity + 1,
        };
      }

      return updatedResources;
    });
  };

  const handleRemoveResource = (planId) => {
    setBookedResources((prevResources) => {
      const updatedResources = [...prevResources];
      const resourceIndex = updatedResources.findIndex(
        (res) => res.id === planId
      );

      if (resourceIndex === -1) return prevResources;

      const currentQuantity = updatedResources[resourceIndex].quantity;

      if (currentQuantity === 1) {
        return updatedResources.filter((res) => res.id !== planId);
      }

      updatedResources[resourceIndex] = {
        ...updatedResources[resourceIndex],
        quantity: currentQuantity - 1,
      };

      return updatedResources;
    });
  };

  useEffect(() => {
    const fetchResources = async () => {
      const response = await getAvailableResources(planId);
      if (response?.status === 200 || response?.status === 201) {
        setResourcesList(response?.data?.data);
        setLoading(false);
      }
    };
    fetchResources();
  }, []);



  if (loading) {
    return <Spinner />;
  }

  const handleSubmit = async () => {
    const updatedBookingData = {
      ...initialBookingData,
      resource_plans: bookedResources,
    };
    updateInitialBookingData(updatedBookingData);
    console.log("updatedBookingData", updatedBookingData);
    const response = await postInitialBookingData(updatedBookingData, token, i18n.language);
    if (response?.status === 200 || response?.status === 201) {
      setBookingData(response?.data?.data);
      setCurrentStep(4);
    }
    console.log("response", response);
  };

  return (
    <>
      <div className="max-h-[470px] overflow-y-auto custom-scrollbar">
        {resourcesList?.map((resource) => (
          <div
            key={resource?.id}
            className="flex items-center gap-2 my-4 bg-white rounded-lg shadow-md resourceCard"
          >
            <div className="resourceImg h-[150px] lg:w-[200px] sm:w-[150px] rounded-l-lg">
              <img
                src={resource?.photo?.url}
                alt=""
                className="w-full h-full rounded-l-lg"
              />
            </div>
            <div className="w-full resourceDetails ">
              <h1 className="text-lg font-semibold">{resource?.name}</h1>
              <p className="text-sm text-[#6B7280] my-3">
                {resource?.type?.label}
              </p>
              <div className="flex items-center justify-between w-full mt-4">
                <p className="text-2xl font-bold">
                  {resource?.plans[0]?.price}{" "}
                  <span className="text-[#252C34] font-normal text-lg mx-1">
                    {resource?.plans[0]?.currency?.label}
                  </span>
                  <span className="text-[#6B7280] font-normal text-lg">
                    {resource?.plans[0]?.name?.label}
                  </span>
                </p>
                <div className="flex items-center gap-4 mb-2 mr-2">
                  <button
                    // disable when have no planid in bookedResources
                    disabled={
                      !bookedResources.find(
                        (res) => res.id === resource.plans[0]?.id
                      )
                    }
                    className="w-10 h-10 bg-[#252C34] rounded-full flex items-center justify-center"
                    onClick={() => handleRemoveResource(resource.plans[0]?.id)}
                  >
                    <span
                      //change color of - sign when disabled
                      className={`text-3xl text-${
                        !bookedResources.find(
                          (res) => res.id === resource.plans[0]?.id
                        )
                          ? "gray-400"
                          : "[#009E1E]"
                      } leading-none`}
                    >
                      -
                    </span>
                  </button>
                  <span className="text-2xl font-bold text-gray-800">
                    {bookedResources.find(
                      (res) => res.id === resource.plans[0]?.id
                    )?.quantity || 0}
                  </span>
                  <button
                    // disable when quantity is equal to available quantity
                    disabled={
                      bookedResources.find(
                        (res) => res.id === resource.plans[0]?.id
                      )?.quantity === resource?.quantity
                    }
                    className="w-10 h-10 bg-[#252C34] rounded-full flex items-center justify-center"
                    onClick={() =>
                      handleAddResource(
                        resource?.id,
                        resource?.quantity,
                        resource?.plans[0]?.id
                      )
                    }
                  >
                    <span
                      //change color of + sign when disabled
                      className={`text-3xl text-${
                        bookedResources.find(
                          (res) => res.id === resource.plans[0]?.id
                        )?.quantity === resource.quantity
                          ? "gray-400"
                          : "[#009E1E]"
                      } leading-none`}
                    >
                      +
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
        {resourcesList?.length == 0 && (
          <div className="flex flex-col items-center justify-center h-[470px]">
            <p className="text-lg font-semibold text-gray-800">
              {t("No resources available for this plan")}
            </p>
          </div>
        )}
      </div>
      <div className="flex justify-end gap-4 mt-5">
        <button
          className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow-sm"
          onClick={onBack}
        >
             {i18n.language === "en" ? (
            <svg
              className="mt-[5px]"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 11L2 6L7 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M12 11L7 6L12 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          ) : (
            <svg
              className="mt-[5px]"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 11L11 6L6 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M1 11L6 6L1 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          )}
          {t("Back")}
        </button>
        <button
          className="bg-[#009E1E] px-4 py-2 text-white rounded-lg"
          onClick={handleSubmit}
        >
          {t("Review Booking")}
        </button>
      </div>
    </>
  );
};

export default ResourcesSelection;
ResourcesSelection.propTypes = null;
