import { useTranslation } from "react-i18next";
const NoConversationsState = () => {
	const { t } = useTranslation("conversations");
	return (
		<div className="flex w-full flex-col justify-center items-center gap-6 h-[100vh] bg-white rounded-2xl">
			<img src="/image 7.png" alt="" className="" />
			<p className="Empty__main_msg">{t("No Conversation Selected")}</p>
			<p className="second_empty_msg">
				{t("Select a conversation from the list to view your messages here.")}
			</p>
		</div>
	);
};

export default NoConversationsState;
