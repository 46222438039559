import axios from "axios";
const apiBaseUrl = import.meta.env.VITE_BASE_URL;
import baseURL from "../../axios";

const websiteRedirectUrl = `${import.meta.env.VITE_CUSTOMER_URL}/login`; //for production
// const redirectUrl = "http://localhost:5173/login"; // for development only
let isRequestInProgress = false;
export async function LinkedInAccessTokenExchange(AuthorizationCode) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skip this request.");
    return;
  }
  isRequestInProgress = true;
  const endPoint = `/api/v1/auth/linkedin-token-exchange`;
  const url = `${apiBaseUrl}${endPoint}`;
  try {
    const requiredData = {
      auth_code: AuthorizationCode,
      // redirect_uri: redirectUrl,
      redirect_uri: websiteRedirectUrl,
    };
    const result = await axios.post(url, requiredData, {
      //   headers: {
      //     Authorization: `Bearer ${token}`, // Ensure correct prefix for token
      //   },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    if (error.response.status !== 200) {
      isRequestInProgress = false;
      return error.response;
    }
  }
}
/*social login the same used with google */
export async function linkedInLoginWithBE(data) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skip this request.");
    return;
  }
  isRequestInProgress = true;
  const endPoint = `/api/v1/auth/social-login`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, data, {
      //   headers: {
      //     Authorization: `Bearer ${token}`, // Ensure correct prefix for token
      //   },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    if (error.response.status !== 200) {
      isRequestInProgress = false;
      return error.response;
    }
  }
}
