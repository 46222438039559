import { useContext, useEffect } from "react";
import AuthContext from "../../../context/AuthProvider";

const LoginHome = () => {
  const { auth, setAuth } = useContext(AuthContext);

  useEffect(() => {
    // Initialize state from localStorage if available
    const localStorageToken = localStorage.getItem("authToken");

    console.log("auth", auth);
    console.log("Login Info", auth?.loginInfo);
    console.log("profile", auth?.profileType);
    return () => {
      setAuth((prev) => {
        return { ...prev, token: localStorageToken };
      });
    };
  }, []);

  return <div>LoginHome</div>;
};

export default LoginHome;
