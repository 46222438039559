import { useState } from "react";
import { ImageUpIcon } from "lucide-react";
import styles from "./CompanyForm.module.css";
import { useTranslation } from "react-i18next";
import { Archive } from "lucide-react";
import { ConfirmModal, IconModal } from "../../ui/Models";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getCompany,
  createService,
  deleteService,
} from "../../../data_featching/community";
import { Link } from "react-router-dom";
import StatusBar from "../../ui/status-bar/StatusBar";

export const AddProductService = () => {
  const [productList, setProductList] = useState([]);
  const { uuid } = useParams();
  const { t, i18n } = useTranslation("entities");
  const token = localStorage.getItem("authToken");
  useEffect(() => {
    const fetchCompany = async () => {
      const company = await getCompany(token, uuid, i18n.language);
      if (company?.data?.services)
      setProductList(company.data.services);
    };
    fetchCompany();
  }, [uuid, i18n.language]);

  const [productImage, setProductImage] = useState(null);
  const [FormImage, setFormImage] = useState(null);
  const [productNameEN, setProductNameEN] = useState("");
  const [productNameAR, setProductNameAR] = useState("");
  const [isArchivingModalOpen, setIsArchivingModalOpen] = useState(false);
  const [isArchivedModalOpen, setIsArchivedModalOpen] = useState(false);
  const formDataToSend = new FormData();
  const [statusMessage, setStatusMessage] = useState({
    isActive: false,
    message: "",
    currentStatus: "",
  });
  const handleProductImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProductImage(URL.createObjectURL(e.target.files[0]));
      setFormImage(e.target.files[0]);
    }
  };

  const resetForm = () => {
    setProductNameEN("");
    setProductNameAR("");
    setProductImage(null);
    setFormImage(null);
  };

  const handleAddProduct = () => {
    formDataToSend.append("name[en]", productNameEN);
    formDataToSend.append("name[ar]", productNameAR);
    formDataToSend.append("image", FormImage);
    // validation of form
    if (!productNameEN || !productNameAR) {
      setStatusMessage({
        isActive: true,
        message: t("Please fill all the fields"),
        currentStatus: 2,
      });
      return;
    }
    if (!FormImage) {
      setStatusMessage({
        isActive: true,
        message: t("Please upload an image"),
        currentStatus: 2,
      });
      return;
    }

    setStatusMessage({
      isActive: true,
      message: t("Product adding now"),
      currentStatus: 1,
    });

    createService(token, uuid, formDataToSend)
      .then((res) => {
        setStatusMessage({
          isActive: true,
          message: t("Product added successfully"),
          currentStatus: 3,
        });
        setProductList((prev) => [...prev, res?.data]);
      })
      .catch((err) => {
        console.log(err);
      });
    resetForm();
    setTimeout(() => {
      setStatusMessage({
        isActive: false,
        message: "",
        currentStatus: "",
      });
    }, 3000);
  };

  const handleDeleteProduct = (id) => {
    setStatusMessage({
      isActive: true,
      message: t("Product deleting now"),
      currentStatus: 1,
    });
    deleteService(token, uuid, id).then(() => {
      setStatusMessage({
        isActive: false,
        message: t("Product deleted successfully"),
        currentStatus: 3,
      });
      setIsArchivedModalOpen(true);
      setProductList((prev) => prev.filter((product) => product.id !== id));
    });
  };

  return (
    <>
      {statusMessage.isActive && (
        <StatusBar
          message={statusMessage.message}
          statusFlag={statusMessage.currentStatus}
          currentLanguage={i18n.language}
        />
      )}
      <h2 className={styles.sectionTitle}>{t("Adding Product/Service")}</h2>
      <div className={styles.productRow}>
        <div className={styles.productImageWrapper}>
          {t("Image")}
          <label
            htmlFor="productImageInput"
            className={styles.imageUploadWrapper}
          >
            {!productImage ? (
              <div className="flex flex-col items-center justify-center w-full h-full cursor-pointer">
                <ImageUpIcon size={24} />
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-full cursor-pointer">
                <img
                  src={productImage}
                  alt="Product"
                  className="w-full h-full"
                />
              </div>
            )}
          </label>
          <input
            type="file"
            accept=".png, .jpg, .jpeg, .svg"
            id="productImageInput"
            style={{ display: "none" }}
            onChange={handleProductImageChange}
          />
        </div>
        <div className="flex flex-col w-full">
          <div className="flex xl:flex-row gap-[24px] flex-col">
            <label htmlFor="productNameEN" className={styles.inputGroupLabel}>
              {t("English name")}
              <input
                type="text"
                placeholder={t("English name")}
                className={styles.input}
                value={productNameEN}
                onChange={(e) => setProductNameEN(e.target.value)}
              />
            </label>
            <label htmlFor="productNameAR" className={styles.inputGroupLabel}>
              {t("Arabic name")}
              <input
                type="text"
                placeholder={t("Arabic name")}
                className={styles.input}
                value={productNameAR}
                onChange={(e) => setProductNameAR(e.target.value)}
              />
            </label>
          </div>
          <button
            onClick={handleAddProduct}
            className={
              !productNameEN || !productNameAR || !FormImage
                ? styles.addButtonDisabled
                : styles.addButton
            }
          >
            {t("Add")}
          </button>
        </div>
      </div>

      <div className={styles.productList}>
        <table className={styles.productTable}>
          <thead>
            <tr>
              <th>{t("Image")}</th>
              <th>{t("English Name")}</th>
              <th>{t("Arabic Name")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {productList?.map((product) => (
              <tr key={product.id}>
                <td>
                  <img
                    src={product.image.url}
                    alt={product.name}
                    className={styles.productImage}
                  />
                </td>
                <td>{product.name_translations.en}</td>
                <td>{product.name_translations.ar}</td>
                <td>
                  <button
                    onClick={() => setIsArchivingModalOpen(true)}
                    className={styles.archiveButton}
                    style={
                      i18n.language === "ar"
                        ? { marginRight: "auto" }
                        : { marginLeft: "auto" }
                    }
                  >
                    <Archive size={16} className={styles.archiveIcon} />
                    {t("Archive")}
                  </button>
                </td>
                {isArchivingModalOpen && (
                  <ConfirmModal
                    isOpen={isArchivingModalOpen}
                    onClose={() => setIsArchivingModalOpen(false)}
                    onConfirm={() => {
                      setIsArchivingModalOpen(false);
                      handleDeleteProduct(product.id);
                    }}
                    title={t("Archiving Product")}
                    message={`${t(
                      `Are you sure you want to archive the Product`
                    )}
                  "${product.name}"`}
                    confirmText={t("Yes,Archive Product")}
                    icon={product.image.url}
                    name={product.name}
                  />
                )}
                {isArchivedModalOpen && (
                  <IconModal
                    isOpen={isArchivedModalOpen}
                    onClose={() => setIsArchivedModalOpen(false)}
                    icon="/build_info.svg"
                    message={t(`Product has been archived successfully`)}
                    actionText={t("Undo")}
                    onAction={() => setIsArchivedModalOpen(false)}
                    title={t("Archive Product")}
                  />
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.actions}>
        <>
          <Link
            to={`/my-companies/edit/${uuid}`}
            className={styles.cancelButton}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 15L5 10L10 5"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M15 15L10 10L15 5"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>

            {t("Back")}
          </Link>
          <Link to={`/my-companies/view/${uuid}`} className={styles.nextButton}>
            {t("Finish")}
          </Link>
        </>
      </div>
    </>
  );
};
