import { useEffect, useRef, useState } from "react";
import { GoogleMap, useJsApiLoader, InfoWindow } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { Star } from "lucide-react";
import "./Map.css";
import { useNavigate } from "react-router-dom";

const containerStyle = {
  width: "100%",
  height: "400px",
};


const AdvancedMarker = ({ space }) => {
  const markerRef = useRef(null);
  const infoWindowRef = useRef(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  useEffect(() => {
    if (
      !window.google ||
      !window.google.maps ||
      !window.google.maps.marker ||
      !window.google.maps.marker.AdvancedMarkerElement
    ) {
      console.error("AdvancedMarkerElement is not available.");
      return;
    }

    const { AdvancedMarkerElement, PinElement } = window.google.maps.marker;

    const pinBackground = new PinElement({
      background: "black",
      borderColor: "#D94C43",
      glyphColor: "#fff",
    });

    markerRef.current = new AdvancedMarkerElement({
      position: space.position,
      content: pinBackground.element,
      draggable: false,
      map: window.mapInstance,
    });

    markerRef.current.addListener("click", () => {
      setShowInfoWindow(true);
    });

    const handleClickOutside = (event) => {
      if (infoWindowRef.current && !infoWindowRef.current.contains(event.target)) {
        setShowInfoWindow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
  
    return () => {
      if (markerRef.current) {
        markerRef.current.map = null;
      }
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [space.position]);
  const navigate = useNavigate();
  return (
    <>
      {showInfoWindow && (
        <InfoWindow
        headerDisabled={true}
          position={space.position}
          options={{ disableAutoPan: true }}
         
        >
          <div
            ref={infoWindowRef}
            key={space.id}
            className="flex items-start bg-white cursor-pointer"
             onClick={() => {
              navigate(`/explore/workspace/${space.id}`);
              
             }
            }
          >
            <div className="Workspace_img relative flex items-center sm:w-full bg-white rounded-l-2xl shadow-sm max-h-[200px] w-[150px] h-[150px]">
              <img
                src={`${
                  space?.media?.length > 0
                    ? space?.media[0]?.url
                    : "/workspace-img.png"
                }`}
                alt=""
                className="object-cover w-full h-full "
              />
              {space.rating > 0 && (
                <div className="absolute top-2 left-2 flex items-center bg-black bg-opacity-50 text-white rounded-lg px-2 py-1">
                  <span className="mr-1 text-md font-bold">{space.rating}</span>
                  <Star className="w-4 h-4 text-yellow-400 fill-current" />
                </div>
              )}
            </div>

            <div className="relative flex flex-col justify-start w-full gap-4 p-4 WorkSpace_card_details">
              <div className="flex flex-col gap-[8px] WorkSpace_title_address">
                <div className="flex items-center gap-2 workSpace_title">
                  <span className="text-[#111827] text-xl font-bold">
                     {space.name}
                  </span>
                </div>
                <div className="workspac_adderes text-[#6b7280] text-sm">
                  <span>

                    {space?.address?.country.name},
                    {space?.address?.city.name},
                    {space?.address?.district.name},
                    {space?.address?.area}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

const libraries = ["marker"];

const Map = ({ addresses = [] }) => {

  // Filter out spaces with null lat/lng and parse positions to float
  const validAddresses = addresses
    .filter((item) => {
      const lat = parseFloat(item.address.latitude);
      const lng = parseFloat(item.address.longitude);
      return !isNaN(lat) && !isNaN(lng);
    })
    .map((item) => ({
      ...item,
      position: {
        lat: parseFloat(item.address.latitude),
        lng: parseFloat(item.address.longitude),
      },
    }));

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAnb-1tnTYxitUdvDUyBkMNoN_hKWrYWQ8",
    libraries: libraries, // Add the marker library explicitly
  });

  const { t } = useTranslation("workspaces");
  return isLoaded ? (
    <div className="mb-8 w-[100%]">
      <GoogleMap
        
        mapContainerStyle={containerStyle}
        center={
          validAddresses[0]?.address.position || { lat: 24.7136, lng: 46.6753 }
        } // Default to Riyadh if no addresses
        zoom={12}
        onLoad={(map) => (window.mapInstance = map)}
        options={{
          mapId: "5e7764b2d96e3d32",
          restriction: {
            latLngBounds: {
              north: 85,  // Near North Pole
              south: -85, // Near South Pole
              west: -179.999, // Slightly inside the left boundary
              east: 179.999,  // Slightly inside the right boundary
            },
            strictBounds: true, // Prevent dragging outside these bounds
          },
        }}
      >
        {validAddresses.map((item, index) => (
          <AdvancedMarker key={index} space={item} />
        ))}
      </GoogleMap>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default Map;