import HourlyBookingPlan from "./bookingPlans/HourlyBookingPlan";
import DailyBookingPlan from "./bookingPlans/DailyBookingPlan";
import MonthlyPlan from "./bookingPlans/MonthlyPlan";
import AnnualPlan from "./bookingPlans/AnnualPlan";

const DateAndTimeSelection = ({
  planType,
  spaceDetails,
  onBack,
  setCurrentStep,
  initialBookingData,
  updateInitialBookingData,
  setShiftType,
  updateBookedShift,
  updateInstantBooking,
  handlePlanID,
}) => {
  return (
    <div>
      {planType === "hourly" && (
        <HourlyBookingPlan
          spaceDetails={spaceDetails}
          onBack={onBack}
          setCurrentStep={setCurrentStep}
          setShiftType={setShiftType}
          updateBookedShift={updateBookedShift}
          updateInstantBooking={updateInstantBooking}
          initialBookingData={initialBookingData}
          updateInitialBookingData={updateInitialBookingData}
          handlePlanID={handlePlanID}
        />
      )}
      {planType === "daily" && (
        <DailyBookingPlan
          spaceDetails={spaceDetails}
          onBack={onBack}
          setCurrentStep={setCurrentStep}
          setShiftType={setShiftType}
          updateBookedShift={updateBookedShift}
          updateInstantBooking={updateInstantBooking}
          initialBookingData={initialBookingData}
          updateInitialBookingData={updateInitialBookingData}
          handlePlanID={handlePlanID}
        />
      )}
      {planType === "monthly" && (
        <MonthlyPlan
          spaceDetails={spaceDetails}
          onBack={onBack}
          setCurrentStep={setCurrentStep}
          setShiftType={setShiftType}
          updateBookedShift={updateBookedShift}
          updateInstantBooking={updateInstantBooking}
          initialBookingData={initialBookingData}
          updateInitialBookingData={updateInitialBookingData}
          handlePlanID={handlePlanID}
        />
      )}
      {planType === "annually" && (
        <AnnualPlan
          spaceDetails={spaceDetails}
          onBack={onBack}
          setCurrentStep={setCurrentStep}
          setShiftType={setShiftType}
          updateBookedShift={updateBookedShift}
          updateInstantBooking={updateInstantBooking}
          initialBookingData={initialBookingData}
          updateInitialBookingData={updateInitialBookingData}
          handlePlanID={handlePlanID}
        />
      )}
    </div>
  );
};
export default DateAndTimeSelection;
