export const PriceRanges = {
  hourly: {
    min: 0,
    max: 200,
    step: 5,
  },
  daily: {
    min: 0,
    max: 1000,
    step: 10,
  },
  monthly: {
    min: 0,
    max: 10000,
    step: 200,
  },
  annually: {
    min: 0,
    max: 150000,
    step: 5000,
  },
};
export const areaOptions = [
  { min: 0, max: 20, label: "Up to 20 m² (Compact)" },
  { min: 21, max: 50, label: "21-50 m² (Small)" },
  { min: 51, max: 150, label: "51-150 m² (Medium)" },
  { min: 151, max: 300, label: "151-300 m² (Large)" },
  { min: 301, max: null, label: "301+ m² (Extra-Large)" },
];

export const eventCapacityOptions = [
  { min: 0, max: 25, label: "Up to 25 people (Small)" },
  { min: 26, max: 50, label: "26-50 people (Mid-Small)" },
  { min: 51, max: 100, label: "51-100 people (Medium)" },
  { min: 101, max: 200, label: "101-200 people (Large)" },
  { min: 201, max: null, label: "201+ people (Extra-Large)" },
];

export const roomCapacityOptions = [
  { min: 1, max: 4, label: "1-4 People (Small)" },
  { min: 5, max: 8, label: "5-8 People (Medium-Small)" },
  { min: 9, max: 12, label: "9-12 People (Medium)" },
  { min: 13, max: 20, label: "13-20 People (Large)" },
  { min: 21, max: null, label: "21+ People (Extra-Large)" },
];