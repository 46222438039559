import { useContext, useEffect, useRef, useState } from "react";
import "./Notification.css"; // Import the CSS file
import { getAllNotification } from "../../data_featching/notification";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../context/LanguageProvider";
import FeedbackForm from "../ui/feedback/FeedbackModel";
import { Spinner } from "../ui/Spinner";
import useTimeFormat from "../../hooks/useTimeFormat";

const Notification = ({ onClose, isClosing, setIsClosing }) => {
  const { t } = useTranslation("home");
  const { language } = useContext(LanguageContext);

  const [notification, setNotification] = useState(null);
  const { timeAgo } = useTimeFormat();

  const modalRef = useRef();
  const notifiRef = useRef();
  const token = localStorage.getItem("authToken");
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  /*close modal handler  */
  function closeAction() {
    setIsClosing(true); // Start the closing animation

    // Set timeout to match the animation duration (e.g., 2s for slide-right)
    setTimeout(() => {
      setIsClosing(false); // Reset the closing state
      onClose(); // Trigger the close action (e.g., remove the modal from the DOM)
    }, 1000); // Timeout should match the closing animation duration (2s here)
  }
  function handleClick(e) {
    if (modalRef.current && modalRef.current == e.target) {
      closeAction();
    }
  }
  const fetchNotification = async () => {
    const result = await getAllNotification(token);
    if (result.status == 200 || result.status == 201) {
      setNotification(result?.data?.data);
    }
    if (result.status === 429) {
      const message = result.data.message;
      const waitTimeMatch = message.match(/(\d+)\s*seconds/);
      const waitTime = waitTimeMatch ? parseInt(waitTimeMatch[1], 10) : 60;
      setTimeout(() => {
        fetchNotification();
      }, waitTime * 1000);
    }
  };
  useEffect(() => {
    fetchNotification();
  }, [modalRef.current]);
  useEffect(() => {
    window.addEventListener("click", handleClick);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);
  return (
    <div
      className={`${
        isClosing ? "" : `bg-black bg-opacity-50`
      } fixed top-0 left-0 z-20 w-full h-full  flex  `}
      ref={modalRef}
    >
      {showFeedbackForm && (
        <FeedbackForm
          bookingDetails={{
            name: "Meeting Room",
            location: "Riyadh, Saudi Arabia",
            date: "2021-09-15",
            time: "09:00 AM - 12:00 PM",
            price: 200,
          }}
          onClose={() => setShowFeedbackForm(false)}
        />
      )}
      <div
        className={`w-[500px] h-full bg-[#fff] border-l-2 border-[#E9EAEB]  absolute z-11 top-0 right-0 
          overflow-y-auto
          ${isClosing ? "closing" : "modalNotifi"}`}
        // style={{ animationDuration: "10s" }}
        ref={notifiRef}
      >
        <div className="flex items-center justify-between px-8 py-6 w-full bg-[#F9FAFB]">
          <h1 className="text-xl font-semibold Notification_Header">
            {t("Notifications")}
          </h1>
          <svg
            onClick={closeAction}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            className="cursor-pointer"
          >
            <path
              d="M5 15L15 5M5 5L15 15"
              stroke="#6B7280"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {notification &&
          notification.length > 0 &&
          notification.map((item, idx) => (
            <div className="flex flex-col gap-3 notification_message" key={idx}>
              <div
                className={`message_head flex ${
                  language == "ar" && "flex-row-reverse"
                } justify-between w-full items-center`}
              >
                <h1 className="">
                  {language == "en"
                    ? item?.data?.extra_data?.title.en
                    : item?.data?.extra_data?.title.ar}
                </h1>
                <p className="time_message">{timeAgo(item?.created_at)}</p>
              </div>
              <div className="message_body">
                {language == "en"
                  ? item?.data?.extra_data?.body.en
                  : item?.data?.extra_data?.body.ar}
              </div>
              {/* action button */}
              {/* <div className="flex justify-end">
                <button className="action_button"
                  onClick={() => setShowFeedbackForm(true)}
                >{t("Rate")}</button>
               </div>  */}
            </div>
          ))}
        {!notification && <Spinner />}
        {notification && notification.length === 0 && (
          <div className="flex justify-center items-center h-[80%]">
            <p className="text-lg font-semibold text-gray-400">
              {t("No notifications found")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
