import { useContext, useEffect, useState } from "react";
import {
  getCountOFUnReadMessages,
  getThreadMeassages,
  sendClientMessage,
} from "../../data_featching/conversations";
import { formatTo12Hour } from "./sharedFunctions";
import { Spinner } from "../ui/Spinner";
import AuthContext from "../../context/AuthProvider";
import { useTranslation } from "react-i18next";
import useTimeFormat from "../../hooks/useTimeFormat";
import { Send } from "lucide-react";

const ConvDetails = ({ selectedThreadDetails }) => {
  const { t, i18n } = useTranslation("conversations");
  const [sendMesInput, setSendMesInput] = useState("");
  const [conversationDetails, setConversationDetails] = useState([]);
  const [workspaceData, setWorkspaceData] = useState({
    workspaceDetails: {},
    threadID: null,
  });
  const [participantsDetails, setParticipantsDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { workspaceDetails, threadID } = workspaceData;
  const { setNumOfUnreadMess, newConversation, setNewConversation } =
    useContext(AuthContext);

  const token = localStorage.getItem("authToken");
  const { timeAgo } = useTimeFormat();

  /*catch workspace and participants data once / and when selected thead changed */
  useEffect(() => {
    if (selectedThreadDetails) {
      const currentWorkspaceDetails =
        selectedThreadDetails?.ThreadDetails?.participants &&
        selectedThreadDetails?.ThreadDetails?.participants.find(
          (item) => item.participant_type === "workspace"
        );
      setParticipantsDetails(selectedThreadDetails.ThreadDetails.participants);
      setWorkspaceData({
        workspaceDetails: { ...currentWorkspaceDetails },
        threadID: selectedThreadDetails?.ThreadDetails["thread_id"],
      });
      // setNewConversation(null);
    }
  }, [selectedThreadDetails]);

  useEffect(() => {
    if (newConversation) {
      const currentWorkspaceDetails =
        newConversation?.participants &&
        newConversation?.participants.find(
          (item) => item.participant_type === "workspace"
        );
      setParticipantsDetails(newConversation.participants);
      setWorkspaceData({
        workspaceDetails: { ...currentWorkspaceDetails },
        threadID: newConversation["thread_id"],
      });
    }
  }, []);

  /*get messages for specific workspace with specific client  */
  async function fetchConversationDetails() {
    setIsLoading(true);
    try {
      console.log("threaID11111", threadID);

      const conversationDetailsRes = await getThreadMeassages(token, threadID);
      console.log("conversationDetailsRes", conversationDetailsRes);
      if (
        conversationDetailsRes?.status === 200 ||
        conversationDetailsRes?.status === 201
      ) {
        setConversationDetails(conversationDetailsRes.data.data);
      }
    } catch (error) {
      console.log("Error fetching conversation details:", error.message);
    } finally {
      setIsLoading(false);
    }
    console.log("threaID0000", threadID);
  }

  useEffect(() => {
    fetchConversationDetails();
  }, [workspaceData]);

  const handleSendMessage = async () => {
    try {
      const sendMessage = await sendClientMessage(
        token,
        threadID,
        sendMesInput
      );
      if (sendMessage.status === 200 || sendMessage.status === 201) {
        setSendMesInput("");
        fetchConversationDetails();
      }
    } catch (error) {
      console.log("Error sending message:", error.message);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <div className="convDetails__main w-full h-full relative max-h-[750px] overflow-y-auto ">
      <>
        <div className="workspace_name_message_Header w-full rounded-t-lg h-full max-h-[60px]">
          <div className="flex items-center m-auto gap-[25px]">
            <img
              src={workspaceDetails?.image?.url || "/emptyIMg.png"}
              alt="Workspace"
              className="workspaceHeader_Img__ bg-white"
            />
            <h3 className="workspace_name__Space">{workspaceDetails.name}</h3>
          </div>
        </div>

        <div className="w-full grow overflow-auto h-full">
          {isLoading ? (
            <Spinner />
          ) : conversationDetails.length === 0 ? (
            <></>
          ) : (
            conversationDetails
              .slice()
              .reverse()
              .map((message, idx) => (
                <div
                  className="Message__flow_Details w-full flex-col"
                  key={idx}
                >
                  {!message.mine ? (
                    <div className="recieved__message_details w-full">
                      <div className="Recieved_message_inner relative w-full">
                        <span>{message.body}</span>
                        <div
                          className={`absolute ${
                            i18n.language === "en"
                              ? "right-[10.71px]"
                              : "left-[10.71px]"
                          } bottom-[6px] timeOfMessage flex flex-col gap-1 justify-end`}
                        >
                          <div>{timeAgo(message.created_at)}</div>
                          {/* <div>{message.created_at.split("T")[0]}</div> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="Host__message_details w-full relative">
                      <div className="Sent_message_inner relative w-full">
                        <span>{message.body}</span>
                        <div
                          className={`absolute ${
                            i18n.language === "en"
                              ? "right-[10.71px]"
                              : "left-[10.71px]"
                          } bottom-[6px] timeOfMessage flex flex-col gap-1 justify-end`}
                        >
                          <div>{timeAgo(message.created_at)}</div>
                          {/* <div>{message.created_at.split("T")[0]}</div> */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))
          )}
        </div>
        <div className="SentMessage__input w-full">
          <div className="flex w-full">
            <div className="innerMessage_sent_Box w-full">
              <div
                className={`inputBox_border w-full ${
                  isFocused ? "border-blue-500" : ""
                }`}
              >
                <input
                  type="text"
                  placeholder={t("Send Message")}
                  className={`sentMessage_Box__input w-full bg-white`}
                  value={sendMesInput}
                  onChange={(e) => setSendMesInput(e.target.value)}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
            <button
              className={`Sent_icon_Box cursor-pointer flex items-center justify-center ${
                sendMesInput.trim() ? "bg-green-500" : "bg-gray-300"
              } ${isFocused ? "border-blue-500" : ""}`}
              onClick={handleSendMessage}
              disabled={!sendMesInput.trim()}
            >
              <Send
                size={24}
                color="grey"
                className={`w-6 h-6 ${
                  !sendMesInput.trim() ? "opacity-50" : "opacity-100"
                }`}
              />
            </button>
          </div>
        </div>
      </>
    </div>
  );
};

export default ConvDetails;
