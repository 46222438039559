import baseURL from "../../axios";

export const getRatingList = async (accessToken,lang=en) => {
  const endPoint = `/api/v1/ratings/rating-item`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": lang,
      },
    });
    return result;
  } catch (error) {
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      return error.response;
    }
  }
};
//{{base_url}}/api/v1/application/ratings post

export const postRating = async (accessToken, data) => {
  const endPoint = `/api/v1/application/ratings`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": "en",
      },
    });
    return result;
  } catch (error) {
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      return error.response;
    }
  }
};
