import { useContext, useEffect, useState } from "react";
import ConvDetails from "./ConvDetails";
import ConvSubSidebar from "./ConvSubSidebar";
import LoginNavBar from "../ui/login-nav_bar/LoginNavBar";
import "./Conversations.css";
import { getAllThreads } from "../../data_featching/conversations";
import NoConversationsState from "./NoConversationsState";
import { useNavigate } from "react-router-dom";
import Footer from "../ui/footer/Footer";
import AuthContext from "../../context/AuthProvider";

const Conversations = () => {
  const [AllThreads, setAllThreads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [sideBarUpload, setSideBarUpload] = useState(false);
  const [conversationMount, setConversationMount] = useState(false);
  const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);

  const [selectedThreadDetails, setSelectedThreadDetails] = useState({
    id: "",
    ifAnyThreadSelected: false,
    ThreadDetails: [],
  });
  const pathName = location.pathname;
  const mobilePath = "/dashboard/conversations";
  const navigate = useNavigate();
  const { newConversation } = useContext(AuthContext);
  const handleResizeX = () => {
    if (window.innerWidth < 1280 && pathName == mobilePath) {
      navigate("/dashboard");
    }
  };
  useEffect(() => {
    if (pathName == mobilePath) {
      window.addEventListener("resize", handleResizeX);

      return () => {
        window.removeEventListener("resize", handleResizeX);
      };
    }
  }, []);
  const token = localStorage.getItem("authToken");
  console.log("token", token);
  /*get all conversations thread for Host */
  const pullAllConversationsThreads = async () => {
    try {
      // setIsLoading(true);
      const threadsResults = await getAllThreads(token);
      if (threadsResults?.status == 200 || threadsResults?.status == 201) {
        setConversationMount(true);
        // setIsLoading(false);
        setAllThreads(threadsResults?.data?.data);
        console.log("threadsResults", threadsResults?.data?.data);
      } else {
        throw new Error("not sucess 200");
      }
    } catch (error) {
      setConversationMount(true);

      // setIsLoading(false);
      console.log("error inside conversation", error.message);
    }
  };

  useEffect(() => {
    pullAllConversationsThreads();
  }, [selectedThreadDetails]);
  console.log(
    "selectedThreadDetails.ifAnyThreadSelected ",
    selectedThreadDetails.ifAnyThreadSelected
  );
  console.log("newConversation", newConversation);
  console.log("selectedThreadDetails.id ", selectedThreadDetails.id);
  return (
    <>
      <div className="bg-[#f9fafb] ">
        <LoginNavBar
          showBurgerLoginMenu={showBurgerLoginMenu}
          setShowBurgerLoginMenu={setShowBurgerLoginMenu}
        />
        {!showBurgerLoginMenu && (
          <>
            {" "}
            <div className="flex gap-4  w-full relative xl:px-[112px] lg:px-[70px] md:px[48px] sm:px-[16px] py-10">
              <ConvSubSidebar
                AllThreads={AllThreads}
                isLoading={isLoading}
                setSelectedThreadDetails={setSelectedThreadDetails}
                selectedThreadDetails={selectedThreadDetails}
                conversationMount={conversationMount}
              />
              {selectedThreadDetails.ifAnyThreadSelected ||
              newConversation != null ? (
                <ConvDetails
                  // sideBarUpload={sideBarUpload}
                  setIsLoading={setIsLoading}
                  selectedThreadDetails={selectedThreadDetails}
                  isLoading={isLoading}
                />
              ) : (
                <NoConversationsState />
              )}
            </div>
            <div style={{ backgroundColor: "black" }}>
              <div className="GooglePlay">
                <div className="Hint">Get workey now</div>
                <div className="GooglePlay_logo">
                  <img src="/Group.png" alt="google play img" />
                  <img src="/AppleStore.png" alt="Apple Store img" />
                </div>
              </div>
            </div>
            <Footer />
          </>
        )}
      </div>
    </>
  );
};

export default Conversations;
