// import Footer from "../ui/footer/Footer";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import LoginNavBar from "../ui/login-nav_bar/LoginNavBar";
import { loginUserInfoRequest } from "../../data_featching/login";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/AuthProvider";
import ScrollableCardContainer from "../ui/GenericScroll";

function PersonalProfile() {
  const { t } = useTranslation("profile");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);

  const { userInfo, setUserInfo } = useContext(AuthContext);
  const token = localStorage.getItem("authToken");
  const apiBaseUrl = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    const getUserInfo = async () => {
      if (token) {
        const result = await loginUserInfoRequest(token);
        if (result?.status === 200 || result?.status === 201) {
          setUserInfo(result?.data?.data);
        }
        console.log("result", result);
      }
    };
    if (!userInfo) getUserInfo();
  }, [token]);

  const logOut = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/v1/auth/logout`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Logout failed");
      }
      localStorage.removeItem("authToken");
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };
  return (
    <div className="w-full bg-gray-100">
      {/* <NavBar isHostAccount={true} /> */}
      <LoginNavBar
        showBurgerLoginMenu={showBurgerLoginMenu}
        setShowBurgerLoginMenu={setShowBurgerLoginMenu}
      />
      {!showBurgerLoginMenu && (
        <div className="w-full  mx-auto mt-[2.75rem] flex  gap-4 flex-col  xl:flex-row xl:px-[7rem] pb-[44px] lg:px-[4.375rem] px-[3rem]">
          <ScrollableCardContainer>
            <div className="flex  flex-row xl:flex-col p-[16px]   gap-[16px]   min-w-[25rem]  xl:bg-white xl:p-4 xl:border xl:rounded-2xl">
              <Link to="personalinfo">
                <div
                  className={`${
                    pathname.includes("personalinfo") ? "bg-gray-300" : ""
                  } flex items-center  gap-3 border rounded-2xl mb-4`}
                >
                  <p className="ltr:px-5 rtl:px-2 py-6 whitespace-nowrap w-[18.25rem] font-normal text-xl">
                    {t("Personal info")}
                  </p>
                  <div className="w-6 h-6 ltr:mr-9 rtl:ml-9">
                    <img
                      className="w-full h-full"
                      src={
                        pathname.includes("personalinfo")
                          ? "/personalB.svg"
                          : "/personal.svg"
                      }
                      alt=""
                    />
                  </div>
                </div>
              </Link>
              <Link to="account">
                <div
                  className={`${
                    pathname.includes("account") ? "bg-gray-300" : ""
                  } flex items-center gap-3 border rounded-2xl mb-4`}
                >
                  <p className="px-5 py-6  w-[18.25rem] font-normal text-xl">
                    {t("Account")}
                  </p>
                  <div className="w-6 h-6 ltr:mr-9 rtl:ml-9">
                    <img
                      className="w-full h-full"
                      src={
                        pathname.includes("account")
                          ? "/accountB.svg"
                          : "/account.svg"
                      }
                      alt=""
                    />
                  </div>
                </div>
              </Link>
              <Link to="preferences">
                <div
                  className={`${
                    pathname.includes("preferences") ? "bg-gray-300" : ""
                  } flex items-center gap-3 border rounded-2xl mb-4`}
                >
                  <p className="px-5 py-6  w-[18.25rem] font-normal text-xl">
                    {t("Preferences")}
                  </p>
                  <div className="w-6 h-6 ltr:mr-9 rtl:ml-9">
                    <img
                      className="w-full h-full"
                      src={
                        pathname.includes("preferences")
                          ? "/preferencesB.svg"
                          : "/preferences.svg"
                      }
                      alt=""
                    />
                  </div>
                </div>
              </Link>
              {userInfo?.has_business_profile && (
                <Link to="my-companies">
                  <div
                    className={`${
                      pathname.includes("my-companies") ? "bg-gray-300" : ""
                    } flex items-center gap-3 border rounded-2xl mb-4`}
                  >
                    <p className="px-5 py-6  w-[18.25rem] font-normal text-xl">
                      {t("my companies")}
                    </p>
                    <div className="w-8 h-8 ltr:mr-9 rtl:ml-9">
                      <img
                        className="w-full h-full"
                        src={
                          pathname.includes("my-companies")
                            ? "/companiesB.svg"
                            : "/companies.svg"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </Link>
              )}
              <Link to="wallet">
                <div
                  className={`${
                    pathname.includes("wallet") ? "bg-gray-300" : ""
                  } flex items-center gap-3 border rounded-2xl`}
                >
                  <p className="px-5 py-6  w-[18.25rem] font-normal text-xl">
                    {t("wallet")}
                  </p>
                  <div className="w-6 h-6 ltr:mr-9 rtl:ml-9">
                    <img
                      className="w-full h-full"
                      src={
                        pathname.includes("wallet")
                          ? "/walletB.svg"
                          : "/wallet.svg"
                      }
                      alt=""
                    />
                  </div>
                </div>
              </Link>
              <button
                onClick={logOut}
                className="px-5 py-[0.938rem] hidden  gap-2 items-center justify-center text-red-500 border rounded-lg  xl:flex  align-end mt-auto"
              >
                <div className="w-[1.125rem] h-[1.125rem] mt-[-2px]">
                  <img src="/logout.svg" alt="" className="w-full h-full" />
                </div>
                <p className="text-base font-medium">{t("logout")}</p>
              </button>
            </div>
          </ScrollableCardContainer>

          <div className="w-full mx-auto bg-white border rounded-2xl">
            <div className="flex flex-col w-full gap-4 xl:flex-row">
              <Outlet />
            </div>
          </div>
          <button
            onClick={logOut}
            className="px-5 py-[0.938rem]   gap-2 items-center justify-center text-red-500 border rounded-lg sm:flex  xl:hidden  align-end mt-auto"
          >
            <div className="w-[1.125rem] h-[1.125rem] mt-[-2px]">
              <img src="/logout.svg" alt="" className="w-full h-full" />
            </div>
            <p className="text-base font-medium">{t("logout")}</p>
          </button>
        </div>
      )}
    </div>
  );
}

export default PersonalProfile;
