import * as Dialog from "@radix-ui/react-dialog";

const Modal = ({
  children,
  title,
  Description,
  isOpen,
  setIsOpen,
  progress,
}) => (
  <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
    <Dialog.Portal className=" bg-[#F9FAFB] ">
      <Dialog.Overlay className="bg-black/60 data-[state=open]:animate-overlayShow fixed inset-0 z-[10000000]" />
      <Dialog.Content
        className={`data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] overflow-y-auto custom-scrollbar ${
          Description ? "w-[90vw]" : ""
        } z-[10000000] max-w-[900px] translate-x-[-50%] translate-y-[-50%] rounded-xl bg-[#F9FAFB] p-4 pt-12 shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none`}
      >
        {progress && (
          <div className="relative w-full h-2 mb-2 overflow-hidden bg-gray-300 rounded-full">
            <div
              className="absolute inset-y-0 -left-[100%] bg-green-600 w-full 3 h-full rounded-full transition-transform duration-300"
              style={{
                transform: `translateX(${progress}%)`,
              }}
            />
          </div>
        )}
        <div className="flex items-center justify-between gap-2">
          <Dialog.Title className="m-0 text-2xl font-black text-black">
            {title}
          </Dialog.Title>
          <Dialog.Description className="text-sm text-gray-500">
            {Description}
          </Dialog.Description>
        </div>
        <div className="mt-[25px]">{children}</div>
        <Dialog.Close asChild>
          <button
            className=" p-1 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:outline-none"
            aria-label="Close"
          >
            <svg
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-[15px] w-[15px] text-[#1E293B]"
            >
              <path
                d="M1 17L17 1M1 1L17 17"
                stroke="#1E293B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);

export default Modal;
Modal.propTypes = null;
