export const formatDateAndTime = (lang,checkinDateString, checkoutDateString) => {
    // const formatDate = (dateString) => {
    //   const date = new Date(dateString.replace("Z", ""));
    //   if (isNaN(date.getTime())) {
    //     console.error("Invalid date:", dateString);
    //     return { formattedDate: undefined, formattedTime: undefined };
    //   }

    //   const formattedDate = new Intl.DateTimeFormat("en-GB", {
    //     weekday: "short",
    //     day: "numeric",
    //     month: "numeric",
    //     year: "numeric",
    //   }).format(date);

    //   const formattedTime = new Intl.DateTimeFormat("en-US", {
    //     hour: "numeric",
    //     minute: "numeric",
    //     hour12: true,
    //   }).format(date);

    //   return { formattedDate, formattedTime };
    // };

    const formatDate = (dateString, lang = "en") => {
      const date = new Date(dateString.replace("Z", ""));
      if (isNaN(date.getTime())) {
        console.error("Invalid date:", dateString);
        return { formattedDate: undefined, formattedTime: undefined };
      }
    
      const dateFormatOptions = {
        weekday: "short",
        day: "numeric",
        month: "numeric",
        year: "numeric",
      };
    
      const timeFormatOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
    
      const formattedDate = new Intl.DateTimeFormat(lang === "ar" ? "ar-EG" : "en-GB", dateFormatOptions).format(date);
      const formattedTime = new Intl.DateTimeFormat(lang === "ar" ? "ar-EG" : "en-US", timeFormatOptions).format(date);
    
      return { formattedDate, formattedTime };
    };
    const checkin = formatDate(checkinDateString, lang);
    const checkout = formatDate(checkoutDateString, lang);

    return { checkin, checkout };
  };
  export function getActiveDaysIndexes(workingDays) {
    const activeIndexes = [];
    const daysOrder = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  
    workingDays.forEach((dayObj) => {
      if (dayObj.is_active) {
        const index = daysOrder.indexOf(dayObj.day.key.toLowerCase());
        if (index !== -1) {
          activeIndexes.push(index);
        }
      }
    });
  
    return activeIndexes;
  }