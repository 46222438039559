const useVerificationInput = (inputsRef, setVerificationNum) => {
  const handleInputChange = (event, field, index) => {
    const { value } = event.target;
    const key = event.key;

    // Update the state with the last entered character
    setVerificationNum((prev) => ({
      ...prev,
      [field]: value.slice(-1),
    }));

    // Handle backspace to move focus to the previous field if empty
    if (key === "Backspace" && value === "" && index > 0) {
      inputsRef.current[index - 1].focus();
    }
    // Automatically focus the next input only if the current input is empty
    else if (
      value &&
      value.length === 1 &&
      index < inputsRef.current.length - 1
    ) {
      inputsRef.current[index + 1].focus();
    }
    // Handle arrow keys for navigation only if the field is empty
    else if (key === "ArrowLeft" && index > 0 && !value) {
      inputsRef.current[index - 1].focus();
    } else if (
      key === "ArrowRight" &&
      index < inputsRef.current.length - 1 &&
      !value
    ) {
      inputsRef.current[index + 1].focus();
    }
  };

  return { handleInputChange };
};

export default useVerificationInput;
