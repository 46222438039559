import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoginNavBar from "../ui/login-nav_bar/LoginNavBar";
import Footer from "../ui/footer/Footer";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../context/LanguageProvider";

function SupplierDetails() {
  const { id } = useParams();
  const [companyData, setCompanyData] = useState([]);
  const [otherCompanies, setOtherCompanies] = useState([]);
  const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);
  const [NumberToShow, setNumberToShow] = useState(5);
  const apiBaseUrl = import.meta.env.VITE_BASE_URL;
  const { t } = useTranslation("supplier");
  const { language } = useContext(LanguageContext);

  const settings = {
    lazyLoad: true,
    infinite: otherCompanies.length > 2,
    // speed: 500,
    slidesToShow:NumberToShow,
    slidesToScroll: 1,
    // initialSlide: 0,
  };
  // adjust the number of companies to show in the slider according to the screen size
  const updateNumberToShow = () => {
    if (window.innerWidth < 1110) {
      setNumberToShow(3);
    } else if (window.innerWidth < 800) {
      setNumberToShow(2);
    } else {
      setNumberToShow(5);
    }
  };

  useEffect(() => {
    // Set initial number of companies to show
    updateNumberToShow();

    // Add event listener for window resize
    window.addEventListener("resize", updateNumberToShow);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateNumberToShow);
    };
  }, []);

  function goToWebsite() {
    if (companyData?.url) {
      window.open(companyData.url, "_blank");
    } else {
      return;
    }
  }

  useEffect(() => {
    const getFilters = async () => {
      try {
        const response = await fetch(
          `${apiBaseUrl}/api/v1/application/supplier-companies/${id}`,
          {
            headers: {
              "Accept-Language": language,
            },
          }
        );

        if (!response.ok) {
          throw new Error("failed");
        }
        const data = await response.json();
        console.log(data.data);
        setCompanyData(data.data);
      } catch (error) {
        console.error("Error during:", error);
      }
    };
    getFilters();
  }, [id, apiBaseUrl, language]);

  useEffect(() => {
    const getCompanies = async () => {
      try {
        const response = await fetch(
          `${apiBaseUrl}/api/v1/application/supplier-companies`,
          {
            headers: {
              "Accept-Language": language,
            },
          }
        );

        if (!response.ok) {
          throw new Error("failed");
        }
        const data = await response.json();
        console.log(data.data);
        setOtherCompanies(data.data);
      } catch (error) {
        console.error("Error during:", error);
      }
    };
    getCompanies();
  }, [apiBaseUrl, language, id]);
  return (
    <>
      <LoginNavBar
        showBurgerLoginMenu={showBurgerLoginMenu}
        setShowBurgerLoginMenu={setShowBurgerLoginMenu}
      />
      {!showBurgerLoginMenu && (
        <>
          <div className="w-full h-[39.125rem] relative">
            <img
              src={companyData?.cover?.url}
              alt=""
              className="object-cover w-full h-[28.125rem]"
            />
            <div className="absolute w-[12.5rem] h-[12.5rem] text-white left-1/2 transform -translate-x-1/2 bottom-[4.75rem]">
              <img
                src={companyData?.logo?.url}
                alt=""
                className="w-full h-full rounded-full"
              />
            </div>
            <h2 className="mt-[7.25rem] text-center text-4xl font-extrabold">
              {companyData?.name}
            </h2>
            <button
              onClick={goToWebsite}
              className="bg-[#009E1E] px-4 py-3 rounded-lg flex gap-1 absolute ltr:right-[7rem] bottom-[7rem] rtl:left-[7rem]"
            >
              <span className="text-sm font-medium text-white">
                {t("visit")}
              </span>
              <img src="/web.svg" alt="" />
            </button>
          </div>
          <div className="px-[7rem] py-[2.75rem] relative">
            <div className="flex flex-wrap gap-1 absolute top-[2.75rem] rtl:left-[7rem] ltr:right-[7rem]">
              {companyData?.business_segments?.map((segment) => (
                <p
                  key={segment.id}
                  className="font-medium text-sm  bg-[#B8E9B7] px-2 py-1 rounded-3xl"
                >
                  {segment.name}
                </p>
              ))}
            </div>
            <div className="mb-12">
              <h3 className="mb-4 text-xl font-semibold">{t("about")}</h3>
              <p className="text-[#6B7280] font-normal text-lg">
                {companyData?.details}
              </p>
            </div>
            <div className="mb-12">
              <h3 className="mb-4 text-xl font-semibold">{t("website")}</h3>
              <button onClick={goToWebsite} className="flex items-center gap-1">
                <img src="/link.svg" alt="" className="mt-[2px] w-5 h-5" />
                <p className="text-[#6B7280] font-normal text-lg">
                  {companyData?.url}
                </p>
              </button>
            </div>
            <div>
              <h3 className="mb-4 text-xl font-semibold">{t("contact")}</h3>
              <div className="flex items-center gap-1">
                <img src="/address.svg" alt="" className="mt-[2px]" />
                <p className="text-[#6B7280] font-normal text-lg">
                  {companyData?.address}
                </p>
              </div>
              <div className="flex items-center gap-1">
                <img src="/email.svg" alt="" className="mt-[2px]" />
                <p className="text-[#6B7280] font-normal text-lg">
                  {companyData?.email}
                </p>
              </div>
              <div className="flex items-center gap-1">
                <img src="/phone.svg" alt="" className="mt-[2px]" />
                <p className="text-[#6B7280] font-normal text-lg">
                  {companyData?.phone}
                </p>
              </div>
            </div>
          </div>
          <div className="px-[7rem] py-[2.75rem]">
            <h3 className="mb-6 text-xl font-semibold">{t("prod/serv")}</h3>
            <div className="flex flex-wrap gap-6">
              {companyData?.services?.map((service) => (
                <div key={service.id} className="w-[17.875rem]">
                  <img
                    className="rounded-t-lg w-full h-[10.625rem] drop-shadow-md"
                    src={service?.image?.url}
                    alt=""
                  />
                  <div className="bg-white rounded-b-lg drop-shadow-md">
                    <p className="px-3 py-2 text-lg font-bold text-center">
                      {service?.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-black px-[7rem]  py-[2.75rem]">
            <h2 className="mb-4 text-xl font-semibold text-white">
              {t("explore similar")}
            </h2>
            <div className="slider-container">
              <Slider {...settings}>
                {otherCompanies?.filter((company) => company.uuid != companyData?.uuid)
                .map((company) => (
                  <div
                    key={crypto.randomUUID()}
                    className="w-[15.625rem] relative cursor-pointer"
                    onClick={()=>{
                      setCompanyData(company)

                    }}
                  >
                    <img
                      className="rounded-t-lg w-full h-[12rem]"
                      src={company?.logo?.url}
                      alt=""
                    />
                    <div className="bg-white rounded-b-lg drop-shadow-md">
                      <p className="px-3 py-2 text-lg font-bold text-center">
                        {company?.name}
                      </p>
                    </div>
                    <div className="absolute flex flex-wrap gap-1 top-2 ltr:left-1 rtl:right-1 rtl:left-auto">
                      {company?.business_segments?.map((segment) => (
                        <p
                          key={segment.id}
                          className="font-medium text-sm  bg-[#B8E9B7] px-2 py-1 rounded-3xl"
                        >
                          {segment.name}
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="w-full py-4 bg-[#252C34]">
            <div className="w-[76rem] mx-auto flex items-center justify-between">
              <p className="text-4xl font-extrabold text-green-500">
                {t("get workey")}
              </p>
              <div className="flex gap-8">
                <img src="/Group.png" alt="google play img" />
                <img src="/AppleStore.png" alt="Apple Store img" />
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default SupplierDetails;
