/* eslint-disable react/prop-types */
// import { Link } from "react-router-dom";
import "./FixedUpperNav.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/prop-types
const FixedUpperNav = ({
  // eslint-disable-next-line react/prop-types

  isHostAccount = false,

  // eslint-disable-next-line react/prop-types
  isSupplierAccount = false,
  // eslint-disable-next-line react/prop-types
  // setSelectedCurrency,
  // selectedCurrency,
  // setSelectedLanguage,
  // selectedLanguage,
}) => {
  /*appear in Host Dashboard */
  const { t } = useTranslation("home");
  const suppliersLink = import.meta.env.VITE_SUPPLIERS_URL;
  const customersLink = import.meta.env.VITE_BASE_URL;
  const hostsLink = import.meta.env.VITE_HOST_URL;

  function goToWebsite(website) {
    window.location.href = website;
  }

  const customerAccount = {
    accuntSwitchTitle: t("Customer account"),
    accuntSwitchLink: customersLink,
    accuntSwitchSrc: "/customer_account.png",
  };
  /*appear in customer(Host) Account */
  const sharedspace = {
    accuntSwitchTitle: t("Share your space"),
    accuntSwitchLink: hostsLink,
    accuntSwitchSrc: "/fi_3523183.png",
  };
  /*appear in supplier Account */
  const supplierAccount = {
    accuntSwitchTitle: t("Supplier account"),
    accuntSwitchLink: suppliersLink,
    accuntSwitchSrc: "/supplier_account.png",
  };
  /*appear in supplier Account */
  const supplierSharedspace = {
    accuntSwitchTitle: t("Share your space"),
    accuntSwitchLink: suppliersLink,
    accuntSwitchSrc: "/supplier_shared.png",
  };
  const [switchToaccount, setSwitchToaccount] = useState({});
  const [supplierSwitchToaccount, setSupplierSwitchToaccount] = useState({});
  useEffect(() => {
    setSwitchToaccount(isHostAccount ? sharedspace : customerAccount);
    setSupplierSwitchToaccount(
      isSupplierAccount ? supplierSharedspace : supplierAccount
    );
  }, []);

  return (
    <>
      <div className="flex items-center gap-9">
        <div className="flex items-center register">
          <button
            onClick={() => goToWebsite(sharedspace.accuntSwitchLink)}
            className="text-base font-medium text-[#04C946]"
          >
            {t("Share your space")}
          </button>
          <img src={switchToaccount.accuntSwitchSrc} alt="Host" />
        </div>
        <div className="line_space"></div>
        <div className="flex items-center register">
          <button
            onClick={() => goToWebsite(supplierAccount.accuntSwitchLink)}
            className="text-base font-medium text-white"
          >
            {t("Supplier account")}
          </button>
          <img src={supplierSwitchToaccount.accuntSwitchSrc} alt="supplier" />
        </div>
      </div>
    </>
  );
};

export default FixedUpperNav;
