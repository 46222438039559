export function verficationValidation(code) {
  if (
    code.code1.trim() !== "" &&
    code.code2.trim() !== "" &&
    code.code3.trim() !== "" &&
    code.code4.trim() !== "" &&
    code.code5.trim() !== "" &&
    code.code6.trim() !== ""
  ) {
    return true;
  } else {
    return false;
  }
}
