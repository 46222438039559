import { PhoneNumberUtil } from "google-libphonenumber";
import { useState } from "react";
import "react-international-phone/style.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const PhoneNumber = ({ value, onChange }) => {
  const flags = {
    SA: () => (
      <img
        src="/KSA.png"
        alt="Saudi Arabia Flag"
      />
    ),
  };

  return (
    <div className="border-red-200  mb-3" dir="ltr" >
      <PhoneInput
        className="border-red-200 flex-start"
        international={false}
        defaultCountry="SA"
        value={value}
        onChange={onChange}
        flags={flags}
      />
    </div>
  );
};

export default PhoneNumber;