import { Link } from "react-router-dom";
import "./Footer.css";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation("home");

  return (
    <div className="Foooter_container">
      <div className="main_footer_content">
        <div className="logo_description">
          <div className="logo_footer">
            <img src="/WORKEY_footer.png" alt="WORKEY Logo" />
          </div>
          <div className="brand_desc">{t("footer_description")}</div>
        </div>
        <div className="footer_main_links ">
          <div className="header_links">
            <div className="Links_Title">{t("company")}</div>
            <Link to={""} className="Links_to_section">
              {t("about")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("leadership")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("blog")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("careers")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("referral_program")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("community")}
            </Link>
          </div>
          <div className="header_links">
            <div className="Links_Title">{t("product")}</div>
            <Link to={""} className="Links_to_section">
              {t("overview")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("features")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("pricing")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("documentation")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("tools_integration")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("releases")}
            </Link>
          </div>
          <div className="header_links">
            <div className="Links_Title">{t("support")}</div>
            <Link to={""} className="Links_to_section">
              {t("help_centre")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("faq")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("contact")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("press")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("status")}
            </Link>
          </div>
        </div>
      </div>
      <div className="terms_condition">
        <div className="terms_links">
          <Link to={""}>{t("terms_of_service")}</Link>
          <Link to={""}>{t("privacy_policy")}</Link>
          <Link to={""}>{t("security")}</Link>
          <Link to={""}>{t("sitemap")}</Link>
        </div>
        <div className="copy_rights">{t("copy_rights")}</div>
      </div>
    </div>
  );
};

export default Footer;
