import { useEffect, useRef } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import "./Map.css";

const containerStyle = {
  width: "100%",
  height: "200px",
};

const AdvancedMarker = ({ position }) => {
  const markerRef = useRef(null);

  useEffect(() => {
    if (
      !window.google ||
      !window.google.maps ||
      !window.google.maps.marker ||
      !window.google.maps.marker.AdvancedMarkerElement
    ) {
      console.error("AdvancedMarkerElement is not available.");
      return;
    }

    const { AdvancedMarkerElement, PinElement } = window.google.maps.marker;

    const pinBackground = new PinElement({
      background: "black",
      borderColor: "#D94C43",
      glyphColor: "#fff",
    });

    markerRef.current = new AdvancedMarkerElement({
      position: position,
      content: pinBackground.element,
      draggable: false,
      map: window.mapInstance,
    });

    return () => {
      if (markerRef.current) {
        markerRef.current.map = null;
      }
    };
  }, [position]);

  return null;
};

const libraries = ["marker"];

const LocateMap = ({ address }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAnb-1tnTYxitUdvDUyBkMNoN_hKWrYWQ8",
    libraries: libraries, // Add the marker library explicitly
  });

  const validAddress = {

    ...address,
    position: {
      lat: parseFloat(address.latitude)||  24.7136,
      lng: parseFloat(address.longitude) ||46.6753
    },
  };

 

  return isLoaded ? (
    <div className="mb-8 w-[100%]">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={validAddress.position } // Default to Riyadh if no address
        zoom={8}
        onLoad={(map) => (window.mapInstance = map)}
        options={{
          mapId: "5e7764b2d96e3d32",
          mapTypeControl: false,
            streetViewControl: false,
            restriction: {
              latLngBounds: {
                north: 85,  // Near North Pole
                south: -85, // Near South Pole
                west: -179.999, // Slightly inside the left boundary
                east: 179.999,  // Slightly inside the right boundary
              },
              strictBounds: true, // Prevent dragging outside these bounds
            },
        }}
      >
       
        <AdvancedMarker position={ validAddress.position} />
      </GoogleMap>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default LocateMap;