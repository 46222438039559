import { useEffect } from "react";

const useFocusOnMount = (inputsRef) => {
  //refArray is the const of the useRef that points to the
  useEffect(() => {
    // Focus the first input field if available
    if (inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }
  }, [inputsRef]);
};

export default useFocusOnMount;
