import { useTranslation } from "react-i18next";

function AccountDeletionPolicy({ setShowPolicy }) {
  const { t } = useTranslation("profile");
  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center bg-black bg-opacity-25">
      <div className="p-6  bg-white sm:w-[80%] flex flex-col rounded-[0.75rem] shadow-md xl:w-[62.5rem] h-[42.5rem] max-h-[calc(100vh-10%)]">
        <div className="flex items-center justify-between">
          <h2 className="text-[1.75rem] font-extrabold">
            {t("Account Deletion Policy")}
          </h2>
          <button onClick={() => setShowPolicy(false)}>
            <img src="/close.svg" alt="" className="w-8 h-8 mt-[-1rem]" />
          </button>
        </div>
        <div className="p-4 overflow-y-auto custom-scroll-container">
          <div>
            <h3 className="text-xl font-semibold mb-8 text-[#000021]">
              {t("Conditions for Account Deletion:")}
            </h3>
            <ul className="list-disc">
              <li className="text-[#6B7280] font-normal text-lg mb-4">
                {t("condition1")}
              </li>
              <li className="text-[#6B7280] font-normal text-lg mb-8">
                {t("condition2")}
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl text-[#000021] font-semibold mb-8">
              {t("Deletion Procedure:")}
            </h3>
            <ul className="list-disc">
              <li className="text-[#6B7280] font-normal text-lg mb-4">
                {t("request_submission")}
              </li>
              <li className="text-[#6B7280] font-normal text-lg mb-4">
                {t("verification_prodcedure")}
              </li>
              <li className="text-[#6B7280] font-normal text-lg mb-4">
                {t("notification_prodcedure")}
              </li>
              <li className="text-[#6B7280] font-normal text-lg mb-8">
                {t("final_deletion")}
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl text-[#000021] font-semibold mb-8">
              {t("important_notes")}
            </h3>
            <ul className="list-disc">
              <li className="text-[#6B7280] font-normal text-lg mb-4">
                {t("profile_deletion")}
              </li>
              <li className="text-[#6B7280] font-normal text-lg mb-4">
                {t("host_access")}
              </li>
              <li className="text-[#6B7280] font-normal text-lg mb-4">
                {t("data_retention")}
              </li>
              <li className="text-[#6B7280] font-normal text-lg mb-8">
                {t("reactivation")}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountDeletionPolicy;
AccountDeletionPolicy.propTypes = null;
