import { useContext } from "react";
import { MapPin, Mail, Phone, Plus, Archive, LinkIcon } from "lucide-react";
import styles from "./CompanyProfile.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ConfirmModal, IconModal } from "../../../ui/Models";
import { useTranslation } from "react-i18next";
import {
  getCompany,
  toggleCompany,
  deleteCompany,
} from "../../../../data_featching/community";
import { useParams } from "react-router-dom";
import { Spinner } from "../../../ui/Spinner";
import StatusBar from "../../../ui/status-bar/StatusBar";
import LanguageContext from "../../../../context/LanguageProvider";

const CompanyProfile = () => {
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation("profile");
  const [isArchivingModalOpen, setIsArchivingModalOpen] = useState(false);
  const [isArchivedModalOpen, setIsArchivedModalOpen] = useState(false);
  const { uuid } = useParams();
  const [Company, setCompany] = useState(null);
  const [toggled, setToggled] = useState(false);
  const [statusMessage, setStatusMessage] = useState({
    isActive: false,
    message: "",
    currentStatus: "",
  });
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchCompany = async () => {
      console.log("uuid", uuid);
      const company = await getCompany(token, uuid, language);
      console.log("company", company?.data);
      setCompany(company?.data);
      setToggled(company?.data?.active);
    };
    fetchCompany();
  }, [language, token, uuid]);
  const toggleListing = (uuid) => {
    setStatusMessage({
      isActive: true,
      message: t("Company toggling now"),
      currentStatus: 1,
    });

    toggleCompany(token, uuid).then((res) => {
      setToggled(!toggled);
      setStatusMessage({
        isActive: true,
        message: t("Company toggled successfully"),
        currentStatus: 3,
      }).catch((error) => {
        console.log("Company toggling error >>>>>> ", error);
        setStatusMessage({
          isActive: true,
          message: t("Company toggling failed"),
          currentStatus: 2,
        });
      });
    });
    setTimeout(() => {
      setStatusMessage({
        isActive: false,
        message: "",
        currentStatus: "",
      });
    }, 3000);
  };
  const navigate = useNavigate();
  const handleDelete = (uuid) => {
    setStatusMessage({
      isActive: true,
      message: t("Company deleting now"),
      currentStatus: 1,
    });

    deleteCompany(token, uuid).then((res) => {
      console.log("Company deleted >>>>>> ", res.data);
      setStatusMessage({
        isActive: false,
      });
      setIsArchivedModalOpen(true);
      setTimeout(() => {
        navigate("/profile/my-companies");
      }, 3000);
    });
  };
  if (!Company) {
    return <Spinner />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img
          src={Company?.cover?.url || "/background.svg"}
          alt={Company?.name}
          className={styles.banner}
        />
        <div className={styles.logoContainer}>
          <img
            src={Company?.logo?.url}
            alt="Company logo"
            className={styles.logo}
          />
          <span className={styles.companyName}>{Company?.name}</span>
        </div>
      </div>
      {statusMessage.isActive && (
        <StatusBar
          message={statusMessage.message}
          statusFlag={statusMessage.currentStatus}
          currentLanguage={language}
        />
      )}
      <div className={styles.content}>
        <section className={styles.about}>
          <div className={styles.aboutTags}>
            <h3 className={styles.Htitle}>{t("about-some")}</h3>
            <div className={styles.Tags}>
              {Company.business_segments?.map((segment) => (
                <span key={crypto.randomUUID()} className={styles.tag}>
                  {segment.name}
                </span>
              ))}
            </div>
          </div>
          <p className={styles.companyDescription}>{Company.details}</p>
        </section>

        <section className={styles.profileVisibility}>
          <h3 className={styles.Htitle}>{t("profile visibility")}</h3>
          <div className={styles.infoItem}>
            <span className={styles.companyDescription}>
              {t("show in community")}
            </span>
            <label className={styles.switch}>
              <input
                type="checkbox"
                checked={toggled}
                onChange={() => toggleListing(uuid)}
              />
              <span className={styles.slider}></span>
            </label>
          </div>
        </section>

        <section className={styles.contactInfo}>
          <h3 className={styles.Htitle}>{t("website")}</h3>
          <a className={styles.infoItems} href={Company.url}>
            <LinkIcon size={18} color="green" />
            <span className={styles.websiteLink}>{Company.url}</span>
          </a>
        </section>
        <section className={styles.contactInfo}>
          <h3 className={styles.Htitle}>{t("contact-info")}</h3>
          <div className={styles.infoItems}>
            <MapPin size={18} color="green" />
            <span className={styles.companyDescription}>{Company.address}</span>
          </div>
          <div className={styles.infoItems}>
            <Mail size={18} color="green" />
            <span className={styles.companyDescription}>{Company.email}</span>
          </div>
          <div className={styles.infoItems}>
            <Phone size={18} color="green" />
            <span className={styles.companyDescription}>{Company.phone}</span>
          </div>
        </section>

        <section className={styles.productsServices}>
          <div className={styles.infoItem}>
            <h3 className={styles.Htitle}>{t("prod/serv")}</h3>
            <Link
              to={`/my-companies/${uuid}/add-service`}
              className={styles.addButton}
            >
              {t("add")}
              <Plus size={18} />
            </Link>
          </div>
          <div className={styles.serviceGrid}>
            {Company.services.map((service, index) => (
              <div className={styles.serviceCard} key={index}>
                <div className={styles.serviceImageContainer}>
                  <img
                    src={service.image.url}
                    alt={service.name}
                    className={styles.serviceImage}
                  />
                </div>
                <h4>{service.name}</h4>
              </div>
            ))}
          </div>
        </section>
        {isArchivingModalOpen && (
          <ConfirmModal
            isOpen={isArchivingModalOpen}
            onClose={() => setIsArchivingModalOpen(false)}
            onConfirm={() => {
              setIsArchivingModalOpen(false);
              handleDelete(uuid);
            }}
            title={t("Archiving Company")}
            message={`${t(`Are you sure you want to archive Company`)}
            "${Company.name}"`}
            confirmText={t("Yes,Archive Company")}
            icon={Company.logo.url}
            name={Company.name}
          />
        )}
        {isArchivedModalOpen && (
          <IconModal
            isOpen={isArchivedModalOpen}
            onClose={() => setIsArchivedModalOpen(false)}
            icon="/build_info.svg"
            message={t(`Company has been archived successfully`)}
            actionText={t("Undo")}
            onAction={() => setIsArchivedModalOpen(false)}
            title={t("Archive Company")}
          />
        )}
        <div className={styles.actions}>
          <button
            className={`${styles.archiveButton}`}
            onClick={() => setIsArchivingModalOpen(true)}
          >
            <Archive size={18} />
            <span className="ltr:ml-2 rtl:mr-2">{t("archive")}</span>
          </button>
          <Link to={`/my-companies/edit/${uuid}`} className={styles.editButton}>
            {t("Edit information")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
