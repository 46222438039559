import './Ratings.css';
const Progressbar = ({ progressValue }) => {
  //   const [value, setValue] = useState(50);

  //  const handleInputChange = (e) => {
  //    const newValue = e.target.value;
  //    setValue(newValue);
  //  };

  return (
    <div className="bar__con w-full">
      <input
        onChange={() => {}}
        type="range"
        value={`${progressValue}`}
        max="100"
        className="w-full bar__style"
        style={{
          background: `linear-gradient(to right, #f8cb00 ${progressValue}%, #e0e0e0 ${progressValue}%)`,
        }}
      />
    </div>
  );
};

export default Progressbar;
