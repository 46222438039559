import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { completeProfileStepOne } from "../../../data_featching/UpdateProfile";
import LanguageContext from "../../../context/LanguageProvider";

function SecondStep({
  otp,
  setOtp,
  setCurrentStep,
  stepTwoClicked,
  phoneInput,
  stepTwoError,
  setStepTwoError,
  setId,
}) {
  const initialTime = 2 * 60;
  const [timeRemaining, setTimeRemaining] = useState(initialTime);
  const [showResend, setShowResend] = useState(false);
  const [resended, setResended] = useState(false);
  const { t } = useTranslation("profile");
  const token = localStorage.getItem("authToken");
  const { language } = useContext(LanguageContext);

  async function handleResendOtp() {
    const formData = new FormData();
    formData.set("phone", phoneInput);
    setShowResend(false);
    setResended(true);
    try {
      const response = await completeProfileStepOne(token, formData, language);
      if (response.status == 200 || response.status === 201) {
        setId(response?.data?.data?.id);
      }
    } catch (error) {
      setStepTwoError(error.message);
    }
  }

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timerInterval);

          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowResend(true);
    }, 2000 * 60);
  }, []);

  const minutes = Math.floor((timeRemaining % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (timeRemaining % 60).toString().padStart(2, "0");

  return (
    <>
      <p className="mb-6 text-lg font-medium text-center">
        {t("verification code sent")}
        <span className="text-lg block font-bold text-[#2563EB]">
          {phoneInput}
        </span>
        {t("enter the code below")}
      </p>
      <div className="flex items-center justify-center gap-3 mb-6" dir="ltr">
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputType="number"
          shouldAutoFocus
          renderInput={(props) => <input {...props} />}
          containerStyle={{ display: "flex", gap: "10px" }}
          inputStyle={{
            borderColor: "#E5E7EB",
            borderWidth: "1px",
            borderRadius: "8px",
            width: "56px",
            height: "56px",
            fontSize: "16px",
            backgroundColor: "#fff",
          }}
        />
      </div>

      {stepTwoClicked && !/^[0-9]{6}$/.test(otp) && !stepTwoError && (
        <p className="mb-2 text-sm font-semibold text-red-500">
          {t("incorrect OTP")}
        </p>
      )}
      {stepTwoError && (
        <p className="mb-2 text-sm font-semibold text-red-500">
          {stepTwoError}
        </p>
      )}

      <div className="flex items-center w-[92%] mx-auto mb-8 text-center">
        {!resended && (
          <p className="text-base font-medium">
            {t("you can resend")}
            <span className="ltr:ml-4 rtl:mr-4">{`${minutes}:${seconds}`}</span>
          </p>
        )}
        {showResend && (
          <button
            onClick={handleResendOtp}
            className="font-medium text-[#009E1E] ltr:ml-4 rtl:mr-4"
          >
            {t("resend_otp")}
          </button>
        )}
      </div>
      <button
        onClick={() => setCurrentStep(1)}
        type="button"
        className="text-[#009E1E] w-full text-center  text-base font-bold"
      >
        {t("change number")}
      </button>
    </>
  );
}

export default SecondStep;
SecondStep.propTypes = null;
