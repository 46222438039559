import React, { useState, useEffect } from "react";
import styles from "./CompanyForm.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SuccessModal } from "../../ui/Models";

import { CompanyInfoPart1, CompanyInfoPart2, CompanyInfoPart3 } from "./Forms";
import { useParams } from "react-router-dom";
import {
  getCompany,
  createCompany,
  updateCompany,
} from "../../../data_featching/community";
import { Spinner } from "../../ui/Spinner";
import { useNavigate } from "react-router-dom";
import StatusBar from "../../ui/status-bar/StatusBar";

const CompanyForm = () => {
  const { uuid } = useParams();
  const { t, i18n } = useTranslation("entities");
  const [isSharedModalOpen, setIsSharedModalOpen] = useState(false);
  const token = localStorage.getItem("authToken");
  const [PreviousData, setPreviousData] = useState({});
  const [IsValid, setIsValid] = useState(true);
  const [formData, setFormData] = useState({
    name: {
      en: "",
      ar: "",
    },
    email: "",
    phone: "",
    url: "",
    details: {
      en: "",
      ar: "",
    },
    address: "",
    commercial_record: "",
    tax_number: "",
    logo: null, // For file upload
    cover: null, // For file upload
    business_segment_ids: [],
  });
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const [statusMessage, setStatusMessage] = useState({
    isActive: false,
    message: "",
    currentStatus: "",
  });
  // get company data if uuid is provided
  useEffect(() => {
    console.log("uuid", uuid);
    if (uuid) {
      getCompany(token, uuid, i18n.language).then((data) => {
        console.log("company data", data.data);
        setPreviousData(data.data);
        // Update the form Fields
        setFormData({
          name: {
            en: data.data.name_translations.en,
            ar: data.data.name_translations.ar,
          },
          email: data.data.email,
          phone: data.data.phone,
          url: data.data.url,
          details: {
            en: data.data.details_translations.en,
            ar: data.data.details_translations.ar,
          },
          address: data.data.address,
          commercial_record: data.data.commercial_record,
          tax_number: data.data.tax_number,
          logo: null, // For file upload
          cover: null, // For file upload
          business_segment_ids: data.data.business_segments.map(
            (segment) => segment.id
          ),
        });
      });
    }
  }, [uuid, setPreviousData]);

  // Validation function
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.en)
      newErrors.nameEn = t("Company Name (EN) is required");
    if (!formData.name.ar)
      newErrors.nameAr = t("Company Name (AR) is required");
    if (!formData.email) newErrors.email = t("Email is required");
    if (!formData.phone) newErrors.phone = t("Phone Number is required");
    if (!formData.url) newErrors.url = t("Website is required");
    if (!formData.details.en)
      newErrors.detailsEn = t("About Company (EN) is required");
    if (!formData.details.ar)
      newErrors.detailsAr = t("About Company (AR) is required");
    if (!formData.address) newErrors.address = t("Company Address is required");
    if (!formData.commercial_record)
      newErrors.commercialRecord = t("Commercial Record (CR) is required");
    if (!formData.tax_number) newErrors.taxNumber = t("Tax Number is required");
    if (formData.business_segment_ids.length === 0)
      newErrors.businessSegment = t("Business Segment is required");
    if (!formData.logo && (!uuid || (uuid && !PreviousData?.logo?.url)))
      newErrors.logo = t("Logo is required");
    if (!formData.cover && (!uuid || (uuid && !PreviousData?.cover?.url)))
      newErrors.cover = t("Cover is required");
    setErrors(newErrors);
    return newErrors;
  };

  // handle create company
  const handleCreateCompany = async () => {
    const errors = validateForm();
    const isvalid = Object.keys(errors).length === 0;
    if (!isvalid) {
      console.log("Form is not valid", errors);

      setStatusMessage({
        isActive: true,
        // set all the error messages in one message
        message: Object.values(errors).join(", "),
        currentStatus: 2,
      });
      return;
    }

    const formDataToSend = new FormData();

    // create company if uuid is not provided
    let res;
    if (!uuid) {
      Object.keys(formData).forEach((key) => {
        if (formData[key] instanceof File) {
          formDataToSend.append(key, formData[key]);
        } else if (Array.isArray(formData[key])) {
          formData[key].forEach((item) =>
            formDataToSend.append(`${key}[]`, item)
          );
        } else if (typeof formData[key] === "object") {
          Object.keys(formData[key]).forEach((subKey) => {
            formDataToSend.append(`${key}[${subKey}]`, formData[key][subKey]);
          });
        } else {
          formDataToSend.append(key, formData[key]);
        }
      });
      setStatusMessage({
        isActive: true,
        message: t("Company creating now"),
        currentStatus: 1,
      });

      res = await createCompany(token, formDataToSend);
    } else {
      formDataToSend.append("name[en]", formData.name.en);
      formDataToSend.append("name[ar]", formData.name.ar);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("phone", formData.phone);
      formDataToSend.append("url", formData.url);
      formDataToSend.append("details[en]", formData.details.en);
      formDataToSend.append("details[ar]", formData.details.ar);
      formDataToSend.append("address", formData.address);
      formDataToSend.append("commercial_record", formData.commercial_record);
      formDataToSend.append("tax_number", formData.tax_number);

      if (formData.logo instanceof File) {
        formDataToSend.append("logo", formData.logo);
      }
      if (formData.cover instanceof File) {
        formDataToSend.append("cover", formData.cover);
      }
      if (formData.business_segment_ids) {
        formData.business_segment_ids.forEach((item) =>
          formDataToSend.append("business_segment_ids[]", item)
        );
      }
      setStatusMessage({
        isActive: true,
        message: t("Company updating now"),
        currentStatus: 1,
      });

      res = await updateCompany(token, formDataToSend, uuid);
    }

    if (res) {
      setStatusMessage({
        isActive: false,
        message: "",
        currentStatus: "",
      });
      setIsSharedModalOpen(true);
      console.log("success", res);
      setTimeout(() => {
        //my-companies/:uuid/add-service
        navigate(`/my-companies/${res.data.uuid}/add-service`);
      }, 3000);
    } else {
      console.log("error", res);
    }
  };

  // close modal after 3 seconds
  useEffect(() => {
    if (isSharedModalOpen) {
      setTimeout(() => {
        setIsSharedModalOpen(false);
      }, 3000);
    }
  }, [isSharedModalOpen]);

  // close status message after 3 seconds
  useEffect(() => {
    if (statusMessage.isActive) {
      setTimeout(() => {
        setStatusMessage({
          isActive: false,
          message: "",
          currentStatus: "",
        });
      }, 3000);
    }
  }, [statusMessage.isActive]);

  // wait for the company data to be fetched if uuid is provided
  if (uuid && Object.keys(PreviousData).length === 0) {
    return <Spinner />;
  }

  return (
    <div className={styles.content}>
      {statusMessage.isActive && (
        <StatusBar
          message={statusMessage.message}
          statusFlag={statusMessage.currentStatus}
          currentLanguage={i18n.language}
        />
      )}
      <form onSubmit={(e) => e.preventDefault()}>
      <CompanyInfoPart1
        key="Part1"
        FormData={formData}
        setFormData={setFormData}
        intialCover={PreviousData?.cover?.url || null}
        intialLogo={PreviousData?.logo?.url || null}
        setIsValid={setIsValid}
      />
      <CompanyInfoPart2
        key="Part2"
        FormData={formData}
        setFormData={setFormData}
        setIsValid={setIsValid} 
      />
      <CompanyInfoPart3
        key="Part3"
        FormData={formData}
        setFormData={setFormData}
        setIsValid={setIsValid}
      />

      <div className={styles.actions}>
        <Link to="/profile/my-companies" className={styles.cancelButton}>
          {t("Cancel")}
        </Link>
        <button
          onClick={() => handleCreateCompany()}
          disabled={!IsValid}
          className={styles.nextButton}
        >
          {uuid ? t("Update Company") : t("Create Company")}
        </button>

        {isSharedModalOpen && (
          <SuccessModal
            isOpen={isSharedModalOpen}
            onClose={() => setIsSharedModalOpen(false)}
            message={t("Company shared to the community")}
          />
        )}
      </div>
      </form>
    </div>
  );
};

export default CompanyForm;
