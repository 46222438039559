import { useEffect, useState } from "react";
import LoginNavBar from "../../ui/login-nav_bar/LoginNavBar";
import VerificationResetPassword from "./verification-reset-password/VerificationResetPassword";
import "./ForgetPassword.css";
import { forgetPassword } from "../../../data_featching/forgetPassword";
import ResetPassword from "./reset_password/ResetPassword";
import { useTranslation } from "react-i18next";
const ForgetPassword = () => {
  const { t } = useTranslation("login");
  const [emailInput, setEmailInput] = useState("");
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [showEmailError, setShowEmailError] = useState("");
  const [VERIFIATCAION_ID, setVERIFIATCAION_ID] = useState("");
  const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);
  const [VERIFIATCAION_CODE, setVERIFIATCAION_CODE] = useState(0);
  const [showVerificationPhase, setShowVerificationPhase] = useState(false); //false
  const [verificationNum, setVerificationNum] = useState({
    code1: "",
    code2: "",
    code3: "",
    code4: "",
    code5: "",
    code6: "",
  });
  /*set password phase */
  const [password, setPassword] = useState("");

  const formValidation = () => {
    if (emailInput.trim()) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (formValidation()) {
      setShowNextButton(true);
    } else {
      setShowNextButton(false);
    }
    if (showEmailError) {
      setShowEmailError(false);
    }
  }, [emailInput]);

  /*handle submitting email */
  async function handleResetPasswordSubmit() {
    const emailForm = new FormData();
    emailForm.append("identifier", emailInput);
    const result = await forgetPassword(emailForm);
    if (result.status == 200 || result.status == 201) {
      console.log("VERIFIATCAION_ID", result?.data?.data?.id);
      setVERIFIATCAION_ID(result?.data?.data?.id);
      setShowVerificationPhase(true);
    } else if (result.status !== 200 || result.status !== 201) {
      setShowEmailError(result?.data?.message);
      console.log("showEmailError", result);
    }
  }

  return (
    <div className="Login_container">
      <LoginNavBar
        loginNav={false}
        showBurgerLoginMenu={showBurgerLoginMenu}
        setShowBurgerLoginMenu={setShowBurgerLoginMenu}
      />
      {!showBurgerLoginMenu && (
        <>
          <div className="register-container px-3 min-h-[calc(100vh-8.083rem)]  max-h-fit pt-16 pb-8 md:px-[3.5rem] lg:px-[4.375rem] xl:px-0">
            <>
              {showVerificationPhase ? (
                <VerificationResetPassword
                  verificationNum={verificationNum}
                  setVerificationNum={setVerificationNum}
                  setShowSetPassword={setShowSetPassword}
                  setShowVerificationPhase={setShowVerificationPhase}
                  sendOtp={handleResetPasswordSubmit}
                  VERIFIATCAION_ID={VERIFIATCAION_ID}
                  VERIFIATCAION_CODE={VERIFIATCAION_CODE}
                  setVERIFIATCAION_CODE={setVERIFIATCAION_CODE}
                />
              ) : !showSetPassword ? (
                <div className="personal_main_container_reset_password  sm:mx-0 xl:mx-[7rem]  sm:w-full xl:w-[36rem]">
                  <div className="w-full Header_register_phase">
                    <h1>{t("Password reset")}</h1>
                    <div className="flex gap-1.5 items-start">
                      <div className="phase Active_phase"></div>
                      <div className="phase"></div>
                      <div className="phase"></div>
                    </div>
                  </div>
                  <form
                    className="flex flex-col justify-start w-full gap-8 personal_registerForm"
                    onSubmit={(event) => {
                      event.preventDefault();
                      handleResetPasswordSubmit();
                    }}
                  >
                    <div className="reset_password_hint_email">
                      <p className="reset_password_hint">
                        {t(
                          "Please enter registered email or phone number to verify your identity"
                        )}
                      </p>
                      <div className="flex flex-col w-full gap-2">
                        <label htmlFor="" className="name_input_text">
                          {t("Email/phone number")}
                        </label>
                        <input
                          type="text"
                          className="name_input_personal"
                          onChange={(e) => setEmailInput(e.target.value)}
                          value={emailInput}
                        />
                        {showEmailError && (
                          <div style={{ color: "red" }}>{showEmailError}</div>
                        )}
                      </div>
                    </div>

                    <button
                      className="w-full next_button"
                      style={
                        showNextButton ? { backgroundColor: "#009e1e" } : null
                      }
                    >
                      <span className="flex items-center gap-2">
                        {t("Send verification code")}
                        <img src="/Send_fill.png" alt="" />
                      </span>
                    </button>
                  </form>
                  {/* <div className="flex w-full gap-4 social_buttons">
                <button className="submit_form_social w-50%">
                  <img
                    src="/LinkedIn_Logo.png
            "
                    alt="lock-closed img"
                  />
                  <span>Register with LinkedIn</span>
                </button>
                <button className="submit_form_social w-50%">
                  <img
                    src="/Google_Logo.png
            "
                    alt="lock-closed img"
                  />
                  <span>Register with Google</span>
                </button>
              </div> */}
                </div>
              ) : (
                <ResetPassword
                  setShowVerificationPhase={setShowVerificationPhase}
                  password={password}
                  setPassword={setPassword}
                  setShowSetPassword={setShowSetPassword}
                  VERIFIATCAION_CODE={VERIFIATCAION_CODE}
                  VERIFIATCAION_ID={VERIFIATCAION_ID}
                  emailInput={emailInput}
                />
              )}
            </>
            <div className="mt-6 text-center right_reserved_hint_login xl:ltr:ml-auto xl:rtl:mr-auto xl:ltr:pr-28 xl:rtl:pl-28">
              {t("copy_rights")}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ForgetPassword;
