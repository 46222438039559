import baseURL from "../../axios";

export async function forgetPassword(resetUserInfo) {
  const endPoint = `/api/v1/user/forgot-password`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, resetUserInfo);
    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      return error.response;
    }
  }
}
/*request an OTP to verify mobile number */
/*recieve verification code id  */
let isRequestInProgress = false;
export async function verify_OTP_ID_reset_password(
  verificationInfo,
  currentLanguage = "en"
) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }
  isRequestInProgress = true;

  const endPoint = `/api/v1/user/verification/verify`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, verificationInfo, {
      headers: {
        "Accept-language": currentLanguage,
      },
    });
    console.log("reselt inside  function", result);
    isRequestInProgress = false;

    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      isRequestInProgress = false;

      return error.response;
    }
  }
}
/*reset password */
export async function resetPasswordDataRequest(resetPwdInfo) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }
  const jsonData = JSON.stringify(resetPwdInfo);

  console.log("jsonData", jsonData);
  const endPoint = `/api/v1/user/forgot-password`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.patch(url, jsonData, {
      headers: {
        "Content-Type": "application/json", // must to tell Api that the data is json format
      },
    });
    console.log("reselt inside  function", result);

    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      isRequestInProgress = false;

      return error.response;
    }
  }
}
