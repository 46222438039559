import { AlertTriangleIcon } from "lucide-react";

const EmptyState = ({message}) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
        <AlertTriangleIcon size="64" />
      {/* <img src="/image 9.png" alt="" className="w-[150px] h-[150px]" /> */}
      <h1 className="text-[#6B7280] font-semibold text-xl">
        {message}
      </h1>
    </div>
  );
};

export default EmptyState;
