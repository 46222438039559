import React from "react";
import { AlertCircle, WifiOff, RefreshCw } from "lucide-react";
import { useTranslation } from "react-i18next";

const NoInternetConnection = () => {
  const { t, i18n } = useTranslation("terms");

  const handleRetry = () => {
    window.location.reload();
  };

  const isRTL = i18n.dir() === "rtl";
  return (
    <div
      className={`min-h-screen flex items-center justify-center bg-gray-50 p-4 ${
        isRTL ? "font-expo" : "font-inter"
      }`}
    >
      <div className="max-w-md w-full space-y-8">
        <div className="text-center">
          <WifiOff className="mx-auto h-24 w-24 text-gray-400" />
          <h2 className="mt-6 text-3xl font-bold text-gray-900">
            {t("titleN")}
          </h2>
          <p className="mt-2 text-gray-600">{t("subtitle")}</p>
        </div>

        <div className="rounded-lg border border-red-200 bg-red-50 p-4">
          <div
            className={`flex items-start ${isRTL ? "flex-row-reverse" : ""}`}
          >
            <AlertCircle
              className={`h-5 w-5 text-red-600 mt-0.5 ${
                isRTL ? "ml-3" : "mr-3"
              }`}
            />
            <div className={`${isRTL ? "mr-3" : "ml-3"} flex-grow`}>
              <h3 className="text-lg font-medium text-red-800">
                {t("errorTitle")}
              </h3>
              <div className="mt-2 text-lg text-red-700">
                {t("errorDescription")}
                <ul
                  className={`list-disc ${
                    isRTL ? "mr-6" : "ml-6"
                  } mt-2 space-y-1`}
                >
                  <li className="text-gray-500 text-lg font-normal">
                    {t("reason1")}
                  </li>
                  <li className="text-gray-500 text-lg font-normal">
                    {t("reason2")}
                  </li>
                  <li className="text-gray-500 text-lg font-normal">
                    {t("reason3")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <button
            onClick={handleRetry}
            className={`inline-flex items-center px-4 py-2 bg-green-600 text-white font-medium rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors ${
              isRTL ? "flex-row-reverse" : ""
            }`}
          >
            <RefreshCw className={`h-4 w-4 mr-2`} />
            <span>{t("retryButton")}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoInternetConnection;
