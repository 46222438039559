import { useTranslation } from "react-i18next";
const EmptyReviwes = () => {
  const { t } = useTranslation("workspace");
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <img src="/image 10.png" alt="" className="w-[150px] h-[150px]" />
      <h1 className="text-[#6B7280] font-semibold text-xl">
        {t("no_reviews_yet")}
      </h1>
    </div>
  );
};

export default EmptyReviwes;
