export const Spinner = () => {
  return (
    <div
      className="w-[8rem] flex justify-center items-center
      mx-auto absolute mt-[-5rem] inset-1/2 transform -translate-x-1/2 -translate-y-1/2"
    >
      <img src="/loading.gif" alt="loading.." />
    </div>
  );
};
