import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import "./output.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider.jsx";
import { LanguageProvider } from "./context/LanguageProvider.jsx";
import "./i18n.js";
import { SearchProvider } from "./context/SearchContext.jsx";
import "../sentry"; // run sentry Module
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider>
      <LanguageProvider>
        <SearchProvider>
          <Router>
            <Routes>
              <Route path="/*" element={<App />}></Route>
            </Routes>
          </Router>
        </SearchProvider>
      </LanguageProvider>
    </AuthProvider>
  </React.StrictMode>
);
