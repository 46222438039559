import { useContext, useState } from "react";
import FirstStep from "./emailSteps/FirstStep";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../context/LanguageProvider";
import { changeEmailStepOne } from "../../data_featching/UpdateProfile";

function EmailChange({
  setGoToCompleteEmail,
  setHasCompletedEmail,
  setShowSuccessEmail,
}) {
  const { t } = useTranslation("profile");
  const [email, setEmail] = useState("");
  const [stepOneClicked, setStepOneClicked] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [emailStepOneError, setEmailStepOneError] = useState("");
  const token = localStorage.getItem("authToken");
  const { language } = useContext(LanguageContext);

  const handleStepOne = async () => {
    setStepOneClicked(true);
    if (isValidEmail) {
      const data = JSON.stringify({
        email: email,
      });
      try {
        const response = await changeEmailStepOne(token, data, language);

        if (response.status == 200 || response.status == 201) {
          setGoToCompleteEmail(false);
          setHasCompletedEmail(true);
          setShowSuccessEmail(true);
          setTimeout(() => {
            setShowSuccessEmail(false);
          }, 3000);
        }
      } catch (error) {
        setEmailStepOneError(error.message);
      }
    } else {
      return;
    }
  };

  return (
    <div className="fixed  inset-0  z-[1000] flex items-center justify-center bg-black bg-opacity-25">
      <div className="p-6 bg-white flex flex-col h-[35.5rem]  rounded-lg shadow-md w-[31.25rem]">
        <h2 className="mb-[5.5rem] text-xl font-semibold">
          {t("changing email")}
        </h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <FirstStep
            email={email}
            setEmail={setEmail}
            setIsValidEmail={setIsValidEmail}
            stepOneClicked={stepOneClicked}
            emailStepOneError={emailStepOneError}
          />
        </form>
        <div className="flex items-center w-full gap-[0.75rem] mt-auto">
          <button
            type="button"
            onClick={() => setGoToCompleteEmail(false)}
            className="text-sm flex-1 shadow-sm outline-none font-medium border px-4 py-[0.625rem] rounded-lg border-[#E5E7EB]"
          >
            {t("cancel")}
          </button>

          <button
            type="button"
            onClick={handleStepOne}
            className={`text-sm flex-1 shadow-sm outline-none font-medium bg-[#009E1E] text-white px-4 py-[0.625rem] rounded-lg`}
          >
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EmailChange;
EmailChange.propTypes = null;
