import { useContext } from "react";
import LanguageContext from "../../context/LanguageProvider";
import { useTranslation } from "react-i18next";

function Preferences() {
  const { language, changeLanguage } = useContext(LanguageContext);
  const { t } = useTranslation("profile");

  return (
    <div className="p-6 w-full h-full">
      <h3 className="mb-6 text-xl font-semibold">{t("Preferences")}</h3>
      <div>
        <div className="flex items-center gap-16 px-4">
          <h3 className="w-[8.75rem]  text-xl font-semibold">
            {t("Location")}
          </h3>
          <p className="text-lg font-normal text-gray-500">
            {t("Saudi Arabia")}
          </p>
          {/* <button className="flex items-center gap-1 ltr:ml-auto rtl:mr-auto">
            <span className="text-xs font-medium text-blue-600">
              {t("Change")}
            </span>
            <img
              src="/prefer-change.svg"
              alt=""
              className="mt-[2.5px] h-4 w-4"
            />
          </button> */}
        </div>
        <div className="h-[1px] bg-gray-300 my-4"></div>
        <div className="flex items-center gap-16 px-4">
          <h3 className="w-[8.75rem] text-xl font-semibold">{t("Currency")}</h3>
          <p className="text-lg font-normal text-gray-500">
            {t("Saudi Riyal")}
          </p>
          {/* <button className="flex items-center gap-1 ltr:ml-auto rtl:mr-auto">
            <span className="text-xs font-medium text-blue-600">
              {t("Change")}
            </span>
            <img
              src="/prefer-change.svg"
              alt=""
              className="mt-[2.5px] h-4 w-4"
            />
          </button> */}
        </div>
        <div className="h-[1px] bg-gray-300 my-4"></div>
        <div className="flex items-center gap-16 px-4">
          <h3 className="w-[8.75rem] text-xl font-semibold">{t("Language")}</h3>
          <p className="text-lg font-normal text-gray-500">
            {language === "en" ? "English" : "العربية"}
          </p>

          <button
            onClick={() => changeLanguage(language === "en" ? "ar" : "en")}
            className="flex items-center gap-1 ltr:ml-auto rtl:mr-auto"
          >
            <span className="text-xs font-medium text-blue-600">
              {t("Change")}
            </span>
            <img
              src="/prefer-change.svg"
              alt=""
              className="mt-[2.5px] h-4 w-4"
            />
          </button>
        </div>
        <div className="h-[1px] bg-gray-300 mt-4"></div>
      </div>
    </div>
  );
}

export default Preferences;
