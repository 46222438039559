import { useContext, useState } from "react";
import ThirdStep from "./steps/ThirdStep";
import FirstStep from "./steps/FirstStep";
import SecondStep from "./steps/SecondStep";
import { useTranslation } from "react-i18next";
import {
  completeProfile,
  completeProfileStepOne,
  completeProfileStepTwo,
} from "../../data_featching/UpdateProfile";
import LanguageContext from "../../context/LanguageProvider";

function CompleteProfile({ setGoToComplete, setHasCompleted, setShowSuccess }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [phoneInput, setPhoneInput] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [stepOneClicked, setStepOneClicked] = useState(false);
  const [stepTwoClicked, setStepTwoClicked] = useState(false);
  const [stepThreeClicked, setStepThreeClicked] = useState(false);
  const [stepOneError, setStepOneError] = useState("");
  const [stepTwoError, setStepTwoError] = useState("");
  const [stepThreeError, setStepThreeError] = useState("");
  const { t } = useTranslation("profile");
  const [id, setId] = useState("");
  const [otp, setOtp] = useState("");
  const token = localStorage.getItem("authToken");
  const { language } = useContext(LanguageContext);

  const isValidPassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+\-=[\]{}|;':"<>,.?]).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleStepOne = async () => {
    setStepOneClicked(true);
    if (isValidPhone) {
      const formData = new FormData();
      formData.set("phone", phoneInput);

      try {
        const response = await completeProfileStepOne(
          token,
          formData,
          language
        );
        if (response.status == 200 || response.status === 201) {
          setId(response?.data?.data?.id);
          setCurrentStep(2);
        }
      } catch (error) {
        setStepOneError(error.message);
      }
    } else {
      return;
    }
  };

  const handleStepTwo = async () => {
    setStepTwoClicked(true);
    if (/^[0-9]{6}$/.test(otp)) {
      const formData = new FormData();
      formData.set("verification_id", id);
      formData.set("verification_code", otp);

      try {
        const response = await completeProfileStepTwo(
          token,
          formData,
          language
        );

        if (response.status == 200 || response.status == 201) {
          setCurrentStep(3);
        }
      } catch (error) {
        setStepTwoError(error.message);
      }
    } else {
      return;
    }
  };

  const handleStepThree = async () => {
    setStepThreeClicked(true);
    if (password == confirmPassword && isValidPassword(password)) {
      try {
        const data = JSON.stringify({
          password: password,
          phone: phoneInput,
          verification_id: id,
          verification_code: otp,
        });
        const response = await completeProfile(token, data, language);

        if (response.status == 200 || response.status == 201) {
          setHasCompleted(true);
          setShowSuccess(true);
        }
      } catch (error) {
        setStepThreeError(error.message);
      }
    } else {
      return;
    }
  };

  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center bg-black bg-opacity-25">
      <div className="p-6 bg-white flex flex-col h-[35.5rem]  rounded-lg shadow-md w-[31.25rem]">
        <h2 className="mb-6 text-xl font-semibold">
          {t("completing profile")}
        </h2>
        <div className="flex justify-between w-full mb-16">
          <div className="bg-[#009E1E] h-2 w-[32.5%] rounded-lg border border-[#009E1E]"></div>
          <div
            className={`${
              currentStep > 1 ? "bg-[#009E1E]" : ""
            } h-2 border  rounded-lg  w-[32.5%] border-[#009E1E]`}
          ></div>
          <div
            className={`${
              currentStep > 2 ? "bg-[#009E1E]" : ""
            } h-2 border  rounded-lg  w-[32.5%] border-[#009E1E]`}
          ></div>
        </div>

        <form onSubmit={(e) => e.preventDefault()}>
          {currentStep === 1 && (
            <FirstStep
              phoneInput={phoneInput}
              setIsValidPhone={setIsValidPhone}
              setPhoneInput={setPhoneInput}
              isClicked={stepOneClicked}
              isValidPhone={isValidPhone}
              stepOneError={stepOneError}
            />
          )}

          {currentStep === 2 && (
            <SecondStep
              otp={otp}
              setOtp={setOtp}
              setCurrentStep={setCurrentStep}
              stepTwoClicked={stepTwoClicked}
              phoneInput={phoneInput}
              stepTwoError={stepTwoError}
              setStepTwoError={setStepTwoError}
              setId={setId}
            />
          )}
          {currentStep === 3 && (
            <ThirdStep
              password={password}
              setPassword={setPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              showConfirmPassword={showConfirmPassword}
              setShowConfirmPassword={setShowConfirmPassword}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              stepThreeClicked={stepThreeClicked}
              stepThreeError={stepThreeError}
            />
          )}
        </form>

        <div className="flex items-center w-full gap-[0.75rem] mt-auto">
          <button
            type="button"
            onClick={() => setGoToComplete(false)}
            className="text-sm flex-1 shadow-sm outline-none font-medium border px-4 py-[0.625rem] rounded-lg border-[#E5E7EB]"
          >
            {t("cancel")}
          </button>

          {currentStep === 1 && (
            <button
              type="button"
              onClick={handleStepOne}
              className={`text-sm flex-1 shadow-sm outline-none font-medium bg-[#009E1E] text-white px-4 py-[0.625rem] rounded-lg`}
            >
              {t("next")}
            </button>
          )}
          {currentStep === 2 && (
            <button
              type="button"
              onClick={handleStepTwo}
              className="text-sm flex-1 shadow-sm outline-none font-medium bg-[#009E1E] text-white px-4 py-[0.625rem] rounded-lg"
            >
              {t("Verify")}
            </button>
          )}
          {currentStep === 3 && (
            <button
              type="button"
              onClick={handleStepThree}
              className={`text-sm flex-1  bg-[#009E1E]  shadow-sm outline-none font-medium text-white px-4 py-[0.625rem] rounded-lg`}
            >
              {t("next")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CompleteProfile;

CompleteProfile.propTypes = null;
