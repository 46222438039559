import baseURL from "../../axios";

export const fetchAllCompanies = async (lang) => {
  try {
    const response = await baseURL.get(`/api/v1/application/companies`, {
      headers: {
        "Accept-Language": lang,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching companies:", error);
    return error.response;
  }
};
//{{base_url}}/api/v1/application/my-companies
export const fetchMyCompanies = async (token, page = 1, language = "en") => {
  const url = `/api/v1/application/my-companies`;
  try {
    const response = await baseURL.get(url, {
      params: {
        page: page,
        per_page: 4,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": language,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
//{{base_url}}/api/v1/application/my-companies/:uud/active-toggle

export const toggleCompany = async (token, uud) => {
  console.log("token toggg", token);
  const url = `/api/v1/application/my-companies/${uud}/active-toggle`;
  console.log("url", url, uud, token);
  try {
    const response = await baseURL.patch(url, [], {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
// get public company
//{{base_url}}/api/v1/application/companies/:uuid
export const getCompanyPublic = async (uuid, lang) => {
  const url = `/api/v1/application/companies/${uuid}`;
  try {
    const response = await baseURL.get(url, {
      headers: {
        "Accept-Language": lang,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
//{{base_url}}/api/v1/application/supplier-companies/:supplier-company
export const getCompany = async (token, uud, lang = "en") => {
  const url = `/api/v1/application/my-companies/${uud}`;
  try {
    const response = await baseURL.get(url, {
      headers: {
        "Accept-Language": lang,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
//Delete {{base_url}}/api/v1/application/my-companies/:uud

export const deleteCompany = async (token, uud) => {
  const url = `/api/v1/application/my-companies/${uud}`;
  try {
    const response = await baseURL.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
//{{base_url}}/api/v1/application/my-companies
export const createCompany = async (token, data) => {
  console.log("data", data);

  const url = `/api/v1/application/my-companies`;
  try {
    const response = await baseURL.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
//{{base_url}}/api/v1/application/tags?filters[type]=business-segment
export const getBusinessSegments = async (lang) => {
  const url = `/api/v1/application/tags?filters[type]=business-segment`;
  try {
    const response = await baseURL.get(url, {
      headers: {
        "Accept-Language": lang,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
//updateCompany
// {{base_url}}/api/v1/application/my-companies/:uuid?_method=PATCH
export const updateCompany = async (token, data, uud) => {
  console.log("updating >>>>>> ", data);
  const url = `/api/v1/application/my-companies/${uud}?_method=PATCH`;
  try {
    const response = await baseURL.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
//{{base_url}}/api/v1/application/my-companies/:comapny_uuid/services
export const createService = async (token, company_uuid, data) => {
  const url = `/api/v1/application/my-companies/${company_uuid}/services`;
  try {
    const response = await baseURL.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};

//{{base_url}}/api/v1/application/my-companies/:company_uuid/services/:service_id
export const deleteService = async (token, company_uuid, service_id) => {
  const url = `/api/v1/application/my-companies/${company_uuid}/services/${service_id}`;
  try {
    const response = await baseURL.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
