import baseURL from "../../axios";

//{{base_url}}/api/v1/application/workspaces/:workspaceId/spaces
export async function getWorkspaceSpaces(workspaceId, lang = "en") {
  const endPoint = `/api/v1/application/workspaces/${workspaceId}`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: {
        "Accept-Language": lang,
      },
    });

    return result;
  } catch (error) {
    console.log("error.response.data", error.response);
    if (error?.response?.status !== 200) {
      return error?.response;
    }
  }
}
// {{base_url}}/api/v1/application/workspace/ratings/:workspace-id
export async function getWorkspaceRatings(workspaceId, lang = "en") {
  const endPoint = `/api/v1/application/workspace/ratings/${workspaceId}`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: {
        "Accept-Language": lang,
      },
    });

    return result;
  } catch (error) {
    console.log("error.response.data", error.response);
    if (error?.response?.status !== 200) {
      return error?.response;
    }
  }
}

export async function getMapSpaces(lang = "en") {
  const endPoint = `/api/v1/application/workspaces`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: {
        "Accept-Language": lang,
      },
      params: {
        page: 1,
        per_page: 100,
      },
    });

    return result;
  } catch (error) {
    console.log("error.response.data", error.response);
    if (error?.response?.status !== 200) {
      return error?.response;
    }
  }
}

//{{base_url}}/api/v1/cities

export async function getCities(lang = "en") {
  const endPoint = `/api/v1/cities`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: {
        "Accept-Language": lang,
      },
    });

    return result;
  } catch (error) {
    console.log("error.response.data", error?.response);
    if (error.response?.status !== 200) {
      return error?.response;
    }
  }
}
// districts
//{{base_url}}/api/v1/districts
export async function getDistricts(cityId, lang = "en") {
  const endPoint = `/api/v1/districts`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: {
        "Accept-Language": lang,
      },
      params: {
        filters: {
          city: cityId,
        },
      },
    });

    return result;
  } catch (error) {
    console.log("error.response.data", error?.response);
    if (error?.response?.status !== 200) {
      return error.response;
    }
  }
}
// featured workspaces
export  const fetchFeaturedWorkspaces = async (lang) => {
  const endPoint = `/api/v1/application/featured-workspaces`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: {
        "Accept-Language": lang,
      },
      params: {
        page: 1,
        per_page: 4,
      },
    });

    return result;
  } catch (error) {
    console.log("error.response.data", error.response);
    if (error?.response?.status !== 200) {
      return error?.response;
    }
  }
 
};