import baseURL from "../../axios";

export async function getAllNotification(accessToken) {
  const endPoint = `/api/v1/notifications?page=1&type=client_application`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response?.status !== 200) {
      return error.response;
    }
  }
}
export async function getUnReadedMessage(accessToken) {
  const endPoint = `/api/v1/notifications/unread/count`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response?.status !== 200) {
      return error.response;
    }
  }
}
