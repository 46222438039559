import { useState, useEffect, useContext } from "react";
import { getBookingDetails, cancelBooking } from "../../data_featching/booking";
// import useUnAuthorized from "../../hooks/useUnAuthorized";
import { useParams, useNavigate } from "react-router-dom";
import LoginNavBar from "../ui/login-nav_bar/LoginNavBar";
// import Gallary from "../Home/Gallary";
import { Spinner } from "../ui/Spinner";
import Footer from "../ui/footer/Footer";
import { formatDateAndTime } from "../../helpers/timeFormat";
import FeedbackForm from "../ui/feedback/FeedbackModel";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../context/LanguageProvider";
import { IconModal, ConfirmModal } from "../ui/Models";
import LocateMap from "../ui/Map/LocateMap";
import {
  addRemoveFavourites,
  getFavourites,
} from "../../data_featching/favorites";
import StatusBar from "../ui/status-bar/StatusBar";

const ShowBooking = () => {
  const [booking, setBooking] = useState(null);
  // const { checkAuth } = useUnAuthorized();
  const navigate = useNavigate();
  const { id: bookingId } = useParams();
  const token = localStorage.getItem("authToken");
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const { t } = useTranslation("bookings");
  const { language } = useContext(LanguageContext);
  const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);
  const [isArchivingModalOpen, setIsArchivingModalOpen] = useState(false);
  const [isArchivedModalOpen, setIsArchivedModalOpen] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [statusMessage, setStatusMessage] = useState({
    isActive: false,
    message: "",
    currentStatus: "",
  });
  const { checkin, checkout } = booking
    ? formatDateAndTime(
      language,
      booking.start_at, booking.end_at)
    : { checkin: null, checkout: null };

  const handleBookingCancel = async () => {
    const response = await cancelBooking(bookingId, token);
    console.log("cancelResponse", response);
    if (response.status === 200 || response.status === 201) {
      setIsArchivedModalOpen(true);
      setTimeout(() => {
        navigate("/bookings");
      }, 3000);
    }
  };
  const getAllFavorites = async () => {
    try {
      const response = await getFavourites(token);

      if (response.status === 200 || response.status === 201) {
        const favList = response.data.data;

        if (favList.length === 0) {
          setIsFavourite(false);
        } else {
          const fav = favList.find(
            (fav) => fav.space.id === booking?.space?.id
          );
          setIsFavourite(fav ? true : false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFavourites = async () => {
    try {
      setStatusMessage({
        isActive: true,
        message: isFavourite
          ? t("removing from favourites")
          : t("adding to favourites"),
        currentStatus: 1,
      });
      const response = await addRemoveFavourites(token, booking?.space?.id);
      if (response?.status == 200 || response?.status == 201) {
        console.log("Favourites:", response.data);

        setIsFavourite(!isFavourite);
        setStatusMessage({
          isActive: true,
          message: isFavourite
            ? t("removed from favourites")
            : t("added to favourites"),
          currentStatus: 3,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setStatusMessage({
        isActive: true,
        message: t("Error to change favourites"),
        currentStatus: 2,
      });
    }
  };
  useEffect(() => {
    const fetchBooking = async () => {
      const response = await getBookingDetails(bookingId, token, language);
      if (response.status === 200 || response.status === 201) {
        setBooking(response.data.data);
      }
    };

    fetchBooking();
  }, [token, bookingId, language]);

  // New useEffect to run getAllFavorites only after booking is set
  useEffect(() => {
    if (booking) {
      getAllFavorites();
    }
  }, [booking]); // Run only when `booking` state changes
  function getDirections() {
    // Get the latitude & longitude of the location
    const latitude = booking?.space?.workspace?.address?.latitude;
    const longitude = booking?.space?.workspace?.address?.longitude;
    // Construct the Google Maps directions URL
    const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    // Open the URL in a new tab
    window.open(url, "_blank");
  }
  // RESET STATUS MESSAGE
  useEffect(() => {
    const timer = setTimeout(() => {
      setStatusMessage({
        isActive: false,
        message: "",
        currentStatus: "",
      });
    }, 3000);
    return () => clearTimeout(timer);
  }, [statusMessage.isActive]);
  if (!booking) {
    return <Spinner />;
  }
  return (
    <>
      <LoginNavBar
        showBurgerLoginMenu={showBurgerLoginMenu}
        setShowBurgerLoginMenu={setShowBurgerLoginMenu}
      />
      {statusMessage.isActive && (
        <StatusBar
          message={statusMessage.message}
          statusFlag={statusMessage.currentStatus}
          currentLanguage={language}
        />
      )}
      {showFeedbackForm && (
        <FeedbackForm
          booking={booking}
          onClose={() => setShowFeedbackForm(false)}
        />
      )}
      {isArchivingModalOpen && (
        <ConfirmModal
          isOpen={isArchivingModalOpen}
          onClose={() => setIsArchivingModalOpen(false)}
          onConfirm={() => {
            setIsArchivingModalOpen(false);
            handleBookingCancel();
          }}
          title={t("Booking cancellation")}
          message={t("Are you sure you want to cancel booking")}
          confirmText={t("Yes, cancel booking")}
          icon={null}
          name={""}
          showBottom={false}
        />
      )}
      {isArchivedModalOpen && (
        <IconModal
          isOpen={isArchivedModalOpen}
          onClose={() => setIsArchivedModalOpen(false)}
          icon="/Book_cancel.svg"
          message={t(`Booking Cancelled`)}
          actionText={t("Undo")}
          onAction={() => setIsArchivedModalOpen(false)}
          title={t("")}
          showBottom={false}
        />
      )}
      <div className="bookingPage ">
        <div className="w-full">
          {booking?.space?.media?.length > 0 && (
            <img
              src={booking?.space?.media[0]?.url}
              alt="Meeting Room"
              className="object-cover w-full h-96"
              width="1200"
              height="400"
              style={{ aspectRatio: "1200/400", objectFit: "cover" }}
            />
          )}
        </div>
        <div className="w-full  xl:px-[114px] lg:px-[70px] md:px-[48px] sm:px-[24px] py-[44px] bg-[#f9fafb]">
          <div className="flex items-start justify-between gap-4 sm:flex-col lg:flex-row">
            <div className="p-2 bg-white rounded-lg shadow-md sm:w-full">
              <div className="flex flex-row items-center justify-between">
                <h1 className="text-2xl font-bold ">
                  {booking?.space?.name} ({booking?.space?.workspace?.name})
                </h1>
                <button
                  onClick={handleFavourites}
                  className=" text-center align-middle rounded-full shadow-md p-2 w-[40px] h-[40px] bg-white"
                >
                  <svg
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill={isFavourite ? "#009E1E" : "none"}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.40325 11.0361L10.4846 17.6883C10.662 17.855 10.7507 17.9383 10.8472 17.9806C11.0035 18.049 11.1813 18.049 11.3376 17.9806C11.434 17.9383 11.5227 17.855 11.7001 17.6883L18.7815 11.0361C20.7739 9.16449 21.0159 6.0845 19.3401 3.9247L19.0251 3.51859C17.0204 0.934846 12.9966 1.36816 11.5881 4.31946C11.3891 4.73634 10.7957 4.73634 10.5967 4.31946C9.18812 1.36816 5.16432 0.934844 3.15969 3.51858L2.8446 3.92469C1.1689 6.0845 1.41085 9.16449 3.40325 11.0361Z"
                      stroke={isFavourite ? "#009E1E" : "#252C34"}
                      strokeWidth="1.83333"
                    />
                  </svg>
                </button>
              </div>

              <div className="flex items-center space-x-2">
                <div className="flex items-center my-2">
                  {Array.from({ length: booking?.space?.rating }, () => (
                    <svg
                      key={crypto.randomUUID()}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.52482 4.83676C10.5736 2.2221 11.098 0.914764 11.9998 0.914764C12.9016 0.914764 13.426 2.2221 14.4748 4.83676L14.5236 4.95852C15.1161 6.43567 15.4124 7.17425 16.0162 7.62317C16.62 8.07209 17.4126 8.14307 18.9978 8.28504L19.2844 8.31071C21.8788 8.54306 23.176 8.65924 23.4536 9.48455C23.7312 10.3099 22.7678 11.1863 20.8411 12.9392L20.1981 13.5243C19.2227 14.4116 18.735 14.8553 18.5077 15.4368C18.4653 15.5453 18.4301 15.6564 18.4022 15.7695C18.2528 16.3757 18.3956 17.0194 18.6812 18.3066L18.7701 18.7073C19.295 21.0731 19.5575 22.256 19.0992 22.7662C18.928 22.9569 18.7055 23.0942 18.4582 23.1616C17.7966 23.3421 16.8573 22.5767 14.9787 21.046C13.7452 20.0408 13.1284 19.5382 12.4203 19.4251C12.1417 19.3806 11.8579 19.3806 11.5793 19.4251C10.8712 19.5382 10.2544 20.0408 9.02086 21.046C7.14226 22.5767 6.20295 23.3421 5.54135 23.1616C5.29413 23.0942 5.07158 22.9569 4.90035 22.7662C4.44211 22.256 4.70456 21.0731 5.22946 18.7073L5.31836 18.3066C5.60397 17.0194 5.74678 16.3757 5.59737 15.7695C5.5695 15.6564 5.53425 15.5453 5.49185 15.4368C5.26455 14.8553 4.77688 14.4116 3.80154 13.5243L3.1585 12.9392C1.23178 11.1863 0.268413 10.3099 0.545984 9.48455C0.823556 8.65924 2.12076 8.54306 4.71518 8.31071L5.00177 8.28504C6.58699 8.14307 7.3796 8.07209 7.9834 7.62317C8.5872 7.17425 8.88346 6.43567 9.47598 4.95851L9.52482 4.83676Z"
                        fill="#F8CB00"
                      />
                    </svg>
                  ))}
                </div>
                {booking?.space?.rating > 0 && (
                  <>
                    <span className="text-gray-800 font-[800]">
                      {`(${booking?.space?.rating})`}
                    </span>
                    <span>
                      {`${t("based_on")} ${
                        booking.space?.workspace?.space_ratings_count
                      } ${
                        booking.space?.workspace?.space_ratings_count > 1
                          ? t("reviews")
                          : t("review")
                      }`}
                    </span>
                  </>
                )}
              </div>
              <p className="flex items-center text-gray-500 ">
                <svg
                  className="me-2"
                  width="16"
                  height="18"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.39804 16.804L8.74336 17.4697H8.74336L8.39804 16.804ZM7.60196 16.804L7.25664 17.4697H7.25664L7.60196 16.804ZM14.25 8C14.25 10.2364 13.111 12.0661 11.7225 13.4666C10.3347 14.8664 8.76052 15.7711 8.05271 16.1382L8.74336 17.4697C9.51848 17.0677 11.2453 16.0785 12.7877 14.5227C14.3295 12.9676 15.75 10.78 15.75 8H14.25ZM8 1.75C11.4518 1.75 14.25 4.54822 14.25 8H15.75C15.75 3.71979 12.2802 0.25 8 0.25V1.75ZM1.75 8C1.75 4.54822 4.54822 1.75 8 1.75V0.25C3.71979 0.25 0.25 3.71979 0.25 8H1.75ZM7.94729 16.1382C7.23948 15.7711 5.66527 14.8664 4.27749 13.4666C2.88902 12.0661 1.75 10.2364 1.75 8H0.25C0.25 10.78 1.67049 12.9676 3.21227 14.5227C4.75474 16.0785 6.48152 17.0677 7.25664 17.4697L7.94729 16.1382ZM8.05271 16.1382C8.01637 16.157 7.98363 16.157 7.94729 16.1382L7.25664 17.4697C7.72602 17.7132 8.27398 17.7132 8.74336 17.4697L8.05271 16.1382ZM10.25 8C10.25 9.24264 9.24264 10.25 8 10.25V11.75C10.0711 11.75 11.75 10.0711 11.75 8H10.25ZM8 5.75C9.24264 5.75 10.25 6.75736 10.25 8H11.75C11.75 5.92893 10.0711 4.25 8 4.25V5.75ZM5.75 8C5.75 6.75736 6.75736 5.75 8 5.75V4.25C5.92893 4.25 4.25 5.92893 4.25 8H5.75ZM8 10.25C6.75736 10.25 5.75 9.24264 5.75 8H4.25C4.25 10.0711 5.92893 11.75 8 11.75V10.25Z"
                    fill="#000021"
                  />
                </svg>
               
                {booking?.space?.workspace?.address?.building_number
                || booking?.space?.workspace?.address?.street_name
                },
                {booking?.space?.workspace?.address?.district.name}
              </p>
              <div className="flex items-center gap-4 my-8">
                <span className="text-lg font-medium">{t("workspace")}:</span>
                <p className="flex items-center gap-2 text-sm font-normal ">
                  <img
                    className=" w-[20px] h-[20px] rounded-full "
                    src={booking?.space?.workspace?.logo?.url}
                  />{" "}
                  {booking?.space?.workspace?.name}{" "}
                </p>
              </div>
              <div className="flex justify-between gap-6 my-8 checkDates xl:flex-row sm:flex-col">
                <div className="checkIn flex items-center justify-between gap-1 mt-4 w-[320px]">
                  <span className="text-lg font-[500]">{t("checkIn")}:</span>
                  <div className="flex items-center gap-2">
                    <span className="text-[15px] font-[400] text-[#2563EB] p-2  text-sm ">
                      {checkin.formattedDate}
                    </span>
                    <span className="text-[15px] font-[400] text-[#2563EB] p-2  text-sm ">
                      {checkin.formattedTime}
                    </span>
                  </div>
                </div>
                <div className="checkOut flex items-center justify-between mt-4 w-[320px] ">
                  <span className="text-lg font-[500]">{t("checkOut")}:</span>
                  <div className="flex items-center gap-2">
                    <span className="text-[15px] font-[400] text-[#2563EB] p-2 text-sm ">
                      {checkout.formattedDate}
                    </span>
                    <span className="text-[15px] font-[400] p-2 text-[#2563EB] text-sm ">
                      {checkout.formattedTime}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-4 my-8">
                <span className="text-lg font-medium">
                  {t("booking state")}:
                </span>
                <p
                  className={`text-${booking?.state?.color} text-[15px] font-normal`}
                >
                  {booking?.state?.label}
                </p>
              </div>
              <div className="flex justify-between gap-4 my-8 md:flex-row sm:flex-col">
                <div className="flex items-center gap-8">
                  <span className="text-lg font-medium">{t("space")}:</span>
                  <p className="flex items-center gap-2 text-[15px] font-normal">
                    <img
                      src={booking?.space?.media[0]?.url}
                      alt="Space"
                      className="w-[40px] h-[40px]"
                    />
                    <span className=" text-[15px] font-normal">
                      {booking?.space?.name}
                    </span>
                  </p>
                </div>
                <div className="flex items-center gap-8">
                  <span className="text-[15px] font-normal">
                    {booking?.space?.type.label}
                  </span>
                  <p className="flex items-center gap-2 text-[15px] font-normal">
                    <span className=" text-[15px] font-normal">
                      {booking?.price}
                    </span>
                    <span className=" text-[15px] font-normal">
                      {booking?.currency?.label}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-4 my-8">
                <span className="text-lg font-medium">{t("price")}:</span>
                <span className="flex-grow mx-2 border-b border-black border-dotted"></span>

                <p className="text-[15px] font-normal text-success">
                  {booking?.final_price} {booking?.currency?.label}
                </p>
              </div>
              <div className="flex items-center gap-4 my-8">
                <span className="text-lg font-medium">{t("plan")}:</span>
                <span className="text-[15px] font-normal ">
                  {" "}
                  {booking?.plan?.label}{" "}
                </span>
              </div>
              <div className="flex items-center gap-4 my-8">
                <span className="text-lg font-medium">{t("payment")}:</span>
                <span className="text-[15px] font-normal text-destructive">
                  {t("unpaid")}
                  {/* {booking?.plan?.label}{" "} */}
                </span>
              </div>
              {booking?.can_be_canceled && (
                <div className="justify-end hidden my-8 text-right lg:block">
                  <button
                    onClick={() => setIsArchivingModalOpen(true)}
                    className=" bg-white text-destructive shadow-md boder border-[#E5E7EB] p-2 rounded-lg"
                  >
                    {t("cancelBooking")}
                  </button>
                </div>
              )}
            </div>
            <div className="sm:w-full lg:max-w-[400px]">
              <div className="relative ">
                <h2 className="mb-4 text-xl font-bold">{t("location")}</h2>
                <div className="relative z-5">
                  <LocateMap address={booking?.space?.workspace?.address} />
                  <button
                    onClick={getDirections}
                    className="mt-4 p-3 bg-[#252C34] rounded-lg text-white absolute bottom-10 left-1/2 -translate-x-1/2 "
                  >
                    {t("get directions")}
                  </button>
                </div>
                {booking?.can_be_rated && (
                  <button
                    onClick={() => {
                      setShowFeedbackForm(true);
                    }}
                    className="bg-[#009E1E] text-white px-4 py-1 rounded-lg mt-10 w-full flex items-center justify-center"
                  >
                    {t("rate")}
                    <img
                      src="/like_rate.svg"
                      alt="like"
                      className="ml-2 w-7 h-7"
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
          {booking?.can_be_canceled && (
            <div className="my-8 text-right sm:block lg:hidden ">
              <button
                onClick={() => setIsArchivingModalOpen(true)}
                className=" w-full  bg-white text-destructive shadow-md boder border-[#E5E7EB] p-2 rounded-lg"
              >
                {t("cancelBooking")}
              </button>
            </div>
          )}
        </div>
      </div>
      <div style={{ backgroundColor: "#252C34" }}>
        <div className="GooglePlay">
          <div className="Hint">{t("get workey")}</div>
          <div className="GooglePlay_logo">
            <img src="/Group.png" alt="google play img" />
            <img src="/AppleStore.png" alt="Apple Store img" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ShowBooking;
