import Progressbar from "./Progressbar";
import { useTranslation } from "react-i18next";

const getOverAllRating = (item) => {
  const {
    five_score = 0,
    four_score = 0,
    three_score = 0,
    two_score = 0,
    one_score = 0,
    rating_count: ratingCount = 0,
  } = item;

  const five = five_score * 5;
  const four = four_score * 4;
  const three = three_score * 3;
  const two = two_score * 2;
  const one = one_score * 1;

  // Calculate weighted average
  const rating = (five + four + three + two + one) / ratingCount;

  // Round the rating to the nearest integer (approximate fractions)
  return Math.round(rating);
};

function getRatingCounts(item) {
  if (item) {
    const {
      five_score = 0,
      four_score = 0,
      three_score = 0,
      two_score = 0,
      one_score = 0,
    } = item;
    // Calculate total ratings count for the current item
    const ratingCount =
      five_score + four_score + three_score + two_score + one_score;

    return ratingCount || 0;
  }
}
const OverallRatingItem = ({ itemNo, ratingItem, removeTitle = false }) => {
  const { t } = useTranslation("workspace");
  const {
    five_score = 0,
    four_score = 0,
    three_score = 0,
    two_score = 0,
    one_score = 0,
  } = ratingItem;
  const calculatePercentage = (score, item) => {
    const { rating_count: ratingCount } = item;
    return ratingCount > 0 ? ((score / ratingCount) * 100).toFixed(1) : 0;
  };

  const ratingCount = getRatingCounts(ratingItem);
  const ratingStars = getOverAllRating(ratingItem);
  return (
    <>
      <div
        className={`flex flex-col gap-[40px] w-full pb-16
             ${itemNo && "pt-16"}
        `}
        // key={itemNo}
      >
        <div className="flex flex-col gap-2">
          {!removeTitle && (
            <h1 className="overall__rating text-[#111827] text-base font-medium">
              {t("overall")}
            </h1>
          )}
          <div className="flex items-center justify-start gap">
            {Array.from({ length: ratingStars }).map((_, idx) => (
              <svg
                key={idx}
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M10.4339 6.629C11.2205 4.668 11.6138 3.6875 12.2901 3.6875C12.9665 3.6875 13.3598 4.668 14.1464 6.629L14.183 6.72031C14.6274 7.82818 14.8496 8.38212 15.3024 8.7188C15.7553 9.05549 16.3497 9.10873 17.5387 9.21521L17.7536 9.23446C19.6994 9.40872 20.6723 9.49585 20.8805 10.1148C21.0887 10.7338 20.3661 11.3912 18.9211 12.7058L18.4388 13.1446C17.7073 13.8101 17.3416 14.1429 17.1711 14.579C17.1393 14.6604 17.1129 14.7437 17.092 14.8285C16.9799 15.2832 17.087 15.7659 17.3012 16.7314L17.3679 17.0319C17.7616 18.8063 17.9584 19.6934 17.6147 20.0761C17.4863 20.2191 17.3194 20.322 17.134 20.3726C16.6378 20.508 15.9333 19.934 14.5243 18.7859C13.5992 18.032 13.1366 17.6551 12.6055 17.5703C12.3966 17.5369 12.1837 17.5369 11.9748 17.5703C11.4437 17.6551 10.9811 18.032 10.0559 18.7859C8.64698 19.934 7.9425 20.508 7.4463 20.3726C7.26088 20.322 7.09397 20.2191 6.96555 20.0761C6.62187 19.6934 6.8187 18.8063 7.21238 17.0319L7.27905 16.7314C7.49326 15.7659 7.60037 15.2832 7.48831 14.8285C7.46741 14.7437 7.44097 14.6604 7.40917 14.579C7.2387 14.1429 6.87295 13.8101 6.14144 13.1446L5.65916 12.7059C4.21411 11.3912 3.49159 10.7338 3.69977 10.1148C3.90795 9.49585 4.88086 9.40872 6.82667 9.23446L7.04161 9.21521C8.23052 9.10873 8.82498 9.05549 9.27783 8.7188C9.73068 8.38212 9.95288 7.82818 10.3973 6.72031L10.4339 6.629Z"
                  fill="#F8CB00"
                />
              </svg>
            ))}
          </div>
          <p className="text-[#1E293B] font-normal text-sm">
            {t("based_on")} {ratingCount}{" "}
            {`${ratingCount > 1 ? t("reviews") : t("review")}`}
          </p>
        </div>
        <div className="flex flex-col w-full gap-6 ">
          <div className="5-star flex gap-[12px] w-full items-center">
            <div className="flex items-center">
              <span className="font-medium text-lg text-[#1E293B]"> 5</span>{" "}
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M10.4339 6.629C11.2205 4.668 11.6138 3.6875 12.2901 3.6875C12.9665 3.6875 13.3598 4.668 14.1464 6.629L14.183 6.72031C14.6274 7.82818 14.8496 8.38212 15.3024 8.7188C15.7553 9.05549 16.3497 9.10873 17.5387 9.21521L17.7536 9.23446C19.6994 9.40872 20.6723 9.49585 20.8805 10.1148C21.0887 10.7338 20.3661 11.3912 18.9211 12.7058L18.4388 13.1446C17.7073 13.8101 17.3416 14.1429 17.1711 14.579C17.1393 14.6604 17.1129 14.7437 17.092 14.8285C16.9799 15.2832 17.087 15.7659 17.3012 16.7314L17.3679 17.0319C17.7616 18.8063 17.9584 19.6934 17.6147 20.0761C17.4863 20.2191 17.3194 20.322 17.134 20.3726C16.6378 20.508 15.9333 19.934 14.5243 18.7859C13.5992 18.032 13.1366 17.6551 12.6055 17.5703C12.3966 17.5369 12.1837 17.5369 11.9748 17.5703C11.4437 17.6551 10.9811 18.032 10.0559 18.7859C8.64698 19.934 7.9425 20.508 7.4463 20.3726C7.26088 20.322 7.09397 20.2191 6.96555 20.0761C6.62187 19.6934 6.8187 18.8063 7.21238 17.0319L7.27905 16.7314C7.49326 15.7659 7.60037 15.2832 7.48831 14.8285C7.46741 14.7437 7.44097 14.6604 7.40917 14.579C7.2387 14.1429 6.87295 13.8101 6.14144 13.1446L5.65916 12.7059C4.21411 11.3912 3.49159 10.7338 3.69977 10.1148C3.90795 9.49585 4.88086 9.40872 6.82667 9.23446L7.04161 9.21521C8.23052 9.10873 8.82498 9.05549 9.27783 8.7188C9.73068 8.38212 9.95288 7.82818 10.3973 6.72031L10.4339 6.629Z"
                  fill="#F8CB00"
                />
              </svg>
            </div>
            <div className="flex flex-col w-full gap-2 Progress_bar">
              <div className="flex justify-between w-full">
                <p className="text-sm font-normal no_of_reviews">
                  {five_score} {t("reviews")}
                </p>
                <p className="reviews_%">
                  {calculatePercentage(five_score, ratingItem)}%
                </p>
              </div>
              <Progressbar
                progressValue={calculatePercentage(five_score, ratingItem)}
              />
            </div>
          </div>
          <div className="4-star flex gap-[12px] w-full items-center">
            <div className="flex items-center">
              <span className="font-medium text-lg text-[#1E293B]"> 4</span>{" "}
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M10.4339 6.629C11.2205 4.668 11.6138 3.6875 12.2901 3.6875C12.9665 3.6875 13.3598 4.668 14.1464 6.629L14.183 6.72031C14.6274 7.82818 14.8496 8.38212 15.3024 8.7188C15.7553 9.05549 16.3497 9.10873 17.5387 9.21521L17.7536 9.23446C19.6994 9.40872 20.6723 9.49585 20.8805 10.1148C21.0887 10.7338 20.3661 11.3912 18.9211 12.7058L18.4388 13.1446C17.7073 13.8101 17.3416 14.1429 17.1711 14.579C17.1393 14.6604 17.1129 14.7437 17.092 14.8285C16.9799 15.2832 17.087 15.7659 17.3012 16.7314L17.3679 17.0319C17.7616 18.8063 17.9584 19.6934 17.6147 20.0761C17.4863 20.2191 17.3194 20.322 17.134 20.3726C16.6378 20.508 15.9333 19.934 14.5243 18.7859C13.5992 18.032 13.1366 17.6551 12.6055 17.5703C12.3966 17.5369 12.1837 17.5369 11.9748 17.5703C11.4437 17.6551 10.9811 18.032 10.0559 18.7859C8.64698 19.934 7.9425 20.508 7.4463 20.3726C7.26088 20.322 7.09397 20.2191 6.96555 20.0761C6.62187 19.6934 6.8187 18.8063 7.21238 17.0319L7.27905 16.7314C7.49326 15.7659 7.60037 15.2832 7.48831 14.8285C7.46741 14.7437 7.44097 14.6604 7.40917 14.579C7.2387 14.1429 6.87295 13.8101 6.14144 13.1446L5.65916 12.7059C4.21411 11.3912 3.49159 10.7338 3.69977 10.1148C3.90795 9.49585 4.88086 9.40872 6.82667 9.23446L7.04161 9.21521C8.23052 9.10873 8.82498 9.05549 9.27783 8.7188C9.73068 8.38212 9.95288 7.82818 10.3973 6.72031L10.4339 6.629Z"
                  fill="#F8CB00"
                />
              </svg>
            </div>
            <div className="flex flex-col w-full gap-2 Progress_bar">
              <div className="flex justify-between w-full">
                <p className="text-sm font-normal no_of_reviews">
                  {four_score} {t("reviews")}
                </p>
                <p className="reviews_%">
                  {calculatePercentage(four_score, ratingItem)}%
                </p>
              </div>
              <Progressbar
                progressValue={calculatePercentage(four_score, ratingItem)}
              />
            </div>
          </div>
          <div className="3-star flex gap-[12px] w-full items-center">
            <div className="flex items-center">
              <span className="font-medium text-lg text-[#1E293B]"> 3</span>{" "}
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M10.4339 6.629C11.2205 4.668 11.6138 3.6875 12.2901 3.6875C12.9665 3.6875 13.3598 4.668 14.1464 6.629L14.183 6.72031C14.6274 7.82818 14.8496 8.38212 15.3024 8.7188C15.7553 9.05549 16.3497 9.10873 17.5387 9.21521L17.7536 9.23446C19.6994 9.40872 20.6723 9.49585 20.8805 10.1148C21.0887 10.7338 20.3661 11.3912 18.9211 12.7058L18.4388 13.1446C17.7073 13.8101 17.3416 14.1429 17.1711 14.579C17.1393 14.6604 17.1129 14.7437 17.092 14.8285C16.9799 15.2832 17.087 15.7659 17.3012 16.7314L17.3679 17.0319C17.7616 18.8063 17.9584 19.6934 17.6147 20.0761C17.4863 20.2191 17.3194 20.322 17.134 20.3726C16.6378 20.508 15.9333 19.934 14.5243 18.7859C13.5992 18.032 13.1366 17.6551 12.6055 17.5703C12.3966 17.5369 12.1837 17.5369 11.9748 17.5703C11.4437 17.6551 10.9811 18.032 10.0559 18.7859C8.64698 19.934 7.9425 20.508 7.4463 20.3726C7.26088 20.322 7.09397 20.2191 6.96555 20.0761C6.62187 19.6934 6.8187 18.8063 7.21238 17.0319L7.27905 16.7314C7.49326 15.7659 7.60037 15.2832 7.48831 14.8285C7.46741 14.7437 7.44097 14.6604 7.40917 14.579C7.2387 14.1429 6.87295 13.8101 6.14144 13.1446L5.65916 12.7059C4.21411 11.3912 3.49159 10.7338 3.69977 10.1148C3.90795 9.49585 4.88086 9.40872 6.82667 9.23446L7.04161 9.21521C8.23052 9.10873 8.82498 9.05549 9.27783 8.7188C9.73068 8.38212 9.95288 7.82818 10.3973 6.72031L10.4339 6.629Z"
                  fill="#F8CB00"
                />
              </svg>
            </div>
            <div className="flex flex-col w-full gap-2 Progress_bar">
              <div className="flex justify-between w-full">
                <p className="text-sm font-normal no_of_reviews">
                  {three_score} {t("reviews")}
                </p>
                <p className="reviews_%">
                  {" "}
                  {calculatePercentage(three_score, ratingItem)}%
                </p>
              </div>
              <Progressbar
                progressValue={calculatePercentage(three_score, ratingItem)}
              />
            </div>
          </div>
          <div className="2-star flex gap-[12px] w-full items-center">
            <div className="flex items-center">
              <span className="font-medium text-lg text-[#1E293B]"> 2</span>{" "}
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M10.4339 6.629C11.2205 4.668 11.6138 3.6875 12.2901 3.6875C12.9665 3.6875 13.3598 4.668 14.1464 6.629L14.183 6.72031C14.6274 7.82818 14.8496 8.38212 15.3024 8.7188C15.7553 9.05549 16.3497 9.10873 17.5387 9.21521L17.7536 9.23446C19.6994 9.40872 20.6723 9.49585 20.8805 10.1148C21.0887 10.7338 20.3661 11.3912 18.9211 12.7058L18.4388 13.1446C17.7073 13.8101 17.3416 14.1429 17.1711 14.579C17.1393 14.6604 17.1129 14.7437 17.092 14.8285C16.9799 15.2832 17.087 15.7659 17.3012 16.7314L17.3679 17.0319C17.7616 18.8063 17.9584 19.6934 17.6147 20.0761C17.4863 20.2191 17.3194 20.322 17.134 20.3726C16.6378 20.508 15.9333 19.934 14.5243 18.7859C13.5992 18.032 13.1366 17.6551 12.6055 17.5703C12.3966 17.5369 12.1837 17.5369 11.9748 17.5703C11.4437 17.6551 10.9811 18.032 10.0559 18.7859C8.64698 19.934 7.9425 20.508 7.4463 20.3726C7.26088 20.322 7.09397 20.2191 6.96555 20.0761C6.62187 19.6934 6.8187 18.8063 7.21238 17.0319L7.27905 16.7314C7.49326 15.7659 7.60037 15.2832 7.48831 14.8285C7.46741 14.7437 7.44097 14.6604 7.40917 14.579C7.2387 14.1429 6.87295 13.8101 6.14144 13.1446L5.65916 12.7059C4.21411 11.3912 3.49159 10.7338 3.69977 10.1148C3.90795 9.49585 4.88086 9.40872 6.82667 9.23446L7.04161 9.21521C8.23052 9.10873 8.82498 9.05549 9.27783 8.7188C9.73068 8.38212 9.95288 7.82818 10.3973 6.72031L10.4339 6.629Z"
                  fill="#F8CB00"
                />
              </svg>
            </div>
            <div className="flex flex-col w-full gap-2 Progress_bar">
              <div className="flex justify-between w-full">
                <p className="text-sm font-normal no_of_reviews">
                  {two_score} {t("reviews")}
                </p>
                <p className="reviews_%">
                  {" "}
                  {calculatePercentage(two_score, ratingItem)}%
                </p>
              </div>
              <Progressbar
                progressValue={calculatePercentage(two_score, ratingItem)}
              />
            </div>
          </div>
          <div className="1-star flex gap-[12px] w-full items-center">
            <div className="flex items-center">
              <span className="font-medium text-lg text-[#1E293B]"> 1</span>{" "}
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M10.4339 6.629C11.2205 4.668 11.6138 3.6875 12.2901 3.6875C12.9665 3.6875 13.3598 4.668 14.1464 6.629L14.183 6.72031C14.6274 7.82818 14.8496 8.38212 15.3024 8.7188C15.7553 9.05549 16.3497 9.10873 17.5387 9.21521L17.7536 9.23446C19.6994 9.40872 20.6723 9.49585 20.8805 10.1148C21.0887 10.7338 20.3661 11.3912 18.9211 12.7058L18.4388 13.1446C17.7073 13.8101 17.3416 14.1429 17.1711 14.579C17.1393 14.6604 17.1129 14.7437 17.092 14.8285C16.9799 15.2832 17.087 15.7659 17.3012 16.7314L17.3679 17.0319C17.7616 18.8063 17.9584 19.6934 17.6147 20.0761C17.4863 20.2191 17.3194 20.322 17.134 20.3726C16.6378 20.508 15.9333 19.934 14.5243 18.7859C13.5992 18.032 13.1366 17.6551 12.6055 17.5703C12.3966 17.5369 12.1837 17.5369 11.9748 17.5703C11.4437 17.6551 10.9811 18.032 10.0559 18.7859C8.64698 19.934 7.9425 20.508 7.4463 20.3726C7.26088 20.322 7.09397 20.2191 6.96555 20.0761C6.62187 19.6934 6.8187 18.8063 7.21238 17.0319L7.27905 16.7314C7.49326 15.7659 7.60037 15.2832 7.48831 14.8285C7.46741 14.7437 7.44097 14.6604 7.40917 14.579C7.2387 14.1429 6.87295 13.8101 6.14144 13.1446L5.65916 12.7059C4.21411 11.3912 3.49159 10.7338 3.69977 10.1148C3.90795 9.49585 4.88086 9.40872 6.82667 9.23446L7.04161 9.21521C8.23052 9.10873 8.82498 9.05549 9.27783 8.7188C9.73068 8.38212 9.95288 7.82818 10.3973 6.72031L10.4339 6.629Z"
                  fill="#F8CB00"
                />
              </svg>
            </div>
            <div className="flex flex-col w-full gap-2 Progress_bar">
              <div className="flex justify-between w-full">
                <p className="text-sm font-normal no_of_reviews">
                  {one_score} {t("review")}
                </p>
                <p className="reviews_%">
                  {" "}
                  {calculatePercentage(one_score, ratingItem)}%
                </p>
              </div>
              <Progressbar
                progressValue={calculatePercentage(one_score, ratingItem)}
              />
            </div>
          </div>
        </div>
      </div>
      {!removeTitle && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="725"
          height="2"
          viewBox="0 0 725 2"
          fill="none"
        >
          <path
            d="M1.29028 1H724"
            stroke="#E9EAEB"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )}
    </>
  );
};

export { OverallRatingItem, getRatingCounts, getOverAllRating };
