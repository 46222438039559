import { useState, useEffect } from "react";
import Footer from "../ui/footer/Footer";
import LoginNavBar from "../ui/login-nav_bar/LoginNavBar";
import { Spinner } from "../ui/Spinner";
import { Link } from "react-router-dom";
import { Star } from "lucide-react";
import {
  getFavourites,
  addRemoveFavourites,
} from "../../data_featching/favorites";
import { useTranslation } from "react-i18next";

const Favorites = () => {
  const [favoritesList, setFavoritesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("authToken");
  const { t } = useTranslation("explore");
  const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);

  const getFavoritesList = async () => {
    const res = await getFavourites(token);
    try {
      if (res.status === 200 || res.status === 201) {
        setFavoritesList(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getFavoritesList();
  }, []);
  console.log("favoritesList", favoritesList);
  if (isLoading) return <Spinner />;

  const handleFavourites = async (spaceId) => {
    const response = await addRemoveFavourites(token, spaceId);
    if (response?.status == 200 || response?.status == 201) {
      console.log("Favourites:", response.data);
      getFavoritesList();
    }
  };

  return (
    <>
      <LoginNavBar
        showBurgerLoginMenu={showBurgerLoginMenu}
        setShowBurgerLoginMenu={setShowBurgerLoginMenu}
      />
      {!showBurgerLoginMenu && (
        <>
          <div className="bg-[#f9fafb]">
            <div className="xl:px-[112px] lg:px-[70px] md:px[48px] sm:px-[16px] py-10">
              <h1 className="text-2xl font-semibold">
                {t("Favorites")}
              </h1>
              <div className="flex flex-col">
                {favoritesList &&
                  favoritesList?.map((fav) => {
                    return (
                      <div key={fav.space.id}>
                         <div
                          key={fav.space.id}
                          className="flex items-start my-4 bg-white shadow-sm feature_workSpace_card rounded-2xl"
                        >
                          <div className="Workspace_img relative  flex items-center 2xl:w-[26.25rem] lg:w-[25rem] sm:w-full bg-white  sm:rounded-t-2xl lg:ltr:rounded-l-2xl lg:rtl:rounded-r-2xl lg:rounded-t-none">
                            <img
                              src={`${
                                fav.space?.media?.length > 0
                                  ? fav.space?.media[0]?.url
                                  : "/workspace-img.png"
                              }`}
                              alt=""
                              className="object-cover w-full h-full sm:rounded-t-2xl lg:ltr:rounded-l-2xl lg:rtl:rounded-r-2xl lg:rounded-t-none"
                            />
                            {fav.space.rating > 0 && (
                              <div className="absolute flex items-center px-2 py-1 text-white bg-black bg-opacity-50 rounded-lg top-2 rtl:right-2 ltr:left-2">
                                <span className="mr-1 font-bold text-md">
                                  {fav.space.rating}
                                </span>
                                <Star className="w-4 h-4 text-yellow-400 fill-current" />
                              </div>
                            )}
                          </div>
                          <div className="relative flex flex-col justify-start w-full gap-4 p-4 WorkSpace_card_details">
                            <div className="flex flex-col gap-1 WorkSpace_title_address">
                              <div className="flex items-center gap-2 workSpace_title">
                                <span className="text-[#111827] text-xl font-bold">
                                  {fav.space.name}
                                </span>
                              </div>
                              <div className="flex items-center space-x-2">
                                {fav.space.rating > 0 && (
                                  <>
                                    <div className="flex items-center my-2">
                                      {Array.from({ length: fav.space.rating }).map(
                                        (_, index) => (
                                          <Star
                                            key={index}
                                            size={24}
                                            className="text-yellow-400"
                                            fill="currentColor"
                                          />
                                        )
                                      )}
                                    </div>

                                    <span className="font-bold text-gray-800 rtl:pr-2">
                                      ({fav.space?.rating})
                                    </span>
                                    {fav.space.ratings_count && (
                                      <span className="text-gray-500">
                                        {t("based on")} {fav.space.ratings_count}{" "}
                                        {t("reviews")}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="workspac_adderes text-[#6b7280] text-sm">
                                <span>
                                  {fav.space?.workspace?.address?.building_number ||
                                    fav.space?.workspace?.address?.street_name}
                                  ,{fav.space.workspace.address.district.name}
                                </span>
                              </div>
                            </div>
                            <div className="hidden sm:grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2 h-[135px] overflow-hidden">
                              {fav.space?.amenities?.map((amenity, index) => (
                                <div
                                  key={amenity.id}
                                  className="flex items-center gap-2"
                                  style={{
                                    gridColumn: `${Math.floor(index / 4) + 1}`,
                                    gridRow: `${(index % 4) + 1}`,
                                  }}
                                >
                                  <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{
                                      __html: amenity.icon,
                                    }}
                                  />
                                  <span className="text-sm truncate">
                                    {amenity.name}
                                  </span>
                                </div>
                              ))}
                            </div>

                            <div className="flex flex-row items-center justify-between workspace-cost-action">
                              <div className="flex items-center gap-1 cost-details">
                                <span className="text-[#252c34] text-4xl font-bold">
                                  {`${
                                    fav?.space?.plans[0]?.shifts[0]?.price
                                      ? Number(fav?.space?.plans[0]?.shifts[0]?.price).toFixed(
                                          0
                                        )
                                      : ""
                                  }`}
                                </span>
                                <span className="cost-currency text-[#252c34] text-base">
                                  {fav?.space?.plans[0]?.shifts[0]?.currency?.label}
                                </span>

                                <span className="cost-per text-[#6b7280] text-sm">
                                  {"/"}
                                  {fav?.space?.plans[0]?.name?.label}
                                  <span className="ml-2 text-gray-500">
                                    {fav?.space?.plans[0]?.shifts[0]?.name?.key !== "all" &&
                                      '"' +
                                      fav?.space?.plans[0]?.shifts[0]?.name?.label +
                                        '"'}
                                  </span>
                                </span>
                              </div>
                              <Link
                                to={`/explore/space/${fav.space.id}`}
                                className="space-action flex items-center justify-center whitespace-nowrap text-[#000021] px-5  py-2 rounded-lg bg-white border border-[#009e1e] cursor-pointer shadow-sm"
                              >
                                <span className=" text-sm font-medium leading-5 text-[#000021] pe-3">
                                  {t("get pass")}
                                </span>
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M10.8571 0C8.01682 0 5.71429 2.30254 5.71429 5.14286C5.71429 5.44284 5.74003 5.73726 5.78956 6.02392C5.84075 6.32021 5.76517 6.56957 5.62108 6.71365L0.66947 11.6653C0.240816 12.0939 0 12.6753 0 13.2815V15.4286C0 15.7442 0.255837 16 0.571429 16H3.42857C3.74416 16 4 15.7442 4 15.4286V14.2857H5.14286C5.45845 14.2857 5.71429 14.0299 5.71429 13.7143V12.5714H6.85714C7.0087 12.5714 7.15404 12.5112 7.2612 12.4041L9.28635 10.3789C9.43043 10.2348 9.67979 10.1593 9.97608 10.2104C10.2627 10.26 10.5572 10.2857 10.8571 10.2857C13.6975 10.2857 16 7.98318 16 5.14286C16 2.30254 13.6975 0 10.8571 0ZM10.8571 2.28571C10.5416 2.28571 10.2857 2.54155 10.2857 2.85714C10.2857 3.17273 10.5416 3.42857 10.8571 3.42857C11.8039 3.42857 12.5714 4.19608 12.5714 5.14286C12.5714 5.45845 12.8273 5.71429 13.1429 5.71429C13.4584 5.71429 13.7143 5.45845 13.7143 5.14286C13.7143 3.5649 12.4351 2.28571 10.8571 2.28571Z"
                                    fill="#000021"
                                  />
                                </svg>
                              </Link>
                            </div>
                          </div>
                        </div>
                        {/* <div
                          key={fav.id}
                          className="flex items-start my-4 bg-white shadow-sm feature_workSpace_card rounded-2xl "
                        >
                          <div className="Workspace_img relative  flex items-center 2xl:w-[350px] lg:w-[300px] sm:w-full bg-white !sm:h-[250px] !md:h-[300px] !lg-[260px]  rounded-l-2xl shadow-sm">
                            <img
                              src={`${
                                fav?.space?.media?.length > 0
                                  ? fav?.space?.media[0]?.url
                                  : "/workspace-img.png"
                              }`}
                              alt=""
                              className="w-full h-full sm:rounded-t-2xl lg:rounded-l-2xl"
                            />
                          </div>
                          <div className="relative flex flex-col justify-start w-full gap-4 p-4 WorkSpace_card_details">
                            <div className="absolute w-6 h-6 rtl:left-4 ltr:right-8 rtl:top-4 ltr:top-4">
                              <button
                                className={`  text-center align-middle rounded-full shadow-md p-2 w-[40px] h-[40px] bg-white`}
                                onClick={() => handleFavourites(fav.space.id)}
                              >
                                <svg
                                  width="22"
                                  height="19"
                                  viewBox="0 0 22 19"
                                  fill="#009E1E"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.40325 11.0361L10.4846 17.6883C10.662 17.855 10.7507 17.9383 10.8472 17.9806C11.0035 18.049 11.1813 18.049 11.3376 17.9806C11.434 17.9383 11.5227 17.855 11.7001 17.6883L18.7815 11.0361C20.7739 9.16449 21.0159 6.0845 19.3401 3.9247L19.0251 3.51859C17.0204 0.934846 12.9966 1.36816 11.5881 4.31946C11.3891 4.73634 10.7957 4.73634 10.5967 4.31946C9.18812 1.36816 5.16432 0.934844 3.15969 3.51858L2.8446 3.92469C1.1689 6.0845 1.41085 9.16449 3.40325 11.0361Z"
                                    stroke="#009E1E"
                                    strokeWidth="1.83333"
                                  />
                                </svg>
                              </button>
                            </div>

                            <div className="flex flex-col gap-1 WorkSpace_title_address">
                              <div className="flex items-center gap-2 workSpace_title">
                                <span className="text-[#111827] text-xl font-bold">
                                  {fav.space.name}
                                </span>
                              </div>
                              <div className="workspac_adderes text-[#6b7280] text-sm">
                                <span>
                                  {fav.space.workspace.address.district.id},
                                  {fav.space.workspace.address.district.name}
                                </span>
                              </div>
                            </div>
                            <div className="flex-wrap hidden gap-4 py-2 Available_Workspace_facilities md:flex">
                              <div className="flex flex-col gap-4 facillity_column_group">
                                {fav?.space?.amenities.map((amenity) => {
                                  return (
                                    <div
                                      key={crypto.randomUUID()}
                                      className="flex items-start gap-1 facility_title"
                                    >
                                      <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{
                                          __html: amenity.icon,
                                        }}
                                      />
                                      <span className="text-[#111827] text-base">
                                        {amenity.name}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="flex flex-row items-center justify-between workspace-cost-action">
                              <div className="flex items-center gap-1 cost-details">
                                <span className="text-[#252c34] text-4xl font-bold">
                                  {fav?.space?.plans[0]?.shifts[0]?.price}
                                </span>
                                <span className="cost-currency text-[#252c34] text-base">
                                  {
                                    fav?.space?.plans[0]?.shifts[0]?.currency
                                      .label
                                  }
                                </span>
                                <span className="cost-per text-[#6b7280] text-sm">
                                  / {fav?.space?.plans[0]?.name?.label}
                                </span>
                              </div>
                              <Link
                                to={`/explore/space/${fav.space.id}`}
                                className="space-action flex items-center justify-center whitespace-nowrap text-[#000021] px-5  py-2 rounded-lg bg-white border border-[#009e1e] cursor-pointer shadow-sm"
                              >
                                <span className=" text-sm font-medium leading-5 text-[#000021] pe-3">
                                  {t("get pass")}
                                </span>
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M10.8571 0C8.01682 0 5.71429 2.30254 5.71429 5.14286C5.71429 5.44284 5.74003 5.73726 5.78956 6.02392C5.84075 6.32021 5.76517 6.56957 5.62108 6.71365L0.66947 11.6653C0.240816 12.0939 0 12.6753 0 13.2815V15.4286C0 15.7442 0.255837 16 0.571429 16H3.42857C3.74416 16 4 15.7442 4 15.4286V14.2857H5.14286C5.45845 14.2857 5.71429 14.0299 5.71429 13.7143V12.5714H6.85714C7.0087 12.5714 7.15404 12.5112 7.2612 12.4041L9.28635 10.3789C9.43043 10.2348 9.67979 10.1593 9.97608 10.2104C10.2627 10.26 10.5572 10.2857 10.8571 10.2857C13.6975 10.2857 16 7.98318 16 5.14286C16 2.30254 13.6975 0 10.8571 0ZM10.8571 2.28571C10.5416 2.28571 10.2857 2.54155 10.2857 2.85714C10.2857 3.17273 10.5416 3.42857 10.8571 3.42857C11.8039 3.42857 12.5714 4.19608 12.5714 5.14286C12.5714 5.45845 12.8273 5.71429 13.1429 5.71429C13.4584 5.71429 13.7143 5.45845 13.7143 5.14286C13.7143 3.5649 12.4351 2.28571 10.8571 2.28571Z"
                                    fill="#000021"
                                  />
                                </svg>
                              </Link>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    );
                  })}
                {favoritesList.length == 0 && (
                  <div className="flex justify-center items-center h-[400px]">
                    <p className="text-[#6b7280] text-lg">
                      {t("No Favorites Added")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-10 bg-black">
              <div className="GooglePlay">
                <div className="Hint">{t("get workery")}</div>
                <div className="GooglePlay_logo">
                  <img src="/Group.png" alt="google play img" />
                  <img src="/AppleStore.png" alt="Apple Store img" />
                </div>
              </div>
            </div>
          <Footer />
        </>
      )}
    </>
  );
};
export default Favorites;
