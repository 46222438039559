export function convertTo24HourTime(time12h) {
  // Extract the time and period (AM/PM) from the input string
  const [time, modifier] = time12h.split(" ");

  // Split the time into hours and minutes
  let [hours, minutes] = time.split(":");

  // Convert hours to an integer for manipulation
  hours = parseInt(hours, 10);

  // Adjust the hours based on the period (AM/PM)
  if (modifier.toLowerCase() === "pm" && hours !== 12) {
    hours += 12;
  } else if (modifier.toLowerCase() === "am" && hours === 12) {
    hours = 0; // Handle midnight case
  }

  // Ensure the hours and minutes are two digits
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  // Construct the final time string with seconds
  return `${formattedHours}:${formattedMinutes}:00`;
}
// Helper function to convert time to hours in 12-hour format
export const convertTo12HourFormat = (hour) => {
  const suffix = hour >= 12 ? "pm" : "am";
  const adjustedHour = hour % 12 || 12; // Convert 24h to 12h format
  return `${adjustedHour}:00 ${suffix}`;
};
