import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import NoInternetConnection from './NoInternetConnection';

const WithInternetConnection = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [previousPath, setPreviousPath] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Store the current path if we're online and not on the no-connection page
    if (isOnline && location.pathname !== '/no-connection') {
      setPreviousPath(location.pathname);
    }
  }, [location.pathname, isOnline]);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      // If we have a previous path, navigate back to it
      if (previousPath) {
        window.location.href = previousPath;
      }
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    // Add event listeners
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [previousPath]);

  // If offline and not already on the no-connection page, show the NoInternetConnection component
  if (!isOnline) {
    return <NoInternetConnection previousPath={previousPath} />;
  }

  // If online, render the children
  return children;
};

export default WithInternetConnection;