import { useTranslation } from "react-i18next";
const EmptyRatings = () => {
  const { t } = useTranslation("workspace");
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <img src="/image 9.png" alt="" className="w-[150px] h-[150px]" />
      <h1 className="text-[#6B7280] font-semibold text-xl">
        {t("no_ratings")}
      </h1>
    </div>
  );
};

export default EmptyRatings;
