import { useState } from "react";
import { ImageUpIcon, Pen, Plus } from "lucide-react";
import styles from "./CompanyForm.module.css";
import PhoneNumber from "../../ui/PhoneNumber";
import { useTranslation } from "react-i18next";
import { getBusinessSegments } from "../../../data_featching/community";
import { useEffect } from "react";

// Validation constants
const VALIDATION_RULES = {
  NAME_MAX_LENGTH: 100,
  ABOUT_MAX_LENGTH: 1000,
  TAX_NUMBER_PATTERN: /^[0-9]{15}$/,// 15 numbers
  EMAIL_PATTERN: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  WEBSITE_PATTERN: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
  COMMERCIAL_RECORD_PATTERN: /^[0-9]{10}$/, // Assuming 10-digit CR number
  ADDRESS_MAX_LENGTH: 250,
};

// Validation helper functions
const validateInput = (value, type) => {
  // Return true if field is empty
  if (!value || value.trim() === '') return true;
  
  switch (type) {
    case 'name':
      return value.length <= VALIDATION_RULES.NAME_MAX_LENGTH;
    case 'about':
      return value.length <= VALIDATION_RULES.ABOUT_MAX_LENGTH;
    case 'taxNumber':
      return VALIDATION_RULES.TAX_NUMBER_PATTERN.test(value);
    case 'email':
      return VALIDATION_RULES.EMAIL_PATTERN.test(value);
    case 'website':
      return VALIDATION_RULES.WEBSITE_PATTERN.test(value);
    case 'commercialRecord':
      return VALIDATION_RULES.COMMERCIAL_RECORD_PATTERN.test(value);
      case 'address':
        return value.length <= VALIDATION_RULES.ADDRESS_MAX_LENGTH;
    default:
      return true;
  }
};

const CompanyInfoPart1 = ({
  FormData,
  setFormData,
  intialCover,
  intialLogo,
  setIsValid,
}) => {
  const [coverImage, setCoverImage] = useState(intialCover);
  const [logo, setLogo] = useState(intialLogo);
  const [errors, setErrors] = useState({
    nameEn: "",
    nameAr: "",
    detailsEn: "",
    detailsAr: "",
  });
  const { t } = useTranslation("entities");

  const handleNameChange = (lang, value) => {
    if (!validateInput(value, "name")) {
      setErrors((prev) => ({
        ...prev,
        [`name${lang}`]: ` ${t("Name must be")} ${
          VALIDATION_RULES.NAME_MAX_LENGTH
        } ${t("characters or less")}`,
      }));
    } else {
      setErrors((prev) => ({ ...prev, [`name${lang}`]: "" }));}
   

    setFormData({
      ...FormData,
      name: {
        ...FormData.name,
        [lang.toLowerCase()]: value,
      },
    });
  
  };
  // if there is an error in the form, set isValid to false
  useEffect(() => {
    if (
      errors.nameEn ||
      errors.nameAr ||
      errors.detailsEn ||
      errors.detailsAr
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [errors, setIsValid]);


  const handleDetailsChange = (lang, value) => {
    if (!validateInput(value, "about")) {
      setErrors((prev) => ({
        ...prev,
        [`details${lang}`]: `${t("About must be")} ${
          VALIDATION_RULES.ABOUT_MAX_LENGTH
        } ${t("characters or less")}`,
      }));
    } else {
      setErrors((prev) => ({ ...prev, [`details${lang}`]: "" }));}
      setFormData({
        ...FormData,
        details: {
          ...FormData.details,
          [lang.toLowerCase()]: value,
        },
      });
    

    
  };

  // Image handlers remain the same...
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setCoverImage(URL.createObjectURL(e.target.files[0]));
      setFormData({ ...FormData, cover: e.target.files[0] });
    }
  };

  const handleLogoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setLogo(URL.createObjectURL(e.target.files[0]));
      setFormData({ ...FormData, logo: e.target.files[0] });
    }
  };

  return (
    <div className={styles.formSection}>
      <span>{t("Cover image")}</span>
      <div className={styles.coverImage}>
        <label htmlFor="coverImageInput" className="w-full h-full">
          {coverImage ? (
            <img src={coverImage} alt="Cover" className={styles.imagePreview} />
          ) : (
            <div className="flex flex-col items-center justify-center h-full cursor-pointer">
              <ImageUpIcon size={48} />
            </div>
          )}
        </label>
        <input
          type="file"
          id="coverImageInput"
          accept=".jpg,.jpeg,.png,.svg"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
      </div>
      <span>{t("Logo")}</span>
      <div className={styles.logoUpload}>
        <label htmlFor="logoInput" className={styles.logoContainer}>
          {logo ? (
            <div className={styles.logoWrapper}>
              <img src={logo} alt="Logo" className={styles.logoPreviewImage} />
              <div className={styles.addIcon}>
                <Pen size={18} />
              </div>
            </div>
          ) : (
            <div className={styles.logoPreview}>
              <div className={styles.addIcon}>
                <Plus size={24} />
              </div>
            </div>
          )}

          <input
            type="file"
            id="logoInput"
            accept=".jpg,.jpeg,.png,.svg"
            style={{ display: "none" }}
            onChange={handleLogoChange}
          />
        </label>
      </div>
      <div className={styles.inputGroup}>
        <label htmlFor="companyNameEN" className={styles.inputGroupLabel}>
          {t("Company Name (EN)")}
          <input
            type="text"
            value={FormData.name.en}
            placeholder={t("Enter company name here in English")}
            className={`${styles.input} ${
              errors.nameEn ? styles.errorInput : ""
            }`}
            onChange={(e) => handleNameChange("En", e.target.value)}
          />
        </label>
        <label htmlFor="companyNameAR" className={styles.inputGroupLabel}>
          {t("Company Name (AR)")}
          <input
            type="text"
            value={FormData.name.ar}
            placeholder={t("أدخل اسم الشركة هنا باللغة العربية")}
            className={`${styles.input} ${
              errors.nameAr ? styles.errorInput : ""
            }`}
            onChange={(e) => handleNameChange("Ar", e.target.value)}
          />
        </label>
      </div>
      <div className={styles.inputGroup}>
        {(errors.nameEn || errors.nameAr) && (
          <span className={styles.errorText}>{errors.nameEn}</span>
        )}
      </div>

      <label>
        {t("About Company (EN)")}
        <textarea
          value={FormData.details.en}
          placeholder={t("Enter company about here in English language")}
          className={`${styles.textarea} ${
            errors.detailsEn ? styles.errorInput : ""
          }`}
          onChange={(e) => handleDetailsChange("En", e.target.value)}
        ></textarea>
        {errors.detailsEn && (
          <span className={styles.errorText}>{errors.detailsEn}</span>
        )}
      </label>

      <label>
        {t("About Company (AR)")}
        <textarea
          value={FormData.details.ar}
          placeholder={t("أدخل معلومات عن الشركة هنا باللغة العربية")}
          className={`${styles.textarea} ${
            errors.detailsAr ? styles.errorInput : ""
          }`}
          onChange={(e) => handleDetailsChange("Ar", e.target.value)}
        ></textarea>
        {errors.detailsAr && (
          <span className={styles.errorText}>{errors.detailsAr}</span>
        )}
      </label>
    </div>
  );
};

const CompanyInfoPart2 = ({ FormData, setFormData ,
   setIsValid
}) => {
  const { t } = useTranslation("entities");
  const [phone, setPhone] = useState(FormData.phone);

  const [errors, setErrors] = useState({
    email: "",
    website: "",
  });

  const handleEmailChange = (value) => {
    if (!validateInput(value, "email")) {
      setErrors((prev) => ({
        ...prev,
        email: t("Please enter a valid email address"),
      }));
    } else {
      setErrors((prev) => ({ ...prev, email: "" }));}
      setFormData({ ...FormData, email: value });
    
   
  };

  const handleWebsiteChange = (value) => {
    if (!validateInput(value, "website")) {
      setErrors((prev) => ({
        ...prev,
        website: t("Please enter a valid website URL"),
      }));
    } else {
      setErrors((prev) => ({ ...prev, website: "" }));}
      setFormData({ ...FormData, url: value });
    
  
  };
  // if there is an error in the form, set isValid to false
  useEffect(() => {
    if (errors.email || errors.website) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [errors, setIsValid]);

  return (
    <div className={styles.formSection}>
      <div className={styles.inputGroup}>
        <label htmlFor="email" className={styles.inputGroupLabel}>
          {t("Email")}
          <input
            type="email"
            value={FormData.email}
            placeholder={t("Email")}
            className={`${styles.input} ${
              errors.email ? styles.errorInput : ""
            }`}
            onChange={(e) => handleEmailChange(e.target.value)}
          />
        </label>
        <label htmlFor="phone" className={styles.inputGroupLabel}>
          {t("Phone Number")}
          <PhoneNumber
            value={phone}
            onChange={(e) => {
              setFormData({ ...FormData, phone: e });
              setPhone(e);
            }}
          />
        </label>
      </div>
      {errors.email && <span className={styles.errorText}>{errors.email}</span>}
      <label htmlFor="website" className={styles.inputGroupLabel}>
        {t("Website")}
        <input
          type="url"
          value={FormData.url}
          placeholder="https://example.com"
          className={`${styles.input} ${
            errors.website ? styles.errorInput : ""
          }`}
          onChange={(e) => handleWebsiteChange(e.target.value)}
        />
        {errors.website && (
          <span className={styles.errorText}>{errors.website}</span>
        )}
      </label>
    </div>
  );
};

const CompanyInfoPart3 = ({ FormData, setFormData,setIsValid }) => {
  const { t, i18n } = useTranslation("entities");
  const [businessSegments, setBusinessSegments] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [errors, setErrors] = useState({
    taxNumber: "",
    commercialRecord: "",
  });
  const handleSegmentChange = (segment) => {
    const updatedSegments = selectedSegments.includes(segment)
      ? selectedSegments.filter((s) => s.id !== segment.id)
      : [...selectedSegments, segment];

    setSelectedSegments(updatedSegments);
    setFormData({
      ...FormData,
      business_segment_ids: updatedSegments.map((s) => s.id),
    });
  };
  useEffect(() => {
    getBusinessSegments(i18n.language).then((data) => {
      setBusinessSegments(data.data);
    });
  }, [i18n.language]);

  useEffect(() => {
    if (FormData.business_segment_ids) {
      const selected = businessSegments.filter((segment) =>
        FormData.business_segment_ids.includes(segment.id)
      );
      setSelectedSegments(selected);
    } else if (FormData.business_segments) {
      setSelectedSegments(FormData.business_segments);
    }
  }, [FormData.business_segment_ids, businessSegments]);

  const handleTaxNumberChange = (value) => {
    if (!validateInput(value, "taxNumber")) {
      setErrors((prev) => ({
        ...prev,
        taxNumber: t("Tax number must be a 15-digit number"),
      }));
    } else {
      setErrors((prev) => ({ ...prev, taxNumber: "" }));}
      setFormData({ ...FormData, tax_number: value });

    
  };

  const handleCommercialRecordChange = (value) => {
    if (!validateInput(value, "commercialRecord")) {
      setErrors((prev) => ({
        ...prev,
        commercialRecord: t("Commercial Record must be a 10-digit number"),
      }));
    } else {
      setErrors((prev) => ({ ...prev, commercialRecord: "" }));}
      setFormData({ ...FormData, commercial_record: value });

    
  };
  const handleAddressChange = (value) => {
    if (!validateInput(value, "address")) {
      setErrors((prev) => ({
        ...prev,
        address: `${t("Address must be")} ${VALIDATION_RULES.ADDRESS_MAX_LENGTH} ${t("characters or less")}`,
      }));
    } else {
      setErrors((prev) => ({ ...prev, address: "" }));}
      setFormData({ ...FormData, address: value });
    
  };
  // if there is an error in the form, set isValid to false
  useEffect(() => {
    if (errors.taxNumber || errors.commercialRecord || errors.address) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [errors, setIsValid]);
  return (
    <div className={styles.formSection}>
      <label htmlFor="companyAddress" className={styles.inputGroupLabel}>
        {t("Company Address")}
        <input
          type="text"
          placeholder={t("Company Address")}
          value={FormData.address}
          className={styles.input}
          onChange={(e) => handleAddressChange(e.target.value)}
        />
      </label>
      {errors.address && (
        <span className={styles.errorText}>{errors.address}</span>
      )}
      <label htmlFor="businessSegment" className={styles.inputGroupLabel}>
        {t("Business Segment")}
        <div className={styles.selectedSegmentsContainer}>
          <div className={styles.selectedSegments}>
            {selectedSegments.map((segment) => (
              <span key={segment.id} className={styles.segmentTag}>
                {segment.name}
                <button
                  className={styles.removeSegment}
                  onClick={() => handleSegmentChange(segment)}
                >
                  ×
                </button>
              </span>
            ))}
          </div>
        </div>
        <div className={styles.segmentContainer}>
          {businessSegments.map((segment) => (
            <button
              key={segment.id}
              className={`${styles.segmentButton} ${
                selectedSegments.includes(segment) ? styles.selected : ""
              }`}
              onClick={() => handleSegmentChange(segment)}
            >
              {segment.name}
            </button>
          ))}
        </div>
      </label>

      <div className={styles.inputGroup}>
        <label htmlFor="commercialRecord" className={styles.inputGroupLabel}>
          {t("Commercial Record (CR)")}
          <input
            value={FormData.commercial_record}
            type="text"
            placeholder={t("Commercial record (CR)")}
            className={`${styles.input} ${
              errors.commercialRecord ? styles.errorInput : ""
            }`}
            onChange={(e) => handleCommercialRecordChange(e.target.value)}
          />
        </label>
        <label htmlFor="taxNumber" className={styles.inputGroupLabel}>
          {t("Tax Number")}
          <input
            type="text"
            value={FormData.tax_number}
            placeholder={t("Tax number")}
            className={`${styles.input} ${
              errors.taxNumber ? styles.errorInput : ""
            }`}
            onChange={(e) => handleTaxNumberChange(e.target.value)}
          />
        </label>
      </div>
      {errors.commercialRecord && (
        <span className={styles.errorText}>{errors.commercialRecord}</span>
      )}
      {errors.taxNumber && (
        <span className={styles.errorText}>{errors.taxNumber}</span>
      )}
    </div>
  );
};

export { CompanyInfoPart1, CompanyInfoPart2, CompanyInfoPart3 };