import { MapPin, Mail, Phone, LinkIcon } from "lucide-react";
import styles from "./Profile.module.css";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  getCompanyPublic,
  fetchAllCompanies,
} from "../../../data_featching/community";
import { useParams } from "react-router-dom";
import { Spinner } from "../../ui/Spinner";
import LoginNavBar from "../../ui/login-nav_bar/LoginNavBar";
import Footer from "../../ui/footer/Footer";
import Slider from "react-slick";

const Profile = () => {
  const { t, i18n } = useTranslation("entities");
  const { uuid } = useParams();
  const [Company, setCompany] = useState(null);
  const [otherCompanies, setOtherCompanies] = useState([]);
  const [NumberToShow, setNumberToShow] = useState(5);

  useEffect(() => {
    const fetchCompany = async () => {
      console.log("uuid", uuid);
      const company = await getCompanyPublic(uuid, i18n.language);
      console.log("company", company.data);
      setCompany(company.data);
    };
    const FetchOtherCompanies = async () => {
      const response = await fetchAllCompanies(i18n.language);
      if (response.status === 200 || response.status === 201) {
        setOtherCompanies(response.data.data);
      }
    };
    fetchCompany();
    FetchOtherCompanies();
  }, [i18n.language, uuid]);
 
  const settings = {
    lazyLoad: true,
    infinite: otherCompanies.length > 2,
    // speed: 500,
    slidesToShow:NumberToShow,
    slidesToScroll: 1,
    // initialSlide: 0,
  };
  // adjust the number of companies to show in the slider according to the screen size
  const updateNumberToShow = () => {
    if (window.innerWidth < 1110) {
      setNumberToShow(3);
    } else if (window.innerWidth < 800) {
      setNumberToShow(2);
    } else {
      setNumberToShow(5);
    }
  };

  useEffect(() => {
    // Set initial number of companies to show
    updateNumberToShow();

    // Add event listener for window resize
    window.addEventListener("resize", updateNumberToShow);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateNumberToShow);
    };
  }, []);

  return (
    <div className="">
      <LoginNavBar />
      {Company ? (
        <div className={styles.container}>
          <div className={styles.header}>
            <img
              src={Company?.cover?.url}
              alt={Company?.name}
              className={styles.banner}
            />
            <div className={styles.actions}>
              <button className={styles.addButton}>
                {t("Visit Website")}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.16667 0.491211L7.16667 5.39801C5.88619 5.25187 4.70695 4.77294 3.81145 4.04535C3.31395 3.64113 2.92626 3.1795 2.65257 2.68664C3.83009 1.48939 5.4066 0.685777 7.16667 0.491211ZM8.83333 0.491211V5.39801C10.1138 5.25187 11.2931 4.77294 12.1886 4.04535C12.6861 3.64113 13.0737 3.1795 13.3474 2.68664C12.1699 1.4894 10.5934 0.685777 8.83333 0.491211ZM14.4307 4.08363C14.1006 4.5421 13.7005 4.96437 13.2395 5.33887C12.0295 6.322 10.4731 6.92121 8.83333 7.0734L8.83333 8.81715C9.51214 8.88013 10.1798 9.02 10.817 9.23447C11.7136 9.53622 12.5379 9.98162 13.2395 10.5517C13.6975 10.9238 14.0985 11.3453 14.4309 11.8069C15.1096 10.6789 15.5 9.35778 15.5 7.94544C15.5 6.53295 15.1095 5.21167 14.4307 4.08363ZM13.3475 13.2042C13.0718 12.7081 12.6819 12.2461 12.1885 11.8452C11.6521 11.4093 11.0065 11.0567 10.2854 10.8141C9.8206 10.6576 9.33251 10.5496 8.83333 10.4926V15.3997C10.5934 15.2051 12.17 14.4014 13.3475 13.2042ZM7.16667 15.3997L7.16667 10.4926C6.66749 10.5496 6.1794 10.6576 5.71458 10.8141C4.99352 11.0567 4.34793 11.4093 3.81145 11.8452C3.31806 12.2461 2.92823 12.7081 2.65251 13.2042C3.83003 14.4014 5.40657 15.2051 7.16667 15.3997ZM1.56914 11.8069C1.90147 11.3453 2.30251 10.9238 2.76046 10.5517C3.4621 9.98162 4.28636 9.53622 5.18297 9.23447C5.82023 9.02 6.48786 8.88013 7.16667 8.81715V7.0734C5.52694 6.92121 3.97046 6.322 2.76046 5.33887C2.29953 4.96437 1.89945 4.5421 1.56934 4.08363C0.890464 5.21167 0.5 6.53295 0.5 7.94544C0.5 9.35778 0.890386 10.6789 1.56914 11.8069Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>

            <div className={styles.logoContainer}>
              <img
                src={Company?.logo?.url}
                alt="Company logo"
                className={styles.logo}
              />
              <span className={styles.companyName}>{Company?.name}</span>
            </div>
          </div>

          <div className={styles.content}>
            <section className={styles.about}>
              <div className={styles.aboutTags}>
                <h3 className={styles.Htitle}>{t("About")}</h3>
                <div className={styles.Tags}>
                  {Company.business_segments?.map((segment) => (
                    <span key={crypto.randomUUID()} className={styles.tag}>
                      {segment.name}
                    </span>
                  ))}
                </div>
              </div>
              <p className={styles.companyDescription}>{Company.details}</p>
            </section>

            <section className={styles.contactInfo}>
              <h3 className={styles.Htitle}>{t("Website")}</h3>
              <a className={styles.infoItems} href={Company.url}>
                <LinkIcon size={18} color="green" />
                <span className={styles.websiteLink}>{Company.url}</span>
              </a>
            </section>
            <section className={styles.contactInfo}>
              <h3 className={styles.Htitle}>{t("Contact info")}</h3>
              <div className={styles.infoItems}>
                <MapPin size={18} color="green" />
                <span className={styles.companyDescription}>
                  {Company.address}
                </span>
              </div>
              <div className={styles.infoItems}>
                <Mail size={18} color="green" />
                <span className={styles.companyDescription}>
                  {Company.email}
                </span>
              </div>
              <div className={styles.infoItems}>
                <Phone size={18} color="green" />
                <span className={styles.companyDescription}>
                  {Company.phone}
                </span>
              </div>
            </section>

            <section className={styles.productsServices}>
              <h3 className={styles.Htitle}>{t("Products/Services")}</h3>
              <div className={styles.serviceGrid}>
                {Company.services.map((service, index) => (
                  <div key={index} className={styles.serviceCard}>
                    <div className={styles.serviceImageContainer}>
                      <img
                        src={service.image.url}
                        alt={service.name}
                        className={styles.serviceImage}
                      />
                    </div>
                    <h4>{service.name}</h4>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
      <div style={{ backgroundColor: "black" }}>
        <div className="bg-black px-[7rem]  py-[2.75rem]">
          <h2 className="mb-4 text-xl font-semibold text-white">
            {t("explore similar")}
          </h2>
          <div className="slider-container">
            <Slider {...settings}>
              {otherCompanies
                ?.filter((company) => company.uuid !== Company?.uuid)
                .map((company) => (
                  <div
                    key={company.uuid}
                    className="w-[15.625rem] relative cursor-pointer"
                    onClick={() => {
                      setCompany(company);
                    }}
                  >
                    <img
                      className="rounded-t-lg w-full h-[12rem]"
                      src={company?.logo?.url}
                      alt=""
                    />
                    <div className="bg-white rounded-b-lg drop-shadow-md">
                      <p className="px-3 py-2 text-lg font-bold text-center">
                        {company?.name}
                      </p>
                    </div>
                    <div className="absolute flex flex-wrap gap-1 top-2 left-1">
                      {company?.business_segments?.map((segment) => (
                        <p
                          key={segment.id}
                          className="font-medium text-sm  bg-[#B8E9B7] px-2 py-1 rounded-3xl"
                        >
                          {segment.name}
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
        <div className="GooglePlay">
          <div className="Hint">{t("get workery")}</div>
          <div className="GooglePlay_logo">
            <img src="/Group.png" alt="google play img" />
            <img src="/AppleStore.png" alt="Apple Store img" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
