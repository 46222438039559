import { createContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { updateLanguagePreferance } from "../data_featching/UpdateProfile";
import useLocalStorage from "../hooks/useLocalStorage";
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "en"
  );
  const { token } = useLocalStorage();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);
  useEffect(() => {
    document.body.dir = getDirectionFromLanguage(language);
    return () => {
      document.body.dir = "";
    };
  }, [language]);

  function getDirectionFromLanguage(lang) {
    switch (lang.toLowerCase()) {
      case "ar":
        return "rtl";
      default:
        return "ltr";
    }
  }

  const changeLanguage = async (lng) => {
    setLanguage(lng);
    localStorage.setItem("language", lng);
    const data = {
      locale: lng,
    };
    const langPreferanceResult = await updateLanguagePreferance(token, data);
    console.log("langPreferanceResult", langPreferanceResult);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
LanguageProvider.propTypes = null;
