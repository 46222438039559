import React, { createContext, useState, useMemo, useEffect } from "react";
import { getCities, getDistricts } from "../data_featching/workspace";
import { useTranslation } from "react-i18next";

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const { i18n } = useTranslation("workspace");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchCityId, setSearchCityId] = useState("");
  const [searchDistrictsIds, setSearchDistrictsIds] = useState([]);
  const [Nearby, setNearby] = useState(false);

 
  
  useEffect(() => {
    const fetchCities = async () => {
      const response = await getCities(i18n.language);
      if (response?.status === 429) {
        const message = response.data.message;
        const waitTimeMatch = message.match(/(\d+)\s*seconds/);
        const waitTime = waitTimeMatch ? parseInt(waitTimeMatch[1], 10) : 60;
        setTimeout(() => {
          fetchCities();
        }, waitTime * 1000);
      }
      if (response?.status === 200 || response?.status === 201) {
        setCities(response.data?.data || []);
        setFilteredItems(response.data?.data || []);
      }
    };
    fetchCities();
  }, [i18n.language]);
  const memoizedDistricts = useMemo(
    () => (cityId) => {
      const fetchDistricts = async () => {
        const response = await getDistricts(cityId, i18n.language);
        if (response?.status === 429) {
          const message = response.data.message;
          const waitTimeMatch = message.match(/(\d+)\s*seconds/);
          const waitTime = waitTimeMatch ? parseInt(waitTimeMatch[1], 10) : 60;
          setTimeout(() => {
            fetchDistricts();
          }, waitTime * 1000);
        }
        if (response?.status === 200 || response?.status === 201)
        {
        const districtsData = response.data?.data || [];
        setDistricts(districtsData);
        // Filter out already selected districts when initially loading
        const filteredDistricts = districtsData.filter(
          district => !selectedDistricts.some(selected => selected.id === district.id)
        );
        setFilteredItems(filteredDistricts);
        }
      };
      fetchDistricts();
    },
    [i18n.language, selectedDistricts] // Add selectedDistricts as dependency
  );

 

  // Update filtered items when districts or selected districts change
  useEffect(() => {
    if (selectedCity && districts.length > 0) {
      const filtered = districts.filter(
        district => !selectedDistricts.some(selected => selected.id === district.id)
      );
      setFilteredItems(filtered);
    }
  }, [districts, selectedDistricts, selectedCity]);

  const handleSearchChange = (value) => {
    setSearchTerm(value);

    if (selectedCity) {
      const availableDistricts = districts.filter(
        district => !selectedDistricts.some(selected => selected.id === district.id)
      );
      const filtered = availableDistricts.filter((district) =>
        district.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredItems(filtered);
    } else {
      const filtered = cities.filter((city) =>
        city.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredItems(filtered);
    }
    setShowDropdown(true);
  };

  const handleCitySelect = (cityId, cityName) => {
    setSelectedCity({ id: cityId, name: cityName });
    setSearchCityId(cityId);
    setSearchTerm("");
    setShowDropdown(false);
    memoizedDistricts(cityId);
  };

  const handleDistrictSelect = (districtId, districtName) => {
    if (!selectedDistricts.some((d) => d.id === districtId)) {
      const updatedDistricts = [
        ...selectedDistricts,
        { id: districtId, name: districtName },
      ];
      setSelectedDistricts(updatedDistricts);
      setSearchDistrictsIds(updatedDistricts.map((d) => d.id));
      
      // Update filtered items to remove the newly selected district
      const updatedFilteredItems = filteredItems.filter(
        item => item.id !== districtId
      );
      setFilteredItems(updatedFilteredItems);
    }
    setSearchTerm("");
  };

  const clearSelection = () => {
    setSelectedCity(null);
    setSelectedDistricts([]);
    setSearchCityId("");
    setSearchDistrictsIds([]);
    setNearby(false);
    setSearchTerm("");
    setFilteredItems(cities);
  };

  // New function to handle district removal
  const handleDistrictRemoval = (removedDistricts) => {
    // Update selected districts
    const remainingDistricts = selectedDistricts.filter(
      district => !removedDistricts.some(removed => removed.id === district.id)
    );
    setSelectedDistricts(remainingDistricts);
    setSearchDistrictsIds(remainingDistricts.map(d => d.id));

    // Add removed districts back to filtered items if we're in district selection mode
    if (selectedCity) {
      const updatedFilteredItems = [
        ...filteredItems,
        ...removedDistricts
      ].sort((a, b) => a.name.localeCompare(b.name)); // Sort by name to maintain order
      
      setFilteredItems(updatedFilteredItems);
    }
  };

  return (
    <SearchContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        selectedCity,
        setSelectedCity,
        selectedDistricts,
        setSelectedDistricts,
        cities,
        setCities,
        districts,
        setDistricts,
        filteredItems,
        setFilteredItems,
        showDropdown,
        setShowDropdown,
        isFocused,
        setIsFocused,
        searchCityId,
        setSearchCityId,
        searchDistrictsIds,
        setSearchDistrictsIds,
        Nearby,
        setNearby,
        handleSearchChange,
        handleCitySelect,
        handleDistrictSelect,
        handleDistrictRemoval, // New function exposed through context
        clearSelection,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContext;