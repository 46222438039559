import { useEffect, useRef, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { loginUserInfoRequest } from "../../data_featching/login";
import {
  switchToPersonalProfile,
  switchToBusinessProfile,
  changePassword,
} from "../../data_featching/UpdateProfile";
import AuthContext from "../../context/AuthProvider";
import CompleteProfile from "./CompleteProfile";
import PhoneChange from "./PhoneChange";
import EmailChange from "./EmailChange";
import SuccessMessage from "./SuccessMessage";
import AccountDeletionPolicy from "./AccountDeletionPolicy";
import DeleteAccount from "./DeleteAccount";
import DeleteAccountSuccess from "./DeleteAccountSuccess";

function Account() {
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const [passwordModal, setPasswordModal] = useState(false);
  const [accountTypeModal, setAccountTypeModal] = useState(false);
  const [passwordTypeModal, setPasswordTypeModal] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);
  const [passClicked, setPassClicked] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const [switchedToPersonal, setSwitchedToPersonal] = useState(false);
  const [switchedToBusiness, setSwitchedToBusiness] = useState(false);
  const [nextClicked, setnextClicked] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [goToComplete, setGoToComplete] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [goToCompletePhone, setGoToCompletePhone] = useState(false);
  const [hasCompletedPhone, setHasCompletedPhone] = useState(false);
  const [showSuccessPhone, setShowSuccessPhone] = useState(false);
  const [goToCompleteEmail, setGoToCompleteEmail] = useState(false);
  const [hasCompletedEmail, setHasCompletedEmail] = useState(false);
  const [showSuccessEmail, setShowSuccessEmail] = useState(false);
  const [showCompletedProfile, setShowCompletedProfile] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [succeedWithDelete, setSucceedWithDelete] = useState(false);
  const [deleteAccountSuccess, setDeleteAccountSuccess] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [saveChangesClicked, setSaveChangesClicked] = useState(false);
  const timeoutRef = useRef(null);
  const { t, i18n } = useTranslation("profile");
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");

  const isValidPassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+\-=[\]{}|;':"<>,.?]).{8,}$/;
    return passwordRegex.test(password);
  };

  function handlePasswordChange(e) {
    setPassword(e.target.value);
    setSaveChangesClicked(false);
  }

  function handleNewPassword(e) {
    setNewPassword(e.target.value);
    setSaveChangesClicked(false);
  }

  function handleNewPasswordConfirm(e) {
    setNewPasswordConfirm(e.target.value);
    setSaveChangesClicked(false);
  }

  function closeModal() {
    setPasswordModal(false);
    setNewPassword("");
    setNewPasswordConfirm("");
    setPassword("");
    setPasswordError("");
    setPassClicked(false);
    setAccountTypeModal(false);
    setPasswordTypeModal(false);
    setSaveChangesClicked(false);
  }

  function toPersonal() {
    setAccountTypeModal(false);
    setPasswordTypeModal(true);
    setnextClicked(true);
  }

  function toBusiness() {
    setAccountTypeModal(false);
    setPasswordTypeModal(true);
    setnextClicked(true);
  }

  function openModal() {
    setPasswordModal(true);
  }

  function openAccountTypeModal() {
    setAccountTypeModal(true);
  }

  const saveNewPassword = async () => {
    setSaveChangesClicked(true);
    setPasswordError("");
    if (
      isValidPassword(password) &&
      isValidPassword(newPassword) &&
      newPassword === newPasswordConfirm
    ) {
      try {
        const response = await changePassword(
          token,
          JSON.stringify({
            current_password: password,
            password: newPassword,
            password_confirmation: newPasswordConfirm,
          }),
          i18n.language
        );

        if (response.status === 200 || response.status == 201) {
          setIsChanged(true);
          closeModal();
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
      } catch (error) {
        setPasswordError(error.message);
      }
    } else {
      return;
    }
  };

  const switchToPersonal = async () => {
    setSwitchedToBusiness(false);
    setPassClicked(true);
    if (isValidPassword(password)) {
      const formData = new FormData();
      formData.set("password", password);

      try {
        const response = await switchToPersonalProfile(
          token,
          formData,
          i18n.language
        );

        if (response.status === 200 || response.status === 201) {
          setSwitchedToPersonal(true);
          setIsChanged(true);
          closeModal();
        }
      } catch (error) {
        setPasswordError(error.message);
      }
    } else {
      return;
    }
  };

  const switchToBusiness = async () => {
    setSwitchedToPersonal(false);
    setPassClicked(true);
    if (isValidPassword(password)) {
      const formData = new FormData();
      formData.set("password", password);

      try {
        const response = await switchToBusinessProfile(
          token,
          formData,
          i18n.language
        );

        if (response.status == 200 || response.status == 201) {
          setSwitchedToBusiness(true);
          setIsChanged(true);
          closeModal();
        }
      } catch (error) {
        setPasswordError(error.message);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    const getUserInfo = async () => {
      if (token) {
        const result = await loginUserInfoRequest(token);
        if (result?.status === 200 || result?.status === 201) {
          setUserInfo(result?.data?.data);
        }
        console.log("result", result);
      }
    };
    if (
      !userInfo ||
      switchToBusiness ||
      switchToPersonal ||
      (userInfo && (hasCompleted || hasCompletedPhone || hasCompletedEmail))
    ) {
      getUserInfo();
      setHasCompleted(false);
      setHasCompletedPhone(false);
      setHasCompletedEmail(false);
    }
  }, [
    token,
    hasCompleted,
    hasCompletedPhone,
    hasCompletedEmail,
    switchedToBusiness,
    switchedToPersonal,
  ]);

  useEffect(() => {
    if (isChanged) {
      setIsSuccessModalVisible(true);
      setTimeout(() => {
        setIsSuccessModalVisible(false);
        setIsChanged(false);
      }, 3000);
    }
  }, [isChanged]);

  useEffect(() => {
    if (deleteAccountSuccess) {
      setTimeout(() => {
        setDeleteAccountSuccess(false);
      }, 3000);
    }
  }, [deleteAccountSuccess]);

  useEffect(() => {
    if (userInfo?.completed_profile === true && hasCompleted) {
      setShowCompletedProfile(true);

      timeoutRef.current = setTimeout(() => {
        setShowCompletedProfile(false);
      }, 8000);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [userInfo?.completed_profile, hasCompleted]);

  return (
    <>
      <div className="w-full h-full p-6">
        {goToCompletePhone && (
          <PhoneChange
            setGoToCompletePhone={setGoToCompletePhone}
            setHasCompletedPhone={setHasCompletedPhone}
            setShowSuccessPhone={setShowSuccessPhone}
          />
        )}
        {goToCompleteEmail && (
          <EmailChange
            setGoToCompleteEmail={setGoToCompleteEmail}
            setHasCompletedEmail={setHasCompletedEmail}
            setShowSuccessEmail={setShowSuccessEmail}
          />
        )}
        {goToComplete && !hasCompleted && (
          <CompleteProfile
            setGoToComplete={setGoToComplete}
            setHasCompleted={setHasCompleted}
            setShowSuccess={setShowSuccess}
          />
        )}
        {hasCompletedPhone && showSuccessPhone && (
          <SuccessMessage
            setShowSuccess={setShowSuccessPhone}
            title={t("phone updated")}
            subTitle={t("phone successfully updated")}
            buttonText={t("finish")}
          />
        )}
        {hasCompletedEmail && showSuccessEmail && (
          <SuccessMessage
            setShowSuccess={setShowSuccessEmail}
            title={t("email updated")}
            subTitle={t("email successfully updated")}
            buttonText={t("finish")}
          />
        )}
        {hasCompleted && showSuccess && (
          <SuccessMessage
            setShowSuccess={setShowSuccess}
            title={t("profile completed")}
            subTitle={t("your profile is now complete")}
            buttonText={t("ok")}
          />
        )}
        {showPolicy && <AccountDeletionPolicy setShowPolicy={setShowPolicy} />}
        {showCompletedProfile ? (
          <div className="flex items-center py-2 px-4 rounded-t-lg mb-2 bg-[#22C55E]">
            <img src="/completed-profile.svg" alt="" className="w-8 h-8 mr-4" />
            <p className="text-lg font-medium text-white">
              {t("completed profile")}
            </p>
          </div>
        ) : (
          <h3 className="mb-6 text-xl font-semibold">{t("Account")}</h3>
        )}

        {userInfo?.completed_profile === true && (
          <>
            <div className="h-[1px] bg-gray-300 my-4"></div>
            <div className="flex items-center gap-16 px-4">
              <h3 className="min-w-[8.75rem] text-xl font-semibold">
                {t("Phone number")}
              </h3>
              <p className="w-[21rem] font-normal text-lg text-gray-500">
                {userInfo?.phone}
              </p>
              <button
                onClick={() => setGoToCompletePhone(true)}
                className="flex items-center gap-1 mr-4 ltr:ml-auto rtl:mr-auto"
              >
                <span className="text-xs font-medium text-blue-600">
                  {t("Change")}
                </span>
                <img
                  src="/prefer-change.svg"
                  alt=""
                  className="mt-[2.5px] h-4 w-4"
                />
              </button>
            </div>
          </>
        )}

        {userInfo?.completed_profile === true && (
          <>
            <div className="h-[1px] bg-gray-300 my-4"></div>
            <div className="flex items-center gap-16 px-4">
              <h3 className="w-[8.75rem] text-xl font-semibold">
                {t("Email")}
              </h3>
              <p className="w-[21rem] font-normal text-lg text-gray-500">
                {userInfo?.email}
              </p>
              <button
                onClick={() => setGoToCompleteEmail(true)}
                className="flex items-center gap-1 mr-4 ltr:ml-auto rtl:mr-auto"
              >
                <span className="text-xs font-medium text-blue-600">
                  {t("Change")}
                </span>
                <img
                  src="/prefer-change.svg"
                  alt=""
                  className="mt-[2.5px] h-4 w-4"
                />
              </button>
            </div>
          </>
        )}

        <div className="h-[1px] bg-gray-300 my-4"></div>
        <div>
          <div className="flex items-center gap-16 px-4">
            <h3 className="w-[8.75rem] text-xl whitespace-nowrap font-semibold">
              {t("account type")}
            </h3>
            <p className="w-[12.688rem] text-gray-500 font-normal text-lg">
              {userInfo?.has_business_profile
                ? t("business account")
                : t("personal account")}
            </p>

            {userInfo?.completed_profile && (
              <button
                onClick={openAccountTypeModal}
                className="flex items-center gap-1 mr-4 ltr:ml-auto rtl:mr-auto"
              >
                <p className="text-xs font-medium text-blue-600 whitespace-nowrap">
                  {userInfo?.has_business_profile
                    ? t("switch to personal account")
                    : t("switch to business account")}
                </p>
                <img
                  src="/prefer-change.svg"
                  alt=""
                  className="mt-[2.5px] h-4 w-4"
                />
              </button>
            )}

            {userInfo?.completed_profile === false && (
              <button
                type="button"
                onClick={() => setGoToComplete(true)}
                className="flex items-center gap-1 mr-4 ltr:ml-auto rtl:mr-auto"
              >
                <p className="text-xs font-medium text-blue-600">
                  {userInfo.has_business_profile
                    ? t("switch to personal account")
                    : t("switch to business account")}
                </p>
                <img
                  src="/prefer-change.svg"
                  alt=""
                  className="mt-[2.5px] h-4 w-4"
                />
              </button>
            )}
          </div>

          {userInfo?.completed_profile && (
            <>
              <div className="h-[1px] bg-gray-300 my-4"></div>
              <div className="flex items-center gap-16 px-4">
                <h3 className="w-[8.75rem] text-xl font-semibold">
                  {t("Password")}
                </h3>
                <p className="w-[21rem] font-normal text-lg text-gray-500">
                  {t(
                    "Change your password regularly to keep your account safe"
                  )}
                </p>
                <button
                  onClick={openModal}
                  className="flex items-center gap-1 mr-4 ltr:ml-auto rtl:mr-auto"
                >
                  <span className="text-xs font-medium text-blue-600">
                    {t("Change")}
                  </span>
                  <img
                    src="/prefer-change.svg"
                    alt=""
                    className="mt-[2.5px] h-4 w-4"
                  />
                </button>
              </div>
            </>
          )}

          <div className="h-[1px] bg-gray-300 my-4"></div>
          <div className="flex items-center gap-16 px-4">
            <h3 className="w-[8.75rem] whitespace-nowrap text-xl font-semibold">
              {t("Delete Account")}
            </h3>
            <div className="w-[18rem] flex flex-col gap-5">
              <p className="text-lg font-normal text-gray-500">
                {t("permanent delete account")}
              </p>
              <p className="text-lg font-normal text-gray-500">
                {t("delete_note")}
              </p>
              <button
                onClick={() => setShowPolicy(true)}
                className="flex items-center gap-1.5"
              >
                <span className="text-sm font-medium text-blue-600">
                  {t("Check policy")}
                </span>
                <img src="/policy.svg" alt="" className="mt-[-2px] h-5 w-5" />
              </button>
            </div>
            <button
              onClick={() => setShowDeleteAccountModal(true)}
              className="flex min-w-[10rem] items-center rounded-lg px-4 py-2.5  gap-1 rtl:mr-auto ltr:ml-auto bg-red-500"
            >
              <span className="text-sm font-medium text-white">
                {t("Delete Account")}
              </span>
              <img
                src="/delete-account.svg"
                alt=""
                className="mt-[-2.5px] h-5 w-5"
              />
            </button>
          </div>
          <div className="h-[1px] bg-gray-300 mt-4"></div>
        </div>
      </div>
      {showDeleteAccountModal && (
        <>
          <div className="fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-25">
            <div className="p-6 bg-white flex flex-col  rounded-lg shadow-md w-[31.25rem]">
              <div className="flex items-center justify-between">
                <h3 className="mb-2 text-lg font-medium">
                  {t("Delete Account")}
                </h3>
                <button onClick={() => setShowDeleteAccountModal(false)}>
                  <img src="/x.svg" alt="" className="mt-[-0.5rem]" />
                </button>
              </div>

              <div className="text-[#6B7280] text-sm font-normal mb-8">
                <p>{t("sure to delete")}</p>
                <p>{t("hint before delete")}</p>
              </div>
              <button
                onClick={() => setShowPolicy(true)}
                className="flex items-center gap-1.5"
              >
                <span className="text-sm font-medium text-blue-600">
                  {t("Check policy")}
                </span>
                <img src="/policy.svg" alt="" className="mt-[-2px] h-5 w-5" />
              </button>
              <div className="flex items-center justify-end gap-3 mt-8">
                <button
                  onClick={() => setShowDeleteAccountModal(false)}
                  className="w-[4.938rem] border px-4 py-2 rounded-lg  drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]"
                >
                  {t("Cancel")}
                </button>
                <button
                  onClick={() => {
                    setSucceedWithDelete(true);
                    setShowDeleteAccountModal(false);
                  }}
                  className={`min-w-[8rem] text-white text-sm font-normal 
                  bg-[#EF4444] flex gap-1 items-center border whitespace-nowrap px-4 py-2 rounded-lg  drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]`}
                >
                  {t("yes delete account")}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {succeedWithDelete && (
        <DeleteAccount
          setShowDeleteAccountModal={setShowDeleteAccountModal}
          setSucceedWithDelete={setSucceedWithDelete}
          setDeleteAccountSuccess={setDeleteAccountSuccess}
        />
      )}
      {deleteAccountSuccess && <DeleteAccountSuccess />}
      {passwordModal && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-30">
          <div className="absolute w-[34.375rem] p-6 rounded-md bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]">
            <div className="flex items-center justify-between">
              <h3 className="mb-2 text-lg font-medium">
                {t("changing_password")}
              </h3>
              <button onClick={closeModal}>
                <img src="/x.svg" alt="" className="mt-[-0.5rem]" />
              </button>
            </div>
            <p className="mb-5 text-sm font-normal text-gray-500">
              {t("Set password")}
            </p>
            <h3 className="mb-2 text-lg font-medium">
              {t("current_password")}
            </h3>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="relative mb-8">
                <input
                  id="pass"
                  type={showPassword ? "text" : "password"}
                  className="w-full pr-3 ltr:pl-10 rtl:pr-10 py-2 border outline-none text-[#9CA3AF] border-[#DCE1E9] rounded-lg placeholder:absolute placeholder:top-4"
                  placeholder="*******************"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <img
                  src="/pass-lock.svg"
                  alt=""
                  className="absolute w-5 h-5 top-3 left-3"
                />
                {showPassword && (
                  <button
                    type="button"
                    onClick={() => setShowPassword(false)}
                    className="absolute top-3 right-3"
                  >
                    <img src="/pass-eye.svg" alt="" />
                  </button>
                )}
                {!showPassword && (
                  <button
                    type="button"
                    onClick={() => setShowPassword(true)}
                    className="absolute top-3 right-3"
                  >
                    <img src="/open-eye.png" alt="" />
                  </button>
                )}
                {passwordError && (
                  <p className="my-2 text-sm font-medium text-red-400">
                    {passwordError}
                  </p>
                )}

                {saveChangesClicked &&
                  !passwordError &&
                  !isValidPassword(password) && (
                    <p className="my-2 text-sm font-medium text-red-400">
                      {t("password_hint")}
                    </p>
                  )}
              </div>

              <div>
                <h3 className="mb-2 text-lg font-medium">{t("Password")}</h3>

                <div className={`relative mb-8`}>
                  <input
                    id="new-pass"
                    type={`${showNewPassword ? "text" : "password"}`}
                    className="w-full pr-3 ltr:pl-10 rtl:pr-10 py-2  border outline-none text-[#9CA3AF] border-[#DCE1E9] rounded-lg placeholder:absolute placeholder:top-4"
                    placeholder="*******************"
                    value={newPassword}
                    onChange={handleNewPassword}
                  />
                  <img
                    src="/pass-lock.svg"
                    alt=""
                    className="absolute w-5 h-5 top-3 left-3"
                  />
                  {showNewPassword && (
                    <button
                      type="button"
                      onClick={() => setShowNewPassword(false)}
                      className="absolute top-3 right-3"
                    >
                      <img src="/pass-eye.svg" alt="" />
                    </button>
                  )}
                  {!showNewPassword && (
                    <button
                      type="button"
                      onClick={() => setShowNewPassword(true)}
                      className="absolute top-3 right-3"
                    >
                      <img src="/open-eye.png" alt="" />
                    </button>
                  )}
                </div>

                <h3 className="mb-2 text-lg font-medium">
                  {t("confirm_password")}
                </h3>

                <div className={`relative mb-2`}>
                  <input
                    id="confirm-new-pass"
                    type={`${showNewConfirmPassword ? "text" : "password"}`}
                    className="w-full pr-3 ltr:pl-10 rtl:pr-10 py-2  border outline-none text-[#9CA3AF] border-[#DCE1E9] rounded-lg placeholder:absolute placeholder:top-4"
                    placeholder="*******************"
                    value={newPasswordConfirm}
                    onChange={handleNewPasswordConfirm}
                  />
                  <img
                    src="/pass-lock.svg"
                    alt=""
                    className="absolute w-5 h-5 top-3 left-3"
                  />
                  {showNewConfirmPassword && (
                    <button
                      type="button"
                      onClick={() => setShowNewConfirmPassword(false)}
                      className="absolute top-3 right-3"
                    >
                      <img src="/pass-eye.svg" alt="" />
                    </button>
                  )}
                  {!showNewConfirmPassword && (
                    <button
                      type="button"
                      onClick={() => setShowNewConfirmPassword(true)}
                      className="absolute top-3 right-3"
                    >
                      <img src="/open-eye.png" alt="" />
                    </button>
                  )}
                </div>
                {newPassword &&
                  newPasswordConfirm &&
                  newPassword === newPasswordConfirm &&
                  !passwordError &&
                  !saveChangesClicked && (
                    <p className="my-2 text-sm font-medium text-green-400">
                      {t("password_match")}
                    </p>
                  )}
                {newPassword &&
                  newPasswordConfirm &&
                  newPassword !== newPasswordConfirm &&
                  !passwordError &&
                  !saveChangesClicked && (
                    <p className="my-2 text-sm font-medium text-red-400">
                      {t("passwords_mismatch")}
                    </p>
                  )}
                {saveChangesClicked &&
                  !passwordError &&
                  !isValidPassword(newPassword) && (
                    <p className="my-2 text-sm font-medium text-red-400">
                      {t("password_hint")}
                    </p>
                  )}
              </div>

              <div className="flex items-center justify-end gap-3 mt-8">
                <button
                  onClick={closeModal}
                  className="w-[4.938rem] border px-4 py-2 rounded-lg  drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]"
                >
                  {t("Cancel")}
                </button>
                <button
                  onClick={saveNewPassword}
                  className={`min-w-[8rem] text-white text-sm font-normal 
                  ${
                    isValidPassword(password) &&
                    isValidPassword(newPassword) &&
                    isValidPassword(newPasswordConfirm) &&
                    newPassword === newPasswordConfirm
                      ? "bg-green-600"
                      : "bg-[#C3C9D0]"
                  } flex gap-1 items-center border whitespace-nowrap px-4 py-2 rounded-lg  drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]`}
                >
                  {t("save_changes")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isSuccessModalVisible && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-30">
          <div className="absolute w-[34.375rem] p-6 rounded-md bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]">
            <div className="flex flex-col items-center justify-center gap-5">
              <img src="/success.svg" alt="" />
              {switchedToBusiness && (
                <h3 className="mb-2 text-lg font-medium">
                  {t("business success")}
                </h3>
              )}
              {switchedToPersonal && (
                <h3 className="mb-2 text-lg font-medium">
                  {t("personal success")}
                </h3>
              )}
              {!switchedToBusiness && !switchedToPersonal && (
                <h3 className="mb-2 text-lg font-medium">
                  {t("password success")}
                </h3>
              )}
            </div>
          </div>
        </div>
      )}
      {accountTypeModal && !userInfo?.has_business_profile && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-30">
          <div className="absolute w-[34.375rem] p-6 rounded-md bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]">
            <div className="flex items-center justify-between">
              <h3 className="mb-2 text-lg font-medium">
                {t("switch to business")}
              </h3>
              <button onClick={closeModal}>
                <img src="/x.svg" alt="" className="mt-[-0.5rem]" />
              </button>
            </div>

            <p className="mb-5 text-sm font-normal text-gray-500">
              {t("business note")}
            </p>

            <div className="mb-8">
              <img src="/community.svg" alt="" />
            </div>

            <div className="flex items-center justify-end gap-3">
              <button
                onClick={closeModal}
                className="w-[4.938rem] border px-4 py-2 rounded-lg  drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]"
              >
                {t("Cancel")}
              </button>
              <button
                onClick={toBusiness}
                className={`w-[5.625rem] text-white
                bg-green-600
                 flex gap-1 items-center border px-4 py-2 rounded-lg  drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]`}
              >
                <span>{t("next")}</span>
                {i18n.language === "ar" ? (
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    color="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 11L2 6L7 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M12 11L7 6L12 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="13"
                    color="white"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 11L11 6L6 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M1 11L6 6L1 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      {accountTypeModal && userInfo?.has_business_profile && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-30">
          <div className="absolute w-[34.375rem] p-6 rounded-md bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]">
            <div className="flex items-center justify-between">
              <h3 className="mb-2 text-lg font-medium">
                {t("switch to personal account")}
              </h3>
              <button onClick={closeModal}>
                <img src="/x.svg" alt="" className="mt-[-0.5rem]" />
              </button>
            </div>

            <p className="pr-2 mb-8 text-sm font-normal text-gray-500">
              {t("personal note")}
            </p>

            <div className="flex items-center justify-end gap-3">
              <button
                onClick={closeModal}
                className="w-[4.938rem] border px-4 py-2 rounded-lg  drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]"
              >
                {t("Cancel")}
              </button>
              <button
                onClick={toPersonal}
                className={`w-[5.625rem] text-white
                bg-green-600
                 flex gap-1 items-center border px-4 py-2 rounded-lg  drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]`}
              >
                <span>{t("next")}</span>
                {i18n.language === "ar" ? (
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    color="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 11L2 6L7 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M12 11L7 6L12 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="13"
                    color="white"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 11L11 6L6 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M1 11L6 6L1 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      {passwordTypeModal && nextClicked && !isChanged && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-30">
          <div className="absolute w-[34.375rem] p-6 rounded-md bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]">
            <div className="flex items-center justify-between">
              <h3 className="mb-2 text-lg font-medium">
                {t("enter password")}
              </h3>
              <button onClick={closeModal}>
                <img src="/x.svg" alt="" className="mt-[-0.5rem]" />
              </button>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              <p className="mb-5 text-sm font-normal text-gray-500">
                {t("please enter password")}
              </p>
              <h3 className="mb-2 text-lg font-medium">{t("password")}</h3>
              <div
                className={`relative ${
                  passwordError || (!isValidPassword(password) && passClicked)
                    ? "mb-2"
                    : "mb-[5.375rem]"
                }`}
              >
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  placeholder="**************"
                  value={password}
                  onChange={handlePasswordChange}
                  className="outline-none w-full py-2 ltr:pr-3 rtl:pr-10  border border-gray-400 rounded-lg pl-10 placeholder:absolute placeholder:top-3.5"
                />
                <img
                  src="/pass-lock.svg"
                  alt=""
                  className="absolute top-2.5 left-3"
                />
                {showPassword && (
                  <button
                    type="button"
                    onClick={() => setShowPassword(false)}
                    className="absolute top-2.5 right-3"
                  >
                    <img src="/pass-eye.svg" alt="" />
                  </button>
                )}
                {!showPassword && (
                  <button
                    type="button"
                    onClick={() => setShowPassword(true)}
                    className="absolute top-2.5 right-3"
                  >
                    <img src="/open-eye.png" alt="" />
                  </button>
                )}
              </div>

              {passClicked && !isValidPassword(password) && !passwordError && (
                <p className="mb-[5.375rem] text-sm font-medium text-red-400">
                  {t("please enter correct password")}
                </p>
              )}
              {passwordError && (
                <p className="mb-[5.375rem] text-sm font-medium text-red-400">
                  {passwordError}
                </p>
              )}

              <div className="flex items-center justify-end gap-3">
                <button
                  onClick={closeModal}
                  className="w-[4.938rem] border px-4 py-2 rounded-lg  drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]"
                >
                  {t("Cancel")}
                </button>
                <button
                  onClick={
                    userInfo?.has_business_profile
                      ? switchToPersonal
                      : switchToBusiness
                  }
                  className={`min-w-[10.625rem] text-white ${
                    isValidPassword(password) ? "bg-green-600" : "bg-[#C3C9D0]"
                  } flex gap-1 items-center border whitespace-nowrap px-4 py-2 rounded-lg  drop-shadow-[0_2px_4px_rgb(0,0,0,0.1)]`}
                >
                  <span className="whitespace-nowrap">
                    {userInfo?.has_business_profile
                      ? t("switch to personal")
                      : t("switch to business")}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default Account;
