import LoginNavBar from "../ui/login-nav_bar/LoginNavBar";
import { ChevronsRight } from "lucide-react";
import Footer from "../ui/footer/Footer";
import CompanyForm from "./Company/CompanyForm";
import CompanyProfile from "./Company/view/CompanyProfile";
import { AddProductService } from "./Company/AddService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import LanguageContext from "../../context/LanguageProvider";
function CompanyOption({ mode }) {
  const { t } = useTranslation("profile");
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  return (
    <>
      <LoginNavBar />
      <div className="spacePage lg:px-[112px] xl:px-[112px] sm:px-[10px] md:px-[10px]  ">
        <div
          className="flex items-center gap-2 mt-[2.75rem] mb-[2.5rem] cursor-pointer"
          onClick={() => navigate("/profile/my-companies")}
        >
          {language === "en" && <img src="/left-blue.svg" alt="" />}
          {language === "ar" && <ChevronsRight color="#2563EB" />}
          <p className="text-[#2563EB] text-center font-semibold text-base">
            {t("my companies")}
          </p>
        </div>
        {mode === "add" && <CompanyForm />}
        {mode === "edit" && <CompanyForm />}
        {mode === "view" && <CompanyProfile />}
        {mode === "service" && <AddProductService />}
      </div>
      <div style={{ backgroundColor: "black" }}>
        <div className="GooglePlay">
          <div className="Hint">{t("get workey")}</div>
          <div className="GooglePlay_logo">
            <img src="/Group.png" alt="google play img" />
            <img src="/AppleStore.png" alt="Apple Store img" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CompanyOption;
CompanyOption.propTypes = null;
