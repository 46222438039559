import { useEffect, useState } from "react";
import { Spinner } from "../ui/Spinner";
import { formatTo12Hour } from "./sharedFunctions";
import { useTranslation } from "react-i18next";
import useTimeFormat from "../../hooks/useTimeFormat";
const ConvSubSidebar = ({
  isLoading,
  AllThreads,
  setSelectedThreadDetails,
  selectedThreadDetails,
  setMobileFlag,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const { t } = useTranslation("conversations");
  const { timeAgo } = useTimeFormat();

  useEffect(() => {
    console.log("AllThreads", AllThreads);
  }, [AllThreads]);
  /*handle specific message select */
  function handleThreadSelection(item) {
    console.log("handleThreadSelection", item);
    console.log("threeeeeeeeed_id", item?.thread_id);
    setSelectedThreadDetails({
      id: item?.thread_id,
      ifAnyThreadSelected: true,
      ThreadDetails: { ...item },
    });
    setMobileFlag(true);
  }
  return (
    <>
      <div className="subSidebar w-[400px] h-full max-xl:w-full rounded-md">
        <div className="flex flex-col gap-7 w-full">
          <div className="flex flex-col gap-4 w-full">
            <div className="flex items-start py-2 gap-[25px]">
              <h1 className="conv__Header">{t("Conversations")}</h1>
            </div>
            <div className="conv__search w-full">
              <img src="/search-black.png" alt="" className="w-5 h-5" />
              <input
                type="text"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="inputSearch w-[90%]"
                placeholder={t("Search conversations")}
              />
            </div>
          </div>
          <div className="flex flex-col gap-[10px]">
            {isLoading ? (
              <Spinner />
            ) : (
              AllThreads &&
              AllThreads.map((item, idx) => (
                <div
                  className={
                    selectedThreadDetails?.id == item?.thread_id
                      ? "active_selection   messageForWorkspace cursor-pointer p-4 gap-[14px]"
                      : "messageForWorkspace cursor-pointer p-4 gap-[14px]"
                  }
                  key={idx}
                  onClick={() => handleThreadSelection(item)}
                >
                  <div className="workspaceImg rounded-full w-[44px] h-[44px] ">
                    <img
                      src={
                        item?.participants.find(
                          (item) => item.participant_type == "workspace" //get personal info
                        )?.image?.url || "/emptyIMg.png"

                        // ? item.participants[0]?.image?.url
                        // : "/Meeting.png"
                      }
                      alt=""
                      className="rounded-full "
                    />
                  </div>
                  <div className="flex flex-col items-start moreDetails__w">
                    <h3 className="">
                      {
                        item?.participants.find(
                          (item) => item.participant_type == "workspace"
                        )?.name
                      }
                    </h3>
					<div className="flex items-center gap-1">
                      <span className="partOfMessage">
                        {item.latest_message}
                      </span>
                      <span className="Message__TimeStamp flex gap-3">
                        {item.unread_messages_count !== 0 && (
                          <div className="rounded-full w-5 h-4 bg-red-400 text-white flex items-center justify-center">
                            {item.unread_messages_count}
                          </div>
                        )}
                        <span>
                          {timeAgo(
                            item?.latest_message_created_at
                          )}
                        </span>
                        {/* {formatTo12Hour(
                          item?.participants.find(
                            (item) => item.participant_type == "workspace" //get Host/workspace info
                          )?.last_read
                        )} */}
                        {/* {formatTo12Hour(item.created_at)} */}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConvSubSidebar;
