import { useEffect, useRef } from "react";
import { TRUSTED_SUBSCRIPERS } from "./Data";
import { useTranslation } from "react-i18next";

const TrustedBy = () => {
  const { t } = useTranslation("home");
  const containerRef = useRef(null);
  const animationRef = useRef(null);
  const scrollSpeed = 2; // Adjust scroll speed as needed

  useEffect(() => {
    const container = containerRef.current;
    let scrollAmount = 0;

    const startScroll = () => {
      const step = () => {
        scrollAmount -= scrollSpeed;
        container.style.transform = `translateX(${scrollAmount}px)`;

        if (Math.abs(scrollAmount) >= container.scrollWidth / 2) {
          scrollAmount = 0;
        }

        animationRef.current = requestAnimationFrame(step);
      };

      animationRef.current = requestAnimationFrame(step);
    };

    startScroll();

    return () => {
      cancelAnimationFrame(animationRef.current);
    };
  }, []);

  return (
    <div className="TRUSTED_BY_section">
      <div className="trusted_title_subscripers">
        <h3 className="Trusted_title">{t("trusted")}</h3>
        <div className="trusted_subscripers">
          <div className="subscripers_slider" ref={containerRef}>
            {[...TRUSTED_SUBSCRIPERS, ...TRUSTED_SUBSCRIPERS].map(
              (element, idx) => (
                <img
                  key={idx}
                  src={element}
                  alt="Subscripers img"
                  style={{ width: "111px", height: "90px" }}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedBy;
