import baseURL from "../../axios";

export const getFavourites = async (token) => {
  try {
    const res = await baseURL.get(
      `${import.meta.env.VITE_BASE_URL}/api/v1/user/favorite`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log("Error fetching favourites:", error.message);
  }
};

export const addRemoveFavourites = async (token, spaceId) => {
  try {
    const res = await baseURL.post(
      `${import.meta.env.VITE_BASE_URL}/api/v1/user/favorite`,
      {
        space_id: spaceId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.log("Error fetching favourites:", error.message);
  }
};
