import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Error = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  return (
    <div className="flex  items-center w-[55.063rem] mt-[8rem] gap-16 mx-auto">
      <div className="flex flex-col">
        <p className="font-semibold text-center text-shallowGreen text-[7.5rem]">
          404
        </p>
        <p className="mb-5 text-2xl font-extrabold text-center">
          {i18n.language == "en" ? " Page Not Found" : "الصفحة غير موجودة "}
        </p>
        <p
          className={`mb-8 ${
            i18n.language == "en" ? "text-left" : "text-center"
          } text-gray-400 w-[27rem] mx-auto`}
        >
          {i18n.language == "en"
            ? "  Sorry, the page you’re looking for does not exist or has been moved please go back to the Home page"
            : "عفواً؛ الصفحة التي تبحث عنها غير موجودة او تم حذفها برجاء العودة الي الصفحة الرئيسية"}
        </p>
        <button
          onClick={() => {
            navigate("/");
          }}
          className="px-4 py-2.5 mx-auto bg-black text-sm font-medium text-white rounded-lg w-[10rem]"
        >
          {i18n.language == "en"
            ? "Back to home page"
            : "العودة إلي الصفحة الرئيسية"}
        </button>
      </div>
      <div className="w-[23.813rem] h-[30.938rem]">
        <img src="/error.svg" alt="error" className="w-full h-full" />
      </div>
    </div>
  );
};
