import { useContext, useEffect, useState } from "react";
import SecondStep from "./mobileSteps/SecondStep";
import FirstStep from "./mobileSteps/FirstStep";
import { useTranslation } from "react-i18next";
import {
  changeNumberStepOne,
  changeNumberStepTwo,
  changePhoneNumber,
} from "../../data_featching/UpdateProfile";
import LanguageContext from "../../context/LanguageProvider";

function PhoneChange({
  setGoToCompletePhone,
  setHasCompletedPhone,
  setShowSuccessPhone,
}) {
  const { language } = useContext(LanguageContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [phoneInput, setPhoneInput] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [stepOneClicked, setStepOneClicked] = useState(false);
  const [stepTwoClicked, setStepTwoClicked] = useState(false);
  const [phoneStepOneError, setPhoneStepOneError] = useState("");
  const [phoneStepTwoError, setPhoneStepTwoError] = useState("");
  const [changePhoneError, setChangePhoneError] = useState("");
  const { t } = useTranslation("profile");
  const [id, setId] = useState("");
  const [otp, setOtp] = useState("");
  const [verifiedOtp, setVerifiedOtp] = useState(false);
  const token = localStorage.getItem("authToken");
  // const apiBaseUrl = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    const changePhone = async () => {
      if (verifiedOtp) {
        const data = JSON.stringify({
          phone: phoneInput,
          verification_id: id,
          verification_code: otp,
        });
        try {
          const response = await changePhoneNumber(token, data, language);
          if (response.status == 200 || response.status == 201) {
            setGoToCompletePhone(false);
            setHasCompletedPhone(true);
            setShowSuccessPhone(true);
            setTimeout(() => {
              setShowSuccessPhone(false);
            }, 3000);
          }
        } catch (error) {
          setChangePhoneError(error.message);
        }
      } else {
        return;
      }
    };
    if (verifiedOtp) {
      changePhone();
    } else {
      return;
    }
  }, [verifiedOtp]);

  const handleStepOne = async () => {
    setStepOneClicked(true);
    if (isValidPhone) {
      const formData = new FormData();
      formData.set("phone", phoneInput);

      try {
        const response = await changeNumberStepOne(token, formData, language);

        if (response.status == 200 || response.status == 201) {
          setId(response?.data?.data?.id);
          setCurrentStep(2);
        }
      } catch (error) {
        setPhoneStepOneError(error.message);
      }
    } else {
      return;
    }
  };

  const handleStepTwo = async () => {
    setStepTwoClicked(true);
    if (/^[0-9]{6}$/.test(otp)) {
      const formData = new FormData();
      formData.set("verification_id", id);
      formData.set("verification_code", otp);

      try {
        const response = await changeNumberStepTwo(token, formData, language);

        if (response.status == 200 || response.status == 201) {
          setVerifiedOtp(true);
        }
      } catch (error) {
        setPhoneStepTwoError(error.message);
      }
    } else {
      return;
    }
  };

  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center bg-black bg-opacity-25">
      <div className="p-6 bg-white flex flex-col h-[35.5rem]  rounded-lg shadow-md w-[31.25rem]">
        <h2 className="mb-[5.5rem] text-xl font-semibold">
          {t("change phone")}
        </h2>
        <form onSubmit={(e) => e.preventDefault()}>
          {currentStep === 1 && (
            <FirstStep
              phoneInput={phoneInput}
              setIsValidPhone={setIsValidPhone}
              setPhoneInput={setPhoneInput}
              isClicked={stepOneClicked}
              isValidPhone={isValidPhone}
              phoneStepOneError={phoneStepOneError}
            />
          )}

          {currentStep === 2 && (
            <SecondStep
              otp={otp}
              setOtp={setOtp}
              setCurrentStep={setCurrentStep}
              stepTwoClicked={stepTwoClicked}
              phoneInput={phoneInput}
              phoneStepTwoError={phoneStepTwoError}
              changePhoneError={changePhoneError}
              setPhoneStepTwoError={setPhoneStepTwoError}
              setId={setId}
            />
          )}
        </form>
        <div className="flex items-center w-full gap-[0.75rem] mt-auto">
          <button
            type="button"
            onClick={() => setGoToCompletePhone(false)}
            className="text-sm flex-1 shadow-sm outline-none font-medium border px-4 py-[0.625rem] rounded-lg border-[#E5E7EB]"
          >
            {t("cancel")}
          </button>

          {currentStep === 1 && (
            <button
              type="button"
              onClick={handleStepOne}
              className={`text-sm flex-1 shadow-sm outline-none font-medium bg-[#009E1E] text-white px-4 py-[0.625rem] rounded-lg`}
            >
              {t("next")}
            </button>
          )}
          {currentStep === 2 && (
            <button
              type="button"
              onClick={handleStepTwo}
              className="text-sm flex-1 shadow-sm outline-none font-medium bg-[#009E1E] text-white px-4 py-[0.625rem] rounded-lg"
            >
              {t("next")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default PhoneChange;
PhoneChange.propTypes = null;
