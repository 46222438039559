import { useTranslation } from "react-i18next";

function ThirdStep({
  showPassword,
  showConfirmPassword,
  setConfirmPassword,
  setShowConfirmPassword,
  password,
  confirmPassword,
  setPassword,
  setShowPassword,
  stepThreeClicked,
  stepThreeError,
}) {
  const isValidPassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+\-=[\]{}|;':"<>,.?]).{8,}$/;
    return passwordRegex.test(password);
  };
  const { t } = useTranslation("profile");
  return (
    <>
      <div className="mb-16">
        <label htmlFor="pass" className="block mb-2 text-base font-medium">
          {t("Password")}
        </label>
        <div className="relative">
          <input
            id="pass"
            type={`${showPassword ? "text" : "password"}`}
            className="w-full pr-3 pl-10 justify-center  text-[#9CA3AF] outline-none py-2 border border-[#DCE1E9] placeholder:absolute placeholder:top-4 rounded-lg"
            placeholder="*******************"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <img
            src="/pass-lock.svg"
            alt=""
            className="absolute w-5 h-5 top-3 left-3"
          />
          <button
            type="button"
            onClick={() => setShowPassword((pass) => !pass)}
          >
            <img
              src="/pass-eye.svg"
              alt=""
              className="absolute w-5 h-5 top-3 right-3"
            />
          </button>
        </div>
      </div>

      <div>
        <label
          htmlFor="confirm-pass"
          className="block mb-2 text-base font-medium"
        >
          {t("confirm_password")}
        </label>
        <div className="relative">
          <input
            id="confirm-pass"
            type={`${showConfirmPassword ? "text" : "password"}`}
            className="w-full pr-3 ltr:pl-10 rtl:pr-10 py-2  border outline-none text-[#9CA3AF] border-[#DCE1E9] rounded-lg placeholder:absolute placeholder:top-4"
            placeholder="*******************"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <img
            src="/pass-lock.svg"
            alt=""
            className="absolute w-5 h-5 top-3 left-3"
          />
          <button
            type="button"
            onClick={() => setShowConfirmPassword((pass) => !pass)}
          >
            <img
              src="/pass-eye.svg"
              alt=""
              className="absolute w-5 h-5 top-3 right-3"
            />
          </button>
        </div>
        {stepThreeClicked && password !== confirmPassword && (
          <p className="mb-2 text-sm text-red-400">{t("passwords_mismatch")}</p>
        )}
        {stepThreeClicked &&
          !isValidPassword(password) &&
          password === confirmPassword && (
            <p className="mb-2 text-sm text-red-400">{t("password_hint")}</p>
          )}
        {stepThreeError && (
          <p className="mb-2 text-sm text-red-400">{stepThreeError}</p>
        )}
      </div>
    </>
  );
}

export default ThirdStep;

ThirdStep.propTypes = null;
