import { useTranslation } from "react-i18next";

const useTimeFormat = () => {
  const { t, i18n } = useTranslation("home");

  /* Convert server timestamp to the exact time + date only */
  function getFormattedDateTime(timestamp) {
    const date = new Date(timestamp);
    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000); // Adjust for UTC timezone

    const year = utcDate.getFullYear();
    const month = (utcDate.getMonth() + 1).toString().padStart(2, "0");
    const day = utcDate.getDate().toString().padStart(2, "0");

    let hours = utcDate.getHours();
    const minutes = utcDate.getMinutes().toString().padStart(2, "0");
    const seconds = utcDate.getSeconds().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes}:${seconds}`;

    return `${formattedDate} ${formattedTime}`; // UTC
  }

  /* Convert server timestamp to the exact time + date only */
  function convertToCustomFormat(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  /* Date only without time or day name */
  function getDateFormatonly(timestamp) {
    const date = new Date(timestamp);
    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000); // Adjust for UTC timezone

    const year = utcDate.getFullYear();
    const month = (utcDate.getMonth() + 1).toString().padStart(2, "0");
    const day = utcDate.getDate().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate; // Return only the UTC date
  }

  /* Date only with day name */
  function getFormattedDate(timestamp) {
    const date = new Date(timestamp);
    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000); // Adjust for UTC timezone

    const dayOfWeek = utcDate.toLocaleDateString(i18n.language, { weekday: "short" }); // Get short day (e.g., Thu)
    const day = utcDate.getDate();
    const month = (utcDate.getMonth() + 1).toString(); // Get month without leading zero
    const year = utcDate.getFullYear();

    const formattedDate = `${dayOfWeek}, ${day}/${month}/${year}`;

    return formattedDate; // Return date in "Thu, 18/5/2024" format
  }

  function getTimeOnlyFormat(isoTimestamp) {
    const date = new Date(isoTimestamp);

    // Add 3 hours (3 * 60 * 60 * 1000 milliseconds)
    const adjustedTimestamp = date.getTime() + 3 * 60 * 60 * 1000;
    const adjustedDate = new Date(adjustedTimestamp);

    let hours = adjustedDate.getUTCHours();
    const minutes = adjustedDate.getUTCMinutes().toString().padStart(2, "0");
    const seconds = adjustedDate.getUTCSeconds().toString().padStart(2, "0");

    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format (0 becomes 12)

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes}:${seconds} ${period}`;

    return formattedTime; // Returns time in HH:mm:ss AM/PM format
  }

  /* Human-readable time format */
  function timeAgo(timeString) {
    const now = new Date();
    const time = new Date(timeString);
    const diffInSeconds = Math.floor((now - time) / 1000);

    const timeUnits = [
      { unit: t("year"), seconds: 31536000 },
      { unit: t("month"), seconds: 2592000 },
      { unit: t("week"), seconds: 604800 },
      { unit: t("day"), seconds: 86400 },
      { unit: t("hour"), seconds: 3600 },
      { unit: t("minute"), seconds: 60 },
      { unit: t("second"), seconds: 1 },
    ];

    for (const { unit, seconds } of timeUnits) {
      const interval = Math.floor(diffInSeconds / seconds);
      if (interval >= 1) {
        const direction = i18n.language === "ar" ? "rtl" : "ltr";
        return (
          !direction?
          <span >
            {interval} {unit}
            {interval > 1 ? t("s") : ""} {t("ago")}
          </span>
          :
          <span>
            {t("ago")} {interval} {unit}
            {interval > 1 ? t("s") : ""}
          </span>
        );
      }
    }

    return t("just now");
  }

  return {
    getFormattedDateTime,
    getTimeOnlyFormat,
    getDateFormatonly,
    getFormattedDate,
    convertToCustomFormat,
    timeAgo,
  };
};

export default useTimeFormat;