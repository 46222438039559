/* eslint-disable react/prop-types */
import { createContext, useState } from "react";

const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [updateDashNavbar, setUpdateDashNavbar] = useState(false); //flage to update profile icon
  const [numOfUnreadMess, setNumOfUnreadMess] = useState(0); // num of unreaded conversations
  const [mobileFlag, setMobileFlag] = useState(false); // mobile conversation flag
  const [newConversation, setNewConversation] = useState(null); // long term space details
  const [userInfo, setUserInfo] = useState(null); // user info

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        updateDashNavbar,
        setUpdateDashNavbar,
        numOfUnreadMess,
        setNumOfUnreadMess,
        mobileFlag,
        setMobileFlag,
        newConversation,
        setNewConversation,
        userInfo,
        setUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
