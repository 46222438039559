import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteUserAccount } from "../../data_featching/UpdateProfile";
import LanguageContext from "../../context/LanguageProvider";

function DeleteAccount({
  setSucceedWithDelete,
  setShowDeleteAccountModal,
  setDeleteAccountSuccess,
}) {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [clicked, setClicked] = useState(false);
  const token = localStorage.getItem("authToken");
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation("profile");

  const isValidPassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+\-=[\]{}|;':"<>,.?]).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleDeleteAccount = async () => {
    setClicked(true);
    if (isValidPassword(password)) {
      const formData = new FormData();
      formData.set("password", password);

      try {
        const response = await deleteUserAccount(token, formData, language);
        if (response.status == 200 || response.status === 201) {
          setDeleteAccountSuccess(true);
        }
      } catch (error) {
        setPasswordError(error.message);
      }
    } else {
      return;
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-30">
      <div className="absolute w-[34.375rem] p-6 rounded-md bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]">
        <div className="flex items-center justify-between">
          <h3 className="mb-2 text-lg font-medium">{t("changing_password")}</h3>
          <button
            onClick={() => {
              setShowDeleteAccountModal(false);
              setSucceedWithDelete(false);
              setPassword("");
              setPasswordError("");
              setClicked(false);
            }}
          >
            <img src="/x.svg" alt="" className="mt-[-0.5rem]" />
          </button>
        </div>

        <p className="mb-5 text-sm font-normal text-gray-500">
          {t("please enter password")}
        </p>
        <h3 className="mb-2 text-lg font-medium">{t("Password")}</h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div
            className={`relative ${
              passwordError || (!isValidPassword(password) && clicked)
                ? "mb-2"
                : "mb-[5.375rem]"
            }`}
          >
            <input
              id="pass"
              type={showPassword ? "text" : "password"}
              className="w-full pr-3 ltr:pl-10 rtl:pr-10 py-2 border outline-none text-[#9CA3AF] border-[#DCE1E9] rounded-lg placeholder:absolute placeholder:top-4"
              placeholder="*******************"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img
              src="/pass-lock.svg"
              alt=""
              className="absolute w-5 h-5 top-3 left-3"
            />
            {showPassword && (
              <button
                type="button"
                onClick={() => setShowPassword(false)}
                className="absolute top-3 right-3"
              >
                <img src="/pass-eye.svg" alt="" />
              </button>
            )}
            {!showPassword && (
              <button
                type="button"
                onClick={() => setShowPassword(true)}
                className="absolute top-3 right-3"
              >
                <img src="/open-eye.png" alt="" />
              </button>
            )}
          </div>
          {clicked && !isValidPassword(password) && !passwordError && (
            <p className="mb-[5.375rem] text-sm font-medium text-red-400">
              {t("please enter correct password")}
            </p>
          )}
          {passwordError && (
            <p className="mb-[5.375rem] text-sm font-medium text-red-400">
              {passwordError}
            </p>
          )}
          <div className="flex items-center justify-end gap-3">
            <button
              onClick={() => {
                setShowDeleteAccountModal(false);
                setSucceedWithDelete(false);
                setPassword("");
                setPasswordError("");
                setClicked(false);
              }}
              className="min-w-[4.938rem] border px-4 py-2 rounded-lg  drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]"
            >
              {t("Cancel")}
            </button>
            <button
              type="submit"
              onClick={handleDeleteAccount}
              className={`${
                isValidPassword(password) ? "bg-[#EF4444]" : "bg-[#C3C9D0]"
              } min-w-[5.625rem] text-white
          } flex gap-1 items-center border px-4 py-2 rounded-lg  drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]`}
            >
              <span>{t("Delete Account")}</span>
              <img
                src="/delete-account.svg"
                alt=""
                className="mt-[-2.5px] h-5 w-5"
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DeleteAccount;
DeleteAccount.propTypes = null;
