import baseURL from "../../axios";

export const getTags = async (tagType, perPage,lang) => {
  try {
    const response = await baseURL.get(`/api/v1/application/tags`, {
       headers: {
        "Accept-Language": lang,
      },

      params: {
        filters: {
          type: tagType,
        },
        per_page: perPage,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchSpaceTypes = async (lang) => {
  try {
    const response = await baseURL.get(`/api/v1/application/space-types`, {
      headers: {
        "Accept-Language": lang,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};



export const fetchWorkspaceTypes = async (lang) => {
  try {
    const response = await baseURL.get(`/api/v1/application/workspace-types`, {
      headers: {
        "Accept-Language": lang,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}