import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import workspaceAR from "./locales/ar/workspace.json";
import workspaceEN from "./locales/en/workspace.json";
import bookingsAR from "./locales/ar/bookings.json";
import bookingsEN from "./locales/en/bookings.json";
import conversationsAR from "./locales/ar/conversations.json";
import conversationsEN from "./locales/en/conversations.json";
import profileAR from "./locales/ar/profile.json";
import profileEN from "./locales/en/profile.json";
import homeAR from "./locales/ar/home.json";
import homeEN from "./locales/en/home.json";
import exploreAR from "./locales/ar/explore.json";
import exploreEN from "./locales/en/explore.json";
import loginAR from "./locales/ar/login.json";
import loginEN from "./locales/en/login.json";
import registerAR from "./locales/ar/register.json";
import registerEN from "./locales/en/register.json";
import supplierAR from "./locales/ar/supplier.json";
import supplierEN from "./locales/en/supplier.json";
import communityAR from "./locales/ar/community.json";
import communityEN from "./locales/en/community.json";
import termsAR from "./locales/ar/terms.json";
import termsEN from "./locales/en/terms.json";
import entitiesAR from "./locales/ar/entities.json";
import entitiesEN from "./locales/en/entities.json";

const resources = {
  en: {
    workspace: workspaceEN,
    bookings: bookingsEN,
    conversations: conversationsEN,
    profile: profileEN,
    home: homeEN,
    explore: exploreEN,
    login: loginEN,
    register: registerEN,
    community: communityEN,
    supplier: supplierEN,
    terms: termsEN,
    entities: entitiesEN,


  },
  ar: {
    workspace: workspaceAR,
    bookings: bookingsAR,
    conversations: conversationsAR,
    profile: profileAR,
    home: homeAR,
    explore: exploreAR,
    login: loginAR,
    register: registerAR,
    community: communityAR,
    supplier: supplierAR,
    terms: termsAR,
    entities: entitiesAR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  keySeparator: false,
  ns: [
    "workspace",
    "bookings",
    "conversations",
    "profile",
    "home",
    "login",
    "register",
    "community",
    "supplier",
    "terms",
    "entities",
  ],
  interpolation: {
    escapeValue: false,
  },

  lng: localStorage.getItem("language"),
  fallbackLng: "en",
});

export default i18n;
