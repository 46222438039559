import { useEffect } from "react";
//listen for keydown events and handle navigation between input fields.
const useInputNavigation = (inputsRef) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      const { key } = event;
      const activeElement = document.activeElement;
      const index = inputsRef.current.indexOf(activeElement);

      if (index !== -1) {
        // Handle backspace to move focus to the previous field if empty
        if (key === "Backspace" && index > 0 && !activeElement.value) {
          inputsRef.current[index - 1].focus();
        }
        // Handle arrow keys for navigation
        else if (key === "ArrowLeft" && index > 0) {
          inputsRef.current[index - 1].focus();
        } else if (
          key === "ArrowRight" &&
          index < inputsRef.current.length - 1
        ) {
          inputsRef.current[index + 1].focus();
        }
      }
    };

    // Attach event listener
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup listener on unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputsRef]);
};

export default useInputNavigation;
