function SuccessMessage({ setShowSuccess, title, subTitle, buttonText }) {
  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center bg-black bg-opacity-25">
      <div className="p-6 bg-white flex items-center  flex-col px-[1.5rem] py-[0.75rem] rounded-lg shadow-md w-[31.25rem] h-[20.5rem]">
        <div className="w-[9.375rem] h-[9.375rem] mb-4">
          <img src="/success.gif" alt="" className="w-full h-full" />
        </div>
        <h3 className="mb-2 text-lg font-semibold leading-[1.361rem]">
          {title}
        </h3>
        <p className="mb-6 text-base font-normal leading-[1.141rem] text-center text-[#6B7280]">
          {subTitle}
        </p>
        <button
          onClick={() => setShowSuccess(false)}
          className="bg-[#009E1E] w-[7.5rem] py-[0.875rem] px-5 text-xs font-medium text-white rounded-lg"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
}

export default SuccessMessage;
SuccessMessage.propTypes = null;
