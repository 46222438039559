import baseURL from "../../axios";

export const fetchSpaces = async (
  page,
  selectedWorkspaceTypes,
  selectedSpaceType,
  availableToDate,
  selectedFromDate,
  amenities,
  priceRange,
  selectedPlans,
  selectedRatings,
  selectedOption,
  instantBooking,
  searchCity,
  searchDistricts,
  language,
  mapView,
  myLocation,
  Nearby,
  selectedCapacity,
  selectedArea,
  HasPromotions
) => {
  const sendArea =
    selectedSpaceType === "long_term" || selectedSpaceType === "private_office";
  const sendEventCapacity = selectedSpaceType === "event_space";
  const sendRoomCapacity =
    selectedSpaceType === "meeting_room" ||
    selectedSpaceType === "private_office";
  try {
    const filters = {
      workspace: {
        types: selectedWorkspaceTypes,
        amenties: amenities,
        address: {
          city_id: searchCity || null,
          districts: searchDistricts,
        },
      },
      space: {
        type: selectedSpaceType || null,
        available: {
          to: availableToDate,
          from: selectedFromDate,
        },
        ratings: selectedRatings,

        sort: !mapView ? selectedOption : null,
        has_promo_code: {
          is_active: HasPromotions ? 1 : null,
        },
      },
    };
    if (myLocation && (Nearby || selectedOption?.by === "distance")) {
      filters.space.distance = {
        nearest: Nearby ? 1 : null,
        ...myLocation,
      };
    }
    if (selectedPlans) {
      filters.shift = {
        price: {
          from: priceRange[0],
          to: priceRange[1],
        },
      };

      filters.plan = {
        name: selectedPlans,
        instant_booking: instantBooking ? 1 : 0,
      };
    }
    if (sendArea) {
      filters.space.area = {
        from: selectedArea?.min,
        to: selectedArea?.max,
      };
    }
    if (sendEventCapacity && selectedCapacity?.isEvent) {
      filters.space.capacity = {
        from: selectedCapacity?.min,
        to: selectedCapacity?.max,
      };
    }
    if (sendRoomCapacity && !selectedCapacity?.isEvent) {
      filters.space.capacity = {
        from: selectedCapacity?.min,
        to: selectedCapacity?.max,
      };
    }

    const response = await baseURL.get(
      `/api/v1/application/spaces`,
      {
        params: {
          page: page,
          per_page: mapView ? 100 : 5,
          filters: filters,
        },
        headers: {
          "Accept-Language": language,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching spaces:", error);
    return error.response;
  }
};
// fetching show space
export const fetchSpaceDetails = async (id, lang = "en") => {
  try {
    const response = await baseURL.get(
      `/api/v1/application/spaces/${id}`,
      {
        headers: {
          "Accept-Language": lang,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching workspaces:", error);
  }
};

// fetching workspace details

export const fetchWorkspaceDetails = async (id) => {
  try {
    const response = await baseURL.get(
      `/api/v1/application/workspaces/${id}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching workspaces:", error);
    return error;
  }
};

// get plans {{base_url}}/api/v1/application/plans
export const fetchPlans = async (language) => {
  try {
    const response = await baseURL.get(
      `/api/v1/application/plans`,
      {
        headers: {
          "Accept-Language": language,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching plans:", error);
    return error;
  }
};
//{{base_url}}/api/v1/application/spaces/:spaceId/resources

export const fetchSpaceResources = async (id, lang) => {
  console.log(id);
  try {
    const response = await baseURL.get(
      `/api/v1/application/spaces/${id}/resources`,
      {
        headers: {
          "Accept-Language": lang,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching workspaces:", error);
  }
};
