import { useTranslation } from "react-i18next";
import {
  areaOptions,
  eventCapacityOptions,
  roomCapacityOptions,
} from "./DataRanges";

const RadioFilters = ({
  selectedSpaceType,
  selectedArea,
  setSelectedArea,
  selectedCapacity,
  setSelectedCapacity,
}) => {
  const { t } = useTranslation("explore");

  const showArea =
    selectedSpaceType === "long_term" || selectedSpaceType === "private_office";
  const showEventCapacity = selectedSpaceType === "event_space";
  const showRoomCapacity =
    selectedSpaceType === "meeting_room" ||
    selectedSpaceType === "private_office";

  const handleAreaChange = (option) => {
    if (selectedArea?.min === option.min) {
      setSelectedArea(null); // Deselect if already selected
    } else {
      setSelectedArea({ min: option.min, max: option.max });
    }
  };

  const handleCapacityChange = (option, isEvent) => {
    if (selectedCapacity?.min === option.min) {
      setSelectedCapacity(null); // Deselect if already selected
    } else {
      setSelectedCapacity({
        min: option.min,
        max: option.max,
        isEvent: isEvent,
      });
    }
  };

  return (
    <div className="space-y-6">
      {showArea && (
        <div className="mb-6">
          <h3 className="flex items-center gap-2 mb-2 text-lg font-medium">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_9190_67770)">
                <path
                  d="M14.191 0H5.81098C2.17098 0 0.000976562 2.17 0.000976562 5.81V14.18C0.000976562 17.83 2.17098 20 5.81098 20H14.181C17.821 20 19.991 17.83 19.991 14.19V5.81C20.001 2.17 17.831 0 14.191 0ZM16.751 8C16.751 8.41 16.411 8.75 16.001 8.75C15.591 8.75 15.251 8.41 15.251 8V5.81L5.81098 15.25H8.00098C8.41098 15.25 8.75098 15.59 8.75098 16C8.75098 16.41 8.41098 16.75 8.00098 16.75H4.00098C3.90098 16.75 3.80098 16.73 3.71098 16.69C3.53098 16.61 3.38098 16.47 3.30098 16.28C3.27098 16.19 3.25098 16.1 3.25098 16V12C3.25098 11.59 3.59098 11.25 4.00098 11.25C4.41098 11.25 4.75098 11.59 4.75098 12V14.19L14.191 4.75H12.001C11.591 4.75 11.251 4.41 11.251 4C11.251 3.59 11.591 3.25 12.001 3.25H16.001C16.101 3.25 16.191 3.27 16.291 3.31C16.471 3.39 16.621 3.53 16.701 3.72C16.731 3.81 16.751 3.9 16.751 4V8Z"
                  fill="#252C34"
                />
              </g>
              <defs>
                <clipPath id="clip0_9190_67770">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {t("Area")}
          </h3>
          <div className="flex flex-col space-y-2 ps-5">
            {areaOptions.map((option) => (
              <label key={option.min} className="inline-flex items-center py-1">
                <input
                  type="checkbox"
                  name="area"
                  checked={selectedArea?.min === option.min}
                  onChange={() => handleAreaChange(option)}
                  className="form-checkbox"
                />
                <span className="ltr:ml-2 rtl:mr-2">{t(option.label)}</span>
              </label>
            ))}
          </div>
        </div>
      )}

      {(showEventCapacity || showRoomCapacity) && (
        <div className="mb-6">
          <h3 className="flex items-center gap-2 mb-2 text-lg font-medium">
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.9581 10.5622C16.9429 11.1014 17.5054 12.0622 17.4349 13.0229C17.3876 13.6087 17.3644 13.6327 16.6144 13.7264C16.1456 13.7969 13.1921 13.8202 10.1456 13.8202C6.70014 13.8202 3.09114 13.7497 2.83314 13.6792C1.80189 13.4212 2.36439 11.5462 3.72414 10.6559C4.77864 9.97645 6.93489 8.9452 7.52139 8.82745C8.31864 8.6632 8.41239 8.1712 7.52139 6.57745C7.31064 6.2257 7.07589 5.14795 7.05264 4.02295C7.02939 2.19445 7.38114 0.952449 8.97489 0.343449C9.30339 0.226449 9.63114 0.179199 9.93564 0.179199C10.9901 0.179199 11.9749 0.764949 12.3731 1.6327C12.9589 2.78095 12.7249 5.85145 12.1151 6.9532C11.4116 8.1952 11.4821 8.5942 12.2561 8.80495C12.7721 8.94595 14.3659 9.69595 15.9596 10.5629L15.9581 10.5622ZM3.20814 9.85945C2.83314 10.1174 2.48139 10.4452 2.20014 10.7737C1.30914 10.7737 0.629638 10.7504 0.559138 10.7264C-0.0266123 10.5854 0.301138 9.5077 1.07514 9.03895C1.66089 8.66395 2.85639 8.0782 3.18489 8.0077C3.60714 7.91395 3.70089 7.65595 3.18489 6.7657C3.06789 6.5782 2.92689 5.96845 2.92689 5.3362C2.90364 4.30495 3.11439 3.60145 4.00539 3.29695C4.75539 3.0157 5.59914 3.36745 5.90364 4.00045C6.20814 4.6567 6.04464 6.3442 5.71614 6.95395C5.34114 7.6807 5.41164 7.8682 5.83314 7.9852C5.92689 8.00845 6.11439 8.1022 6.37239 8.21995C5.34114 8.6422 3.93489 9.41545 3.20814 9.86095V9.85945ZM18.6304 8.96845C19.1929 9.27295 19.4974 9.78895 19.4509 10.3282C19.4276 10.6567 19.4276 10.6799 19.0054 10.7264C18.8884 10.7497 18.3491 10.7737 17.6456 10.7737C17.3411 10.3754 16.9189 10.0004 16.4036 9.74245C15.4429 9.17995 14.2706 8.5702 13.4036 8.1952C13.6384 8.10145 13.8491 8.03095 13.9429 8.0077C14.3884 7.91395 14.4589 7.65595 13.9429 6.7657C13.8491 6.5782 13.6849 5.96845 13.6849 5.3362C13.6616 4.30495 13.8491 3.60145 14.7394 3.29695C15.5126 3.0157 16.3564 3.36745 16.6376 4.00045C16.9421 4.6567 16.8251 6.3442 16.4966 6.95395C16.1216 7.6807 16.1681 7.8682 16.5671 7.9852C16.8484 8.07895 17.7394 8.5012 18.6296 8.96995L18.6304 8.96845Z"
                fill="#252C34"
              />
            </svg>
            {t("Capacity")}
          </h3>
          <div className="flex flex-col space-y-2 ps-5">
            {(showEventCapacity
              ? eventCapacityOptions
              : roomCapacityOptions
            ).map((option) => (
              <label key={option.min} className="inline-flex items-center py-1">
                <input
                  type="checkbox"
                  name="capacity"
                  checked={selectedCapacity?.min === option.min}
                  onChange={() =>
                    handleCapacityChange(option, showEventCapacity)
                  }
                  className="form-checkbox"
                />
                <span className="ltr:ml-2 rtl:mr-2">{t(option.label)}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RadioFilters;
