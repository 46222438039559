/* eslint-disable react/prop-types */
import "./Verification.css";
import { verficationValidation } from "../registrationValidation";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { verify_OTP_ID } from "../../../data_featching/registeration";
import { useTranslation } from "react-i18next";
import useFocusOnMount from "../../../hooks/useFocusOnMount";
import useInputNavigation from "../../../hooks/useInputNavigation";
import useVerificationInput from "../../../hooks/useVerificationInput";
const Verification = ({
  verificationNum,
  setVerificationNum,
  setShowSetPassword,
  setShowVerificationPhase,
  sendOtp,
  VERIFIATCAION_ID,
  VERIFIATCAION_CODE,
  setVERIFIATCAION_CODE,
}) => {
  const [secTimer, setSecTimer] = useState(59);
  const [minTimer, setMinTimer] = useState(1);
  const [minTimerShow, setMinTimerShow] = useState("01");

  const [disableHint, setDisableHint] = useState(true);
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [showVerificationError, setShowVerificationError] = useState("");
  const inputsRef = useRef([]);
  /*handle change the input field of the verfication code */
  const { handleInputChange } = useVerificationInput(
    inputsRef,
    setVerificationNum
  );
  // Call the custom hook to handle focusing the first input
  useFocusOnMount(inputsRef);
  //listen for keydown events and handle navigation between input fields.
  useInputNavigation(inputsRef);
  const { t } = useTranslation("register");
  async function HandelVerificationSubmit() {
    const verificationInfo = new FormData();
    /**for registeration */
    /**for registeration */
    verificationInfo.append("verification_id", VERIFIATCAION_ID);
    verificationInfo.append("verification_code", VERIFIATCAION_CODE);
    console.log("VERIFIATCAION_ID", VERIFIATCAION_ID);
    console.log("VERIFIATCAION_CODE", VERIFIATCAION_CODE);
    console.log("VERIFIATCAION_CODE type of ", typeof VERIFIATCAION_CODE);
    const result = await verify_OTP_ID(verificationInfo);
    if (result.status == 200 || result.status == 201) {
      setShowSetPassword(true);
      setShowVerificationPhase(false);
      console.log("verificationNum", verificationNum);
    } else if (result.status !== 200 || result.status !== 201) {
      setShowVerificationError("Verificatiion code isn`t correct");
    }

    /**for Reset_password */
    /**for Reset_password */
    // if (callerAction == "Reset_password") {
    //   const result = await verify_OTP_ID_reset_password(verificationInfo);
    //   if (result.status == 200 || result.status == 201) {
    //     setShowSetPassword(true);
    //     setShowVerificationPhase(false);
    //     console.log("verificationNum", verificationNum);
    //   } else if (result.status !== 200 || result.status !== 201) {
    //     setShowVerificationError("Verificatiion code isn`t correct");
    //   }
    // }
  }

  const concatenateAndConvertToNumber = (obj) => {
    const { code1, code2, code3, code4, code5, code6 } = obj;
    const concatenatedString = `${code1}${code2}${code3}${code4}${code5}${code6}`;
    setVERIFIATCAION_CODE(Number(concatenatedString));
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setSecTimer((prevSec) => {
        if (prevSec > 0) {
          return prevSec - 1;
        } else {
          if (minTimer > 0) {
            setMinTimer((prevMin) => prevMin - 1);
            setMinTimerShow("00");
            return 59;
          } else {
            clearInterval(interval);
            setDisableHint(false);
            return 0;
          }
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [minTimer]);

  useEffect(() => {
    const verificationCodeResult = verficationValidation(verificationNum);
    console.log("verificationCodeResult", verificationCodeResult);
    if (verificationCodeResult) {
      setShowVerifyButton(true);
    } else {
      setShowVerifyButton(false);
    }
    concatenateAndConvertToNumber(verificationNum);
  }, [
    verificationNum.code1,
    verificationNum.code2,
    verificationNum.code3,
    verificationNum.code4,
    verificationNum.code5,
    verificationNum.code6,
  ]);

  useLayoutEffect(() => {
    if (showVerificationError) {
      let timer = setTimeout(() => {
        setShowVerificationError("");
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showVerificationError]);
  console.log("code", verificationNum);
  // const handleInputChange = (event, field, index) => {
  //   const value = event.target.value.slice(-1);

  //   setVerificationNum((prev) => ({
  //     ...prev,
  //     [field]: value,
  //   }));

  //   // Automatically focus the next input
  //   if (value && index < inputsRef.current.length - 1) {
  //     inputsRef.current[index + 1].focus();
  //   }
  // };
  return (
    <div className="personal_main_container">
      <div className="w-full Header_register_phase">
        <h1>{t("create")}</h1>
        <div className="flex gap-1.5 items-start">
          <div className="phase Active_phase"></div>
          <div className="phase Active_phase"></div>
          <div className="phase"></div>
        </div>
      </div>
      <form
        className="flex flex-col justify-start w-full gap-8 personal_registerForm"
        onSubmit={(event) => {
          event.preventDefault();
          if (verficationValidation(verificationNum)) {
            /*here there are a check with API to validate the sent SMS and  */
            /*verification id which recieved */
            HandelVerificationSubmit();
          }
        }}
      >
        <div className="flex flex-col items-center w-full gap-6 py-4 verif_code_container">
          <h1 htmlFor="" className="name_input_text">
            {t("enter verification")}
          </h1>
          <div className="flex flex-col items-center w-full gap-25 input_Ver_code_container">
            <div className="flex-col code_error_container">
              {showVerificationError && (
                <div className="error_verify">{showVerificationError}</div>
              )}
              <div className="code_input">
                {Object.keys(verificationNum).map((field, index) => (
                  <input
                    key={field}
                    ref={(el) => (inputsRef.current[index] = el)}
                    type="number"
                    className="code_field cursor-pointer"
                    maxLength="1"
                    value={verificationNum[field]}
                    onChange={(event) => handleInputChange(event, field, index)}
                    // onKeyDown={(event) => handleKeyDown(event, index)}
                  />
                ))}
              </div>
            </div>
            {disableHint ? (
              <div className="code_hint">
                <p className="code_timer_hint">{t("getting code")}</p>
                <p className="code_timer_hint">
                  {minTimerShow} {String(secTimer).padStart(2, "0")}
                </p>
              </div>
            ) : (
              <div
                className="flex hint_container code_hint  cursor-pointer"
                onClick={() => {
                  sendOtp(); /*resend OTP request */
                  setVerificationNum({
                    code1: "",
                    code2: "",
                    code3: "",
                    code4: "",
                    code5: "",
                    code6: "",
                  }); /*reset verification field to write the recieved OTP again */
                  setDisableHint(true);
                  setSecTimer(59);
                  setMinTimer(1);
                  setMinTimerShow("01");
                }}
              >
                <p className="code_timer_hint">{t("resend")}</p>
                <div className="resend_img">
                  <img src="/resend.png" alt="" />
                  {/* <svg
                    className="svg1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="13"
                    viewBox="0 0 11 13"
                    fill="none"
                  >
                    <path
                      d="M4.66683 6L7.3335 3.33333L4.66683 0.666667"
                      stroke="#6B7280"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M1.95871 10.3337C1.54913 9.62424 1.3335 8.8195 1.3335 8.00033C1.3335 7.18115 1.54913 6.37642 1.95871 5.66699C2.3683 4.95757 2.95741 4.36846 3.66683 3.95887C4.37625 3.54929 5.18099 3.33366 6.00016 3.33366"
                      stroke="#6B7280"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                  <svg
                    className="svg2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="14"
                    viewBox="0 0 11 14"
                    fill="none"
                  >
                    <path
                      d="M6.33317 7L3.6665 9.66667L6.33317 12.3333"
                      stroke="#6B7280"
                      strokeWidth="2"
                    />
                    <path
                      d="M9.04129 2.66634C9.45087 3.37576 9.6665 4.1805 9.6665 4.99967C9.6665 5.81885 9.45087 6.62358 9.04129 7.33301C8.6317 8.04243 8.04259 8.63154 7.33317 9.04113C6.62375 9.45071 5.81901 9.66634 4.99984 9.66634"
                      stroke="#6B7280"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg> */}
                </div>
              </div>
            )}
          </div>
        </div>

        <button
          className="w-full next_button"
          style={showVerifyButton ? { backgroundColor: "#009e1e" } : null}
        >
          <span className="flex items-center gap-2">
            {t("verify")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M10.5 15.5L15.5 10.5L10.5 5.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M5.5 15.5L10.5 10.5L5.5 5.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </span>
        </button>
      </form>
    </div>
  );
};

export default Verification;
