/* eslint-disable react/prop-types */
import "./WorkeyWelcom.css";
const WorkeyWelcom = ({ wlcomText }) => {
  return (
    <div className="welcom_container">
      <h1>{wlcomText}</h1>
      <img src="/workeyLogo.png" alt="" />
    </div>
  );
};

export default WorkeyWelcom;
