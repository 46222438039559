/* eslint-disable react/prop-types */
import { useEffect, useLayoutEffect, useState } from "react";
import "./ResetPassword.css";
import { useNavigate } from "react-router-dom";
import WorkeyWelcom from "../../../ui/workey_Welcom/WorkeyWelcom";
import { resetPasswordDataRequest } from "../../../../data_featching/forgetPassword";

const ResetPassword = ({
  setShowVerificationPhase,
  password,
  setPassword,
  setShowSetPassword,
  VERIFIATCAION_CODE,
  VERIFIATCAION_ID,
  emailInput,
}) => {
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });

  const [passwordStrength, setPasswordStrength] = useState(0);
  const [confirm, setConfirm] = useState("");
  const [match, setMatch] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  /*set show welcom workey */
  const [showWorkeyWelcom, setShowWorkeyWelcom] = useState({
    show: false,
    text: "",
    link: "",
  });

  const navigate = useNavigate();

  console.log("password", password);
  console.log("confirm", confirm);
  console.log("match", match);
  console.log("confirmType", typeof confirm);

  function checkPasswordCMatching(passwordInput, confirmInput) {
    if (password !== "" && confirmInput !== "") {
      if (passwordInput == confirmInput) {
        setMatch(true);
        return true;
      } else {
        setMatch(false);
        return false;
      }
    } else {
      setMatch(false);
      return false;
    }
  }
  /*check password matching */
  /*check password matching */
  useEffect(() => {
    if (checkPasswordCMatching(password, confirm) && confirm && password) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  }, [confirm, password]);

  /*check password strength */
  /*check password strength */
  useEffect(() => {
    const checkStrength = (password) => {
      let strength = 0;
      if (password?.length >= 8) strength++;
      if (/[A-Z]/.test(password)) strength++;
      if (/[0-9]/.test(password)) strength++;
      if (/[^A-Za-z0-9]/.test(password)) strength++;
      setPasswordStrength(strength);
    };
    if (password) {
      checkStrength(password);
    } else {
      setPasswordStrength(0);
    }
  }, [password]);
  /*Activate create account Button */
  useEffect(() => {
    if (match) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [match]);
  /* last stage of resetPwd */
  /* last stage of resetPwd */
  async function resetPwd() {
    const resetPwdInfo = {
      identifier: emailInput,
      password: password,
      verification_id: VERIFIATCAION_ID,
      verification_code: `${VERIFIATCAION_CODE}`,
    };
    console.log("resetPwdInfo", resetPwdInfo);

    const result = await resetPasswordDataRequest(resetPwdInfo);
    if (result.status == 200 || result.status == 201) {
      console.log("result", result);
      setShowWorkeyWelcom({
        show: true,
        text: "Welcome back to",
        link: "/login",
      });
    } else if (result.status !== 200 || result.status !== 201) {
      const currentErrorMessage = result.data.errors.password[0];
      console.log("currentErrorMessage", currentErrorMessage);
      setShowPasswordError(currentErrorMessage);
    }
  }
  useLayoutEffect(() => {
    if (showPasswordError) {
      let timer;
      timer = setTimeout(() => {
        setShowPasswordError("");
      }, 4000);
      return () => {
        clearTimeout(timer);
      };
    }
    if (showWorkeyWelcom.show) {
      let timer;

      timer = setTimeout(() => {
        setShowWorkeyWelcom({ show: false, text: "", link: "" });
        setShowVerificationPhase(false);
        setShowSetPassword(false);
        /* redirect the page to the page of login home page*/
        navigate("/login");
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showWorkeyWelcom.show, showPasswordError]);

  {
    return !showWorkeyWelcom.show ? (
      <div className="personal_main_container sm:mx-0 xl:mx-[7rem]  sm:w-full xl:w-[36rem]">
        <div className="w-full Header_register_phase">
          <h1> Set new password</h1>
          <div className="flex gap-1.5 items-start">
            <div className="phase Active_phase"></div>
            <div className="phase Active_phase"></div>
            <div className="phase Active_phase"></div>
          </div>
        </div>
        <form
          className="flex flex-col justify-start w-full gap-8 personal_registerForm"
          onSubmit={(event) => {
            event.preventDefault();
            if (showButton) {
              resetPwd();
            }
          }}
        >
          <div className="flex flex-col items-center w-full gap-6">
            <div className="flex flex-col w-full gap-2">
              <label htmlFor="" className="name_input_text">
                Password
              </label>
              <div className="setPassword_input ">
                <img src="/lock-closed-black.png" alt="" />
                <input
                  type={showPassword.password ? "text" : "password"}
                  onChange={(e) =>
                    setPassword(() => {
                      const update = e.target.value;
                      // checkPasswordCMatching(update, confirm);
                      return update;
                    })
                  }
                  value={password}
                />
                <img
                  src={!showPassword.password ? "/eye-off.png" : "/Eye.png"}
                  alt="eye-off"
                  onClick={() =>
                    setShowPassword((prev) => {
                      return { ...prev, password: !prev.password };
                    })
                  }
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="flex items-center gap-1">
                <div
                  className={
                    passwordStrength > 0
                      ? "passwordPower power_flag"
                      : "passwordPower"
                  }
                ></div>
                <div
                  className={
                    passwordStrength >= 2
                      ? "passwordPower power_flag"
                      : "passwordPower"
                  }
                ></div>
                <div
                  className={
                    passwordStrength >= 3
                      ? "passwordPower power_flag"
                      : "passwordPower"
                  }
                ></div>
                <div
                  className={
                    passwordStrength >= 4
                      ? "passwordPower power_flag"
                      : "passwordPower"
                  }
                ></div>
              </div>
            </div>
            <div className="flex flex-col w-full gap-2">
              <label htmlFor="" className="name_input_text">
                Confirm Password
              </label>
              <div className="setPassword_input">
                <img src="/lock-closed-black.png" alt="" />
                <input
                  type={showPassword.confirm ? "text" : "password"}
                  onChange={(e) => {
                    setConfirm(() => {
                      const update = e.target.value;
                      // checkPasswordCMatching(password, update);
                      return update;
                    });
                  }}
                  value={confirm}
                />
                <img
                  src={!showPassword.confirm ? "/eye-off.png" : "/Eye.png"}
                  alt="eye-off"
                  onClick={() =>
                    setShowPassword((prev) => {
                      return { ...prev, confirm: !prev.confirm };
                    })
                  }
                  style={{ cursor: "pointer" }}
                />
              </div>
              {password && confirm && (
                <div
                  className={
                    match
                      ? "confirm_password_match"
                      : "confirm_password_match miss_match"
                  }
                >
                  {match ? " Password match" : "Password mismatch"}
                </div>
              )}
              {showPasswordError && (
                <div className="error_Password">{showPasswordError}</div>
              )}
            </div>
          </div>

          <button
            className="w-full next_button"
            style={showButton ? { backgroundColor: "#009e1e" } : null}
          >
            <span className="flex items-center gap-2">
              Confirm
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M10.5 15.5L15.5 10.5L10.5 5.5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M5.5 15.5L10.5 10.5L5.5 5.5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </span>
          </button>
        </form>
      </div>
    ) : (
      <WorkeyWelcom wlcomText={showWorkeyWelcom.text} />
    );
  }
};

export default ResetPassword;
