import "react-international-phone/style.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import { useContext, useEffect } from "react";
import "../../../index.css";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../../context/LanguageProvider";
import ar from "react-phone-number-input/locale/ar";
import en from "react-phone-number-input/locale/en";

const phoneUtil = PhoneNumberUtil.getInstance();

const flags = {
  SA: () => <img src="/KSA.png" alt="Saudi Arabia Flag" />,
};

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

function FirstStep({
  phoneInput,
  setPhoneInput,
  setIsValidPhone,
  isValidPhone,
  isClicked,
  stepOneError,
}) {
  const isValid = isPhoneValid(phoneInput);
  const { t } = useTranslation("profile");
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    setIsValidPhone(isValid);
  }, [isValid]);

  return (
    <>
      <div className="flex">
        <label
          className={`flex mb-2 ${
            language === "ar" ? "self-start" : "self-end"
          }`}
        >
          {t("Phone number")}
        </label>
      </div>
      <div dir="ltr">
        <PhoneInput
          international={false}
          defaultCountry="SA"
          value={phoneInput}
          onChange={(phone) => {
            setPhoneInput(phone);
          }}
          flags={flags}
          labels={language == "en" ? en : ar}
          // className={`${
          //   !isValidPhone ? "border border-red-500 rounded-lg" : ""
          // }`}
        />
      </div>

      {isClicked && !isValidPhone && (
        <p className="mb-2 text-sm text-red-400">{t("enter valid phone")}</p>
      )}
      <p className="text-sm font-normal text-center text-[#6B7280] mt-8">
        {t("we will send to number")}
      </p>
      {stepOneError && (
        <p className="mb-2 text-sm text-red-400">{stepOneError}</p>
      )}
    </>
  );
}

export default FirstStep;

FirstStep.propTypes = null;
