import baseURL from "../../axios";

/*get workspaces types */
let isRequestInProgress = false;

export async function getAllRatingsForSpace(token, spaceId) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }
  isRequestInProgress = true;

  const endPoint = `/api/v1/application/ratings/${spaceId}`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: {
        Authorization: `Bearer ${token}`, // Ensure correct prefix for token
      },
    });
    isRequestInProgress = false;

    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error?.response?.status !== 200) {
      isRequestInProgress = false;

      return error?.response;
    }
  }
}
export async function getAllReviewsForSpace(
  token,
  spaceId,
  ratingSelection,
  sortBy,
  pageNO,
  pageLimit
) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }
  isRequestInProgress = true;

  const endPoint = `/api/v1/application/reviews/${spaceId}?filters[sort]=${sortBy}&${ratingSelection}&per_page=${pageLimit}&page=${pageNO}`; //
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: {
        Authorization: `Bearer ${token}`, // Ensure correct prefix for token
      },
    });
    isRequestInProgress = false;

    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error?.response?.status !== 200) {
      isRequestInProgress = false;

      return error?.response;
    }
  }
}
// {{base_url}}/api/v1/host/reviews/:space-id get all reviews for space without filters

export async function getAllReviewsForSpaceWithoutFilters(
  token,
  spaceId,
  lang = "en"
) {
  const endPoint = `/api/v1/application/reviews/${spaceId}`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": lang,
      },
    });
    console.log("getAllReviewsForSpaceWithoutFilters", result.data);
    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error?.response?.status !== 200) {
      return error?.response;
    }
  }
}
