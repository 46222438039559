import Slider from "react-slick";
import { GALARY_PHOTO } from "./Data";
import { useRef, useState } from "react";

const GallerySlider = () => {
  const [isPause, setIsPause] = useState(false);
  let sliderRef = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1, // Adjust this based on your design
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    centerPadding: "15%", // Adjust padding between slides
    autoplaySpeed: 3000,
    prevArrow: <></>, // Custom empty component for previous arrow
    nextArrow: <></>, // Custom empty component for next arrow
    focusOnSelect: false,
    pauseOnDotsHover: false, //Prevents autoplay while hovering on dots
    pauseOnHover: true, //Prevents autoplay while hovering on track
    // Slide by click on  next/prev image
    

    // draggable: true,
    // customPaging: (i) => (
    //   <div
    //     style={{
    //       width: "12px",
    //       height: "12px",
    //       background: i === 0 ? "#ffffff" : "#000000", // Custom dot color
    //       borderRadius: "50%",
    //       cursor: "pointer",
    //     }}
    //   />
    // ),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 900,
        settings: {
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 600,
        settings: {
          // initialSlide: 2,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "5%",
        },
      },
    ],
  };
  function handelPauseClick() {
    setIsPause((prev) => {
      const updatedPause = !prev;
      if (updatedPause) {
        sliderRef.current.slickPause();
      } else {
        sliderRef.current.slickPlay();
      }
      return updatedPause;
    });
  }
  return (
    <div className="photo_Gallery_container">
      <div className="gallery-slider">
        <Slider {...settings} ref={sliderRef}>
          {GALARY_PHOTO.map((photo) => (
            <div key={photo.id} className="gallery-slide">
              <img
                src={`${photo.src}`}
                alt={`Photo ${photo.id}`}
                style={{ height: "100%" }}
              />
            </div>
          ))}
        </Slider>
        {/* <div
          className="pasue-circul"
          style={
            isPause
              ? { backgroundImage: "url(/play_circle.png)" }
              : { backgroundImage: "url(/pause_circle.png)" }
          }
          onClick={handelPauseClick}
        ></div> */}
      </div>
    </div>
  );
};

export default GallerySlider;
