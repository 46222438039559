import { useEffect, useState } from "react";
import LoginNavBar from "../ui/login-nav_bar/LoginNavBar";
import "./Register.css";
// import CompanyRegister from "./company_register/CompanyRegister";
import PersonalRegister from "./personal_register/personalRegister";
import { useTranslation } from "react-i18next";

const Register = () => {
  const [personalAccount, setPersonalAccount] = useState(false);
  const [companyAccount, setCompanyAccount] = useState(false);
  const { t } = useTranslation("register");
  const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showRegister, setshowRegister] = useState({
    active: false,
    personal: false,
    company: false,
  });

  useEffect(() => {
    let timer;
    if (personalAccount) {
      setCompanyAccount(false);
      timer = setTimeout(() => {
        setshowRegister((prev) => {
          return { ...prev, personal: true, active: true };
        });
      }, 300);
    }
    return () => clearTimeout(timer);
  }, [personalAccount]);
  useEffect(() => {
    let timer;
    if (companyAccount) {
      setPersonalAccount(false);

      timer = setTimeout(() => {
        setshowRegister((prev) => {
          return {
            ...prev,
            company: true,
            active: true,
          };
        });
      }, 300);
    }
    return () => clearTimeout(timer);
  }, [companyAccount]);
  return (
    <>
      <div className="Login_container">
        <LoginNavBar
          loginNav={false}
          showBurgerLoginMenu={showBurgerLoginMenu}
          setShowBurgerLoginMenu={setShowBurgerLoginMenu}
        />

        {!showBurgerLoginMenu && (
          <>
            <div className="register-container px-3 min-h-[calc(100vh-8.083rem)]  max-h-fit pt-16 pb-8 md:px-[3.5rem] lg:px-[4.375rem] xl:px-0">
              {/* <div className="chat_plus">
                <img src="/Chat_plus.png" alt="Chat_plus" />
              </div> */}

              {!showRegister.active && (
                <div className="register_form_container sm:mx-0 xl:mx-[7rem]  sm:w-full xl:w-[36rem]">
                  <h1 className="register_header">{t("select type")}</h1>
                  <div className="Select_account_container">
                    <div
                      className="justify-between gap-3 personal cursor-pointer"
                      onClick={() => {
                        setPersonalAccount((prev) => !prev);
                      }}
                    >
                      <div className="flex items-center gap-3">
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          // onClick={() => {
                          //   setPersonalAccount((prev) => !prev);
                          // }}
                        >
                          <circle
                            cx="14"
                            cy="14"
                            r="5.75" // Slightly smaller radius for the fill circle to create padding
                            fill={personalAccount ? "#252C34" : "none"}
                          />
                          <circle
                            cx="14"
                            cy="14"
                            r="7.25"
                            stroke="#89D98B"
                            strokeWidth="1.5"
                            fill="none"
                          />
                        </svg>
                        <span>{t("personal")}</span>
                      </div>
                      <img src="/User_alt_fill.png" alt="User_alt_fill" />
                    </div>
                    <div
                      className="justify-between gap-3 company cursor-pointer"
                      onClick={() => {
                        setCompanyAccount((prev) => !prev);
                      }}
                    >
                      <div className="flex items-center gap-3 ">
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          // onClick={() => {
                          //   setCompanyAccount((prev) => !prev);
                          // }}
                        >
                          <circle
                            cx="14"
                            cy="14"
                            r="5.75" // Slightly smaller radius for the fill circle to create padding
                            fill={companyAccount ? "#252C34" : "none"}
                          />
                          <circle
                            cx="14"
                            cy="14"
                            r="7.25"
                            stroke="#89D98B"
                            strokeWidth="1.5"
                            fill="none"
                          />
                        </svg>
                        <span>{t("company")}</span>
                      </div>
                      <img src="/company_account.png" alt="User_alt_fill" />
                    </div>
                  </div>
                </div>
              )}
              {showRegister.personal && (
                <PersonalRegister profileType="personal" />
              )}
              {showRegister.company && (
                <PersonalRegister profileType={"business"} />
              )}
              <div className="mt-6 text-center right_reserved_hint_login xl:ltr:ml-auto xl:rtl:mr-auto xl:ltr:pr-28 xl:rtl:pl-28">
                {t("copy_rights")}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Register;
