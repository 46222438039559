import baseURL from "../../axios";

/*personal and company registeration flow requests with Api */
/*the difference between two accounts depend on property profilType */
/*profileType either personal or business */

/*first form validation name email phone */
export async function registrationValidationOnly(formInfo) {
  const endPoint = `/api/v1/user/step-one-validation`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, formInfo);
    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      return error.response;
    }
  }
}
/*request an OTP to verify mobile number */
/*recieve verification code id  */
let isRequestInProgress = false;
export async function sendRegistrationOtp(phoneNum) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }

  isRequestInProgress = true;

  const endPoint = `/api/v1/user/verification`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, phoneNum);
    isRequestInProgress = false;
    return result;
  } catch (error) {
    isRequestInProgress = false;

    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      return error.response;
    }
  }
}

/*verify OTP + verification id */
export async function verify_OTP_ID(verificationInfo) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }
  isRequestInProgress = true;
  const endPoint = `/api/v1/user/verification/verify`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, verificationInfo);
    console.log("reselt inside  function", result);
    isRequestInProgress = false;
    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      isRequestInProgress = false;

      return error.response;
    }
  }
}

/*send registeration final phase (all info) */
export async function registerationDataRequest(registerationData) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }
  isRequestInProgress = true;

  const endPoint = `/api/v1/user`;
  const url = `${endPoint}`;
  try {
    const result = await baseURL.post(url, registerationData);
    console.log("reselt inside  function", result);
    isRequestInProgress = false;

    // console.log("registerationData", registerationData);
    for (let [key, value] of registerationData.entries()) {
      console.log(`${key}: ${value}`);
    }
    return result;
  } catch (error) {
    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      isRequestInProgress = false;

      return error.response;
    }
  }
}
